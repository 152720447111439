/* eslint-disable multiline-ternary */
// ** React Imports
import { Fragment, useEffect } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";

// ** Third Party Components
import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addMonitaringStage,
  updateMonitaringStage,
} from "../../../services/adminDashboard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../common/ErrorMessage";

const AddMonitoringStageData = yup.object().shape({
  stage: yup
    .string()
    .required("Enter Monitoring stage")
    .max(50, "Monitoring stage should not exceed 50 characters")
    .matches(/^[a-z\s]+$/i, "Monitoring stage should be alphabet only.")
    .typeError(""),
});

const AddMonitoringStage = ({
  monitarinstageModal,
  setMonitarinstageModal,
}) => {
  const navigate = useNavigate();
  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddMonitoringStageData),
  });

  const onSubmit = (data) => {
    if (monitarinstageModal?.editstage) {
      const sendData = {
        stage: data.stage,
        id: monitarinstageModal?.data?.id,
      };
      updateMonitaringStage(sendData)
        .then((res) => {
          toast.success(res?.message);
          setMonitarinstageModal({
            isOpen: false,
            data: "",
          });
          navigate("/sa/monitoring-stage");
        })
        .catch((err) => getErrorMsg(err));
    } else {
      addMonitaringStage(data)
        .then((res) => {
          toast.success(res?.message);
          setMonitarinstageModal({
            isOpen: false,
            data: "",
          });
          navigate("/sa/monitoring-stage");
        })
        .catch((err) => getErrorMsg(err));
    }
  };

  useEffect(() => {
    if (monitarinstageModal?.data?.stage) {
      reset({
        stage: monitarinstageModal?.data?.stage
          ? monitarinstageModal?.data?.stage
          : "",
      });
    }

    return () => {
      reset({ stage: "" });
    };
  }, [monitarinstageModal?.data?.stage]);

  return (
    <Fragment>
      <Modal
        isOpen={monitarinstageModal?.isOpen}
        toggle={() =>
          setMonitarinstageModal({
            isOpen: false,
            data: "",
          })
        }
        className="modal-dialog-centered modal-css"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() =>
            setMonitarinstageModal({
              isOpen: false,
              data: "",
            })
          }
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">
              {`${
                monitarinstageModal?.editstage
                  ? "Edit Monitoring Stage"
                  : "Add Monitoring Stage"
              }
              `}
            </h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-1">
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="stage">
                  Monitoring Stage
                </Label>
                <Controller
                  control={control}
                  name="stage"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="stage"
                        placeholder="Enter Monitoring Stage"
                        invalid={errors.stage && true}
                      />
                    );
                  }}
                />
                {errors.stage && (
                  <FormFeedback>{errors.stage.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="modal-footer mt-0">
                <Button
                  className="btn btn-outline-dark waves-effect"
                  type="reset"
                  outline
                  onClick={() =>
                    setMonitarinstageModal({
                      isOpen: false,
                      data: "",
                    })
                  }
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddMonitoringStage;
