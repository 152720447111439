import {
  HomeSvg,
  PermissionSvg,
  New_Work,
  DropdownSvg,
  ReportsSvg,
  FourCircle,
  // AllProjectSvg,
  // PreexecutionSvg,
  // ExecutionSvg,
  // CompeletedSvg,
  // StoppedSvg,
  // Exegt6Svg,
  // UserGroup,
  // FourCircle,
} from "../../assets/images";
import { ROLE } from "../../common/utils";
export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <HomeSvg size={20} />,
    navLink: "/se/dashboard",
    roles: [ROLE.SE],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <ReportsSvg size={20} />,
    navLink: "/reports",
    roles: [ROLE.SE],
  },
  {
    id: "permission",
    title: "Permission",
    icon: <PermissionSvg size={20} />,
    navLink: "/se/edit_permission",
    roles: [ROLE.SE],
  },
  {
    id: "oldwork",
    title: "Old Work",
    icon: <FourCircle size={20} />,
    navLink: "/se/old_completed_works_se",
    roles: [ROLE.SE],
  },
  {
    id: "work",
    title: "New Work",
    icon: <New_Work />,
    navLink: "/se/work",
    roles: [ROLE.SE],
  },
  {
    id: "Dropdown",
    title: "Dropdown",
    icon: <DropdownSvg />,
    navLink: "/se/dropdown",
    roles: [ROLE.SE],
  },
  // {
  //   id: "bookmarks",
  //   title: "BOOKMARKS",
  //   children: [
  //     {
  //       id: "allprojects",
  //       title: "All projects",
  //       icon: <AllProjectSvg />,
  //       navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "Pre-Execution",
  //       title: "Pre-Execution",
  //       icon: <PreexecutionSvg />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "execution",
  //       title: "Execution",
  //       icon: <ExecutionSvg />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "Completed",
  //       title: "Completed",
  //       icon: <CompeletedSvg />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "Stopped",
  //       title: "Stopped",
  //       icon: <StoppedSvg />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "Pre-Execution > 6m",
  //       title: "Pre-Execution > 6m",
  //       icon: <Exegt6Svg />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //     {
  //       id: "time-overrun-projects",
  //       title: "Time-overrun projects",
  //       icon: <UserGroup />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },

  //     {
  //       id: "expenditure-abstract",
  //       title: "Expenditure abstract",
  //       icon: <FourCircle />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },

  //     {
  //       id: "plant-monitoring",
  //       title: "Plant monitoring",
  //       icon: <FourCircle />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },

  //     {
  //       id: "forest-clearance",
  //       title: "Forest clearance",
  //       icon: <FourCircle />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },

  //     {
  //       id: "utility-shifting",
  //       title: "Utility shifting",
  //       icon: <FourCircle />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },

  //     {
  //       id: "land-acquisition ",
  //       title: "Land acquisition ",
  //       icon: <FourCircle />,
  //       // navLink: "/se/allproject",
  //        roles: [ROLE.SE],
  //     },
  //   ],
  // },
];
