import { toast } from "react-toastify";

export const getErrorMsg = (err) => {
  if (!err.response) {
    return toast.error("Something Went Wrong");
  }
  if (err.response) {
    if (err.response.status === 500) {
      return toast.error("Something Went Wrong");
    }
    if (err?.response?.status > 400) {
      return toast.error(err.response.data.message);
    }
  }
};
