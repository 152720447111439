import {
  FourCircle,
  HomeSvg,
  LocationSvg,
  UserGroup,
  Usericon,
} from "../../assets/images";
import { ROLE } from "../../common/utils";

export default [
  // {
  //   header: "Super Admin",
  //    roles: [ROLE.SUPERADMIN],
  // },

  {
    id: "dashboard",
    title: "Dashboard",
    icon: <HomeSvg size={20} />,
    navLink: "/sa/dashboard",
    roles: [ROLE.SUPERADMIN],
  },

  {
    id: "usersMenu",
    title: "Users",
    // icon: <Menu size={20} />,
    children: [
      {
        id: "GovernmentMenu",
        title: "Government User",
        icon: <Usericon className="User-icon" />,
        navLink: "/sa/govr-users",
        roles: [ROLE.SUPERADMIN],
      },
      {
        id: "SecretaryMenu",
        title: "Secretary User",
        icon: <UserGroup className="User-icon" />,
        navLink: "/sa/secretory-users",
        roles: [ROLE.SUPERADMIN],
      },
    ],
  },

  {
    id: "location",
    title: "Locations",
    icon: <LocationSvg size={20} />,

    navLink: "/sa/locations",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "mp",
    title: "MP",
    icon: <FourCircle size={20} />,
    navLink: "/sa/mps",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "mla",
    title: "MLA",
    icon: <FourCircle size={20} />,
    navLink: "/sa/mlas",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "budgethead",
    title: "Budget Head",
    icon: <FourCircle size={20} />,
    navLink: "/sa/budget-head",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "schemeofbudget",
    title: "Scheme of Budget",
    icon: <FourCircle size={20} />,
    navLink: "/sa/scheme-of-budget",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "categoryofroad",
    title: "Category Of Road",
    icon: <FourCircle size={20} />,
    navLink: "/sa/category-of-road",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "tendertype",
    title: "Type Of Tender",
    icon: <FourCircle size={20} />,
    navLink: "/sa/type-of-tender",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "agency",
    title: "Agency",
    icon: <FourCircle size={20} />,
    navLink: "/sa/agency",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "proposedwork",
    title: "Proposed Work",
    icon: <FourCircle size={20} />,
    navLink: "/sa/proposed-work",
    roles: [ROLE.SUPERADMIN],
  },
  {
    id: "workstatus",
    title: "Work Status",
    icon: <FourCircle size={20} />,
    navLink: "/sa/work-status",
    roles: [ROLE.SUPERADMIN],
  },

  {
    id: "monitaringstage",
    title: "Monitoring Stage",
    icon: <FourCircle size={20} />,
    navLink: "/sa/monitoring-stage",
    roles: [ROLE.SUPERADMIN],
  },
];
