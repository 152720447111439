/* eslint-disable no-unneeded-ternary */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDate from "../../../../components/ReactDatePicker";
import moment from "moment";
import { YMDdate } from "../../../../../common/utils";
import { updateUtilityWork } from "../../../../../services/reportsServices";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import { UtilityShiftingSchema } from "./lautils";
import { yupResolver } from "@hookform/resolvers/yup";

const UtilityShifting = ({ utility_shifting_required }) => {
  // ** Get Data From Store
  const workData = useSelector((state) => state?.getWork);

  const {
    getValues,
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(UtilityShiftingSchema) });

  console.log("errors>>>>", errors);
  // 1st tab
  const e_utility_aestimate_date = watch("e_utility_aestimate_maliya_date");
  const e_utility_araji_date = watch("e_utility_araji_karel_date");
  const e_utility_sifting_puran_date = watch(
    "e_utility_sifting_puran_thayel_date"
  );
  const {
    ref: e_utility_sifting_puran_thayel_chhe_radio,
    ...e_utility_sifting_puran_thayel_chhe_radiotest
  } = register("e_utility_sifting_puran_thayel_chhe", {
    required: true,
  });

  // 2nd
  const w_utility_aestimate_date = watch("w_utility_aestimate_maliya_date");
  const w_utility_araji_date = watch("w_utility_araji_karel_date");
  const w_utility_sifting_puran_date = watch(
    "w_utility_sifting_puran_thayel_date"
  );

  const {
    ref: w_utility_sifting_puran_thayel_chhe_radio,
    ...w_utility_sifting_puran_thayel_chhe_radiotest
  } = register("w_utility_sifting_puran_thayel_chhe", {
    required: true,
  });

  // 3nd
  const g_utility_aestimate_date = watch("g_utility_aestimate_maliya_date");
  const g_utility_araji_date = watch("g_utility_araji_karel_date");
  const g_utility_sifting_puran_date = watch(
    "g_utility_sifting_puran_thayel_date"
  );

  const {
    ref: g_utility_sifting_puran_thayel_chhe_radio,
    ...g_utility_sifting_puran_thayel_chhe_radiotest
  } = register("g_utility_sifting_puran_thayel_chhe", {
    required: true,
  });

  // 4nd
  const op_utility_aestimate_date = watch("op_utility_aestimate_maliya_date");
  const op_utility_araji_date = watch("op_utility_araji_karel_date");
  const op_utility_sifting_puran_date = watch(
    "op_utility_sifting_puran_thayel_date"
  );

  const {
    ref: op_utility_sifting_puran_thayel_chhe_radio,
    ...op_utility_sifting_puran_thayel_chhe_radiotest
  } = register("op_utility_sifting_puran_thayel_chhe", {
    required: true,
  });

  // 4nd
  const ot_utility_aestimate_date = watch("ot_utility_aestimate_maliya_date");
  const ot_utility_araji_date = watch("ot_utility_araji_karel_date");
  const ot_utility_sifting_puran_date = watch(
    "ot_utility_sifting_puran_thayel_date"
  );

  const {
    ref: ot_utility_sifting_puran_thayel_chhe_radio,
    ...ot_utility_sifting_puran_thayel_chhe_radiotest
  } = register("ot_utility_sifting_puran_thayel_chhe", {
    required: true,
  });

  const electricLinePoleValue = watch("electric_line_pole");
  const waterSupplyValue = watch("water_supply");
  const gasPipelineValue = watch("gas_pipeline");
  const opticalFiberValue = watch("optical_fiber");
  const otherValue = watch("other");

  const [active, setActive] = useState();
  const [pageLoad, setIspageLoad] = useState(true);

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  useEffect(() => {
    if (
      (watch("electric_line_pole") === false ||
        watch("electric_line_pole") === undefined) &&
      (watch("water_supply") === false ||
        watch("water_supply") === undefined) &&
      (watch("optical_fiber") === false ||
        watch("optical_fiber") === undefined) &&
      (watch("other" === false) || watch("other") === undefined) &&
      (watch("gas_pipeline") === false || watch("gas_pipeline") === undefined)
    ) {
      setActive("");
    } else {
      const getAllCheck = [
        electricLinePoleValue,
        waterSupplyValue,
        gasPipelineValue,
        opticalFiberValue,
        otherValue,
      ];
      if (pageLoad) {
        setActive(`${getAllCheck.indexOf(true) + 1}`);
      }
    }
  }, [getValues(), pageLoad]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIspageLoad(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const onSubmit = (data) => {
    const val = {
      ...data,

      // 1
      // 1.1
      e_utility_aestimate_maliya_date:
        data?.e_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.e_utility_aestimate_maliya_date),

      // 1.2
      e_utility_araji_karel_date:
        data?.e_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.e_utility_araji_karel_date),

      // 1.3
      e_utility_sifting_puran_thayel_date:
        data?.e_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.e_utility_sifting_puran_thayel_date),

      // 2
      // 2.1
      g_utility_aestimate_maliya_date:
        data?.g_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.g_utility_aestimate_maliya_date),

      // 2.2
      g_utility_araji_karel_date:
        data?.g_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.g_utility_araji_karel_date),

      // 2.2
      g_utility_sifting_puran_thayel_date:
        data?.g_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.g_utility_sifting_puran_thayel_date),

      // 3
      op_utility_aestimate_maliya_date:
        data?.op_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.op_utility_aestimate_maliya_date),

      op_utility_araji_karel_date:
        data?.op_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.op_utility_araji_karel_date),
      op_utility_sifting_puran_thayel_date:
        data?.op_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.op_utility_sifting_puran_thayel_date),

      // 4
      ot_utility_aestimate_maliya_date:
        data?.ot_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.ot_utility_aestimate_maliya_date),
      ot_utility_araji_karel_date:
        data?.ot_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.ot_utility_araji_karel_date),
      ot_utility_sifting_puran_thayel_date:
        data?.ot_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.ot_utility_sifting_puran_thayel_date),

      // // 5
      w_utility_aestimate_maliya_date:
        data?.w_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.w_utility_aestimate_maliya_date),
      w_utility_araji_karel_date:
        data?.w_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.w_utility_araji_karel_date),
      w_utility_sifting_puran_thayel_date:
        data?.w_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.w_utility_sifting_puran_thayel_date),
    };
    const filteredVal = {
      work_id: workData?.data?.id,
      utility_shifting_required,
      // 1 Submit
      e_utility_sifting_puran_thayel_date_submit:
        data?.e_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.e_utility_sifting_puran_thayel_date),
      e_utility_araji_karel_date_submit:
        data?.e_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.e_utility_araji_karel_date),
      e_utility_aestimate_maliya_date_submit:
        data?.e_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.e_utility_aestimate_maliya_date),

      // 2 Submit
      g_utility_aestimate_maliya_date_submit:
        data?.g_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.g_utility_aestimate_maliya_date),
      g_utility_araji_karel_date_submit:
        data?.g_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.g_utility_araji_karel_date),
      g_utility_sifting_puran_thayel_date_submit:
        data?.g_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.g_utility_sifting_puran_thayel_date),

      // 3 Submit
      op_utility_aestimate_maliya_date_submit:
        data?.op_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.op_utility_aestimate_maliya_date),
      op_utility_araji_karel_date_submit:
        data?.op_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.op_utility_araji_karel_date),
      op_utility_sifting_puran_thayel_date_submit:
        data?.op_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.op_utility_sifting_puran_thayel_date),
      // 4 Submit

      ot_utility_aestimate_maliya_date_submit:
        data?.ot_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.ot_utility_aestimate_maliya_date),
      ot_utility_araji_karel_date_submit:
        data?.ot_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.ot_utility_araji_karel_date),
      ot_utility_sifting_puran_thayel_date_submit:
        data?.ot_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.ot_utility_sifting_puran_thayel_date),
      // 5 Submit
      w_utility_aestimate_maliya_date_submit:
        data?.w_utility_aestimate_maliya_date === null
          ? null
          : YMDdate(data?.w_utility_aestimate_maliya_date),
      w_utility_araji_karel_date_submit:
        data?.w_utility_araji_karel_date === null
          ? null
          : YMDdate(data?.w_utility_araji_karel_date),
      w_utility_sifting_puran_thayel_date_submit:
        data?.w_utility_sifting_puran_thayel_date === null
          ? null
          : YMDdate(data?.w_utility_sifting_puran_thayel_date),
    };

    for (const key in data) {
      if (val[key] !== false) {
        filteredVal[key] = val[key];
      }
      if (val[key] === true) {
        filteredVal[key] = 1;
      }
    }
    updateUtilityWork(filteredVal)
      .then((res) => toast.success(res.message))
      .catch((err) => getErrorMsg(err));
  };

  useEffect(() => {
    const electrict_line_tab = workData?.data?.electrict_line_pole_shifting_data
      ? JSON.parse(workData?.data?.electrict_line_pole_shifting_data)
      : {};
    const water_supply_tab = workData?.data?.water_supply_storm_water_line_data
      ? JSON.parse(workData?.data?.water_supply_storm_water_line_data)
      : {};
    const gas_pipeline_tab = workData?.data?.gas_pipeline_data
      ? JSON.parse(workData?.data?.gas_pipeline_data)
      : {};

    const optical_fiber_tab = workData?.data?.optical_fiber_line_data
      ? JSON.parse(workData?.data?.optical_fiber_line_data)
      : {};

    const other_tab = JSON.parse(workData?.data?.anya_data);

    reset({
      // ** default
      circle_name: workData?.data?.circle_name || "",
      division_name: workData?.data?.division_name || "",
      name_of_work_gujarati: workData?.data?.name_of_work_gujarati || "",

      //**  electric_line_pole tab
      electric_line_pole: electrict_line_tab ? true : false,
      e_utility_aestimate_maliya_date:
        electrict_line_tab?.e_utility_aestimate_maliya_date
          ? new Date(electrict_line_tab?.e_utility_aestimate_maliya_date)
          : null,
      e_utility_araji_karel_date: electrict_line_tab?.e_utility_araji_karel_date
        ? new Date(electrict_line_tab?.e_utility_araji_karel_date)
        : null,
      e_utility_araji_karel_kacheri:
        electrict_line_tab?.e_utility_araji_karel_kacheri || null,
      e_utility_samaygalo: electrict_line_tab?.e_utility_samaygalo || null,
      e_utility_nala_bharavani_vigato:
        electrict_line_tab?.e_utility_nala_bharavani_vigato || null,
      e_utility_siftingni_aajroj_antim_paristhti:
        electrict_line_tab?.e_utility_siftingni_aajroj_antim_paristhti || null,
      e_utility_sifting_puran_thayel_chhe:
        electrict_line_tab?.e_utility_sifting_puran_thayel_chhe || "no",
      e_utility_sifting_puran_thayel_date:
        electrict_line_tab?.e_utility_sifting_puran_thayel_date
          ? new Date(electrict_line_tab?.e_utility_sifting_puran_thayel_date)
          : null,

      // ** વોટર સપ્લાય / સ્ટોર્મ વોટર લાઇન tab
      water_supply: water_supply_tab ? true : false,
      w_utility_aestimate_maliya_date:
        water_supply_tab?.w_utility_aestimate_maliya_date
          ? new Date(water_supply_tab?.w_utility_aestimate_maliya_date)
          : null,
      w_utility_araji_karel_date: water_supply_tab?.w_utility_araji_karel_date
        ? new Date(water_supply_tab?.w_utility_araji_karel_date)
        : null,
      w_utility_araji_karel_kacheri:
        water_supply_tab?.w_utility_araji_karel_kacheri || null,
      w_utility_samaygalo: water_supply_tab?.w_utility_samaygalo || null,
      w_utility_nala_bharavani_vigato:
        water_supply_tab?.w_utility_nala_bharavani_vigato || null,
      w_utility_siftingni_aajroj_antim_paristhti:
        water_supply_tab?.w_utility_siftingni_aajroj_antim_paristhti || null,
      w_utility_sifting_puran_thayel_chhe:
        water_supply_tab?.w_utility_sifting_puran_thayel_chhe || "no",
      w_utility_sifting_puran_thayel_date:
        water_supply_tab?.w_utility_sifting_puran_thayel_date
          ? new Date(water_supply_tab?.w_utility_sifting_puran_thayel_date)
          : null,

      // ** ગેસ પાઇપલાઇન
      gas_pipeline: gas_pipeline_tab ? true : false,
      g_utility_aestimate_maliya_date:
        gas_pipeline_tab?.g_utility_aestimate_maliya_date
          ? new Date(gas_pipeline_tab?.g_utility_aestimate_maliya_date)
          : null,
      g_utility_araji_karel_date: gas_pipeline_tab?.g_utility_araji_karel_date
        ? new Date(gas_pipeline_tab?.g_utility_araji_karel_date)
        : null,
      g_utility_araji_karel_kacheri:
        gas_pipeline_tab?.g_utility_araji_karel_kacheri || null,
      g_utility_samaygalo: gas_pipeline_tab?.g_utility_samaygalo || null,
      g_utility_nala_bharavani_vigato:
        gas_pipeline_tab?.g_utility_nala_bharavani_vigato || null,
      g_utility_siftingni_aajroj_antim_paristhti:
        gas_pipeline_tab?.g_utility_siftingni_aajroj_antim_paristhti || null,
      g_utility_sifting_puran_thayel_chhe:
        gas_pipeline_tab?.g_utility_sifting_puran_thayel_chhe || "no",
      g_utility_sifting_puran_thayel_date:
        gas_pipeline_tab?.g_utility_sifting_puran_thayel_date
          ? new Date(gas_pipeline_tab?.g_utility_sifting_puran_thayel_date)
          : null,

      //  ** ઓપ્ટીકલ ફાઈબર લાઇન
      optical_fiber: optical_fiber_tab ? true : false,
      op_utility_aestimate_maliya_date:
        optical_fiber_tab?.op_utility_aestimate_maliya_date
          ? new Date(optical_fiber_tab?.op_utility_aestimate_maliya_date)
          : null,
      op_utility_araji_karel_date:
        optical_fiber_tab?.op_utility_araji_karel_date
          ? new Date(optical_fiber_tab?.op_utility_araji_karel_date)
          : null,
      op_utility_araji_karel_kacheri:
        optical_fiber_tab?.op_utility_araji_karel_kacheri || null,
      op_utility_samaygalo: optical_fiber_tab?.op_utility_samaygalo || null,
      op_utility_nala_bharavani_vigato:
        optical_fiber_tab?.op_utility_nala_bharavani_vigato || null,
      op_utility_siftingni_aajroj_antim_paristhti:
        optical_fiber_tab?.op_utility_siftingni_aajroj_antim_paristhti || null,
      op_utility_sifting_puran_thayel_chhe:
        optical_fiber_tab?.op_utility_sifting_puran_thayel_chhe || "no",
      op_utility_sifting_puran_thayel_date:
        optical_fiber_tab?.op_utility_sifting_puran_thayel_date
          ? new Date(optical_fiber_tab?.op_utility_sifting_puran_thayel_date)
          : null,

      // ** અન્ય

      other: other_tab ? true : false,
      ot_utility_aestimate_maliya_date:
        other_tab?.ot_utility_aestimate_maliya_date
          ? new Date(other_tab?.ot_utility_aestimate_maliya_date)
          : null,
      ot_utility_araji_karel_date: other_tab?.ot_utility_araji_karel_date
        ? new Date(other_tab?.ot_utility_araji_karel_date)
        : null,
      ot_utility_araji_karel_kacheri:
        other_tab?.ot_utility_araji_karel_kacheri || null,
      ot_utility_samaygalo: other_tab?.ot_utility_samaygalo || null,
      ot_utility_nala_bharavani_vigato:
        other_tab?.ot_utility_nala_bharavani_vigato || null,
      ot_utility_siftingni_aajroj_antim_paristhti:
        other_tab?.ot_utility_siftingni_aajroj_antim_paristhti || null,
      ot_utility_sifting_puran_thayel_chhe:
        other_tab?.ot_utility_sifting_puran_thayel_chhe || "no",
      ot_utility_sifting_puran_thayel_date:
        other_tab?.ot_utility_sifting_puran_thayel_date
          ? new Date(other_tab?.ot_utility_sifting_puran_thayel_date)
          : null,
    });
  }, [workData?.data]);

  return (
    <div className="mt-2">
      <Form>
        <Row className="gy-1">
          <Label className="mb-0">Please select Utility Type.</Label>
          <Col xs={12} className="d-flex">
            <div className="custom-control custom-checkbox">
              <Controller
                name="electric_line_pole"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      // defaultChecked={getValues("electric_line_pole")}
                      type="checkbox"
                      id="electric_line_pole"
                      {...field}
                      checked={watch("electric_line_pole")}
                    />
                    <Label
                      for="electric_line_pole"
                      className="form-check-label ms-1"
                    >
                      ઇલેકટ્રીક લાઇન/પોલ શિફટીંગ
                    </Label>
                  </>
                )}
              />
            </div>

            <div className="custom-control custom-checkbox ms-2">
              <Controller
                name="water_supply"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      type="checkbox"
                      id="water_supply"
                      {...field}
                      checked={watch("water_supply")}
                    />
                    <Label
                      // defaultChecked={watch("water_supply")}
                      for="water_supply"
                      className="form-check-label ms-1"
                    >
                      વોટર સપ્લાય / સ્ટોર્મ વોટર લાઇન
                    </Label>
                  </>
                )}
              />
            </div>
            <div className="custom-control custom-checkbox ms-2">
              <Controller
                name="gas_pipeline"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      // defaultChecked={watch("gas_pipeline")}
                      type="checkbox"
                      id="gas_pipeline"
                      {...field}
                      checked={watch("gas_pipeline")}
                    />
                    <Label for="gas_pipeline" className="form-check-label ms-1">
                      ગેસ પાઇપલાઇન
                    </Label>
                  </>
                )}
              />
            </div>
            <div className="custom-control custom-checkbox ms-2">
              <Controller
                name="optical_fiber"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      // defaultChecked={watch("optical_fiber")}
                      type="checkbox"
                      id="optical_fiber"
                      {...field}
                      checked={watch("optical_fiber")}
                    />
                    <Label
                      for="optical_fiber"
                      className="form-check-label ms-1"
                    >
                      ઓપ્ટીકલ ફાઈબર લાઇન
                    </Label>
                  </>
                )}
              />
            </div>
            <div className="custom-control custom-checkbox ms-2">
              <Controller
                name="other"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      checked={watch("other")}
                      // defaultChecked={watch("other")}
                      type="checkbox"
                      id="other"
                      {...field}
                    />
                    <Label for="other" className="form-check-label ms-1">
                      અન્ય
                    </Label>
                  </>
                )}
              />
            </div>
          </Col>
          <Col md={12} className="app-tabs">
            <Nav tabs>
              {watch("electric_line_pole") === true ? (
                <NavItem>
                  <NavLink
                    active={active === "1"}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    ઇલેકટ્રીક લાઇન/પોલ શિફટીંગ
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {watch("water_supply") === true ? (
                <NavItem>
                  <NavLink
                    active={active === "2"}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    વોટર સપ્લાય / સ્ટોર્મ વોટર લાઇન
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {watch("gas_pipeline") === true ? (
                <NavItem>
                  <NavLink
                    active={active === "3"}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    ગેસ પાઇપલાઇન
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {watch("optical_fiber") === true ? (
                <NavItem>
                  <NavLink
                    active={active === "4"}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    ઓપ્ટીકલ ફાઈબર લાઇન
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {watch("other") === true ? (
                <NavItem>
                  <NavLink
                    active={active === "5"}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    અન્ય
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>
          </Col>
        </Row>

        <TabContent className="py-50" activeTab={active}>
          {/*   ઇલેકટ્રીક લાઇન/પોલ શિફટીંગ */}
          <TabPane tabId="1">
            <Row>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="circle_name">
                  વર્તુળનું નામ
                </Label>
                <Controller
                  control={control}
                  name="circle_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="circle_name"
                        invalid={errors.circle_name && true}
                        disabled
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="division_name">
                  જિલ્લો
                </Label>
                <Controller
                  control={control}
                  name="division_name"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="division_name"
                        invalid={errors.division_name && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="name_of_work_gujarati">
                  રસ્તાનું નામ
                </Label>
                <Controller
                  control={control}
                  name="name_of_work_gujarati"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="name_of_work_gujarati"
                        invalid={errors.name_of_work_gujarati && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label
                  className="form-label"
                  for="e_utility_araji_karel_kacheri"
                >
                  અરજી કરેલ કચેરી
                </Label>
                <Controller
                  control={control}
                  name="e_utility_araji_karel_kacheri"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="e_utility_araji_karel_kacheri"
                        invalid={errors.e_utility_araji_karel_kacheri && true}
                      />
                    );
                  }}
                />
                {errors.e_utility_araji_karel_kacheri && (
                  <FormFeedback>
                    {errors.e_utility_araji_karel_kacheri.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label className="form-label" for="e_utility_araji_karel_date">
                  અરજી કરેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="e_utility_araji_karel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        disabled={!watch("e_utility_araji_karel_kacheri")}
                        {...field}
                        maxDate={new Date()}
                        selected={
                          e_utility_araji_date ? e_utility_araji_date : null
                        }
                        onChange={(date) => {
                          setValue("e_utility_araji_karel_date", date);
                          // For સમયગાળો || e_utility_araji_karel_date set value onChnage
                          const daysToTarget = moment().diff(date, "days");
                          const years = Math.floor(daysToTarget / 365);
                          const months = Math.floor((daysToTarget % 365) / 30);
                          const days = (daysToTarget % 365) % 30;

                          if (years !== 0 && months !== 0 && days >= 0) {
                            setValue(
                              "e_utility_samaygalo",
                              `${years} Years, ${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months !== 0 && days >= 0) {
                            setValue(
                              "e_utility_samaygalo",
                              `${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months === 0 && days >= 0) {
                            setValue("e_utility_samaygalo", `${days} Day`);
                          } else {
                            setValue("e_utility_samaygalo", "0");
                          }

                          if (!date) {
                            setError(
                              "e_utility_araji_karel_date",
                              "This field is required"
                            );
                          } else {
                            if (watch("e_utility_araji_karel_kacheri")) {
                              setError("e_utility_araji_karel_date", "");
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.e_utility_araji_karel_date && (
                  <FormFeedback className="d-block">
                    {errors.e_utility_araji_karel_date.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1">
                <Label className="form-label" for="e_utility_samaygalo">
                  સમયગાળો
                </Label>
                <Controller
                  control={control}
                  name="e_utility_samaygalo"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="e_utility_samaygalo"
                        invalid={errors.e_utility_samaygalo && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="e_utility_aestimate_maliya_date"
                >
                  એસ્ટીમેટ મળ્યા તારીખ
                </Label>
                <Controller
                  control={control}
                  name="e_utility_aestimate_maliya_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        selected={
                          e_utility_aestimate_date
                            ? e_utility_aestimate_date
                            : null
                        }
                        disabled={!watch("e_utility_araji_karel_date")}
                        onChange={(date) => {
                          setValue("e_utility_aestimate_maliya_date", date);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="e_utility_nala_bharavani_vigato"
                >
                  નાણાં ભરવાની વિગતો
                </Label>
                <Controller
                  control={control}
                  name="e_utility_nala_bharavani_vigato"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="e_utility_nala_bharavani_vigato"
                        invalid={errors.e_utility_nala_bharavani_vigato && true}
                        type="textarea"
                        className="gujarati-bg"
                        rows="3"
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="e_utility_siftingni_aajroj_antim_paristhti"
                >
                  યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી
                </Label>
                <Controller
                  control={control}
                  name="e_utility_siftingni_aajroj_antim_paristhti"
                  render={({ field }) => {
                    return (
                      <>
                        <Input
                          {...field}
                          className="gujarati-bg"
                          id="e_utility_siftingni_aajroj_antim_paristhti"
                          invalid={
                            errors.e_utility_siftingni_aajroj_antim_paristhti &&
                            true
                          }
                          type="textarea"
                          rows="3"
                        />
                        {/* {fieldState.error && (
                          <FormFeedback>
                            {fieldState.error.message}
                          </FormFeedback>
                        )} */}
                      </>
                    );
                  }}
                />
                {errors.e_utility_siftingni_aajroj_antim_paristhti && (
                  <FormFeedback>
                    {errors.e_utility_siftingni_aajroj_antim_paristhti.message}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mt-1">
                <div>
                  <Label>યુટીલીટી શીફટીંગ પૂર્ણ થયેલ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={e_utility_sifting_puran_thayel_chhe_radio}
                        {...e_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="e_utility_sifting_puran_thayel_chhe"
                        id="e_utility_sifting_puran_thayel_chhe_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="e_utility_sifting_puran_thayel_chhe_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={e_utility_sifting_puran_thayel_chhe_radio}
                        {...e_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="e_utility_sifting_puran_thayel_chhe"
                        id="e_utility_sifting_puran_thayel_chhe_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="e_utility_sifting_puran_thayel_chhe_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="e_utility_sifting_puran_thayel_date"
                >
                  યુટીલીટી શીફટીંગ પૂર્ણ થયેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="e_utility_sifting_puran_thayel_date"
                  render={({ field }) => {
                    return (
                      <>
                        <ReactDate
                          {...field}
                          disabled={
                            watch("e_utility_sifting_puran_thayel_chhe") ===
                              "no" ||
                            !watch("e_utility_sifting_puran_thayel_chhe")
                          }
                          selected={
                            e_utility_sifting_puran_date
                              ? e_utility_sifting_puran_date
                              : null
                          }
                          onChange={(date) => {
                            setValue(
                              "e_utility_sifting_puran_thayel_date",
                              date
                            );
                            if (!date) {
                              if (
                                watch("e_utility_sifting_puran_thayel_chhe") ===
                                "yes"
                              ) {
                                setError(
                                  "e_utility_sifting_puran_thayel_date",
                                  "This field is required"
                                );
                              }
                            } else {
                              if (
                                watch("e_utility_sifting_puran_thayel_chhe") ===
                                "yes"
                              ) {
                                setError(
                                  "e_utility_sifting_puran_thayel_date",
                                  ""
                                );
                              }
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />

                {errors.e_utility_sifting_puran_thayel_date && (
                  <FormFeedback className="d-block">
                    {errors.e_utility_sifting_puran_thayel_date.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </TabPane>

          {/*   ઇલેકટ્રીક લાઇન/પોલ શિફટીંગ end */}

          {/* વોટર સપ્લાય / સ્ટોર્મ વોટર લાઇન */}
          <TabPane tabId="2">
            <Row>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="circle_name">
                  વર્તુળનું નામ
                </Label>
                <Controller
                  control={control}
                  name="circle_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="circle_name"
                        invalid={errors.circle_name && true}
                        disabled
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="division_name">
                  જિલ્લો
                </Label>
                <Controller
                  control={control}
                  name="division_name"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="division_name"
                        invalid={errors.division_name && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="name_of_work_gujarati">
                  રસ્તાનું નામ
                </Label>
                <Controller
                  control={control}
                  name="name_of_work_gujarati"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="name_of_work_gujarati"
                        invalid={errors.name_of_work_gujarati && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label
                  className="form-label"
                  for="w_utility_araji_karel_kacheri"
                >
                  અરજી કરેલ કચેરી
                </Label>
                <Controller
                  control={control}
                  name="w_utility_araji_karel_kacheri"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="w_utility_araji_karel_kacheri"
                        invalid={errors.w_utility_araji_karel_kacheri && true}
                      />
                    );
                  }}
                />
                {errors.w_utility_araji_karel_kacheri && (
                  <FormFeedback>
                    {errors.w_utility_araji_karel_kacheri.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label className="form-label" for="w_utility_araji_karel_date">
                  અરજી કરેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="w_utility_araji_karel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        disabled={!watch("w_utility_araji_karel_kacheri")}
                        {...field}
                        maxDate={new Date()}
                        selected={
                          w_utility_araji_date ? w_utility_araji_date : null
                        }
                        onChange={(date) => {
                          setValue("w_utility_araji_karel_date", date);
                          // For સમયગાળો || w_utility_araji_karel_date set value onChnage
                          const daysToTarget = moment().diff(date, "days");
                          const years = Math.floor(daysToTarget / 365);
                          const months = Math.floor((daysToTarget % 365) / 30);
                          const days = (daysToTarget % 365) % 30;

                          if (years !== 0 && months !== 0 && days >= 0) {
                            setValue(
                              "w_utility_samaygalo",
                              `${years} Years, ${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months !== 0 && days >= 0) {
                            setValue(
                              "w_utility_samaygalo",
                              `${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months === 0 && days >= 0) {
                            setValue("w_utility_samaygalo", `${days} Day`);
                          } else {
                            setValue("w_utility_samaygalo", "0");
                          }
                          if (!date) {
                            setError(
                              "w_utility_araji_karel_date",
                              "This field is required"
                            );
                          } else {
                            if (watch("w_utility_araji_karel_kacheri")) {
                              setError("w_utility_araji_karel_date", "");
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.w_utility_araji_karel_date && (
                  <FormFeedback className="d-block">
                    {errors.w_utility_araji_karel_date.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1">
                <Label className="form-label" for="w_utility_samaygalo">
                  સમયગાળો
                </Label>
                <Controller
                  control={control}
                  name="w_utility_samaygalo"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="w_utility_samaygalo"
                        invalid={errors.w_utility_samaygalo && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="w_utility_aestimate_maliya_date"
                >
                  એસ્ટીમેટ મળ્યા તારીખ
                </Label>
                <Controller
                  control={control}
                  name="w_utility_aestimate_maliya_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        selected={
                          w_utility_aestimate_date
                            ? w_utility_aestimate_date
                            : null
                        }
                        disabled={!watch("w_utility_araji_karel_date")}
                        onChange={(date) => {
                          setValue("w_utility_aestimate_maliya_date", date);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="w_utility_nala_bharavani_vigato"
                >
                  નાણાં ભરવાની વિગતો
                </Label>
                <Controller
                  control={control}
                  name="w_utility_nala_bharavani_vigato"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="w_utility_nala_bharavani_vigato"
                        invalid={errors.w_utility_nala_bharavani_vigato && true}
                        type="textarea"
                        className="gujarati-bg"
                        rows="3"
                      />
                    );
                  }}
                />
                {errors.w_utility_nala_bharavani_vigato && (
                  <FormFeedback>
                    {errors.w_utility_nala_bharavani_vigato.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="w_utility_siftingni_aajroj_antim_paristhti"
                >
                  યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી
                </Label>
                <Controller
                  control={control}
                  name="w_utility_siftingni_aajroj_antim_paristhti"
                  render={({ field }) => {
                    return (
                      <>
                        <Input
                          {...field}
                          className="gujarati-bg"
                          id="w_utility_siftingni_aajroj_antim_paristhti"
                          invalid={
                            errors.w_utility_siftingni_aajroj_antim_paristhti &&
                            true
                          }
                          type="textarea"
                          rows="3"
                        />
                      </>
                    );
                  }}
                />
                {errors.w_utility_siftingni_aajroj_antim_paristhti && (
                  <FormFeedback className="d-block">
                    {errors.w_utility_siftingni_aajroj_antim_paristhti.message}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mt-1">
                <div>
                  <Label>યુટીલીટી શીફટીંગ પૂર્ણ થયેલ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={w_utility_sifting_puran_thayel_chhe_radio}
                        {...w_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="w_utility_sifting_puran_thayel_chhe"
                        id="w_utility_sifting_puran_thayel_chhe_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="w_utility_sifting_puran_thayel_chhe_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={w_utility_sifting_puran_thayel_chhe_radio}
                        {...w_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="w_utility_sifting_puran_thayel_chhe"
                        id="w_utility_sifting_puran_thayel_chhe_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="w_utility_sifting_puran_thayel_chhe_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="w_utility_sifting_puran_thayel_date"
                >
                  યુટીલીટી શીફટીંગ પૂર્ણ થયેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="w_utility_sifting_puran_thayel_date"
                  render={({ field }) => {
                    return (
                      <>
                        <ReactDate
                          {...field}
                          disabled={
                            watch("w_utility_sifting_puran_thayel_chhe") ===
                              "no" ||
                            !watch("w_utility_sifting_puran_thayel_chhe")
                          }
                          selected={
                            w_utility_sifting_puran_date
                              ? w_utility_sifting_puran_date
                              : null
                          }
                          onChange={(date) => {
                            setValue(
                              "w_utility_sifting_puran_thayel_date",
                              date
                            );
                            if (!date) {
                              if (
                                watch("w_utility_sifting_puran_thayel_chhe") ===
                                "yes"
                              ) {
                                setError(
                                  "w_utility_sifting_puran_thayel_date",
                                  "This field is required"
                                );
                              }
                            } else {
                              if (
                                watch("w_utility_sifting_puran_thayel_chhe") ===
                                "yes"
                              ) {
                                setError(
                                  "w_utility_sifting_puran_thayel_date",
                                  ""
                                );
                              }
                            }
                          }}
                        />
                      </>
                    );
                  }}
                />
                {errors.w_utility_sifting_puran_thayel_date && (
                  <FormFeedback className="d-block">
                    {errors.w_utility_sifting_puran_thayel_date.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </TabPane>

          {/* ગેસ પાઇપલાઇન */}
          <TabPane tabId="3">
            <Row>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="circle_name">
                  વર્તુળનું નામ
                </Label>
                <Controller
                  control={control}
                  name="circle_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="circle_name"
                        invalid={errors.circle_name && true}
                        disabled
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="division_name">
                  જિલ્લો
                </Label>
                <Controller
                  control={control}
                  name="division_name"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="division_name"
                        invalid={errors.division_name && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="name_of_work_gujarati">
                  રસ્તાનું નામ
                </Label>
                <Controller
                  control={control}
                  name="name_of_work_gujarati"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="name_of_work_gujarati"
                        invalid={errors.name_of_work_gujarati && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label
                  className="form-label"
                  for="g_utility_araji_karel_kacheri"
                >
                  અરજી કરેલ કચેરી
                </Label>
                <Controller
                  control={control}
                  name="g_utility_araji_karel_kacheri"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="g_utility_araji_karel_kacheri"
                        invalid={errors.g_utility_araji_karel_kacheri && true}
                      />
                    );
                  }}
                />
                {errors.g_utility_araji_karel_kacheri && (
                  <FormFeedback>
                    {errors.g_utility_araji_karel_kacheri.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label className="form-label" for="g_utility_araji_karel_date">
                  અરજી કરેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="g_utility_araji_karel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        disabled={!watch("g_utility_araji_karel_kacheri")}
                        {...field}
                        maxDate={new Date()}
                        selected={
                          g_utility_araji_date ? g_utility_araji_date : null
                        }
                        onChange={(date) => {
                          setValue("g_utility_araji_karel_date", date);
                          // For સમયગાળો || g_utility_araji_karel_date set value onChnage
                          const daysToTarget = moment().diff(date, "days");
                          const years = Math.floor(daysToTarget / 365);
                          const months = Math.floor((daysToTarget % 365) / 30);
                          const days = (daysToTarget % 365) % 30;

                          if (years !== 0 && months !== 0 && days >= 0) {
                            setValue(
                              "g_utility_samaygalo",
                              `${years}  ${
                                years >= 2 ? "Years" : "Year"
                              }, ${months}  ${
                                months >= 2 ? "Months" : "Month"
                              }, ${days} ${days >= 2 ? "Days" : "Day"}`
                            );
                          } else if (years === 0 && months !== 0 && days >= 0) {
                            setValue(
                              "g_utility_samaygalo",
                              ` ${months}  ${
                                months >= 2 ? "Months" : "Month"
                              }, ${days} ${days >= 2 ? "Days" : "Day"}`
                            );
                          } else if (years === 0 && months === 0 && days >= 0) {
                            setValue(
                              "g_utility_samaygalo",
                              `${days} ${days >= 2 ? "Days" : "Day"}`
                            );
                          } else {
                            setValue("g_utility_samaygalo", "0");
                          }

                          if (!date) {
                            setError(
                              "g_utility_araji_karel_date",
                              "This field is required"
                            );
                          } else {
                            if (watch("g_utility_araji_karel_kacheri")) {
                              setError("g_utility_araji_karel_date", "");
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.g_utility_araji_karel_date && (
                  <FormFeedback className="d-block">
                    {errors.g_utility_araji_karel_date.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1">
                <Label className="form-label" for="g_utility_samaygalo">
                  સમયગાળો
                </Label>
                <Controller
                  control={control}
                  name="g_utility_samaygalo"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="g_utility_samaygalo"
                        invalid={errors.g_utility_samaygalo && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="g_utility_aestimate_maliya_date"
                >
                  એસ્ટીમેટ મળ્યા તારીખ
                </Label>
                <Controller
                  control={control}
                  name="g_utility_aestimate_maliya_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        selected={
                          g_utility_aestimate_date
                            ? g_utility_aestimate_date
                            : null
                        }
                        disabled={!watch("g_utility_araji_karel_date")}
                        onChange={(date) => {
                          setValue("g_utility_aestimate_maliya_date", date);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="g_utility_nala_bharavani_vigato"
                >
                  નાણાં ભરવાની વિગતો
                </Label>
                <Controller
                  control={control}
                  name="g_utility_nala_bharavani_vigato"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="g_utility_nala_bharavani_vigato"
                        invalid={errors.g_utility_nala_bharavani_vigato && true}
                        type="textarea"
                        className="gujarati-bg"
                        rows="3"
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="g_utility_siftingni_aajroj_antim_paristhti"
                >
                  યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી
                </Label>
                <Controller
                  control={control}
                  name="g_utility_siftingni_aajroj_antim_paristhti"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="gujarati-bg"
                        id="g_utility_siftingni_aajroj_antim_paristhti"
                        invalid={
                          errors.g_utility_siftingni_aajroj_antim_paristhti &&
                          true
                        }
                        type="textarea"
                        rows="3"
                      />
                    );
                  }}
                />
                {errors.g_utility_siftingni_aajroj_antim_paristhti && (
                  <FormFeedback>
                    {errors.g_utility_siftingni_aajroj_antim_paristhti.message}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mt-1">
                <div>
                  <Label>યુટીલીટી શીફટીંગ પૂર્ણ થયેલ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={g_utility_sifting_puran_thayel_chhe_radio}
                        {...g_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="g_utility_sifting_puran_thayel_chhe"
                        id="g_utility_sifting_puran_thayel_chhe_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="g_utility_sifting_puran_thayel_chhe_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={g_utility_sifting_puran_thayel_chhe_radio}
                        {...g_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="g_utility_sifting_puran_thayel_chhe"
                        id="g_utility_sifting_puran_thayel_chhe_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="g_utility_sifting_puran_thayel_chhe_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="g_utility_sifting_puran_thayel_date"
                >
                  યુટીલીટી શીફટીંગ પૂર્ણ થયેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="g_utility_sifting_puran_thayel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        disabled={
                          watch("g_utility_sifting_puran_thayel_chhe") ===
                            "no" ||
                          !watch("g_utility_sifting_puran_thayel_chhe")
                        }
                        selected={
                          g_utility_sifting_puran_date
                            ? g_utility_sifting_puran_date
                            : null
                        }
                        onChange={(date) => {
                          setValue("g_utility_sifting_puran_thayel_date", date);
                          if (!date) {
                            if (
                              watch("g_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "g_utility_sifting_puran_thayel_date",
                                "This field is required"
                              );
                            }
                          } else {
                            if (
                              watch("g_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "g_utility_sifting_puran_thayel_date",
                                ""
                              );
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.g_utility_sifting_puran_thayel_date && (
                  <FormFeedback className="d-block">
                    {errors.g_utility_sifting_puran_thayel_date.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="circle_name">
                  વર્તુળનું નામ
                </Label>
                <Controller
                  control={control}
                  name="circle_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="circle_name"
                        invalid={errors.circle_name && true}
                        disabled
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="division_name">
                  જિલ્લો
                </Label>
                <Controller
                  control={control}
                  name="division_name"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="division_name"
                        invalid={errors.division_name && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="name_of_work_gujarati">
                  રસ્તાનું નામ
                </Label>
                <Controller
                  control={control}
                  name="name_of_work_gujarati"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="name_of_work_gujarati"
                        invalid={errors.name_of_work_gujarati && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label
                  className="form-label"
                  for="op_utility_araji_karel_kacheri"
                >
                  અરજી કરેલ કચેરી
                </Label>
                <Controller
                  control={control}
                  name="op_utility_araji_karel_kacheri"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="op_utility_araji_karel_kacheri"
                        invalid={errors.op_utility_araji_karel_kacheri && true}
                      />
                    );
                  }}
                />
                {errors.op_utility_araji_karel_kacheri && (
                  <FormFeedback>
                    {errors.op_utility_araji_karel_kacheri.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label className="form-label" for="op_utility_araji_karel_date">
                  અરજી કરેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="op_utility_araji_karel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        disabled={!watch("op_utility_araji_karel_kacheri")}
                        {...field}
                        maxDate={new Date()}
                        selected={
                          op_utility_araji_date ? op_utility_araji_date : null
                        }
                        onChange={(date) => {
                          setValue("op_utility_araji_karel_date", date);
                          // For સમયગાળો || op_utility_araji_karel_date set value onChnage
                          const daysToTarget = moment().diff(date, "days");
                          const years = Math.floor(daysToTarget / 365);
                          const months = Math.floor((daysToTarget % 365) / 30);
                          const days = (daysToTarget % 365) % 30;

                          if (years !== 0 && months !== 0 && days >= 0) {
                            setValue(
                              "op_utility_samaygalo",
                              `${years} Years, ${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months !== 0 && days >= 0) {
                            setValue(
                              "op_utility_samaygalo",
                              `${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months === 0 && days >= 0) {
                            setValue("op_utility_samaygalo", `${days} Day`);
                          } else {
                            setValue("op_utility_samaygalo", "0");
                          }

                          if (!date) {
                            setError(
                              "op_utility_araji_karel_date",
                              "This field is required"
                            );
                          } else {
                            if (watch("op_utility_araji_karel_kacheri")) {
                              setError("op_utility_araji_karel_date", "");
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.op_utility_araji_karel_date && (
                  <FormFeedback className="d-block">
                    {errors.op_utility_araji_karel_date.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1">
                <Label className="form-label" for="op_utility_samaygalo">
                  સમયગાળો
                </Label>
                <Controller
                  control={control}
                  name="op_utility_samaygalo"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="op_utility_samaygalo"
                        invalid={errors.op_utility_samaygalo && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="op_utility_aestimate_maliya_date"
                >
                  એસ્ટીમેટ મળ્યા તારીખ
                </Label>
                <Controller
                  control={control}
                  name="op_utility_aestimate_maliya_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        selected={
                          op_utility_aestimate_date
                            ? op_utility_aestimate_date
                            : null
                        }
                        disabled={!watch("op_utility_araji_karel_date")}
                        onChange={(date) => {
                          setValue("op_utility_aestimate_maliya_date", date);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="op_utility_nala_bharavani_vigato"
                >
                  નાણાં ભરવાની વિગતો
                </Label>
                <Controller
                  control={control}
                  name="op_utility_nala_bharavani_vigato"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="op_utility_nala_bharavani_vigato"
                        invalid={
                          errors.op_utility_nala_bharavani_vigato && true
                        }
                        type="textarea"
                        className="gujarati-bg"
                        rows="3"
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="op_utility_siftingni_aajroj_antim_paristhti"
                >
                  યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી
                </Label>
                <Controller
                  control={control}
                  name="op_utility_siftingni_aajroj_antim_paristhti"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="gujarati-bg"
                        id="op_utility_siftingni_aajroj_antim_paristhti"
                        invalid={
                          errors.op_utility_siftingni_aajroj_antim_paristhti &&
                          true
                        }
                        type="textarea"
                        rows="3"
                      />
                    );
                  }}
                />
                {errors.op_utility_siftingni_aajroj_antim_paristhti && (
                  <FormFeedback>
                    {errors.op_utility_siftingni_aajroj_antim_paristhti.message}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mt-1">
                <div>
                  <Label>યુટીલીટી શીફટીંગ પૂર્ણ થયેલ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={op_utility_sifting_puran_thayel_chhe_radio}
                        {...op_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="op_utility_sifting_puran_thayel_chhe"
                        id="op_utility_sifting_puran_thayel_chhe_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="op_utility_sifting_puran_thayel_chhe_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={op_utility_sifting_puran_thayel_chhe_radio}
                        {...op_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="op_utility_sifting_puran_thayel_chhe"
                        id="op_utility_sifting_puran_thayel_chhe_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="op_utility_sifting_puran_thayel_chhe_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="op_utility_sifting_puran_thayel_date"
                >
                  યુટીલીટી શીફટીંગ પૂર્ણ થયેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="op_utility_sifting_puran_thayel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        disabled={
                          watch("op_utility_sifting_puran_thayel_chhe") ===
                            "no" ||
                          !watch("op_utility_sifting_puran_thayel_chhe")
                        }
                        selected={
                          op_utility_sifting_puran_date
                            ? op_utility_sifting_puran_date
                            : null
                        }
                        onChange={(date) => {
                          setValue(
                            "op_utility_sifting_puran_thayel_date",
                            date
                          );
                          if (!date) {
                            if (
                              watch("op_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "op_utility_sifting_puran_thayel_date",
                                "This field is required"
                              );
                            }
                          } else {
                            if (
                              watch("op_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "op_utility_sifting_puran_thayel_date",
                                ""
                              );
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.op_utility_sifting_puran_thayel_date && (
                  <FormFeedback className="d-block">
                    {errors.op_utility_sifting_puran_thayel_date.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="circle_name">
                  વર્તુળનું નામ
                </Label>
                <Controller
                  control={control}
                  name="circle_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="circle_name"
                        invalid={errors.circle_name && true}
                        disabled
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="division_name">
                  જિલ્લો
                </Label>
                <Controller
                  control={control}
                  name="division_name"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="division_name"
                        invalid={errors.division_name && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label className="form-label" for="name_of_work_gujarati">
                  રસ્તાનું નામ
                </Label>
                <Controller
                  control={control}
                  name="name_of_work_gujarati"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="name_of_work_gujarati"
                        invalid={errors.name_of_work_gujarati && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={6} className="mt-1">
                <Label
                  className="form-label"
                  for="ot_utility_araji_karel_kacheri"
                >
                  અરજી કરેલ કચેરી
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_araji_karel_kacheri"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="ot_utility_araji_karel_kacheri"
                        invalid={errors.ot_utility_araji_karel_kacheri && true}
                      />
                    );
                  }}
                />
                {errors.ot_utility_araji_karel_kacheri && (
                  <FormFeedback>
                    {errors.ot_utility_araji_karel_kacheri.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label className="form-label" for="ot_utility_araji_karel_date">
                  અરજી કરેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_araji_karel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        disabled={!watch("ot_utility_araji_karel_kacheri")}
                        {...field}
                        maxDate={new Date()}
                        selected={
                          ot_utility_araji_date ? ot_utility_araji_date : null
                        }
                        onChange={(date) => {
                          setValue("ot_utility_araji_karel_date", date);
                          // For સમયગાળો || ot_utility_araji_karel_date set value onChnage
                          const daysToTarget = moment().diff(date, "days");
                          const years = Math.floor(daysToTarget / 365);
                          const months = Math.floor((daysToTarget % 365) / 30);
                          const days = (daysToTarget % 365) % 30;

                          if (years !== 0 && months !== 0 && days >= 0) {
                            setValue(
                              "ot_utility_samaygalo",
                              `${years} Years, ${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months !== 0 && days >= 0) {
                            setValue(
                              "ot_utility_samaygalo",
                              `${months} Months, ${days} Day`
                            );
                          } else if (years === 0 && months === 0 && days >= 0) {
                            setValue("ot_utility_samaygalo", `${days} Day`);
                          } else {
                            setValue("ot_utility_samaygalo", "0");
                          }

                          if (!date) {
                            setError(
                              "ot_utility_araji_karel_date",
                              "This field is required"
                            );
                          } else {
                            if (watch("ot_utility_araji_karel_kacheri")) {
                              setError("ot_utility_araji_karel_date", "");
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.ot_utility_araji_karel_date && (
                  <FormFeedback className="d-block">
                    {errors.ot_utility_araji_karel_date.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={4} className="mt-1">
                <Label className="form-label" for="ot_utility_samaygalo">
                  સમયગાળો
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_samaygalo"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled
                        {...field}
                        id="ot_utility_samaygalo"
                        invalid={errors.ot_utility_samaygalo && true}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="ot_utility_aestimate_maliya_date"
                >
                  એસ્ટીમેટ મળ્યા તારીખ
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_aestimate_maliya_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        selected={
                          ot_utility_aestimate_date
                            ? ot_utility_aestimate_date
                            : null
                        }
                        disabled={!watch("ot_utility_araji_karel_date")}
                        onChange={(date) => {
                          setValue("ot_utility_aestimate_maliya_date", date);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="ot_utility_nala_bharavani_vigato"
                >
                  નાણાં ભરવાની વિગતો
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_nala_bharavani_vigato"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="ot_utility_nala_bharavani_vigato"
                        invalid={
                          errors.ot_utility_nala_bharavani_vigato && true
                        }
                        type="textarea"
                        className="gujarati-bg"
                        rows="3"
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Label
                  className="form-label"
                  for="ot_utility_siftingni_aajroj_antim_paristhti"
                >
                  યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_siftingni_aajroj_antim_paristhti"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="gujarati-bg"
                        id="ot_utility_siftingni_aajroj_antim_paristhti"
                        invalid={
                          errors.ot_utility_siftingni_aajroj_antim_paristhti &&
                          true
                        }
                        type="textarea"
                        rows="3"
                      />
                    );
                  }}
                />
                {errors.ot_utility_siftingni_aajroj_antim_paristhti && (
                  <FormFeedback>
                    {errors.ot_utility_siftingni_aajroj_antim_paristhti.message}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mt-1">
                <div>
                  <Label>યુટીલીટી શીફટીંગ પૂર્ણ થયેલ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={ot_utility_sifting_puran_thayel_chhe_radio}
                        {...ot_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="ot_utility_sifting_puran_thayel_chhe"
                        id="ot_utility_sifting_puran_thayel_chhe_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="ot_utility_sifting_puran_thayel_chhe_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={ot_utility_sifting_puran_thayel_chhe_radio}
                        {...ot_utility_sifting_puran_thayel_chhe_radiotest}
                        type="radio"
                        name="ot_utility_sifting_puran_thayel_chhe"
                        id="ot_utility_sifting_puran_thayel_chhe_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="ot_utility_sifting_puran_thayel_chhe_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="mt-1 DatePicker-w100">
                <Label
                  className="form-label"
                  for="ot_utility_sifting_puran_thayel_date"
                >
                  યુટીલીટી શીફટીંગ પૂર્ણ થયેલ તારીખ
                </Label>
                <Controller
                  control={control}
                  name="ot_utility_sifting_puran_thayel_date"
                  render={({ field }) => {
                    return (
                      <ReactDate
                        {...field}
                        disabled={
                          watch("ot_utility_sifting_puran_thayel_chhe") ===
                            "no" ||
                          !watch("ot_utility_sifting_puran_thayel_chhe")
                        }
                        selected={
                          ot_utility_sifting_puran_date
                            ? ot_utility_sifting_puran_date
                            : null
                        }
                        onChange={(date) => {
                          setValue(
                            "ot_utility_sifting_puran_thayel_date",
                            date
                          );
                          if (!date) {
                            if (
                              watch("ot_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "ot_utility_sifting_puran_thayel_date",
                                "This field is required"
                              );
                            }
                          } else {
                            if (
                              watch("ot_utility_sifting_puran_thayel_chhe") ===
                              "yes"
                            ) {
                              setError(
                                "ot_utility_sifting_puran_thayel_date",
                                ""
                              );
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
                {errors.ot_utility_sifting_puran_thayel_date && (
                  <FormFeedback className="d-block">
                    {errors.ot_utility_sifting_puran_thayel_date.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="2">
              <ExpenditureReview />
            </TabPane>
            <TabPane tabId="3">
              <Other reportCountState={reportCountState} />
            </TabPane> */}
        </TabContent>
        <Col xs={12} className=" mt-3 mb-2">
          <Button
            className="btn btn-outline-dark waves-effect me-25"
            type="reset"
            outline
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default UtilityShifting;
