/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import Select from "react-select";
import RNBLoader from "../../../../@core/components/spinner/RNBLoader";
import UILoader from "@components/ui-loader";
// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { getWorkStatusList } from "../../../../services/adminDashboard";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { updateCreateWorkStatus } from "../../../../services/reportsServices";
import WorkStatusAARequired from "./WorkStatusAARequired";
import { getWorkTabsData } from "../../../../redux/WorkRedux/getWorkSlice";
import { ProposedWork, ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const WorkStatus = ({ userData }) => {
  const workData = useSelector((state) => state?.getWork);
  const currentUserRole = getUserData()?.role;
  const [WorkStatus, setWorkStatus] = useState();
  const dispatch = useDispatch();
  const WorkStatusData = yup.object().shape(
    {
      financial_progress: yup.string().typeError(""),
      // proposedwork_id,ProposedWork
      physical_progress: yup
        .string()
        .when(["workstatus_id", "ProposedWork", "proposedwork_id"], {
          is: (workstatus_id, ProposedWork, proposedwork_id) =>
            workstatus_id === 2 && !ProposedWork?.includes(proposedwork_id),
          then: yup
            .string()
            .matches(
              /^(?!0(\.0{1,2})?$)\d{0,20}(\.\d{0,2})?$/,
              "Physical Progress in Percentage should not exceed 20 Digits"
            )
            .required("Enter Physical Progress in Percentage")
            .nullable(),
        })
        .nullable(),
      time_limit_in_percentage: yup.string().typeError(""),
      workstatus_id: yup.number().required("Select Work Status").nullable(),
      actual_date_of_completion_as_per_completion_of_project: yup
        .string()
        .when("workstatus_id", {
          is: (value) => value === 4 || value === 5,
          then: yup
            .string()
            .required("Select Actual Date of Completion of Project")
            .nullable(),
        })
        .nullable(),
      dlp_period_for_work_in_month: yup
        .string()
        .when(["workstatus_id", "ProposedWork", "proposedwork_id"], {
          is: (workstatus_id, ProposedWork, proposedwork_id) =>
            (workstatus_id === 4 || workstatus_id === 5) &&
            !ProposedWork?.includes(proposedwork_id),
          then: yup
            .string()
            .matches(/^[0-9]*$/, "Please enter only digits.")
            .max(
              3,
              "DLP Period for the Work in Months should not exceed 3 Digits"
            )
            .required("Enter DLP Period for work in Month"),
          otherwise: yup
            .string()
            .matches(/^[0-9]*$/, "Please enter only digits.")
            .max(
              3,
              "DLP Period for the Work in Months should not exceed 3 Digits"
            )
            .nullable(),
        })
        .nullable(),

      date_completion_dlp_period: yup.string().typeError(""),
      // workstatus_detail: yup.string().typeError(""),
      // ***
      workstatus_detail: yup
        .string()
        .when("govtplan_bytender", {
          is: (value) => value === "govt",
          then: yup
            .string()
            .required(
              "Enter Status of Work in Detail for Misc. work in Gujarati"
            )
            .nullable(),
          otherwise: yup
            .string()
            .required("Enter Status of Work in Detail in Gujarati")
            .nullable(),
        })
        .nullable(),

      govt_plan_work_detail: yup
        .string()
        .when("govtplan_bytender", {
          is: (value) => value === "govt",
          then: yup
            .string()
            .required(
              "Enter Status of Work in Detail for Govt. Plant in Gujarati"
            )
            .nullable(),
        })
        .nullable(),

      // govt_plan_work_detail: yup
      // .string()
      // .matches(/^[઀-૿\s]*$/, "Please enter only Gujarati characters.")
      // .required("plase enter"),
      // allow_target_radio: yup.string().typeError(""),
    },
    [["workstatus_id", "ProposedWork", "proposedwork_id"]]
  );

  // ** Hooks
  const {
    reset,
    control,
    watch,
    // register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(WorkStatusData),
  });

  const actualdate = watch(
    "actual_date_of_completion_as_per_completion_of_project"
  );

  // const allowtarget = watch("allow_target_radio");
  // console.log("allowtarget: ", allowtarget);

  const workStatusVal = watch("workstatus_id");
  const workStatusId = workStatusVal ? workStatusVal : null;

  const CompletionDLP = moment(actualdate ? actualdate : null)
    .add(watch("dlp_period_for_work_in_month"), "months")
    .subtract(1, "day")
    .format("DD-MM-YYYY");

  const onSubmit = (data) => {
    console.log("onSubmit: ", data);

    const date_completion_dlp = moment(
      data?.date_completion_dlp_period,
      "DD-MM-YYYY"
    ).format("YYYY-MM-DD");

    const sendAPIData = {
      work_id: workData?.data?.id,
      workstatus_id: data.workstatus_id,
      financial_progress: data.financial_progress,
      physical_progress: data.physical_progress,
      time_limit_in_percentage: data.time_limit_in_percentage,
      // actual_date_of_completion_as_per_completion_of_project:
      //   moment(actualDateCompletion).format("YYYY-MM-DD"),
      actual_date_of_completion_as_per_completion_of_project: actualdate
        ? moment(actualdate).format("YYYY-MM-DD")
        : null,

      dlp_period_for_work_in_month: data.dlp_period_for_work_in_month,
      date_completion_dlp_period: data?.date_completion_dlp_period
        ? date_completion_dlp
        : "",
      govt_plan_work_detail: data.govt_plan_work_detail,
      workstatus_detail: data.workstatus_detail,
    };

    updateCreateWorkStatus(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
        dispatch(getWorkTabsData(userData));
      })
      .catch((err) => getErrorMsg(err));
  };

  const handleDateChange = (date) => {
    // setActualDateCompletion(date);
    if (date === null) {
      setValue("actual_date_of_completion_as_per_completion_of_project", null);
      setError("actual_date_of_completion_as_per_completion_of_project", {
        type: "manual",
        message: "Select Actual Date of Completion of Project",
      });
    } else if (
      moment(workData?.data?.work_order_date).isAfter(date, "YYYY-MM-DD") ===
      true
    ) {
      setValue("actual_date_of_completion_as_per_completion_of_project", date);
      setError("actual_date_of_completion_as_per_completion_of_project", {
        type: "manual",
        message:
          "Actual date of Completion of the Project should be after the Work order date.",
      });
    } else {
      setValue("actual_date_of_completion_as_per_completion_of_project", date);
      setError("actual_date_of_completion_as_per_completion_of_project", "");
    }
  };

  useEffect(() => {
    getWorkStatusList()
      .then((res) => {
        setWorkStatus(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(
    "test_test_proposedwork_id>>>",
    watch("proposedwork_id"),
    watch("ProposedWork")
  );
  useEffect(() => {
    if (workData?.data) {
      reset({
        govtplan_bytender: workData?.data?.govtplan_bytender,
        proposedwork_id: workData?.data?.proposedwork_id,
        ProposedWork,
        financial_progress:
          workData?.data?.financial_progress === null
            ? 0
            : workData?.data?.financial_progress,
        physical_progress: workData?.data?.physical_progress
          ? workData?.data?.physical_progress
          : "",
        time_limit_in_percentage:
          workData?.data?.time_limit_in_percentage === null
            ? 0
            : workData?.data?.time_limit_in_percentage,

        workstatus_id: workData?.data?.workstatus_id
          ? workData?.data?.workstatus_id
          : "",
        dlp_period_for_work_in_month: workData?.data
          ?.dlp_period_for_work_in_month
          ? workData?.data?.dlp_period_for_work_in_month
          : "",

        workstatus_detail: workData?.data?.workstatus_detail
          ? workData?.data?.workstatus_detail
          : "",
        govt_plan_work_detail: workData?.data?.govt_plan_work_detail
          ? workData?.data?.govt_plan_work_detail
          : "",
        date_raa: workData?.data?.date_raa ? workData?.data?.date_raa : "",
        amt_raa: workData?.data?.amt_raa ? workData?.data?.amt_raa : "",
        actual_date_of_completion_as_per_completion_of_project: workData?.data
          ?.actual_date_of_completion_as_per_completion_of_project
          ? new Date(
              workData?.data?.actual_date_of_completion_as_per_completion_of_project
            )
          : "",

        allow_target_radio: workData?.data?.revised_aa_required || "",
      });
      if (
        workData?.data?.date_completion_dlp_period ||
        actualdate ||
        watch("dlp_period_for_work_in_month")
      ) {
        setValue("date_completion_dlp_period", CompletionDLP);
      } else {
        setValue("date_completion_dlp_period", "");
      }
    }
  }, [workData?.data]);
  console.log(
    "Financial Progress in Percentage?>>>",
    workData?.data?.financial_progress
  );

  useEffect(() => {
    if (actualdate && watch("dlp_period_for_work_in_month") !== undefined) {
      setValue("date_completion_dlp_period", CompletionDLP);
    } else {
      setValue("date_completion_dlp_period", "");
    }
  }, [CompletionDLP]);

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <>
      <UILoader blocking={workData?.isLoading} loader={<RNBLoader />}>
        <Card>
          <CardBody className="basic_tab">
            <Form>
              <fieldset disabled={isFieldsetDisabled}>
                <Row className="gy-1">
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="financial_progress">
                      Financial Progress in Percentage
                    </Label>
                    <Controller
                      control={control}
                      name="financial_progress"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="financial_progress"
                            // value={field.value}
                            invalid={errors.financial_progress && true}
                          />
                        );
                      }}
                    />
                    {console.log("errors: ", errors)}
                    {errors.financial_progress && (
                      <FormFeedback>
                        {errors.financial_progress.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="physical_progress">
                      Physical Progress in Percentage
                    </Label>
                    <Controller
                      control={control}
                      name="physical_progress"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="physical_progress"
                            disabled={
                              (workStatusId || watch("workstatus_id")) !== 2
                            }
                            onChange={(e) => {
                              const pattern =
                                /^(?!0(\.0{1,2})?$)\d{0,25}(\.\d{0,2})?$/;
                              if (pattern.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            //   placeholder="Enter your Email id/Userunique_code"
                            // value={field.value}
                            invalid={errors.financial_progress && true}
                          />
                        );
                      }}
                    />
                    {errors.physical_progress && (
                      <FormFeedback className="d-block">
                        {errors.physical_progress.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label
                      className="form-label"
                      for="time_limit_in_percentage"
                    >
                      Time Limit in Percentage
                    </Label>
                    <Controller
                      control={control}
                      name="time_limit_in_percentage"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="time_limit_in_percentage"
                            //   placeholder="Enter your Email id/Username"
                            // value={field.value}
                            invalid={errors.time_limit_in_percentage && true}
                          />
                        );
                      }}
                    />
                    {errors.time_limit_in_percentage && (
                      <FormFeedback>
                        {errors.time_limit_in_percentage.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="workstatus_id">
                      Work Status
                    </Label>
                    <Controller
                      id="workstatus_id"
                      control={control}
                      ref={control.register}
                      name="workstatus_id"
                      render={({ field }) => (
                        <Select
                          // defaultMenuIsOpen
                          isClearable
                          {...field}
                          options={WorkStatus}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.status}
                          classNamePrefix="select"
                          className="react-select"
                          value={WorkStatus?.find(
                            (row) => row.id === watch("workstatus_id")
                          )}
                          onChange={(e) => {
                            field.onChange(e?.id);

                            if (e?.id !== 4 || e?.id !== 5 || e === null) {
                              // setActualDateCompletion("");
                              console.log("bjasbdf");
                              setValue(
                                "actual_date_of_completion_as_per_completion_of_project",
                                null
                              );
                              setValue("dlp_period_for_work_in_month", "");
                            }
                          }}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                    {errors.workstatus_id && (
                      <FormFeedback className="d-block">
                        {errors.workstatus_id.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/* Date piker pending for (Actual Date of Completion of Project) --------------------------------------------- */}
                  <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                    <Label
                      className="form-label"
                      for="actual_date_of_completion_as_per_completion_of_project"
                    >
                      Actual Date of Completion of Project
                    </Label>
                    <Controller
                      control={control}
                      name="actual_date_of_completion_as_per_completion_of_project"
                      className="w-100"
                      render={({ field }) => (
                        <ReactDatePicker
                          {...field}
                          disabled={
                            (workStatusId || watch("workstatus_id")) !== 4 &&
                            (workStatusId || watch("workstatus_id")) !== 5
                          }
                          selected={actualdate ? actualdate : null}
                          placeholder="DD-MM-YYYY"
                          onChange={handleDateChange}
                        />
                      )}
                    />
                    {errors.actual_date_of_completion_as_per_completion_of_project && (
                      <FormFeedback className="d-block">
                        {
                          errors
                            .actual_date_of_completion_as_per_completion_of_project
                            .message
                        }
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label
                      className="form-label"
                      for="dlp_period_for_work_in_month"
                    >
                      DLP Period for the Work in Months
                    </Label>
                    <Controller
                      control={control}
                      name="dlp_period_for_work_in_month"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled={
                              (workStatusId || watch("workstatus_id")) !== 4 &&
                              (workStatusId || watch("workstatus_id")) !== 5
                            }
                            id="dlp_period_for_work_in_month"
                            onChange={(e) => {
                              field.onChange(e);
                              // setdateCompletionDlp(e.target.value);
                            }}
                            placeholder="Enter DLP Period for the Work in Months"
                            // value={field.value}
                            invalid={
                              errors.dlp_period_for_work_in_month && true
                            }
                          />
                        );
                      }}
                    />
                    {errors.dlp_period_for_work_in_month && (
                      <FormFeedback>
                        {errors.dlp_period_for_work_in_month.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/* Date piker pending for (Date of Completion of DLP Period) --------------------------------------------- */}
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label
                      className="form-label"
                      for="date_completion_dlp_period"
                    >
                      Date of Completion of DLP Period
                    </Label>
                    <Controller
                      control={control}
                      name="date_completion_dlp_period"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="date_completion_dlp_period"
                            placeholder="Enter Date of Completion of DLP Period"
                            // value={field.value}
                            invalid={errors.date_completion_dlp_period && true}
                          />
                        );
                      }}
                    />
                    {errors.date_completion_dlp_period && (
                      <FormFeedback>
                        {errors.date_completion_dlp_period.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/* ********************************************************************************************************************* */}
                  {workData?.data?.govtplan_bytender === "govt" && (
                    <Col md={12} xs={12} className="mb-1 mt-0">
                      <Label className="form-label" for="govt_plan_work_detail">
                        Enter Status of Work in Detail for Govt. Plant in
                        Gujarati
                      </Label>
                      <Controller
                        control={control}
                        name="govt_plan_work_detail"
                        render={({ field }) => {
                          return (
                            <Input
                              type="textarea"
                              rows="3"
                              className="gujarati-bg"
                              {...field}
                              id="govt_plan_work_detail"
                              //   placeholder="Enter your Email id/Username"
                              // value={field.value}
                              invalid={errors.govt_plan_work_detail && true}
                            />
                          );
                        }}
                      />
                      {errors.govt_plan_work_detail && (
                        <FormFeedback className="d-block">
                          {errors.govt_plan_work_detail.message}
                        </FormFeedback>
                      )}
                    </Col>
                  )}

                  <Col md={12} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="workstatus_detail">
                      {workData?.data?.govtplan_bytender === "govt"
                        ? "Enter Status of Work in Detail for Misc. work in Gujarati"
                        : "Enter Status of Work in Detail in Gujarati"}
                    </Label>
                    <Controller
                      control={control}
                      name="workstatus_detail"
                      render={({ field }) => {
                        return (
                          <Input
                            type="textarea"
                            rows="3"
                            className="gujarati-bg"
                            {...field}
                            id="workstatus_detail"
                            //   placeholder="Enter your Email id/Username"
                            // value={field.value}
                            invalid={errors.workstatus_detail && true}
                          />
                        );
                      }}
                    />
                    {errors.workstatus_detail && (
                      <FormFeedback>
                        {errors.workstatus_detail.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col xs={12} className=" mb-2 pb-1">
                    <Button
                      className="btn btn-outline-dark waves-effect me-25"
                      type="reset"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </Form>
            <WorkStatusAARequired updatedate={userData} />
          </CardBody>
        </Card>
      </UILoader>
    </>
  );
};

export default WorkStatus;
