import moment from "moment";

export const SelectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#ffcd00" : "white",
    color: state.isSelected ? "black" : "black",
    padding: 10,
  }),
};

export const paginationOptions = (data) => [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 150, label: "150" },
  { value: 200, label: "200" },
  { value: data, label: "All" },
];

export const WideninguptoOption = [
  { value: "5.50 m", label: "5.50 m" },
  { value: "7.00 m", label: "7.00 m" },
  { value: "10.00 m", label: "10.00 m" },
  { value: "4-Lane", label: "4-Lane" },
  { value: "6-Lane", label: "6-Lane" },
];

export const ByGovtPlantByTenderOption = [
  { value: "govt", label: "By Govt. plant" },
  { value: "tender", label: "By Tender" },
];

// P.Q. Option
export const PqOption = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
// Tender Bid Validity Option
export const TenderBidValidityOption = [
  { value: "less_than_35", label: "≤ 35 Days" },
  { value: "more_than_35", label: "> 35 Days" },
];

export const TimeLimitOption = [
  { value: "24", label: "0-25" },
  { value: "49", label: "25-50" },
  { value: "74", label: "50-75" },
  { value: "75", label: "More than 75" },
];

export const FinancialProgressOption = [
  { value: "24", label: "0-25" },
  { value: "49", label: "25-50" },
  { value: "74", label: "50-75" },
  { value: "75", label: "More than 75" },
];

export const UtilityTypeOption = [
  {
    value: "electrict_line_pole_shifting_data",
    label: "ઇલેકટ્રીક લાઇન/પોલ શિફટીંગ",
  },
  {
    value: "water_supply_storm_water_line_data",
    label: "વોટર સપ્લાય / સ્ટોર્મ વોટર લાઇન",
  },
  { value: "gas_pipeline_data", label: "ગેસ પાઇપલાઇન" },
  { value: "optical_fiber_line_data", label: "ઓપ્ટીકલ ફાઈબર લાઇન" },
  { value: "anya_data", label: "અન્ય" },
];

export const periodOption = [
  { value: "0-6", label: "0-6 Months" },
  { value: "6-12", label: "6-12 Months" },
  { value: ">12", label: ">12 Months" },
];
export const stageOption = [
  { value: "proposal_level", label: "દરખાસ્ત લેવલે" },
  { value: "la_kalam_ten", label: "કલમ - ૧૦" },
  { value: "la_kalam_eleven", label: "કલમ - ૧૧" },
  { value: "la_kalam_thirteen", label: "કલમ - ૧૩" },
  { value: "la_kalam_nineteen", label: "કલમ - ૧૯ (એવોર્ડ)" },
  { value: "la_kalam_twenty_three", label: "કલમ - ૨૩" },
  { value: "la_jamin_kabajo_malel_chhe", label: "જમીનનો કબજો મળેલ છે." },
];

export const proposedworkWideningupto = [11, 12];

export const dateFormate = () => {
  return (
    <>
      <p>
        Dt. {`${moment().format("DD-MM-YYYY")}`}
        <br />
        Rs. in Lacs
      </p>
    </>
  );
};

export const ROLE = {
  SUPERADMIN: "1",
  SECRETORY: "2",
  CE: "3",
  SE: "4",
  EE: "5",
  DEE: "6",
};

export const proposedWorkArray = [10, 11, 12];
export const Statusarray = [1, 2, 3, 4, 5, 6];

// indian financial year start and end date
// export const getFinancialYearDates = () => {
//   const todayDate = moment(); // Get the current date using moment

//   const currentMonth = todayDate.month() + 1; // Add 1 to the month index (0-indexed to 1-indexed)
//   const currentYear = todayDate.year();
//   let financialYearStart;
//   let financialYearEnd;

//   if (currentMonth < 4) {
//     financialYearStart = moment([currentYear - 1, 3, 1]);
//     financialYearEnd = moment([currentYear, 2, 31]);
//   } else {
//     financialYearStart = moment([currentYear, 3, 1]);
//     financialYearEnd = moment([currentYear + 1, 2, 31]);
//   }

//   const formattedStartDate = financialYearStart.format("DD-MM-YYYY");
//   const formattedEndDate = financialYearEnd.format("DD-MM-YYYY");

//   return { start: formattedStartDate, end: formattedEndDate };
// };

export const getFinancialYearDates = () => {
  const todayDate = moment(); // Get the current date using moment

  const currentMonth = todayDate.month() + 1; // Add 1 to the month index (0-indexed to 1-indexed)
  const currentYear = todayDate.year();
  let financialYearStart;
  let financialYearEnd;

  if (currentMonth < 4) {
    financialYearStart = moment([currentYear - 1, 3, 1]);
    financialYearEnd = moment([currentYear, 2, 31]);
  } else {
    financialYearStart = moment([currentYear, 3, 1]);
    financialYearEnd = moment([currentYear + 1, 2, 31]);
  }

  const lastYearStart = financialYearStart.clone().subtract(1, "years");
  const lastYearEnd = financialYearEnd.clone().subtract(1, "years");

  const formattedStartDate = financialYearStart.format("DD-MM-YYYY");
  const formattedEndDate = financialYearEnd.format("DD-MM-YYYY");
  const formattedLastYearStart = lastYearStart.format("DD-MM-YYYY");
  const formattedLastYearEnd = lastYearEnd.format("DD-MM-YYYY");

  return {
    currentYear: { start: formattedStartDate, end: formattedEndDate },
    lastYear: { start: formattedLastYearStart, end: formattedLastYearEnd },
  };
};

// ? reasult of above  getFinancialYearDates function :
// {
//   "currentYear": {
//       "start": "01-04-2023",
//       "end": "31-03-2024"
//   },
//   "lastYear": {
//       "start": "01-04-2022",
//       "end": "31-03-2023"
//   }
// }

// ** YMD FORMATE DATE
export const YMDdate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

// ** YMD FORMATE DATE
export const DMYFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

// ** Get CurrentFinancialYear
export const CurrentFinancialYear = (date) => {
  if (parseInt(moment(date).format("MM")) < 4) {
    return `${parseInt(moment(date).format("YYYY") - 1)}-${moment().format(
      "YY"
    )}`;
  } else {
    return `${moment(date).format("YYYY")}-${
      parseInt(moment(date).format("YY")) + 1
    }`;
  }
};

// * for Example 2023-24
export const FYOnlyYears = () => {
  const TodayDate = moment();
  if (TodayDate) {
    if (parseInt(moment(TodayDate).format("MM")) < 4) {
      return `${parseInt(moment(TodayDate).format("YYYY") - 1)}-${moment(
        TodayDate
      ).format("YY")}`;
    } else {
      return `${moment(TodayDate).format("YYYY")}-${
        parseInt(moment(TodayDate).format("YY")) + 1
      }`;
    }
  }
};

export const TodayDate = () => {
  return moment().format("DD-MM-YYYY");
};

export const TomorrowDate = () => {
  return moment().add(1, "day").format("DD-MM-YYYY");
};

// For New-Work > Proposed Work field = Pre Construction Activity ( proposedwork_id = 10 )
export const ProposedWork = [10];

// export const columnFrozenFunction = (columnFrozenRoleObject) => {
//   console.log("columnFrozenRoleObject: ", columnFrozenRoleObject);
//   const currentUserRole = getUserData()?.role;
//   console.log("currentUserRole: ", currentUserRole);
//   const [ColumnsFrozenByRole, setColumnsFrozenByRole] = useState();
//   console.log("ColumnsFrozenByRole: ", ColumnsFrozenByRole);
//   useEffect(() => {
//     if (currentUserRole === ROLE.CE || currentUserRole === ROLE.SECRETORY) {
//       setColumnsFrozenByRole(5);
//     } else if (currentUserRole === ROLE.SE) {
//       setColumnsFrozenByRole(5);
//     } else if (currentUserRole === ROLE.EE) {
//       setColumnsFrozenByRole(5);
//     }
//   }, [currentUserRole]);
// };

// * ex: 1212.56
export const Floatvalue = (data) => {
  return parseFloat(data);
};
