/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
import { Input } from "reactstrap";
import {
  updateExpenditure,
  updateExpenditureWork,
} from "../../../../services/eeservices";
import { handleKeyPress } from "../../../../utility/Utils";
import {
  FYOnlyYears,
  TodayDate,
  TomorrowDate,
  getFinancialYearDates,
} from "../../../../common/utils";
import moment from "moment";

const handleTargetExpen = async (payload, onColumnUpdate, isFurnitureRow) => {
  try {
    const res = await updateExpenditure(payload);
    onColumnUpdate(payload, res.data, isFurnitureRow);
  } catch (err) {
    console.log("err: ", err);
  }
};

const handleWorkExpen = async (
  payload,
  onColumnUpdate,
  isFurnitureRow,
  workExpenseIdKey
) => {
  try {
    const res = await updateExpenditureWork(payload);
    onColumnUpdate(payload, res.data, isFurnitureRow, workExpenseIdKey);
  } catch (err) {
    console.log("err: ", err);
  }
};

// const isValidInput = (value) => {
//   const pattern = /^\d*(\.\d{0,2})?$/;
//   return pattern.test(value);
// };

// const handleKeyPress = (event) => {
//   const newValue = event.target.value + event.key;
//   if (!isValidInput(newValue)) {
//     event.preventDefault();
//   }
// };

export const getColumns = (handleEditStage) => {
  const columns = [
    {
      Header: "Sr No",
      accessor: "srnoo",
      show: true,
      minWidth: "60px",
      rowSpan: 2,
      columns: [
        {
          Header: "Sr No",
          isDisplayNone: true,
          accessor: "srno",
        },
      ],
    },
    {
      Header: "Name of Sub Division",
      accessor: "sub_divisionn",
      show: true,
      minWidth: "135px",
      rowSpan: 2,
      columns: [
        {
          Header: "Name of Sub Division",
          isDisplayNone: true,
          accessor: "sub_division",
        },
      ],
    },
    {
      Header: "Budget Head",
      accessor: "budget_headd",
      show: true,
      minWidth: "135px",
      rowSpan: 2,
      columns: [
        {
          Header: "Budget Head",
          isDisplayNone: true,
          accessor: "budget_head",
        },
      ],
    },
    {
      Header: "Name of Work",
      accessor: "name_of_workk",
      show: true,
      minWidth: "287px",
      rowSpan: 2,
      columns: [
        {
          Header: "Name of Work",
          isDisplayNone: true,
          accessor: "name_of_work",
          Cell: (cell) => (
            <div
              className="celllll"
              style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
              onClick={() => handleEditStage(cell.row.original)}
            >
              {cell.value}
            </div>
          ),
        },
      ],
    },
    {
      Header: "Tender App. Amount",
      accessor: "tender_app_amountt",
      show: true,
      minWidth: "137px",
      rowSpan: 2,
      columns: [
        {
          Header: "Tender App. Amount",
          isDisplayNone: true,
          accessor: "tender_app_amount",
        },
      ],
    },

    {
      Header: "Name of Agency",
      accessor: "name_of_agencyy",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: "Name of Agency",
          isDisplayNone: true,
          accessor: "name_of_agency",
        },
      ],
    },

    {
      Header: "Tentative Total Project Cost",
      accessor: "tentativetotall",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: "Tentative Total Project Cost",
          isDisplayNone: true,
          accessor: "tentativetotal",
        },
      ],
    },

    {
      Header: `Expen. incu. upto ${
        getFinancialYearDates()?.lastYear?.end
      } (LAST F.Y.)`,
      accessor: "expenincuu",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `Expen. incu. upto ${
            getFinancialYearDates()?.lastYear?.end
          } (LAST F.Y.)`,
          isDisplayNone: true,
          accessor: "expenincu",
        },
      ],
    },
    {
      Header: `SPILL OVER AS ON ${
        getFinancialYearDates()?.currentYear?.start
      }  & OF CURRENT YEAR APP. WORK`,
      accessor: "Spilloverr",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `SPILL OVER AS ON ${
            getFinancialYearDates()?.currentYear?.start
          }  & OF CURRENT YEAR APP. WORK`,
          isDisplayNone: true,
          accessor: "Spillover",
        },
      ],
    },
    {
      Header: `Target Expen. of Year ${FYOnlyYears()}`,
      accessor: "targetExpenn",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `Target Expen. of Year ${FYOnlyYears()}`,
          isDisplayNone: true,
          accessor: "targetExpen",
          Cell: ({ row, onColumnUpdate }) => {
            // row.original.id === 1262 &&
            //   console.log("updatedData: 1", row.original.targetExpen);
            return (
              <>
                {row.original.targetExpen === "--" ? (
                  "--"
                ) : (
                  <>
                    {row.original.target_yes_no === "0" ? (
                      row.original.targetExpen
                    ) : (
                      <Input
                        type="text"
                        defaultValue={row.original.targetExpen}
                        onKeyPress={handleKeyPress}
                        onBlur={(e) => {
                          const payload = {
                            field: "target_value",
                            value: e.target.value,
                            work_id: row.original.id,
                            package_id: row.original.packageId,
                            govtplan_bytender:
                              row.original.govtPlanByTender === null
                                ? "tender"
                                : row.original.govtPlanByTender,
                          };

                          if (row.original.targetExpen !== payload.value) {
                            handleTargetExpen(
                              payload,
                              onColumnUpdate,
                              row.original?.isFurnitureRow
                            );
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            );
          },
        },
      ],
    },
    {
      Header: `CURRENT YEAR EXPEN. FROM ${
        getFinancialYearDates().currentYear.start
      } TO TILL ${TodayDate()}`,
      accessor: "Expenincurrentyearr",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `CURRENT YEAR EXPEN. FROM ${
            getFinancialYearDates().currentYear.start
          } TO TILL ${TodayDate()}`,
          isDisplayNone: true,
          accessor: "Expenincurrentyear",
        },
      ],
    },

    {
      Header: `Probable Expen. from ${TomorrowDate()} to ${
        getFinancialYearDates()?.currentYear?.end
      } for year ${FYOnlyYears()}`,
      accessor: "probableExpenn",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `Probable Expen. from ${TomorrowDate()} to ${
            getFinancialYearDates()?.currentYear?.end
          } for year ${FYOnlyYears()}`,
          isDisplayNone: true,
          accessor: "probableExpen",
        },
      ],
    },
    {
      Header: `Total Expen. occu. till ${TodayDate()} on Project`,
      accessor: "totalExpenn",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `Total Expen. occu. till ${TodayDate()} on Project`,
          isDisplayNone: true,
          accessor: "totalExpen",
        },
      ],
    },
    {
      Header: `${moment().subtract(1, "months").format("MMM-YY")}`,
      columns: [
        {
          Header: "MAIN WORK",
          accessor: "prev_main_work",
          Cell: ({ row, onColumnUpdate }) => {
            return (
              <>
                {row.original.prev_main_work === "--" ? (
                  "--"
                ) : (
                  <Input
                    type="text"
                    defaultValue={row.original.prev_main_work}
                    disabled={
                      !isNaN(row.original.targetExpen) &&
                      parseInt(row.original.targetExpen) === 0
                    }
                    onKeyPress={handleKeyPress}
                    onBlur={(e) => {
                      const payload = {
                        field: "prev_main_work",
                        value: e.target.value,
                        work_id: row.original.id,
                        id: row.original.prev_work_expense_id,
                        package_id: row.original.packageId,
                        govtplan_bytender:
                          row.original.govtPlanByTender === null
                            ? "tender"
                            : row.original.govtPlanByTender,
                      };
                      if (row.original.prev_main_work !== payload.value) {
                        handleWorkExpen(
                          payload,
                          onColumnUpdate,
                          row.original?.isFurnitureRow,
                          "prev_work_expense_id"
                        );
                      }
                    }}
                  />
                )}
              </>
            );
          },
        },
        {
          Header: "FOREST UTILITY & MISC.",
          accessor: "prev_forest_utility",
          Cell: ({ row, onColumnUpdate }) => {
            return (
              <>
                {row.original.prev_forest_utility === "--" ? (
                  "--"
                ) : (
                  <Input
                    type="text"
                    defaultValue={row.original.prev_forest_utility}
                    disabled={
                      !isNaN(row.original.targetExpen) &&
                      parseInt(row.original.targetExpen) === 0
                    }
                    onKeyPress={handleKeyPress}
                    onBlur={(e) => {
                      const payload = {
                        field: "prev_forest_utility_misc_work",
                        value: e.target.value,
                        work_id: row.original.id,
                        id: row.original.prev_work_expense_id,
                        package_id: row.original.packageId,
                        govtplan_bytender:
                          row.original.govtPlanByTender === null
                            ? "tender"
                            : row.original.govtPlanByTender,
                      };
                      if (row.original.prev_forest_utility !== payload.value) {
                        handleWorkExpen(
                          payload,
                          onColumnUpdate,
                          row.original?.isFurnitureRow,
                          "prev_work_expense_id"
                        );
                      }
                    }}
                  />
                )}
              </>
            );
          },
        },
      ],
    },
    {
      Header: `${moment().format("MMM-YY")}`,
      columns: [
        {
          Header: "MAIN WORK",
          accessor: "curr_main_work",
          Cell: ({ row, onColumnUpdate }) => {
            return (
              <>
                {row.original.curr_main_work === "--" ? (
                  "--"
                ) : (
                  <Input
                    type="text"
                    defaultValue={row.original.curr_main_work}
                    disabled={
                      !isNaN(row.original.targetExpen) &&
                      parseInt(row.original.targetExpen) === 0
                    }
                    onKeyPress={handleKeyPress}
                    onBlur={(e) => {
                      const payload = {
                        field: "current_main_work",
                        value: e.target.value,
                        work_id: row.original.id,
                        id: row.original.curr_work_expense_id,
                        package_id: row.original.packageId,
                        govtplan_bytender:
                          row.original.govtPlanByTender === null
                            ? "tender"
                            : row.original.govtPlanByTender,
                      };
                      if (row.original.curr_main_work !== payload.value) {
                        handleWorkExpen(
                          payload,
                          onColumnUpdate,
                          row.original?.isFurnitureRow,
                          "curr_work_expense_id"
                        );
                      }
                    }}
                  />
                )}
              </>
            );
          },
        },
        {
          Header: "FOREST UTILITY & MISC.",
          accessor: "curr_forest_utility",
          Cell: ({ row, onColumnUpdate }) => {
            return (
              <>
                {row.original.curr_forest_utility === "--" ? (
                  "--"
                ) : (
                  <Input
                    type="text"
                    defaultValue={row.original.curr_forest_utility}
                    disabled={
                      !isNaN(row.original.targetExpen) &&
                      parseInt(row.original.targetExpen) === 0
                    }
                    onKeyPress={handleKeyPress}
                    onBlur={(e) => {
                      const payload = {
                        field: "current_forest_utility_misc_work",
                        value: e.target.value,
                        work_id: row.original.id,
                        id: row.original.curr_work_expense_id,
                        package_id: row.original.packageId,
                        govtplan_bytender:
                          row.original.govtPlanByTender === null
                            ? "tender"
                            : row.original.govtPlanByTender,
                      };
                      if (row.original.curr_forest_utility !== payload.value) {
                        handleWorkExpen(
                          payload,
                          onColumnUpdate,
                          row.original?.isFurnitureRow,
                          "curr_work_expense_id"
                        );
                      }
                    }}
                  />
                )}
              </>
            );
          },
        },
      ],
    },
    {
      Header: "CURRENT SPILL OVER OF PROJECT",
      accessor: "currentSpillOverOfProjectt",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: "CURRENT SPILL OVER OF PROJECT",
          isDisplayNone: true,
          accessor: "currentSpillOverOfProject",
        },
      ],
    },
    {
      Header: `MODIFIED PROBABLE EXPEN. FROM ${TomorrowDate()} TO ${
        getFinancialYearDates().currentYear.end
      } FOR YEAR ${FYOnlyYears()}`,
      accessor: "probableExpennInputt",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `MODIFIED PROBABLE EXPEN. FROM ${TomorrowDate()} TO ${
            getFinancialYearDates().currentYear.end
          } FOR YEAR ${FYOnlyYears()}`,
          isDisplayNone: true,
          accessor: "probableExpennInput",
          Cell: ({ row, onColumnUpdate }) => {
            return (
              <>
                {row.original.probableExpennInput === "--" ? (
                  "--"
                ) : (
                  <>
                    {row.original.modified_yes_no === "0" ? (
                      row.original.probableExpennInput
                    ) : (
                      <Input
                        type="text"
                        defaultValue={row.original.probableExpennInput}
                        disabled={
                          !isNaN(row.original.targetExpen) &&
                          parseInt(row.original.targetExpen) === 0
                        }
                        onKeyPress={handleKeyPress}
                        onBlur={(e) => {
                          const payload = {
                            field: "modified_value",
                            value: e.target.value,
                            work_id: row.original.id,
                            package_id: row.original.packageId,
                            govtplan_bytender:
                              row.original.govtPlanByTender === null
                                ? "tender"
                                : row.original.govtPlanByTender,
                          };
                          if (
                            row.original.probableExpennInput !== payload.value
                          ) {
                            handleTargetExpen(
                              payload,
                              onColumnUpdate,
                              row.original?.isFurnitureRow
                            );
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            );
          },
        },
      ],
    },
    {
      Header: `MODIFICATION IN TARGET EXPENDITURE OF YEAR ${FYOnlyYears()}`,
      accessor: "modificationTargett",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `MODIFICATION IN TARGET EXPENDITURE OF YEAR ${FYOnlyYears()}`,
          isDisplayNone: true,
          accessor: "modificationTarget",
        },
      ],
    },
    {
      Header: `FINAL MODIFIED TARGET EXPENDITURE OF YEAR ${FYOnlyYears()}`,
      accessor: "finalModifiedTargett",
      show: true,
      minWidth: "196px",
      rowSpan: 2,
      columns: [
        {
          Header: `FINAL MODIFIED TARGET EXPENDITURE OF YEAR ${FYOnlyYears()}`,
          isDisplayNone: true,
          accessor: "finalModifiedTarget",
        },
      ],
    },
  ];
  return columns;
};
