import { createUrlFromObject } from "../utility/Utils";
import authHttp from "./authHttp";

// **Dashboard

export const getEEDashboardDataList = async (id) => {
  const url = `/api/eeDashboard/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getEEDashboardHandwiseDatalist = async (value) => {
  const url = `/api/expenditureHeadwise/?circle_id=${value.circle_id}&division_id=${value.division_id}&budgethead_id=all`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getMainWorkPackgeListing = async () => {
  const url = `/api/mainWorkPackgeListing`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deletePackage = async (id) => {
  const url = `/api/deletePackage?id=${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getMainWorkCreatePackgeListing = async () => {
  const url = `/api/mainWorkCreatePackgeListing`;
  const { data } = await authHttp.get(url);
  return data;
};
export const getConsultancyExpenditureList = async () => {
  const url = `/api/consultancy-expenditure-list`;
  const { data } = await authHttp.get(url);
  return data;
};

// /api/createPackage

export const createPackage = async (value) => {
  const url = `/api/createPackage`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getExpenditureList = async (value) => {
  const url = createUrlFromObject(value, "api/expenditure-list");
  const { data } = await authHttp.get(url);
  return data;
};

export const updateExpenditure = async (value) => {
  const url = "api/expenditure/update";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateExpenditureWork = async (value) => {
  const url = "api/expenditure/work-expense-update";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateConsultancyExpenditureUpdatePrevious = async (value) => {
  const url = "/api/consultancy-expenditure-update-previous";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateConsultancyExpenditureUpdateCurrent = async (value) => {
  const url = "/api/consultancy-expenditure-update-current";
  const { data } = await authHttp.post(url, value);
  return data;
};
