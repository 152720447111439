import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BasicDetails from "./AllField/BasicDetails";
import LAforestUtility from "./AllField/LAforestUtility";
import PAA_AA from "./AllField/PAA_AA";
import TS_DTP from "./AllField/TS_DTP";
import TenderToWorkOrderStage from "./AllField/TenderToWorkOrderStage";
import TimeLimit_ExtraExcess from "./AllField/TimeLimit_ExtraExcess";
import Expenditure from "./AllField/Expenditure";
import WorkStatus from "./AllField/WorkStatus";
import LatitudeLongitude from "./AllField/LatitudeLongitude";
import ReasonEntry from "./AllField/ReasonEntry";
import MainTitle from "../../components/MainTitle/MainTitle";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWorkTabsData } from "../../../redux/WorkRedux/getWorkSlice";
import "@styles/react/libs/react-select/_react-select.scss";
import { ProposedWork } from "../../../common/utils";
// import MainTitle from "../MainTitle/MainTitle";

const WorkDetailsTabs = ({ modal, editWork }) => {
  const [active, setActive] = useState("1");
  const userData = useSelector((state) => state?.auth.userData.data);
  const workData = useSelector((state) => state?.getWork);
  const dispatch = useDispatch();
  const { id } = useParams({});
  const urlParams = new URLSearchParams(window.location.search);
  const tabval = urlParams.get("tab");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  useEffect(() => {
    if (editWork?.tab) {
      setActive(editWork?.tab);
    }
    if (tabval) {
      setActive(tabval);
      // urlParams.delete("tab");
      // const newUrl = `${window.location.pathname}${urlParams.toString()}`;
      // window.history.replaceState(null, "", newUrl);
    }
  }, [editWork?.tab, tabval]);

  const work = editWork ? editWork.data : id;

  const data = {
    id: userData?.circle_id,
    role: userData?.role,
    work_id: work ? work : undefined,
  };

  useEffect(() => {
    // const data = {
    //   id: userData?.circle_id,
    //   role: userData?.role,
    //   work_id: id,
    // };
    dispatch(getWorkTabsData(data));
  }, []);

  // const isWorkDataIdNull = workData?.data?.id === null;
  // // ... (rest of the code)
  // useEffect(() => {
  //   // Get all form elements inside the card body
  //   const formElements = document.querySelectorAll(
  //     ".basic_tab form input, .basic_tab form textarea, .basic_tab form select, .btn"
  //   );
  //   // Loop through each form element and set the 'disabled' attribute
  //   formElements.forEach((element) => {
  //     element.disabled = isWorkDataIdNull;
  //     element.style.pointerEvents = isWorkDataIdNull ? "none" : "auto";
  //   });
  // }, [isWorkDataIdNull]);

  return (
    <>
      {/* <div className="table-title ee_new_work_title"> */}
      {!modal ? (
        <MainTitle
          title={workData?.data?.name_of_work_english}
          tpc={workData?.data?.tentative_tpc}
        />
      ) : (
        ""
      )}
      {/* </div> */}
      <div className="card-body app-tabs p-0">
        <Nav tabs>
          <NavItem>
            <NavLink
              active={active === "1"}
              onClick={() => {
                toggle("1");
              }}
            >
              Basic Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "2"}
              onClick={() => {
                toggle("2");
              }}
            >
              LA-Forest-Utility
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "3"}
              onClick={() => {
                toggle("3");
              }}
            >
              PAA_AA
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "4"}
              onClick={() => {
                toggle("4");
              }}
              disabled={ProposedWork?.includes(workData?.data?.proposedwork_id)}
            >
              TS-DTP
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "5"}
              onClick={() => {
                toggle("5");
              }}
              disabled={ProposedWork?.includes(workData?.data?.proposedwork_id)}
            >
              Tender to Work Order Stage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "6"}
              onClick={() => {
                toggle("6");
              }}
              disabled={ProposedWork?.includes(workData?.data?.proposedwork_id)}
            >
              Time limit / Extra - Excess
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "7"}
              onClick={() => {
                toggle("7");
              }}
            >
              Expenditure
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "8"}
              onClick={() => {
                toggle("8");
              }}
            >
              Work Status
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "9"}
              onClick={() => {
                toggle("9");
              }}
            >
              Latitude-Longitude
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={active === "10"}
              onClick={() => {
                toggle("10");
              }}
            >
              Reason Entry
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent className="py-50" activeTab={active}>
        <TabPane tabId="1">
          <BasicDetails userData={data} />
        </TabPane>
        <TabPane tabId="2">
          <LAforestUtility />
        </TabPane>
        <TabPane tabId="3">
          <PAA_AA userData={data} />
        </TabPane>
        <TabPane tabId="4">
          <TS_DTP userData={data} />
        </TabPane>
        <TabPane tabId="5">
          <TenderToWorkOrderStage />
        </TabPane>
        <TabPane tabId="6">
          <TimeLimit_ExtraExcess userData={data} />
        </TabPane>
        <TabPane tabId="7">
          <Expenditure />
        </TabPane>
        <TabPane tabId="8">
          <WorkStatus userData={data} />
        </TabPane>
        <TabPane tabId="9">
          <LatitudeLongitude />
        </TabPane>
        <TabPane tabId="10">
          <ReasonEntry />
        </TabPane>
      </TabContent>
    </>
  );
};

export default WorkDetailsTabs;
