// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import todo from "@src/views/apps/todo/store";
import chat from "@src/views/apps/chat/store";
import users from "@src/views/apps/user/store";
import email from "@src/views/apps/email/store";
import kanban from "@src/views/apps/kanban/store";
import invoice from "@src/views/apps/invoice/store";
import calendar from "@src/views/apps/calendar/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";

// ! R&B Redux
import loading from "./LoadingRedux/loadingSlice";
// ** Admin Location
import location from "./superAdmin/location";
// ** SE user
import sedashboard from "./SERedux/seDashboard";
import taluka from "./SERedux/taluka";

// ** EE user
import eedashboard from "./EERedux/eeDashboard";
import cedashboard from "./CERedux/ceDashboard";
import getWork from "./WorkRedux/getWorkSlice";

const rootReducer = {
  todo,
  chat,
  email,
  users,
  kanban,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  layout,
  navbar,
  auth,
  location,
  sedashboard,
  eedashboard,
  taluka,
  cedashboard,
  getWork,
  loading,
};

export default rootReducer;
