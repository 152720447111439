/* eslint-disable multiline-ternary */
// ** React Imports
import "@styles/react/libs/react-select/_react-select.scss";
import { Fragment, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Excel from "../../../../../assets/images/excel.png";
import Print from "../../../../../assets/images/print.png";
import ColumnsVisibility from "../../../../../assets/images/ColumnsVisibility.png";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { SortArrowDown, SortArrowtop } from "../../../../../assets/images";
import { SelectCustomStyles } from "../../../../../common/utils";
import {
  columnChanges,
  getColumnlist,
} from "../../../../../services/adminDashboard";
import { getPaaReceived_AbstractData } from "../../../../../services/reportsServices";
import {
  getAbstractData,
  getAbstractHeaderColumnsByRole,
} from "./PaaReceivedAaNotReceivedUtils";

const ReportPaaReceivedAaNotReceivedModal = ({
  abstractModal,
  setAbstractModal,
}) => {
  const loginUser = useSelector((state) => state.auth.userData.data);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: tableData?.length, label: "All" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // ** Get List Of Abstract
  useEffect(() => {
    setIsLoading(true);

    // *  PaaReceived_Abstract data
    getPaaReceived_AbstractData()
      .then((res) => {
        const data = getAbstractData(res.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }, []);

  // *  For english/gujrati button
  const [engGujState, setEngGujState] = useState(false);
  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
  };

  // ** Dynamic Get Data
  const data = useMemo(() => {
    // dummy data for table design
    return tableData.map((item) => ({
      id: item.id,
      // srno: item.id,
      bgColor: item.bg_color,
      name_Of_circle: item.name_of_circle,
      name_Of_Division: item.division_name,
      nameOfSubDivision: item.sub_division_name,
      budget_head: item.budget_heads_name,
      scheme_of_budget: item.scheme_of_budgets_name,
      name_of_work: item.total_works,
      // lengthInKM: item.length_in_km,
      paaAmount: item.paa_amount_total,
      // paaDate: item.paa_date,
      blockEstimatesSubmittedToGovernment:
        item.paa_block_estimates_submitted_to_govt_yes,
      blockEstimateAmount: item.paa_block_estimates_remaining_to_submit,
      blockEstimateSubmissionDate:
        item.paa_block_estimates_submitted_to_govt_yes,
    }));
  }, [tableData, engGujState]);
  // console.log("tableData>>>>", tableData);

  // ** Columns For Table
  const columns = useMemo(
    () => getAbstractHeaderColumnsByRole(engGujState),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      if (!hiddenColumns.includes(columns[i].accessor)) {
        left += 120;
      }
    }
    return left;
  }

  // * Start For columns hide -- API
  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      columns.forEach((item, index) => {
        if (row === item.accessor) {
          data.push(index);
        }
      });
    }
    return data;
  };

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUser?.user_id,
      module_name: "paaReceivedAANotReceivedAbstract",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];
    const hiddenCol = columnsArr.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const item = columns[row];
        data.push(item.accessor);
      }
    }
    return data;
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUser?.user_id,
      module_name: "paaReceivedAANotReceivedAbstract",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  }, []);
  // *  Eend For columns hide -- API

  return (
    <Fragment>
      <Modal
        isOpen={abstractModal?.isOpen}
        toggle={() =>
          setAbstractModal({
            isOpen: false,
            data: "",
          })
        }
        className="modal-dialog-centered modal-xl"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => {
            setAbstractModal({
              isOpen: false,
              data: "",
            });
          }}
        ></ModalHeader>
        <ModalBody className="p-0 ">
          <div className="d-flex px-2 justify-content-between align-items-center pb-1">
            {/* <div> */}
            <h2 className="mb-0">
              Abstract - PAA RECEIVED AA NOT RECEIVED WORKS
            </h2>
            {/* </div> */}
            <div className="dt-action-button pull-right abstract_dateinfo btn_search_box d-flex">
              <p>
                Dt. {`${moment().format("DD-MM-YYYY")}`} <br />
                Rs. in Lacs
              </p>
              <div>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">Excel</span>
                </Button.Ripple>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Print} alt="icon" className="me-1" />
                  <span className="align-middle">Print</span>
                </Button.Ripple>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">PDF</span>
                </Button.Ripple>
                <ButtonDropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  className="projects_btn me-25"
                >
                  <DropdownToggle color="primary" outline caret>
                    <img src={ColumnsVisibility} alt="icon" className="me-1" />
                    <span className="align-middle">Columns Visibility</span>
                  </DropdownToggle>
                  <DropdownMenu className="p-40 vb-drop column_visibility">
                    {dropdownOptions.map((option, index) => (
                      <Button.Ripple
                        key={option.value}
                        color="primary"
                        onClick={() =>
                          handleToggleHiddenColumns(index, option.value)
                        }
                        className={
                          !hiddenColumns.includes(option.value)
                            ? "active w-100 visibility-Option"
                            : "w-100 visibility-Option bg-white"
                        }
                      >
                        {option.label}
                      </Button.Ripple>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <Button.Ripple
                  className="me-25"
                  onClick={() => handleGujaratiEnglish()}
                >
                  <span className="align-middle ms-25">Guj. / Eng.</span>
                </Button.Ripple>
              </div>
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id || columnIndex < 0;
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={
                            (isFrozen ? "dtfc-fixed-left" : "dtfc-cell",
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : "")
                          }
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  // {rows.map((row) => {
                  //   prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{ backgroundColor: row.original.bgColor }}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );

                        const isFrozen =
                          frozenColumn === cell.column.id || cellIndex < 0;
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                            }
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: pageSize,
                  label:
                    pageSize === tableData?.length
                      ? "All"
                      : pageSize.toString(),
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPageSize(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                // menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>
            <ReactPaginate
              nextLabel=""
              pageCount={Math.ceil(preGlobalFilteredRows.length / pageSize)}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => gotoPage(selected.selected)}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
            {/* ReactPaginate End */}

            <span className="d-none">
              Page{" "}
              <strong>
                {pageIndex + 1} of{" "}
                {Math.ceil(preGlobalFilteredRows.length / pageSize)}
              </strong>
            </span>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ReportPaaReceivedAaNotReceivedModal;
