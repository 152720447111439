/* eslint-disable object-shorthand */
import { ROLE } from "../../../../../common/utils";

// SUPERADMIN: "1",
// SECRETORY: "2",
// CE: "3",
// SE: "4",
// EE: "5",
// DEE: "6",

const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = (engGujState, handleEditStage) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Circle ",
      accessor: "name_Of_circle",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "પેટા વિભાગનું નામ" : "Name of Sub Division",
      accessor: "nameOfSubDivision",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની યોજના / બજેટ આઈટમનું નામ"
        : "Scheme Of Budget",
      accessor: "scheme_of_budget",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામનું નામ" : "Name Of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "303px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));
        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },
    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Length in KM",
      accessor: "lengthInKM",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "PAA Amount",
      accessor: "paaAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "PAA Date",
      accessor: "paaDate",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "બ્લોક એસ્ટીમેટ સાદર કરેલ છે?"
        : "Block Estimates Submitted to Govt.? Yes / No",
      accessor: "blockEstimatesSubmittedToGovernment",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div style={{ textTransform: "capitalize" }}>{cell.value}</div>
      ),
    },
    {
      Header: engGujState ? "બ્લોક એસ્ટીમેટની રકમ" : "Block Estimate Amount",
      accessor: "blockEstimateAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "બ્લોક એસ્ટીમેટ સાદર કર્યા તારીખ"
        : "Block Estimate submission date",
      accessor: "blockEstimateSubmissionDate",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export const getAbstractHeaderColumnsByRole = (engGujState) => {
  const columnsArr = [
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Circle ",
      accessor: "name_Of_circle",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની યોજના / બજેટ આઈટમનું નામ"
        : "Scheme Of Budget",
      accessor: "scheme_of_budget",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામનું નામ" : "Number Of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "303px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "Total PAA Amount",
      accessor: "paaAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "બ્લોક એસ્ટીમેટ સાદર કરેલ છે?"
        : "No. Of Block Estimates Submitted to Govt.",
      accessor: "blockEstimatesSubmittedToGovernment",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "બ્લોક એસ્ટીમેટની રકમ"
        : "No. Of Block Estimates Remaining To Submit",
      accessor: "blockEstimateAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    // {
    //   Header: engGujState
    //     ? "બ્લોક એસ્ટીમેટ સાદર કર્યા તારીખ"
    //     : "Block Estimate submission date",
    //   accessor: "blockEstimateSubmissionDate",
    //   show: true,
    //   minWidth: "145px",
    //   roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    // },
  ];

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export const getAbstractData = (data) => {
  const output = [];
  let last_sum_total_works = 0;
  let last_sum_paa_amount_total = 0;
  let last_sum_paa_block_estimates_remaining_to_submit = 0;
  let last_sum_paa_block_estimates_submitted_to_govt_yes = 0;

  if (currentUserRole === ROLE.CE) {
    for (const circle in data) {
      let c_sum_total_works = 0;
      let c_sum_paa_amount_total = 0;
      let c_sum_paa_block_estimates_remaining_to_submit = 0;
      let c_sum_paa_block_estimates_submitted_to_govt_yes = 0;

      for (const division in data[circle]) {
        let d_sum_total_works = 0;
        let d_sum_paa_amount_total = 0;
        let d_sum_paa_block_estimates_remaining_to_submit = 0;
        let d_sum_paa_block_estimates_submitted_to_govt_yes = 0;

        for (const schemeOfBudget in data[circle][division]) {
          const budgetHeadsData = data[circle][division][schemeOfBudget][0];
          output.push({
            name_of_circle: circle,
            name_of_division: division,
            scheme_of_budget: schemeOfBudget,
            ...budgetHeadsData,
          });

          d_sum_total_works += budgetHeadsData.total_works;
          d_sum_paa_amount_total += parseInt(budgetHeadsData.paa_amount_total);

          d_sum_paa_block_estimates_remaining_to_submit += parseInt(
            budgetHeadsData.paa_block_estimates_remaining_to_submit
          );
          d_sum_paa_block_estimates_submitted_to_govt_yes += parseInt(
            budgetHeadsData.paa_block_estimates_submitted_to_govt_yes
          );
        }

        output.push({
          bg_color: "#ffc0cb",
          budget_heads_name: "All Heads",
          scheme_of_budgets_name: "Total",
          total_works: d_sum_total_works,
          paa_amount_total: d_sum_paa_amount_total,
          paa_block_estimates_remaining_to_submit:
            d_sum_paa_block_estimates_remaining_to_submit,
          paa_block_estimates_submitted_to_govt_yes:
            d_sum_paa_block_estimates_submitted_to_govt_yes,
        });

        c_sum_total_works += d_sum_total_works;
        c_sum_paa_amount_total += d_sum_paa_amount_total;
        c_sum_paa_block_estimates_remaining_to_submit +=
          d_sum_paa_block_estimates_remaining_to_submit;
        c_sum_paa_block_estimates_submitted_to_govt_yes +=
          d_sum_paa_block_estimates_submitted_to_govt_yes;
      }

      output.push({
        bg_color: "#98fb98",
        division_name: "All Division",
        budget_heads_name: "All Heads",
        scheme_of_budgets_name: "Total",
        total_works: c_sum_total_works,
        paa_amount_total: c_sum_paa_amount_total,
        paa_block_estimates_remaining_to_submit:
          c_sum_paa_block_estimates_remaining_to_submit,
        paa_block_estimates_submitted_to_govt_yes:
          c_sum_paa_block_estimates_submitted_to_govt_yes,
      });

      last_sum_total_works += c_sum_total_works;
      last_sum_paa_amount_total += c_sum_paa_amount_total;
      last_sum_paa_block_estimates_remaining_to_submit +=
        c_sum_paa_block_estimates_remaining_to_submit;
      last_sum_paa_block_estimates_submitted_to_govt_yes +=
        c_sum_paa_block_estimates_submitted_to_govt_yes;
    }

    output.push({
      bg_color: "#FFFF00",
      name_of_circle: "All Circle",
      division_name: "All Division",
      budget_heads_name: "All Heads",
      scheme_of_budgets_name: "Total",
      total_works: last_sum_total_works,
      paa_amount_total: last_sum_paa_amount_total,
      paa_block_estimates_remaining_to_submit:
        last_sum_paa_block_estimates_remaining_to_submit,
      paa_block_estimates_submitted_to_govt_yes:
        last_sum_paa_block_estimates_submitted_to_govt_yes,
    });

    return output;
  } else if (currentUserRole === ROLE.SE) {
    let last_sum_total_works = 0;
    let last_sum_paa_amount_total = 0;
    let last_sum_paa_block_estimates_remaining_to_submit = 0;
    let last_sum_paa_block_estimates_submitted_to_govt_yes = 0;

    for (const division in data) {
      let d_sum_total_works = 0;
      let d_sum_paa_amount_total = 0;
      let d_sum_paa_block_estimates_remaining_to_submit = 0;
      let d_sum_paa_block_estimates_submitted_to_govt_yes = 0;

      for (const schemeOfBudget in data[division]) {
        const budgetHeadsData = data[division][schemeOfBudget][0];
        output.push({
          name_of_division: division,
          scheme_of_budget: schemeOfBudget,
          ...budgetHeadsData,
        });

        d_sum_total_works += budgetHeadsData.total_works;
        d_sum_paa_amount_total += parseInt(budgetHeadsData.paa_amount_total);

        d_sum_paa_block_estimates_remaining_to_submit += parseInt(
          budgetHeadsData.paa_block_estimates_remaining_to_submit
        );
        d_sum_paa_block_estimates_submitted_to_govt_yes += parseInt(
          budgetHeadsData.paa_block_estimates_submitted_to_govt_yes
        );
      }

      output.push({
        bg_color: "#ffc0cb",
        budget_heads_name: "All Heads",
        scheme_of_budgets_name: "Total",
        total_works: d_sum_total_works,
        paa_amount_total: d_sum_paa_amount_total,
        paa_block_estimates_remaining_to_submit:
          d_sum_paa_block_estimates_remaining_to_submit,
        paa_block_estimates_submitted_to_govt_yes:
          d_sum_paa_block_estimates_submitted_to_govt_yes,
      });

      last_sum_total_works += d_sum_total_works;
      last_sum_paa_amount_total += d_sum_paa_amount_total;
      last_sum_paa_block_estimates_remaining_to_submit +=
        d_sum_paa_block_estimates_remaining_to_submit;
      last_sum_paa_block_estimates_submitted_to_govt_yes +=
        d_sum_paa_block_estimates_submitted_to_govt_yes;
    }
    output.push({
      bg_color: "#98fb98",
      division_name: "All Division",
      budget_heads_name: "All Heads",
      scheme_of_budgets_name: "Total",
      total_works: last_sum_total_works,
      paa_amount_total: last_sum_paa_amount_total,
      paa_block_estimates_remaining_to_submit:
        last_sum_paa_block_estimates_remaining_to_submit,
      paa_block_estimates_submitted_to_govt_yes:
        last_sum_paa_block_estimates_submitted_to_govt_yes,
    });

    return output;
  } else if (currentUserRole === ROLE.EE) {
    let last_sum_total_works = 0;
    let last_sum_paa_amount_total = 0;
    let last_sum_paa_block_estimates_remaining_to_submit = 0;
    let last_sum_paa_block_estimates_submitted_to_govt_yes = 0;
    for (const budgetHead in data) {
      for (const schemeOfBudget in data[budgetHead]) {
        const budgetHeadsData = data[budgetHead][schemeOfBudget][0];

        output.push({
          budget_heads_name: budgetHead,
          scheme_of_budget: schemeOfBudget,
          ...budgetHeadsData,
        });

        last_sum_total_works += budgetHeadsData.total_works;
        last_sum_paa_amount_total += parseInt(budgetHeadsData.paa_amount_total);

        last_sum_paa_block_estimates_remaining_to_submit += parseInt(
          budgetHeadsData.paa_block_estimates_remaining_to_submit
        );
        last_sum_paa_block_estimates_submitted_to_govt_yes += parseInt(
          budgetHeadsData.paa_block_estimates_submitted_to_govt_yes
        );
      }
    }
    output.push({
      bg_color: "#ffc0cb",
      // budget_heads_name: "All Heads",
      scheme_of_budgets_name: "Total",
      total_works: last_sum_total_works,
      paa_amount_total: last_sum_paa_amount_total,
      paa_block_estimates_remaining_to_submit:
        last_sum_paa_block_estimates_remaining_to_submit,
      paa_block_estimates_submitted_to_govt_yes:
        last_sum_paa_block_estimates_submitted_to_govt_yes,
    });

    return output;
  }
};
