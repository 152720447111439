import { getUserData } from "../../../../utility/Utils";
import { ROLE } from "../../../../common/utils";

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = () => {
  const columnsArr = [
    {
      Header: "ક્રમ",
      accessor: "sr_no",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: "વર્તુળ",
      accessor: "circle_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: "જિલ્લો",
      accessor: "division_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },

    {
      Header: "રસ્તાનું નામ",
      accessor: "road_name",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: "બજેટ સદર",
      accessor: "budget_heads_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE, ROLE.DEE],
    },
    {
      Header: "યુટીલીટીનો પ્રકાર",
      accessor: "utility_prakar",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "અરજી કરેલ કચેરી",
      accessor: "utility_araji_karel_kacheri",
      show: true,
      minWidth: "245px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "અરજી કરેલ તારીખ",
      accessor: "utility_araji_karel_date",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "સમયગાળો",
      accessor: "utility_samaygalo",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: "એસ્ટીમેટ મળ્યા તારીખ",
      accessor: "utility_aestimate_maliya_date",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "નાણાં ભરવાની વિગતો",
      accessor: "utility_nala_bharavani_vigato",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "યુટીલીટી શીફટીંગની આજરોજ અંતિત પરિસ્થિતી",
      accessor: "utility_siftingni_aajroj_antim_paristhti",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    //
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};
