// ** Icons Import
import {
  AllProjectSvg,
  PreexecutionSvg,
  ExecutionSvg,
  CompeletedSvg,
  StoppedSvg,
  Exegt6Svg,
  UserGroup,
  FourCircle,
} from "../../assets/images";
import { ROLE } from "../../common/utils";

export default [
  {
    id: "bookmarks",
    title: "BOOKMARKS",
    children: [
      {
        id: "allprojects",
        title: "All projects",
        icon: <AllProjectSvg />,
        navLink: "/allproject",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "Pre-Execution",
        title: "Pre-Execution",
        icon: <PreexecutionSvg />,
        navLink: "/report-not-started-all",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "execution",
        title: "Execution",
        icon: <ExecutionSvg />,
        navLink: "/report_work_in_progress",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "Completed",
        title: "Completed",
        icon: <CompeletedSvg />,
        navLink: "/report_works_completed",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "Stopped",
        title: "Stopped",
        icon: <StoppedSvg />,
        navLink: "/report_works_stopped",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "Pre-Execution > 6m",
        title: "Pre-Execution > 6m",
        icon: <Exegt6Svg />,
        navLink: "/reason_in_pre_execution",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
      {
        id: "time-overrun-projects",
        title: "Time-overrun projects",
        icon: <UserGroup />,
        navLink: "/report_time_over_run_work",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },

      {
        id: "expenditure-abstract",
        title: "Expenditure abstract",
        icon: <FourCircle />,
        // navLink: "/se/allproject",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },

      {
        id: "plant-monitoring",
        title: "Plant monitoring",
        icon: <FourCircle />,
        navLink: "/plant_today_report",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },

      {
        id: "forest-clearance",
        title: "Forest clearance",
        icon: <FourCircle />,
        // navLink: "/se/allproject",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },

      {
        id: "utility-shifting",
        title: "Utility shifting",
        icon: <FourCircle />,
        navLink: "/report_utility_shifting",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },

      {
        id: "land-acquisition ",
        title: "Land acquisition ",
        icon: <FourCircle />,
        navLink: "/report_land_acquisition",
        roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
      },
    ],
  },
];
