import { lazy } from "react";
import { ROLE } from "../../common/utils";
// import CategoryOfRoad from "../../views/superAdmin/CategoryOfRoad/CategoryOfRoad";

const SuperAdminDashboard = lazy(() =>
  import("../../views/superAdmin/SuperAdminDashboard")
);

const Locations = lazy(() =>
  import("../../views/superAdmin/Loactions/Locations")
);

const EditCircle = lazy(() =>
  import("../../views/superAdmin/Loactions/EditCircle")
);
const EditDivision = lazy(() =>
  import("../../views/superAdmin/Loactions/EditDivision")
);

const EditSubDivision = lazy(() =>
  import("../../views/superAdmin/Loactions/EditSubDivision")
);

const MpDatatable = lazy(() => import("../../views/superAdmin/Mp/MpDatatable"));

const EditMp = lazy(() => import("../../views/superAdmin/Mp/EditMp"));

const MlasDatatable = lazy(() =>
  import("../../views/superAdmin/Mla/MlaDatatable")
);

const EditMla = lazy(() => import("../../views/superAdmin/Mla/EditMla"));
const MonitaringStageTable = lazy(() =>
  import("../../views/superAdmin/MonitaringStage/MonitaringStage")
);

const WorkStatusTable = lazy(() =>
  import("../../views/superAdmin/WorkStatus/WorkStatusTable")
);

const ProposedWork = lazy(() =>
  import("../../views/superAdmin/ProposedWork/ProposedWork")
);
const TenderType = lazy(() =>
  import("../../views/superAdmin/TenderType/TenderType")
);
const AgencyTable = lazy(() =>
  import("../../views/superAdmin/Agency/AgencyTable")
);
const CategoryOfRoad = lazy(() =>
  import("../../views/superAdmin/CategoryOfRoad/CategoryOfRoad")
);
const SchemeOfBudget = lazy(() =>
  import("../../views/superAdmin/SchemeOfBudget/SchemeOfBudget")
);
const BudgetHead = lazy(() =>
  import("../../views/superAdmin/BudgetHead/BudgetHead")
);
const GovernmentUsers = lazy(() =>
  import("../../views/superAdmin/GovernmentUsers/GovernmentUsers")
);
const SecretaryUsers = lazy(() =>
  import("../../views/superAdmin/SecretaryUsers/SecretaryUsers")
);
const EditProfile = lazy(() =>
  import("../../views/components/EditProfile/EditProfile")
);

const SuperAdminRoutes = [
  {
    path: "/sa/dashboard",
    element: <SuperAdminDashboard />,
    roles: [ROLE.SUPERADMIN],
  },

  {
    path: "/sa/locations",
    element: <Locations />,
    roles: [ROLE.SUPERADMIN],
  },

  {
    path: "/sa/circle/edit",
    element: <EditCircle />,
    roles: [ROLE.SUPERADMIN],
  },

  {
    path: "/sa/division/edit",
    element: <EditDivision />,
    roles: [ROLE.SUPERADMIN],
  },

  {
    path: "/sa/sub-division/edit",
    element: <EditSubDivision />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/mps",
    element: <MpDatatable />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/mlas",
    element: <MlasDatatable />,
    roles: [ROLE.SUPERADMIN],
  },

  {
    path: "/sa/govr-users",
    element: <GovernmentUsers />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/secretory-users",
    element: <SecretaryUsers />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/mp-constituency/edit/:id",
    element: <EditMp />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/mla-constituency/edit/:id",
    element: <EditMla />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/work-status",
    element: <WorkStatusTable />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/monitoring-stage",
    element: <MonitaringStageTable />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/proposed-work",
    element: <ProposedWork />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/category-of-road",
    element: <CategoryOfRoad />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/type-of-tender",
    element: <TenderType />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/scheme-of-budget",
    element: <SchemeOfBudget />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/budget-head",
    element: <BudgetHead />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/sa/agency",
    element: <AgencyTable />,
    roles: [ROLE.SUPERADMIN],
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    roles: [
      ROLE.SUPERADMIN,
      ROLE.SECRETORY,
      ROLE.SE,
      ROLE.CE,
      ROLE.EE,
      ROLE.DEE,
    ],
  },
];

export default SuperAdminRoutes;
