/* eslint-disable multiline-ternary */
import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Delete from "../../../../assets/images/delete.png";
// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { updateLatitudeLongitude } from "../../../../services/reportsServices";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const defaultValues = {
  chainage: [
    {
      from: "",
      from_latitude: "",
      from_longitude: "",
      to: "",
      to_latitude: "",
      to_longitude: "",
    },
  ],
};

const LatitudeLongitudeData = yup.object().shape({
  chainage: yup.array().of(
    yup.object().shape({
      from: yup.string().required("Please Enter Chainage From"),
      from_latitude: yup
        .string()
        .required("Please Enter Chainage From Latitude")
        .matches(
          /^-?([0-9]|[1-8][0-9]|90)(\.\d{5,15})$/,
          "Enter a valid range of Latitude"
        )
        .nullable(),
      from_longitude: yup
        .string()
        .required("Please Enter Chainage From Longitude")
        .matches(
          /^-?((0|[1-9][0-9]|1[0-7][0-9])(\.\d{5,15})|180(\.0{5,15}))$/,
          "Enter a valid range of Longitude"
        )
        .nullable(),
      to: yup.string().required("Please Enter Chainage To"),

      to_latitude: yup
        .string()
        .required("Please Enter Chainage To Latitude")
        .matches(
          /^-?([0-9]|[1-8][0-9]|90)(\.\d{5,15})$/,
          "Enter a valid range of Latitude"
        )
        .nullable(),
      to_longitude: yup
        .string()
        .required("Please Enter Chainage To Longitude")
        .matches(
          /^-?((0|[1-9][0-9]|1[0-7][0-9])(\.\d{5,15})|180(\.0{5,15}))$/,
          "Enter a valid range of Longitude"
        )
        .nullable(),
    })
  ),
});

const LatitudeLongitude = () => {
  const workData = useSelector((state) => state?.getWork);
  const currentUserRole = getUserData()?.role;

  // ** Hooks
  const {
    reset,
    // watch,
    setError,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(LatitudeLongitudeData),
  });

  console.log("errors: ", errors);
  const chainageArray = useFieldArray({ control, name: "chainage" });

  const onSubmit = async (data) => {
    if (data) {
      const payload = {
        work_id: workData.data.id,
        lat_long: JSON.stringify(data.chainage),
      };
      try {
        const res = await updateLatitudeLongitude(payload);
        toast.success(res?.message);
      } catch (err) {
        getErrorMsg(err);
      }
    } else {
      for (const key in data) {
        if (data[key].length === 0) {
          setError(key, {
            type: "manual",
          });
        }
      }
    }
  };

  //   ** Reset Value
  useEffect(() => {
    if (workData?.data?.chainage_from_to) {
      const start_point_latitude = JSON.parse(
        workData?.data?.start_point_latitude
      );

      const start_point_longitude = JSON.parse(
        workData?.data?.start_point_longitude
      );

      const end_point_latitude = JSON.parse(workData?.data?.end_point_latitude);
      const end_point_longitude = JSON.parse(
        workData?.data?.end_point_longitude
      );

      const startFromKeys = start_point_latitude
        ? Object.keys(start_point_latitude)
        : [];
      const endToKeys = end_point_latitude
        ? Object.keys(end_point_latitude)
        : [];

      const arr = [];
      if (startFromKeys.length && endToKeys.length) {
        startFromKeys.forEach((startKey, startIndex) => {
          endToKeys.forEach((endKey, endIndex) => {
            if (startIndex === endIndex) {
              arr.push({
                from: startKey,
                from_latitude: start_point_latitude[startKey],
                from_longitude: start_point_longitude[startKey],
                to: endKey,
                to_latitude: end_point_latitude[endKey],
                to_longitude: end_point_longitude[endKey],
              });
            }
          });
        });
      }

      reset({
        chainage: arr,
      });
    }
  }, [workData?.data]);

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <>
      <Card>
        <CardBody className="basic_tab pt-75  LatitudeLongitude-Tabs">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isFieldsetDisabled}>
              <Row>
                <Col md={12}>
                  <Button
                    color="primary float-end"
                    onClick={() =>
                      chainageArray.append({
                        from: "",
                        from_latitude: "",
                        from_longitude: "",
                        to: "",
                        to_latitude: "",
                        to_longitude: "",
                      })
                    }
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Row className="appendAll destroy_me_0">
                {chainageArray?.fields?.map(({ id }, index) => {
                  return (
                    <Col md={11} className="d-flex">
                      <div key={id} className="repeater-wrapper d-flex">
                        <Row
                          className="d-flex align-items-center"
                          style={{
                            borderRight: "1px solid #000",
                            margin: "0 20px 0 0px",
                          }}
                        >
                          <Col
                            md={3}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="from">
                              Chainage From
                            </Label>
                            <Controller
                              // id={`chainage_from.${index}`}
                              name={`chainage.${index}.from`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    disabled={
                                      workData?.data?.chainge_from &&
                                      JSON.parse(
                                        workData?.data?.chainge_from
                                      ).includes(
                                        getValues(`chainage.${index}.from`)
                                      )
                                    }
                                    {...field}
                                    // name={`chainage.${index}.from`}
                                    placeholder=""
                                  />
                                );
                              }}
                            />
                            {errors?.chainage &&
                              errors?.chainage[index] &&
                              errors?.chainage[index]?.from && (
                                <FormFeedback className="d-block">
                                  {errors.chainage[index].from.message}
                                </FormFeedback>
                              )}
                          </Col>

                          <Col
                            md={4}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="from_latitude">
                              Chainage From Latitude
                            </Label>
                            <Controller
                              // id={`chainage_from.${index}`}
                              name={`chainage.${index}.from_latitude`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    type="number"
                                    // name={`chainage.${index}.from`}
                                    placeholder=""
                                    // onChange={(e) => {
                                    //   const pattern =
                                    //     /^(?!0(\.0{1,2})?$)\d{0,7}(\.\d{0,12})?$/;
                                    //   if (pattern.test(e.target.value)) {
                                    //     field.onChange(e);
                                    //   }
                                    // }}
                                  />
                                );
                              }}
                            />
                            {errors?.chainage &&
                              errors?.chainage[index] &&
                              errors?.chainage[index].from_latitude && (
                                <FormFeedback className="d-block">
                                  {errors.chainage[index].from_latitude.message}
                                </FormFeedback>
                              )}
                          </Col>

                          <Col
                            md={4}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="from_longitude">
                              Chainage From Longitude
                            </Label>
                            <Controller
                              // id={`chainage_from.${index}`}
                              name={`chainage.${index}.from_longitude`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    type="number"
                                    // name={`chainage.${index}.from`}
                                    placeholder=""
                                    // onChange={(e) => {
                                    //   const pattern =
                                    //     // /^(?!0(\.0{1,2})?$)\d{0,7}(\.\d{5,12})?$/;
                                    //   if (pattern.test(e.target.value)) {
                                    //     field.onChange(e);
                                    //   }
                                    // }}
                                  />
                                );
                              }}
                            />
                            {errors?.chainage &&
                              errors?.chainage[index] &&
                              errors?.chainage[index].from_longitude && (
                                <FormFeedback className="d-block">
                                  {
                                    errors.chainage[index].from_longitude
                                      .message
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center ms-1 ">
                          <Col
                            md={3}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="chainge_To">
                              Chainage To
                            </Label>
                            <Controller
                              id={`chainage_to.${index}`}
                              name={`chainage.${index}.to`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    disabled={
                                      workData?.data?.chainge_to &&
                                      JSON.parse(
                                        workData?.data?.chainge_to
                                      ).includes(
                                        getValues(`chainage.${index}.to`)
                                      )
                                    }
                                    {...field}
                                    name={`chainage.${index}.to`}
                                    placeholder=""
                                    // invalid={errors.chainge_from && true}
                                  />
                                );
                              }}
                            />
                            {errors.chainage &&
                              errors?.chainage[index] &&
                              errors.chainage[index].to && (
                                <FormFeedback className="d-block">
                                  {errors.chainage[index].to.message}
                                </FormFeedback>
                              )}
                          </Col>

                          <Col
                            md={4}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="to_latitude">
                              Chainage To Latitude
                            </Label>
                            <Controller
                              id={`chainage_to.${index}`}
                              name={`chainage.${index}.to_latitude`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    name={`chainage.${index}.to_latitude`}
                                    placeholder=""
                                    // invalid={errors.chainge_from && true}
                                  />
                                );
                              }}
                            />
                            {errors.chainage &&
                              errors?.chainage[index] &&
                              errors.chainage[index].to_latitude && (
                                <FormFeedback className="d-block">
                                  {errors.chainage[index].to_latitude.message}
                                </FormFeedback>
                              )}
                          </Col>

                          <Col
                            md={4}
                            xs={12}
                            className="mb-1 mt-0 allRaw position_0"
                          >
                            <Label className="form-label" for="to_longitude">
                              Chainage To Longitude
                            </Label>
                            <Controller
                              id={`chainage_to.${index}`}
                              name={`chainage.${index}.to_longitude`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    name={`chainage.${index}.to_longitude`}
                                    placeholder=""
                                    // invalid={errors.chainge_from && true}
                                  />
                                );
                              }}
                            />
                            {errors.chainage &&
                              errors?.chainage[index] &&
                              errors.chainage[index].to_longitude && (
                                <FormFeedback className="d-block">
                                  {errors.chainage[index].to_longitude.message}
                                </FormFeedback>
                              )}
                          </Col>

                          <Col md={1} xs={12} className="mt-25">
                            {index === 0 ? (
                              <span
                                size={18}
                                className=" ms-1 cursor-pointer"
                                onClick={() => chainageArray.remove(index)}
                                style={{
                                  visibility: "hidden",
                                }}
                              >
                                <img src={Delete} alt="icon" />
                              </span>
                            ) : (
                              <span
                                size={18}
                                className=" ms-1 cursor-pointer"
                                onClick={() => chainageArray.remove(index)}
                              >
                                <img src={Delete} alt="icon" />
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}

                {/* <Col md={11} className="w-100 d-flex">
                <Row
                  className="d-flex align-items-center"
                  style={{
                    borderRight: "1px solid #000",
                    margin: "0 20px 0 0px",
                  }}
                >
                  <Col md={3} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage From
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage From Latitude
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage From Longitude
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex align-items-center ">
                  <Col md={3} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage To
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage To Latitude
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0 allRaw position_0">
                    <Label className="form-label" for="circle_name">
                      Chainage To Longitude
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                </Row>
              </Col> */}
                <Col xs={12} className="basic_detail_btn m-0 mt-50">
                  <Button
                    className="btn btn-outline-dark waves-effect me-25"
                    type="reset"
                    outline
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default LatitudeLongitude;
