/* eslint-disable multiline-ternary */
import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import UILoader from "@components/ui-loader";
import RNBLoader from "../../../../@core/components/spinner/RNBLoader";
import { updateReasonEntry } from "../../../../services/reportsServices";

const ReasonEntryData = yup.object().shape(
  {
    pre_execution_delay_reason: yup
      .string()
      .required("Enter Pre Execution Delay Reason")
      .typeError("")
      .nullable(),

    // -----start--condition 1--------
    time_over_due_to_department_other: yup
      .string()
      .when(["time_over_due_to_agency", "time_over_action_taken_on_agency"], {
        is: (time_over_due_to_agency, time_over_action_taken_on_agency) =>
          !!time_over_due_to_agency || !!time_over_action_taken_on_agency,
        then: yup.string().required("Enter Due to Department").nullable(),
      }),
    // .typeError("")
    // .nullable(),
    time_over_due_to_agency: yup
      .string()
      .when(
        [
          "time_over_due_to_department_other",
          "time_over_action_taken_on_agency",
        ],
        {
          is: (
            time_over_due_to_department_other,
            time_over_action_taken_on_agency
          ) =>
            !!time_over_due_to_department_other ||
            !!time_over_action_taken_on_agency,
          then: yup.string().required("Enter Due to Agency").nullable(),
        }
      ),

    time_over_action_taken_on_agency: yup
      .string()
      .when(["time_over_due_to_department_other", "time_over_due_to_agency"], {
        is: (time_over_due_to_department_other, time_over_due_to_agency) =>
          !!time_over_due_to_department_other || !!time_over_due_to_agency,
        then: yup
          .string()
          .required("Enter Action taken by Department")
          .nullable(),
      }),
    // ------End---condition 1-------

    // -----start--condition 2--------
    stopped_due_to_department_other: yup
      .string()
      .when(["stopped_due_to_agency", "stopped_action_taken_on_agency"], {
        is: (stopped_due_to_agency, stopped_action_taken_on_agency) =>
          !!stopped_due_to_agency || !!stopped_action_taken_on_agency,
        then: yup.string().required("Enter Due to Department").nullable(),
      }),
    stopped_due_to_agency: yup
      .string()
      .when(
        ["stopped_due_to_department_other", "stopped_action_taken_on_agency"],
        {
          is: (
            stopped_due_to_department_other,
            stopped_action_taken_on_agency
          ) =>
            !!stopped_due_to_department_other ||
            !!stopped_action_taken_on_agency,
          then: yup.string().required("Enter Due to Agency").nullable(),
        }
      ),

    stopped_action_taken_on_agency: yup
      .string()
      // .required("Enter Action taken by Department")
      .when(["stopped_due_to_department_other", "stopped_due_to_agency"], {
        is: (stopped_due_to_department_other, stopped_due_to_agency) =>
          !!stopped_due_to_department_other || !!stopped_due_to_agency,
        then: yup
          .string()
          .required("Enter Action taken by Department")
          .nullable(),
      })
      .nullable(),
    // ------End---condition 1-------
  },
  [
    // condition 1
    ["time_over_due_to_department_other", "time_over_action_taken_on_agency"],
    ["time_over_due_to_department_other", "time_over_due_to_agency"],
    ["time_over_due_to_agency", "time_over_due_to_department_other"],
    ["time_over_due_to_agency", "time_over_action_taken_on_agency"],
    ["time_over_action_taken_on_agency", "time_over_due_to_department_other"],
    ["time_over_action_taken_on_agency", "time_over_due_to_agency"],

    // condition 2
    ["stopped_due_to_department_other", "stopped_due_to_agency"],
    ["stopped_due_to_department_other", "stopped_action_taken_on_agency"],
    ["stopped_due_to_agency", "stopped_due_to_department_other"],
    ["stopped_due_to_agency", "stopped_action_taken_on_agency"],
    ["stopped_action_taken_on_agency", "stopped_due_to_department_other"],
    ["stopped_action_taken_on_agency", "stopped_due_to_agency"],
  ]
);

const ReasonEntry = () => {
  const workData = useSelector((state) => state?.getWork);
  console.log("workData: ", workData);
  const currentUserRole = getUserData()?.role;
  // ** Hooks
  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ReasonEntryData),
  });

  console.log("watch:ReasonEntry ", watch());
  const onSubmit = (data) => {
    // if (Object.values(data).every((field) => field.length > 0)) {
    //   console.log("data:>>", data);
    // }
    console.log("ReasonEntryData>>", data);
    const sendAPIData = {
      work_id: workData?.data?.id,
      package_id: workData?.data?.package_id,
      pre_execution_delay_reason: data?.pre_execution_delay_reason,
      stopped_due_to_department_other: data?.stopped_due_to_department_other,
      stopped_due_to_agency: data?.stopped_due_to_agency,
      stopped_action_taken_on_agency: data?.stopped_action_taken_on_agency,
      time_over_due_to_department_other:
        data?.time_over_due_to_department_other,
      time_over_due_to_agency: data?.time_over_due_to_agency,
      time_over_action_taken_on_agency: data?.time_over_action_taken_on_agency,
    };
    console.log("sendAPIData: ", sendAPIData);
    updateReasonEntry(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };

  console.log("errors: ", errors);
  useEffect(() => {
    if (workData?.data) {
      reset({
        pre_execution_delay_reason: workData?.data?.pre_execution_delay_reason
          ? workData?.data?.pre_execution_delay_reason
          : "",
        time_over_due_to_department_other: workData?.data
          ?.time_over_due_to_department_other
          ? workData?.data?.time_over_due_to_department_other
          : "",
        time_over_due_to_agency: workData?.data?.time_over_due_to_agency
          ? workData?.data?.time_over_due_to_agency
          : "",
        time_over_action_taken_on_agency: workData?.data
          ?.time_over_action_taken_on_agency
          ? workData?.data?.time_over_action_taken_on_agency
          : "",
        stopped_due_to_department_other: workData?.data
          ?.stopped_due_to_department_other
          ? workData?.data?.stopped_due_to_department_other
          : "",
        stopped_due_to_agency: workData?.data?.stopped_due_to_agency
          ? workData?.data?.stopped_due_to_agency
          : "",
        stopped_action_taken_on_agency: workData?.data
          ?.stopped_action_taken_on_agency
          ? workData?.data?.stopped_action_taken_on_agency
          : "",
      });
    }
  }, [workData?.data]);

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <>
      <UILoader blocking={workData?.isLoading} loader={<RNBLoader />}>
        <Card>
          <CardBody className="basic_tab">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={isFieldsetDisabled}>
                <Row
                  // tag="form"
                  className="gy-1"
                  // onSubmit={handleSubmit(onSubmit)}
                >
                  {workData?.data?.pre_execution_reason_entry_tab ===
                  "d-none" ? (
                    ""
                  ) : (
                    <Col md={12} xs={12} className="mb-1 mt-0">
                      <Label
                        className="form-label"
                        for="pre_execution_delay_reason"
                      >
                        Pre Execution Delay Reason in Gujarati
                      </Label>
                      <Controller
                        control={control}
                        name="pre_execution_delay_reason"
                        render={({ field }) => {
                          return (
                            <Input
                              // disabled
                              type="textarea"
                              rows="3"
                              {...field}
                              className="gujarati-bg"
                              id="pre_execution_delay_reason"
                              //   placeholder="Enter your Email id/Username"
                              // value={field.value}
                              invalid={
                                errors.pre_execution_delay_reason && true
                              }
                            />
                          );
                        }}
                      />
                      {errors.pre_execution_delay_reason && (
                        <FormFeedback>
                          {errors.pre_execution_delay_reason.message}
                        </FormFeedback>
                      )}
                    </Col>
                  )}
                  {workData?.data?.time_over_run_reason_entry_tab ===
                  "d-none" ? (
                    ""
                  ) : (
                    <>
                      <Col md={6} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="time_over_due_to_department_other"
                        >
                          Time Over Run : Due to Department / Other in Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="time_over_due_to_department_other"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                className="gujarati-bg"
                                rows="3"
                                {...field}
                                id="time_over_due_to_department_other"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={
                                  errors.time_over_due_to_department_other &&
                                  true
                                }
                              />
                            );
                          }}
                        />
                        {errors.time_over_due_to_department_other && (
                          <FormFeedback>
                            {errors.time_over_due_to_department_other.message}
                          </FormFeedback>
                        )}
                      </Col>
                      <Col md={6} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="time_over_due_to_agency"
                        >
                          Time Over Run : Due to Agency in Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="time_over_due_to_agency"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                rows="3"
                                className="gujarati-bg"
                                {...field}
                                id="time_over_due_to_agency"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={errors.time_over_due_to_agency && true}
                              />
                            );
                          }}
                        />
                        {errors.time_over_due_to_agency && (
                          <FormFeedback>
                            {errors.time_over_due_to_agency.message}
                          </FormFeedback>
                        )}
                      </Col>
                      <Col md={12} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="time_over_action_taken_on_agency"
                        >
                          Action taken by Department for Time Over Run Work in
                          Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="time_over_action_taken_on_agency"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                rows="3"
                                className="gujarati-bg"
                                {...field}
                                id="time_over_action_taken_on_agency"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={
                                  errors.time_over_action_taken_on_agency &&
                                  true
                                }
                              />
                            );
                          }}
                        />
                        {errors.time_over_action_taken_on_agency && (
                          <FormFeedback>
                            {errors.time_over_action_taken_on_agency.message}
                          </FormFeedback>
                        )}
                      </Col>
                    </>
                  )}

                  {workData?.data?.stopped_reason_entry_tab === "d-none" ? (
                    ""
                  ) : (
                    <>
                      <Col md={6} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="stopped_due_to_department_other"
                        >
                          Stopped : Due to Department / Other in Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="stopped_due_to_department_other"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                rows="3"
                                className="gujarati-bg"
                                {...field}
                                id="stopped_due_to_department_other"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={
                                  errors.stopped_due_to_department_other && true
                                }
                              />
                            );
                          }}
                        />
                        {errors.stopped_due_to_department_other && (
                          <FormFeedback>
                            {errors.stopped_due_to_department_other.message}
                          </FormFeedback>
                        )}
                      </Col>
                      <Col md={6} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="stopped_due_to_agency"
                        >
                          Stopped : Due to Agency in Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="stopped_due_to_agency"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                rows="3"
                                className="gujarati-bg"
                                {...field}
                                id="stopped_due_to_agency"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={errors.stopped_due_to_agency && true}
                              />
                            );
                          }}
                        />
                        {errors.stopped_due_to_agency && (
                          <FormFeedback className="d-block">
                            {errors.stopped_due_to_agency.message}
                          </FormFeedback>
                        )}
                      </Col>
                      <Col md={12} xs={12} className="mb-1 mt-0">
                        <Label
                          className="form-label"
                          for="stopped_action_taken_on_agency"
                        >
                          Action taken by Department for Stopped Work in
                          Gujarati
                        </Label>
                        <Controller
                          control={control}
                          name="stopped_action_taken_on_agency"
                          render={({ field }) => {
                            return (
                              <Input
                                type="textarea"
                                rows="3"
                                className="gujarati-bg"
                                {...field}
                                id="stopped_action_taken_on_agency"
                                //   placeholder="Enter your Email id/Username"
                                // value={field.value}
                                invalid={
                                  errors.stopped_action_taken_on_agency && true
                                }
                              />
                            );
                          }}
                        />
                        {errors.stopped_action_taken_on_agency && (
                          <FormFeedback>
                            {errors.stopped_action_taken_on_agency.message}
                          </FormFeedback>
                        )}
                      </Col>
                    </>
                  )}
                  <Col xs={12}>
                    <Button
                      className="btn btn-outline-dark waves-effect me-25"
                      type="reset"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        workData?.data?.pre_execution_reason_entry_tab ===
                          "d-none" &&
                        workData?.data?.stopped_reason_entry_tab === "d-none" &&
                        workData?.data?.time_over_run_reason_entry_tab ===
                          "d-none"
                      }
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </Form>
          </CardBody>
        </Card>
      </UILoader>
    </>
  );
};

export default ReasonEntry;
