// ** React Imports
import { Fragment } from "react";

// ** Routes Imports
import AppRoutes from "./Apps";
import FormRoutes from "./Forms";
import PagesRoutes from "./Pages";
import TablesRoutes from "./Tables";
import ChartsRoutes from "./Charts";
import DashboardRoutes from "./Dashboards";
import UiElementRoutes from "./UiElements";
import ExtensionsRoutes from "./Extensions";
import PageLayoutsRoutes from "./PageLayouts";
import AuthenticationRoutes from "./Authentication";

// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";
import PrivateRoute from "@components/routes/PrivateRoute";

// ** Utils
import { isObjEmpty } from "@utils";
import { getUserData } from "../../utility/Utils";

// ** Routes
import SuperAdminRoutes from "./SuperAdmin";
import SERoutes from "./SERouters";
import EERoutes from "./EERouters";
import CERoutes from "./CERouters";
import ReportsRouters from "./ReportsRouters";

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - Vuexy React Admin Template";

// ** Default Route
const SuperAdminRoute = "/sa/dashboard";
const SEDashboard = "/se/dashboard";
const EEDashboard = "/ee/dashboard";
const CEDashboard = "/ce/dashboard";

// ** Merge Routes
const Routes = [
  ...DashboardRoutes,
  ...AppRoutes,
  ...PagesRoutes,
  ...UiElementRoutes,
  ...ExtensionsRoutes,
  ...PageLayoutsRoutes,
  ...FormRoutes,
  ...TablesRoutes,
  ...ChartsRoutes,
  ...AuthenticationRoutes,
  ...SuperAdminRoutes,
  ...SERoutes,
  ...ReportsRouters,
  ...EERoutes,
  ...CERoutes,
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const currentUserRole = getUserData()?.role;
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }

        if (route.element) {
          // Check if the user's role matches the allowed roles for the route
          const canAccessRoute = route?.roles?.includes(currentUserRole);
          // Render the route only if the user has the required role
          if (canAccessRoute || route?.roles?.length === 0) {
            const Wrapper =
              // eslint-disable-next-line multiline-ternary
              isObjEmpty(route.element.props) && isBlank === false
                ? // eslint-disable-next-line multiline-ternary
                  LayoutWrapper
                : Fragment;

            route.element = (
              <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                <RouteTag route={route}>{route.element}</RouteTag>
              </Wrapper>
            );
          } else {
            return null; // Route is hidden for this user
          }
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export {
  SEDashboard,
  TemplateTitle,
  Routes,
  getRoutes,
  SuperAdminRoute,
  EEDashboard,
  CEDashboard,
};
