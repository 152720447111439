import React, { useEffect } from "react";
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import ForestClearance from "./LAForestComponent/ForestClearance";
import UtilityShifting from "./LAForestComponent/UtilityShifting";
import LandAcquisition from "./LAForestComponent/LandAcquisition";
import { useSelector } from "react-redux";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const LAforestUtility = () => {
  const workData = useSelector((state) => state?.getWork);
  const currentUserRole = getUserData()?.role;
  const { register, watch, reset } = useForm({});
  console.log("watch:1234", watch());

  const { ref: forest, ...foresttest } = register("forest_clearance_required", {
    required: true,
  });

  const { ref: utility, ...utilityTest } = register(
    "utility_shifting_required",
    {
      required: true,
    }
  );

  const { ref: land, ...landTest } = register("land_acqusition_required", {
    required: true,
  });

  useEffect(() => {
    reset({
      forest_clearance_required:
        workData?.data?.forest_clearance_required || "no",
      utility_shifting_required:
        workData?.data?.utility_shifting_required || "no",
      land_acqusition_required:
        workData?.data?.land_acqusition_required || "no",
    });
  }, [workData?.data]);

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <>
      <Card>
        <CardBody className="basic_tab pt-50">
          <Form>
            <fieldset disabled={isFieldsetDisabled}>
              <Row>
                <Col md={12}>
                  <div className="card-header forest-header d-flex card-header">
                    <h5 className="card-title">Forest Clearance</h5>
                  </div>
                  <div>
                    <Label>Forest Clearance Required?</Label>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={forest}
                          {...foresttest}
                          type="radio"
                          name="forest_clearance_required"
                          id="forest_clearance_required_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="forest_clearance_required_yes"
                          className="ms-50 form-check-label"
                        >
                          Yes
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={forest}
                          {...foresttest}
                          type="radio"
                          name="forest_clearance_required"
                          id="forest_clearance_required_no"
                          value="no"
                          className="form-check-input"
                        />
                        <Label
                          for="forest_clearance_required_no"
                          className="ms-50 form-check-label"
                        >
                          No
                        </Label>
                      </div>
                    </div>
                    {watch("forest_clearance_required") === "yes" && (
                      <ForestClearance />
                    )}
                  </div>
                </Col>
                <Col md={12} className="pt-2">
                  <div className="card-header forest-header d-flex card-header">
                    <h5 className="card-title">Utility Shifting</h5>
                  </div>
                  <div>
                    <Label>Utility Shifting Required?</Label>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={utility}
                          {...utilityTest}
                          type="radio"
                          name="utility_shifting_required"
                          id="utility_shifting_required_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="utility_shifting_required_yes"
                          className="ms-50 form-check-label"
                        >
                          Yes
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={utility}
                          {...utilityTest}
                          type="radio"
                          name="utility_shifting_required"
                          id="utility_shifting_required_no"
                          value="no"
                          className="form-check-input"
                        />
                        <Label
                          for="utility_shifting_required_no"
                          className="ms-50 form-check-label"
                        >
                          No
                        </Label>
                      </div>
                    </div>
                    {watch("utility_shifting_required") === "yes" && (
                      <UtilityShifting
                        utility_shifting_required={watch(
                          "utility_shifting_required"
                        )}
                      />
                    )}
                  </div>
                </Col>
                <Col md={12} className="pt-2">
                  <div className="card-header forest-header d-flex card-header">
                    <h5 className="card-title">Land Acquisition</h5>
                  </div>
                  <div>
                    <Label>Land Acquisition Required?</Label>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={land}
                          {...landTest}
                          type="radio"
                          name="land_acqusition_required"
                          id="land_acqusition_required_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="land_acqusition_required_yes"
                          className="ms-50 form-check-label"
                        >
                          Yes
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={land}
                          {...landTest}
                          type="radio"
                          name="land_acqusition_required"
                          id="land_acqusition_required_no"
                          value="no"
                          className="form-check-input"
                        />
                        <Label
                          for="land_acqusition_required_no"
                          className="ms-50 form-check-label"
                        >
                          No
                        </Label>
                      </div>
                    </div>
                    {watch("land_acqusition_required") === "yes" && (
                      <LandAcquisition
                        land_acqusition_required={watch(
                          "land_acqusition_required"
                        )}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default LAforestUtility;
