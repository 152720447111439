/* eslint-disable multiline-ternary */
import { createUrlFromObject } from "../utility/Utils";
import authHttp from "./authHttp";

// ** Report Count for All user
export const getReportCountSe = async (userApiCount) => {
  const { data } = await authHttp.get(userApiCount);
  return data;
};

// Report > not started > PAA Received AA Not Received
export const getPaaReceivedAANotReceivedData = async (val) => {
  // const url = `/api/paaReceivedAANotReceived`;
  const url = createUrlFromObject(val, "/api/paaReceivedAANotReceived");
  const { data } = await authHttp.get(url);
  return data;
};

//Report > not started > PAA Received AA Not Received > Abstract

// /api/paaReceivedAANotReceivedAbstract
export const getPaaReceived_AbstractData = async () => {
  const url = `/api/paaReceivedAANotReceivedAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

// Report > not started > Not Started - All
export const getNotStartedAllData = async (val) => {
  const url = createUrlFromObject(val, "/api/notStartedReportFE");
  const { data } = await authHttp.get(url);
  return data;
};
export const getnotStartedReportAbstractData = async () => {
  const url = `/api/notStartedReportAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

// Report > not started > Pre Execution > 6 Months
export const gePreExecutionMonthsData = async (val) => {
  console.log("val>>", val);
  const url = createUrlFromObject(val, "/api/preExecutionFE");
  const { data } = await authHttp.get(url);
  return data;
};
export const getPreExecutionMonthsAbstractData = async () => {
  const url = `api/preExecutionAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

// Report > allProjectListing All
export const getAllProjectListingData = async (val) => {
  const url = createUrlFromObject(val, "/api/allProjectListingFE");
  const { data } = await authHttp.get(url);
  return data;
};

// Report > D.T.P. Level
export const getDtpLevelListingData = async (val) => {
  const url = createUrlFromObject(val, "/api/dtpLevelReportFE");
  const { data } = await authHttp.get(url);
  return data;
};

export const getDTPLevelAbstractData = async () => {
  const url = "/api/dtpLevelReportAbstect";
  const { data } = await authHttp.get(url);
  return data;
};
// Report > not started > Tender Online

export const getTenderOnlineListingData = async (val) => {
  const url = createUrlFromObject(val, "/api/tenderOnlineReportFE");
  const { data } = await authHttp.get(url);
  return data;
};
export const getTenderOnlineListingAbstractData = async () => {
  const url = "/api/tenderOnlineReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};
// Report > not started > Tender Under Approval
export const getTenderUnderApprovalListingData = async (val) => {
  const url = createUrlFromObject(val, "/api/tenderUnderApprovalReport");

  const { data } = await authHttp.get(url);
  return data;
};
export const getTenderUnderApprovalListingAbstractData = async () => {
  const url = "/api/tenderUnderApprovalReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};
// Report > not started > deposit Call Level Report
export const getDepositCallLetterListingData = async (val) => {
  const url = createUrlFromObject(val, "/api/depositCallLevelReport");
  const { data } = await authHttp.get(url);
  return data;
};

export const getDepositCallLetterAbstractData = async () => {
  const url = "/api/depositCallLevelReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

//Report > not started > T.S. LEVEL

export const getTSLevel = async (value) => {
  const url = createUrlFromObject(value, "/api/tsLevelReportFE");
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};
export const getTSLevelAbstractData = async () => {
  const url = "/api/tsLevelReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};
export const getSubDivisionByDivision = async (ids) => {
  const url = `/api/getSubDivisionbyDivisionID?id=${ids}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getInProgressWorkReport = async (val) => {
  const url = createUrlFromObject(val, "/api/inProgressWorkReport");
  const { data } = await authHttp.get(url);
  return data;
};

//  get All Work Data

export const getAllWorkData = async (value) => {
  console.log("value:99090", value);
  const url = `/api/get-work/${value.id}/${value.role}/${value.work_id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// get Taluka for Add Work
export const getTalukaDropDown = async (ids) => {
  // console.log("ids-->>", ids);
  const url = `/api/taluka-by-division/${ids}`;
  const { data } = await authHttp.get(url);
  return data;
};

// for SubDivision DropDown Option
export const getSubDivisionDropDown = async (ids) => {
  // console.log("ids-->>", ids);
  const url = `/api/divisionCascade/${ids}`;
  const { data } = await authHttp.get(url);
  return data;
};

// for Mp ConstituencyDropDown DropDown Option
export const getMpConstituencyDropDown = async (id) => {
  const url = `/api/get-mp-consty/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// getMpNameDropDown
export const getMpNameDropDown = async (value, work_id) => {
  const url = `/api/get-mp-data/${value?.peram1}/${value?.mp_id}/${work_id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// Paa_Aa Work-update
export const updatePaaAaWork = async (value) => {
  const url = `/api/update/paa-aa-work`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// /api/categoryRoad

// ** /api/get-mla-consty/{id}
export const getMLAConstituencyDropDown = async (id) => {
  const url = `/api/get-mla-consty/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getMLANameDropDown = async (value, work_id) => {
  const url = `/api/get-mla-data/${value?.peram1}/${value?.mp_id}/${work_id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// /api/update/basic-details-work
export const updateBasicDetailsWork = async (value) => {
  const url = `/api/update/basic-details-work`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// Extra / Excess
export const getApproveExtraAccess = async (value) => {
  const url = `/api/work-approve-extra-access/${value}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getHeadwiseExpenditureAbstract = async () => {
  const url = "/api/expenditureReviewReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// TS-DTP API
export const updateTsDtpWork = async (value) => {
  const url = `/api/update/ts-dtp-work`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ReasonEntry
export const updateReasonEntry = async (value) => {
  const url = `/api/circle/create-update-reason-entry`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getInProgressInTimeWorksReport = async (val) => {
  const url = createUrlFromObject(val, `/api/inProgressInTimeWorks`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getInProgressWorkReportAbstract = async () => {
  const url = `/api/inProgressWorkReportAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getInProgressInTimeWorksAbstractData = async () => {
  const url = `/api/inProgressInTimeWorksAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getInProgressInTimeLimitMoreThanFiftyPhysicalTwentyFive = async (
  val
) => {
  const url = createUrlFromObject(
    val,
    `/api/inProgressInTimeLimitMoreThanFiftyPhysicalTwentyFive`
  );
  const { data } = await authHttp.get(url);
  return data;
};

// Tender To Work Order Stage
export const updateTenderToWorkOrderStageWork = async (value) => {
  const url = `/api/circle/create-update-tender-work-order-stage`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// *  Tender To Work Order Stage Extended
export const updateTenderToWorkOrderStageExtendedWork = async (value) => {
  const url = `/api/circle/create-update-tender-work-order-stage-extended`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// * tab >> latitude-longitude
export const updateLatitudeLongitude = async (value) => {
  const url = "/api/circle/update-latitude-longitude";
  const { data } = await authHttp.post(url, value);
  return data;
};

//work-status tabs
export const updateCreateWorkStatus = async (value) => {
  const url = `/api/circle/create-update-work-status`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// work-status tabs Revised AA required
export const updateWorkRevisedAArequired = async (value) => {
  const url = `api/circle/create-update-work-status-raa`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// tab >> Time limit / Extra - Excess
export const createTimeLimitExtraExcess = async (value) => {
  const url = "/api/circle/create-update-time-limit-extra-excess";
  const { data } = await authHttp.post(url, value);
  return data;
};
// tab >> delete Extra - Excess
export const deleteExtraExcess = async (value) => {
  console.log("value: ", value);
  const url = `/api/delete-extra-excess-api`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// La-Forest > Land Acquisition
export const updateLandAcquisitionWork = async (value) => {
  const url = `/api/circle/create-update-la-forest-land-acquisition`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// La-Forest > Land Acquisition
export const updateForestClearanceWork = async (value) => {
  const url = `/api/circle/create-update-la-forest-clearance`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** utility-shifting
export const updateUtilityWork = async (value) => {
  const url = `/api/circle/create-update-la-forest-utility-shifting`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getinProgressTLFiftyPhyTwentyFive_AbstractData = async () => {
  const url = `/api/inProgressInTimeLimitMoreThanFiftyPhysicalTwentyFiveAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

// timeoverRunReport

export const getTimeOverRunData = async (val) => {
  const url = createUrlFromObject(val, `/api/timeoverRunReport`);

  const { data } = await authHttp.get(url);
  return data;
};

//

export const getTimeoverRunReport_AbstractData = async () => {
  const url = `/api/timeoverRunReportAbstract`;
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Stopped
export const getStoppedData = async (val) => {
  const url = createUrlFromObject(val, "/api/stoppedWorks");
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Stopped Abstract
export const getStoppedAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/stoppedWorksAbstract");
  const url = "/api/stoppedWorksAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report > PreConstruction Activity Works
export const getPreConstructionWorksData = async (val) => {
  const url = createUrlFromObject(val, "/api/preConsWorksActivityReport");
  const { data } = await authHttp.get(url);
  return data;
};

// Report > PreConstruction Activity Works Abstract
export const getPreConstructionWorksAbstractData = async () => {
  // const url = createUrlFromObject(
  //   val,
  //   "/api/preConsWorksActivityReportAbstract"
  // );
  const url = "/api/preConsWorksActivityReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Time Over Run > 12
export const getTimeOverRun12MonthsData = async (val) => {
  const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReport");
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Time Over Run > 12 Abstract
export const getTimeOverRun12MonthsAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/timeoverRunGT12MonthReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Physically Completed
export const getPhysicallyCompletedData = async (val) => {
  const url = createUrlFromObject(val, "/api/physicallyCompletedReport");
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Physically Completed Abstract
export const getPhysicallyCompletedAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/physicallyCompletedReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Completed
export const getCompletedData = async (val) => {
  const url = createUrlFromObject(val, "/api/completedWorks");
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Completed Abstract
export const getCompletedAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/completedWorksAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Old Completed
export const getOldCompletedData = async (val) => {
  const url = createUrlFromObject(val, "/api/oldCompletedWork");
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Old Completed Abstract
export const getOldCompletedAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/oldCompletedWorkAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Dropped Work
export const getDroppedWorkData = async (val) => {
  const url = createUrlFromObject(val, "/api/droppedWorkReport");
  const { data } = await authHttp.get(url);
  return data;
};

// Report >  Dropped Work Abstract
export const getDroppedWorkAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/droppedWorkAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

// Report > Expenditure Review > Not Started P-1
export const getNotStartedP1Report = async (val) => {
  const url = createUrlFromObject(val, `/api/notStartedP1Report`);

  const { data } = await authHttp.get(url);
  return data;
};

// Report > Expenditure Review >Time Over Run  P-2
export const getTimeOverRunP2Report = async (val) => {
  const url = createUrlFromObject(val, `/api/timeOverRunP2`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getInTimeP3Report = async (val) => {
  const url = createUrlFromObject(val, `/api/inTimeP3Report`);
  const { data } = await authHttp.get(url);
  return data;
};

// * Report > Expenditure Review > PHYSICALLY COMPLETED WORKS (P-4)
export const getPhysicallyCompletedP4ReportReport = async (val) => {
  const url = createUrlFromObject(val, `/api/physicallyCompletedP4Report`);
  const { data } = await authHttp.get(url);
  return data;
};

//

// * Report > Expenditure Review > WORKS COMPLETED DURING CURRENT YEAR 2022-23 (P-5)
export const getPhysicallyCompletedCurrentYearReport = async (val) => {
  const url = createUrlFromObject(
    val,
    `/api/physicallyCompletedCurrentYearReport`
  );
  const { data } = await authHttp.get(url);
  return data;
};

export const getPragatiGData = async (val) => {
  const url = createUrlFromObject(val, `/api/pragatiGReport`);
  const { data } = await authHttp.get(url);
  return data;
};

//
// * Report > Expenditure Review >  STOPPED WORKS (P-6)
export const getStoppedP6Report = async (val) => {
  const url = createUrlFromObject(val, `/api/stoppedP6Report`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getMpWiseData = async (val) => {
  const url = createUrlFromObject(val, `/api/mpReport`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getMpWiseAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/mpReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

export const getMlaWiseData = async (val) => {
  const url = createUrlFromObject(val, `/api/mlaReport`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getMlaWiseAbstractData = async () => {
  // const url = createUrlFromObject(val, "/api/timeoverRunGT12MonthReportAbstract");
  const url = "/api/mlaReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

export const getYearWiseData = async (val) => {
  const url = createUrlFromObject(val, `/api/yearWiseReport`);
  const { data } = await authHttp.get(url);
  return data;
};

// /api/utilityShifting

export const getUtilityShiftingData = async (val) => {
  const url = createUrlFromObject(val, `/api/utilityShifting`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getAgencyWiseData = async (val) => {
  const url = createUrlFromObject(val, `/api/agencyReport`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getUtilityShiftingAbstractData = async () => {
  const url = "/api/utilityShiftingAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

export const getAgencyWiseAbstractData = async () => {
  const url = "/api/agencyReportAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

export const getLandAcquisitionReportData = async (val) => {
  const url = createUrlFromObject(val, `/api/landAcquisition`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getLandAcquisitionAbstractData = async () => {
  const url = "/api/landAcquisitionAbstract";
  const { data } = await authHttp.get(url);
  return data;
};

export const getOwnReportDataData = async (val) => {
  const url = `/api/createOwnReport?length=-1&start=0&select_columns=${val}`;
  const { data } = await authHttp.get(url);
  return data;
};
