// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Row, Col } from "reactstrap";
import AddSecretaryUsers from "./AddSecretaryUsers";
import AddMP_Constituency from "./AddMP_Constituency";
import AddMLA_Constituency from "./AddMLA_Constituency";
import AddBudgetHead from "./AddBudgetHead";
import AddSchemeOfBudget from "./AddSchemeOfBudget";
import AddCategoryOfRoad from "./AddCategoryOfRoad";
import AddTenderType from "./AddTenderType";
// import AddAgency from "./AddAgency";
import AddProposedWork from "./AddProposedWork";
import AddWorkStatus from "./AddWorkStatus";
// import AddMonitoringStage from "./AddMonitoringStage";
import AddGovernmentUser from "./AddGovernmentUser";
import ChangePassword from "./ChangePassword";
// import DeleteProps from "./DeleteProps";
import AddNewPlant from "./AddNewPlant";
import DeleteModal from "../../superAdmin/SAModals/DeleteModal";
import AddMonitoringStage from "../../superAdmin/SAModals/AddMonitoringStage";
import AgencyModal from "../../superAdmin/SAModals/AgencyModal";

// ** Demo Components

const IndexModal = () => {
  return (
    <Fragment>
      <h1> Super Admin all modal</h1>
      <Row className="match-height mt-4">
        <Col md="4">
          <AddGovernmentUser />
        </Col>
        <Col md="4">
          <ChangePassword />
        </Col>
        <Col md="4">
          <AddSecretaryUsers />
        </Col>
        <Col md="4">
          <AddMP_Constituency />
        </Col>
        <Col md="4">
          <AddMLA_Constituency />
        </Col>
        <Col md="4">
          <AddBudgetHead />
        </Col>
        <Col md="4">
          <AddSchemeOfBudget />
        </Col>
        <Col md="4">
          <AddCategoryOfRoad />
        </Col>
        <Col md="4">
          <AddTenderType />
        </Col>
        <Col md="4">
          <AgencyModal />
        </Col>
        <Col ms="4">
          <AddProposedWork />
        </Col>
        <Col md="4">
          <AddWorkStatus />
        </Col>
        <Col md="4">
          <AddMonitoringStage />
        </Col>
        <Col md="4">
          <DeleteModal
            DeleteWarning={"Delete Member of the Legislative Assembly?"}
            SubWarning={
              "It will not be possible to restore this Member of the Legislative Assembly after delete."
            }
          />
        </Col>
        <Col md="4">
          <AddNewPlant />
        </Col>
      </Row>
    </Fragment>
  );
};

export default IndexModal;
