import { lazy } from "react";
import { ROLE } from "../../common/utils";

const CEDashboard = lazy(() => import("../../views/CERole/CEDashboard"));

const CERoutes = [
  {
    path: "/ce/dashboard",
    element: <CEDashboard />,
    roles: [ROLE.CE, ROLE.SECRETORY],
  },
];

export default CERoutes;
