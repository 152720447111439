import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { getAllWorkData } from "../../services/reportsServices";

export const getWorkSlice = createSlice({
  name: "getWork",
  initialState: {
    isLoading: false,
    data: [],
    handWiseLoading: false,

    error: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    isFectchSuccess(state) {
      state.isLoading = false;
    },
    // GET Data
    getData: (state, action) => {
      state.data = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { startLoading, getData, isFectchSuccess, hasError } =
  getWorkSlice.actions;

export default getWorkSlice.reducer;

export const getWorkTabsData = (value) => {
  return async () => {
    dispatch(getWorkSlice.actions.startLoading());
    try {
      const response = await getAllWorkData(value);
      dispatch(getWorkSlice.actions.getData(response?.data));
      dispatch(getWorkSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(getWorkSlice.actions.hasError(error));
    }
  };
};
