import React, { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import Cleave from "cleave.js/react";

const ReactDate = ({
  selected,
  onChange,
  disabled,
  isClearable,
  props,
  maxDate,
}) => {
  const cleaveRef = useRef(null);

  //   useEffect(() => {
  //     const dateCleave = new Cleave(cleaveRef.current, {
  //       date: true,
  //       delimiter: '-',
  //       datePattern: ['d', 'm', 'Y']
  //     });
  //   }, []);
  return (
    <ReactDatePicker
      placeholderText="Select Date"
      selected={selected}
      onChange={onChange}
      showMonthDropdown
      showYearDropdown
      isClearable={isClearable}
      maxDate={maxDate}
      dropdownMode="select"
      className="form-control"
      dateFormat="dd-MM-yyyy"
      inputDateFormat="dd-MM-yyyy"
      disabled={disabled}
      calendarClassName="custom-datepicker-calendar"
      popperClassName="custom-datepicker-popper"
      {...props}
      customInput={
        <Cleave
          placeholder="Select Date"
          value={selected ? selected : ""}
          onChange={(event) => {
            const formattedDate = event.target.rawValue;
            onChange(formattedDate);
          }}
          options={{
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          }}
          inputRef={cleaveRef}
        />
      }
    />
  );
};

export default ReactDate;
