/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";
import ColumnsVisibility from "../../../../assets/images/ColumnsVisibility.png";
import Excel from "../../../../assets/images/excel.png";
import Print from "../../../../assets/images/print.png";
import Abstract from "../../../../assets/images/Abstract.png";
import ReactPaginate from "react-paginate";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../assets/images";
import {
  ROLE,
  SelectCustomStyles,
  dateFormate,
} from "../../../../common/utils";
import { getAgencyWiseData } from "../../../../services/reportsServices";

import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import {
  columnChanges,
  getAgencyList,
  getColumnlist,
} from "../../../../services/adminDashboard";
import EditWorkModal from "../../../EditWorkModal/EditWorkModal";
// import PrintTable from "../../../superAdmin/PrintTable";
import AgencyWiseFilter from "./AgencyWiseFilter";
import { getHeaderColumnsByRole } from "./AgencyWiseUtils";
import FilterInputManageRole from "../../common/FilterInputManageRole";
import { Controller, useForm } from "react-hook-form";
// import { getDivisionListWOID } from "../../../../services/ceServices";
// import { getDivisionbyCircle } from "../../../../services/seServices";
import PrintTable from "../../PrintTable";
import AgencyWiseAbstract from "./Abstract/AgencyWiseAbstract";
import { getFormattedDate } from "../PragatiG/PragatiGUtiils";

// **  Column Freeze as per Login Role
const columnsFreeze = new Map([
  [ROLE.SECRETORY, 5],
  [ROLE.CE, 5],
  [ROLE.SE, 5],
  [ROLE.EE, 4],
  [ROLE.DEE, 4],
]);

const AgencyWise = () => {
  const [searchParams] = useSearchParams({});
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValues, setPaginationValues] = useState({
    currentPage: 1,
    rowPerPage: 50,
    AllPages: 1,
  });
  // Abstract modal ==========
  const [abstractModal, setAbstractModal] = useState({
    isOpen: false,
  });
  const [filterKeys, setFilterKeys] = useState({});
  // const loginUserid.id = useSelector((state) => state.auth.userData.data.user_id);
  const loginUser = useSelector((state) => state.auth.userData.data);

  const [editWork, setEditWork] = useState({
    isOpen: false,
    data: "",
  });
  const [agencyOption, setAgencyOption] = useState([
    { id: 0, agency: "Select Agency" },
  ]);
  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: "all", label: "All" },
  ];

  // ** Get List Of Tender Type
  // const pageLength = 1;

  function addDuplicateForObjectWithSpecificValue(dataArray) {
    const result = [];
    // Remove duplicate package ids
    const seenPackageIds = {};
    const data = dataArray.filter((item) => {
      if (item.package_id > 0) {
        if (!seenPackageIds[item.package_id]) {
          seenPackageIds[item.package_id] = true;
          return true;
        }
        return false;
      } else {
        return true;
      }
    });

    let sr_no = 1;
    for (const obj of data) {
      if (obj.govtplan_bytender === "govt") {
        result.push({
          ...obj,
          name_of_work_title: "Government Plant",
          dtp_approval_date: obj.govplan_date,
          dtp_amount: obj.govplan_amount,
          tentative_tpc: obj.govt_total_project_cost,
          tender_app_amount:
            obj.govplan_amount === null ? "--" : obj.govplan_amount,
          tender_app_date: obj.govplan_date,
          sr_no: sr_no++,
          above_below: "--",
          agency_gujarati: "સરકારી પ્લાન્ટ",
          agency: "Govt. Plant",
          work_order_date: "--",
          date_of_completion_as_per_wo: "--",
          likely_date_of_completion: "--",
          date_of_completion_as_per_approved_timelimit: "--",
          tender_expen_incured_last_fy_year:
            obj.govt_expen_incured_last_fy_year,
          tender_spill_over_this_fy_year_n_current_year_approved_work:
            obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
          tender_target_expenditure_of_current_fy_year:
            obj.govt_target_expenditure_of_current_fy_year,
          tender_current_year_upto_today: obj.govt_current_year_upto_today,
          tender_probable_expen_from_today_to_end_of_this_fy_year:
            obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
          tender_total_expenditure_as_on_today_on_this_project:
            obj.govt_total_expenditure_as_on_today_on_this_project,
          workstatus_detail: obj.govt_plan_work_detail,
        });
        result.push({
          ...obj,
          // id: "",
          name_of_work_title: "Furniture+Misc.",
          sub_division_name: "--",
          unique_code: "--",
          division_name: "--",
          subdivision_name: "--",
          budget_heads_name: "--",
          scheme_of_budgets_name: "--",
          chainage_from_to: "--",
          length_in_km: "--",
          proposed_work: "--",
          widening_cw: "--",
          paa_amount: "--",
          paa_date: "--",
          paa_aa_amount: "--",
          paa_aa_date: "--",
          ts_amount: "--",
          ts_date: "--",
          sr_no: "",
          tentative_tpc: obj.tender_total_project_cost,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
      } else if (obj.package_id !== 0 && obj.package_id !== null) {
        result.push({
          ...obj,
          name_of_work_title: "Package",
          sr_no: sr_no++,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
        obj.sub_work &&
          obj.sub_work.map((row) =>
            result.push({
              ...row,
              name_of_work_title: "",
              sr_no: "",
              dtp_amount: "--",
              dtp_approval_date: "--",
              tender_opening_date: "--",
              tender_app_amount: "--",
              tender_app_date: "--",
              agency_gujarati: "--",
              agency: "--",
              work_order_date: "--",
              date_of_completion_as_per_wo: "--",
              likely_date_of_completion: "--",
              date_of_completion_as_per_approved_timelimit: "--",
              tentative_tpc: "--",
              tender_expen_incured_last_fy_year: "--",
              tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
              tender_target_expenditure_of_current_fy_year: "--",
              tender_current_year_upto_today: "--",
              tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
              tender_total_expenditure_as_on_today_on_this_project: "--",
              financial_progress: "--",
              physical_progress: "--",
              workstatus_detail: "--",
              work_status: "--",
              above_below: "--",
            })
          );
        // }
      } else {
        result.push({
          ...obj,
          name_of_work_title: "",
          sr_no: sr_no++,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
      }
    }
    return result;
  }

  const handleApi = React.useCallback(() => {
    setIsLoading(true);
    const payload = {
      ...filterKeys,
      page: paginationValues.currentPage,
      per_page: paginationValues.rowPerPage,
    };
    getAgencyWiseData(payload)
      .then((res) => {
        setPaginationValues({
          // currentPage: res.data.current_page,
          // rowPerPage: res.data.per_page,
          ...paginationValues,
          AllPages: res.data.last_page,
        });

        const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err--->>>", err);
        setIsLoading(false);
      });
  }, [paginationValues.currentPage, paginationValues.rowPerPage, filterKeys]);

  useEffect(() => {
    handleApi();
  }, [paginationValues.currentPage, paginationValues.rowPerPage, filterKeys]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [canvasOpenData, setCanvasOpenData] = useState({
    isOpen: false,
  });
  const handleFilterOffset = () => {
    setCanvasOpenData({ isOpen: true });
  };

  const navigate = useNavigate();

  const [engGujState, setEngGujState] = useState(false);

  useEffect(() => {
    const watchRoute = searchParams.get("lang");
    watchRoute === "1" ? setEngGujState(true) : setEngGujState(false);
  }, [searchParams.get("lang")]);

  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
    navigate({
      pathname: "/report_agency_wise",
      search: createSearchParams({
        lang: engGujState ? "0" : "1",
      }).toString(),
    });
  };

  const handleAbstract = () => {
    setAbstractModal({ isOpen: true });
  };

  const handlenavigatetotab = () => {
    // const workid = searchParams.get("workid");
    // window.open(`/update-work=${workid}`);
  };

  // ** Edit WORK
  const handleEditStage = (data) => {
    const url = `/update-work/${data?.id}`;
    window.open(url, "_blank");
  };

  handlenavigatetotab();
  // setSearchParams({ workid: `workid=${data.id}` });
  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData?.map((item) => ({
      id: item.id,
      srno: item.sr_no,
      unique_code: item.unique_code,
      name_of_circle: item.circle_name,
      nameOfDivision: item.division_name,
      // sub_division: item.sub_division_name,
      budget_head: item.budget_heads_name,
      name_of_work_title: item.name_of_work_title,
      name_of_work: engGujState
        ? item.name_of_work_gujarati
        : `${item.name_of_work_title}\n${item.name_of_work_english}`,
      lengthInKM: item.length_in_km,
      proposedWork: item.proposed_work_name,
      aaAmount: item.aa_amount,
      aaDate:
        item.aa_date === null
          ? ""
          : item.aa_date === "--"
          ? item.aa_date
          : getFormattedDate(item.aa_date),

      tender_app_amount: item.tender_app_amount,
      tender_app_date:
        item.tender_app_date === null
          ? ""
          : item.tender_app_date === "--"
          ? item.tender_app_date
          : getFormattedDate(item.tender_app_date),
      above_below: item.above_below,
      name_of_agency_eng: item.agency,
      name_of_agency_guj: item.agency_gujarati,

      workOrderDate:
        item.work_order_date === null
          ? ""
          : item.work_order_date === "--"
          ? item.work_order_date
          : getFormattedDate(item.work_order_date),
      likely_date_of_completion: item.likely_date_of_completion,
      tentativetotal: item.tentative_tpc,
      totalExpen: item.tender_total_expenditure_as_on_today_on_this_project,
      currSpillOver: item.tender_current_spillover_of_project,
      financialprogress: item.financial_progress,
      physicalProgress: item.physical_progress,
      workStatus: engGujState ? item.workstatus_detail : item.work_status,
      status_of_work_in_detail: item.workstatus_detail,
    }));
  }, [tableData, engGujState]);

  // ** Columns For Table
  const columns = useMemo(
    () => getHeaderColumnsByRole(engGujState, handleEditStage, setEditWork),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { pageIndex, pageSize },
    // gotoPage,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    // setPageSize,
    // preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      // initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { control, watch } = useForm({});

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (!hiddenColumns.includes(column.accessor)) {
        left += column.minWidth ? parseInt(column.minWidth, 10) : 120;
      }
    }
    return left;
  }

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "AgencyWise",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      columns.forEach((item, index) => {
        if (row === item.accessor) {
          data.push(index);
        }
      });
    }
    return data;
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];
    const hiddenCol = columnsArr.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const item = columns[row];
        data.push(item.accessor);
      }
    }

    return data;
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "AgencyWise",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });

    // if (loginUser) {
    //   if (loginUser?.role === ROLE.CE || loginUser?.role === ROLE.SECRETORY) {
    //     getDivisionListWOID()
    //       .then((res) => setDivisionOption(res?.data))
    //       .catch((err) => getErrorMsg(err));
    //   } else if (loginUser.role === ROLE.SE) {
    //     getDivisionbyCircle(loginUser.circle_id)
    //       .then((res) => setDivisionOption(res?.data))
    //       .catch((err) => getErrorMsg(err));
    //   }
    // }

    getAgencyList()
      .then((res) => setAgencyOption((prev) => [...prev, ...res?.data]))
      .catch((err) => getErrorMsg(err));
  }, []);

  // if (tableData.length === 0) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <div className="table-title ee_new_work_title">
        <h2 className="text-black">AGENCY WISE REPORT</h2>
        {dateFormate()}
      </div>
      <Card>
        <CardBody className="basic_tab p-0">
          <Row className="gy-1   px-2 py-50">
            <Col xs={4} className="mb-1 mt-0 expselet  pt-2">
              <Controller
                id="agency"
                control={control}
                name="agency"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Select Agency"
                    isClearable
                    options={agencyOption}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.agency}
                    classNamePrefix="select"
                    className="react-select"
                    value={watch("agency")}
                    onChange={(e) => {
                      field.onChange(e);
                      setFilterKeys((prev) => ({
                        ...prev,
                        agency_ids: e && e.id ? e.id : "",
                      }));
                    }}
                  />
                )}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              {`Selected Agency: ${
                watch("agency") ? watch("agency")?.agency : `No Agency Selected`
              }`}
            </Col>
          </Row>
          <div className="dt-action-button btn_search_box d-flex">
            <div>
              {/* <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                onClick={() => handleNewPropesdWork()}
              >
                <Plus size={14} />
                <span className="align-middle ms-25">Add Work</span>
              </Button.Ripple> */}
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">Excel</span>
              </Button.Ripple>
              <ReactToPrint
                trigger={() => (
                  <Button.Ripple
                    color="primary"
                    className="projects_btn me-25"
                    outline
                  >
                    <img src={Print} alt="icon" className="me-1" />
                    <span className="align-middle">Print</span>
                  </Button.Ripple>
                )}
                content={() => {
                  const props = {
                    title: `EXPENDITURE REVIEW : IN PROGRESS - IN TIME WORKS (P-3) Dt.${moment().format(
                      "DD-MM-YYYY"
                    )}(Rs in Lacs)`,
                    getTableProps,
                    headerGroups,
                    hiddenColumns,
                    frozenColumn,
                    getFrozenColumnLeft,
                    getTableBodyProps,
                    rows,
                    prepareRow,
                  };
                  PrintTable(props);
                }}
              />
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">PDF</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
                onClick={() => handleAbstract()}
              >
                <img src={Abstract} alt="icon" className="me-1" />
                <span className="align-middle">Abstract</span>
              </Button.Ripple>

              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="projects_btn me-25"
              >
                <DropdownToggle color="primary" outline caret>
                  <img src={ColumnsVisibility} alt="icon" className="me-1" />
                  <span className="align-middle">Columns Visibility</span>
                </DropdownToggle>
                <DropdownMenu className="p-40 vb-drop column_visibility">
                  {dropdownOptions.map((option, index) => (
                    <Button.Ripple
                      key={option.value}
                      color="primary"
                      onClick={() =>
                        handleToggleHiddenColumns(index, option.value)
                      }
                      className={
                        !hiddenColumns.includes(option.value)
                          ? "active w-100 visibility-Option"
                          : "w-100 visibility-Option bg-white"
                      }
                    >
                      {option.label}
                    </Button.Ripple>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button.Ripple
                className="me-25"
                onClick={() => handleGujaratiEnglish()}
              >
                <span className="align-middle ms-25">Guj. / Eng.</span>
              </Button.Ripple>
            </div>
            <div className="search_Box d-flex">
              <input
                type="text"
                placeholder="Search"
                className="form-control search_bar"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <Button
                color="primary"
                className="filter-btn-width"
                onClick={() => handleFilterOffset()}
              >
                <FilterSvg />
                <span className="ps-25">Filters</span>
              </Button>
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          width: "100%",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id ||
                        columnIndex < columnsFreeze.get(loginUser?.role);

                      // Use classnames to apply multiple classes conditionally
                      const headerCellClass = classNames({
                        "dtfc-fixed-left": isFrozen,
                        "dtfc-cell": !isFrozen,
                        desc: column.isSorted && column.isSortedDesc,
                        asc: column.isSorted && !column.isSortedDesc,
                      });
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={headerCellClass}
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="scrollable-body">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );
                        const isFrozen =
                          frozenColumn === cell.column.id ||
                          cellIndex < columnsFreeze.get(loginUser?.role);
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen
                                ? "dtfc-fixed-left"
                                : `dtfc-cell ${cell.column.class}`
                            }
                            onClick={() => {
                              if (cell.column.tab) {
                                setEditWork({
                                  isOpen: true,
                                  data: cell.row.original.id,
                                  tab: cell.column.tab,
                                });
                              }
                            }}
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                              // background: "white",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div className="nodata-loading-div">
                <p className="nodata-loading">Loading...</p>
              </div>
            )}

            {!isLoading && !rows.length && (
              <div className="nodata-loading-div">
                <p className="nodata-loading">No Data Available</p>
              </div>
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: paginationValues.rowPerPage,
                  label:
                    paginationValues.rowPerPage === "all"
                      ? "All"
                      : paginationValues.rowPerPage,
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPaginationValues({
                    ...paginationValues,
                    currentPage: 1,
                    rowPerPage: selectedOption.value,
                  });
                  // setPageSize(Number(selectedOption.value));
                  // setPageSizeForAPI(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>
            <ReactPaginate
              nextLabel=""
              pageCount={paginationValues.AllPages}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => {
                setPaginationValues({
                  ...paginationValues,
                  currentPage: selected.selected + 1,
                });
                // gotoPage(selected.selected)
              }}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
            {/* ReactPaginate End */}

            {/* <span className="d-none">
              Page{" "}
              <strong>
                {pageIndex + 1} of{" "}
                {Math.ceil(preGlobalFilteredRows.length / pageSize)}
              </strong>
            </span> */}
          </div>
        </CardBody>
      </Card>

      <AgencyWiseAbstract
        abstractModal={abstractModal}
        setAbstractModal={setAbstractModal}
      />

      <AgencyWiseFilter
        canvasOpenData={canvasOpenData}
        setCanvasOpenData={setCanvasOpenData}
        setFilterKeys={setFilterKeys}
      />

      {editWork.isOpen && (
        <EditWorkModal editWork={editWork} setEditWork={setEditWork} />
      )}
    </>
  );
};

export default AgencyWise;
