/* eslint-disable multiline-ternary */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { getDivisionbyCircle } from "../../../../services/seServices";
import { getSubDivisionByDivision } from "../../../../services/reportsServices";
import { getCircles } from "../../../../services/ceServices";
import {
  getBudgetHeadList,
  getProposedWorkList,
  getWorkStatusList,
} from "../../../../services/adminDashboard";
import { stageOption, ROLE, periodOption } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";
import FilterInputManageRole from "../../common/FilterInputManageRole";
import { useSelector } from "react-redux";

const defaultValues = {
  circle: "",
  division: "",
  subDivision: "",
  budgetHead: "",
  stage: "",
  proposedWork: "",
  workStatus: "",
  period: "",
};

const LandAcquisitionFilter = ({
  canvasOpenData,
  setCanvasOpenData,
  getFilterData,
}) => {
  const { reset, control, setValue, watch } = useForm({
    defaultValues,
  });

  const loginUser = useSelector((state) => state.auth.userData.data);

  const [options, setOptions] = useState({
    circlesOption: [],
    divisionOption: [],
    subDivisionOption: [],
    BudgetHeadOption: [],
    proposedWorkOption: [],
    workStatusOption: [],
  });
  console.log("options: ", options);

  const allValues = watch();
  const selectedcirclesIds = watch("circle");
  const selectedDivisionIds = watch("division");
  const currentUserRole = getUserData()?.role;
  const userData = getUserData();

  // Load options on component mount
  // useEffect(() => {
  //   async function fetchCirclesAndWorkStatuses() {
  //     try {
  //       const circlesResponse = await getCircles();
  //       const workStatusesResponse = await getWorkStatusList();
  //       setOptions((prev) => ({
  //         ...prev,
  //         circlesOption: circlesResponse.data.allData,
  //         workStatuses: workStatusesResponse.data,
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching circles and work statuses:", error);
  //     }
  //   }

  //   fetchCirclesAndWorkStatuses();
  // }, []);

  // // Update division options when circles change
  // useEffect(() => {
  //   async function updateDivisionOptions() {
  //     try {
  //       let circleId =
  //         currentUserRole === ROLE.CE
  //           ? selectedcirclesIds
  //           : userData.data.circle_id;
  //       if (circleId) {
  //         const divisionRes = await getDivisionbyCircle(circleId);
  //         setOptions((prev) => ({
  //           ...prev,
  //           divisionOption: divisionRes?.data || [],
  //         }));
  //       }
  //       if (!selectedcirclesIds && currentUserRole === ROLE.CE) {
  //         setOptions((prev) => ({
  //           ...prev,
  //           divisionOption: [],
  //         }));
  //         setValue("division", "");
  //         setValue("subDivision", "");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  //   updateDivisionOptions();
  // }, [selectedcirclesIds, currentUserRole, userData.data.circle_id]);

  // // Update sub-Division options when division changes
  // useEffect(() => {
  //   async function updateSubDivisionOptions() {
  //     try {
  //       const value =
  //         currentUserRole === ROLE.CE || currentUserRole === ROLE.SE
  //           ? selectedDivisionIds
  //           : userData.data.division_id;

  //       const subDivisionRes = await getSubDivisionByDivision(value);

  //       setOptions((prev) => ({
  //         ...prev,
  //         subDivisionOption: subDivisionRes?.data || [],
  //       }));

  //       // if (selectedDivisionIds) {
  //       //   setOptions((prev) => ({
  //       //     ...prev,
  //       //     subDivisionOption: [],
  //       //   }));
  //       //   setValue("subDivision", "");
  //       // }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  //   updateSubDivisionOptions();
  // }, [selectedDivisionIds]);

  // * Circles-Option
  useEffect(() => {
    getCircles()
      .then((res) =>
        setOptions((prev) => ({ ...prev, circlesOption: res?.data?.allData }))
      )
      .catch((err) => console.log(err));

    getWorkStatusList()
      .then((res) => {
        setOptions((prev) => ({ ...prev, workStatusOption: res?.data }));
      })
      .catch((err) => console.log(err));
  }, []);

  // * division-Option
  useEffect(() => {
    if (currentUserRole === ROLE.CE) {
      if (selectedcirclesIds) {
        getDivisionbyCircle(selectedcirclesIds)
          .then((res) =>
            setOptions((prev) => ({ ...prev, divisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    } else {
      if (userData.data.circle_id) {
        getDivisionbyCircle(userData.data.circle_id)
          .then((res) =>
            setOptions((prev) => ({ ...prev, divisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    }
    if (!selectedcirclesIds && currentUserRole === ROLE.CE) {
      setOptions((prev) => ({
        ...prev,
        divisionOption: [],
        subDivisionOption: [],
      }));
      setValue("division", "");
      setValue("subDivision", "");
    }
  }, [selectedcirclesIds]);

  // * sub-Division-Option
  useEffect(() => {
    const divisionIdToFetch =
      loginUser?.role === ROLE.EE || loginUser?.role === ROLE.DEE
        ? loginUser?.division_id
        : selectedDivisionIds;

    if (divisionIdToFetch) {
      getSubDivisionByDivision(divisionIdToFetch)
        .then((res) =>
          setOptions((prev) => ({ ...prev, subDivisionOption: res?.data }))
        )
        .catch((err) => console.log(err));
    }
    if (!selectedDivisionIds) {
      setOptions((prev) => ({ ...prev, subDivisionOption: [] }));
      setValue("subDivision", "");
    }
  }, [selectedDivisionIds]);

  // Load other options
  useEffect(() => {
    async function loadOtherOptions() {
      try {
        const budgetHead = await getBudgetHeadList();
        const proposedWork = await getProposedWorkList();
        setOptions((prev) => ({
          ...prev,
          BudgetHeadOption: budgetHead?.data || [],
          proposedWorkOption: proposedWork?.data || [],
        }));
      } catch (err) {
        console.log(err);
      }
    }

    loadOtherOptions();
  }, []);

  const handleFieldHasValue = React.useCallback(() => {
    const {
      circle: circle_id,
      division: divsion_id,
      subDivision: subdivision_id,
      budgetHead: budgethead_id,
      proposedWork: proposed_work,
      stage,
      workStatus: work_status,
      period,
    } = allValues;

    const value = {
      circle_id,
      divsion_id,
      subdivision_id,
      budgethead_id,
      stage,
      proposed_work,
      work_status,
      period,
    };

    getFilterData(value);
  }, [allValues]);

  React.useEffect(() => {
    handleFieldHasValue();
  }, [allValues, handleFieldHasValue]);

  return (
    <>
      <Offcanvas
        direction={"end"}
        isOpen={canvasOpenData?.isOpen}
        toggle={() =>
          setCanvasOpenData({
            isOpen: false,
          })
        }
        className="canvas-main-body"
      >
        <OffcanvasHeader
          toggle={() =>
            setCanvasOpenData({
              isOpen: false,
            })
          }
          className="for-offcanvas"
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">Filters</h5>
            <Button
              color="primary"
              outline
              onClick={() => {
                reset({});
                setCanvasOpenData({
                  isOpen: false,
                });
              }}
            >
              Reset filters
            </Button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div>
            <Form>
              <Row className="gy-1 pt-1">
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="circle">Circle</Label>
                    <Controller
                      id="circle"
                      control={control}
                      name="circle"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={options.circlesOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={options.circlesOption.filter((row) => {
                            const circles = watch("circle").split(",");
                            return circles.includes(`${row.id}`);
                          })}
                          onChange={(e) => {
                            const circleIdArr = e.map((row) => row.id);
                            const circleIds = circleIdArr.join(",");
                            setValue("circle", circleIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE, ROLE.SE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="division">Division</Label>
                    <Controller
                      id="division"
                      control={control}
                      name="division"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={options.divisionOption}
                          getOptionValue={(opt) => opt.division_id}
                          getOptionLabel={(opt) => opt.division_name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={options.divisionOption.filter((row) => {
                            const divisions = watch("division").split(",");
                            return divisions.includes(`${row.division_id}`);
                          })}
                          onChange={(e) => {
                            const divisionIdArr = e.map(
                              (row) => row.division_id
                            );
                            const divisionIds = divisionIdArr.join(",");
                            setValue("division", divisionIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="subDivision">Sub Division</Label>
                  <Controller
                    id="subDivision"
                    control={control}
                    name="subDivision"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.subDivisionOption}
                        getOptionValue={(opt) => opt.sub_division_id}
                        getOptionLabel={(opt) => opt.sub_division_name}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.subDivisionOption.filter((row) => {
                          const subDivisions = watch("subDivision").split(",");
                          return subDivisions.includes(
                            `${row.sub_division_id}`
                          );
                        })}
                        onChange={(e) => {
                          const subDivisionIdArr = e.map(
                            (row) => row.sub_division_id
                          );
                          const subDivisionIds = subDivisionIdArr.join(",");
                          setValue("subDivision", subDivisionIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="budgetHead">Budget Head</Label>
                  <Controller
                    id="budgetHead"
                    control={control}
                    name="budgetHead"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.BudgetHeadOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.budgethead}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.BudgetHeadOption.filter((row) => {
                          const BudgetHeads = watch("budgetHead").split(",");
                          return BudgetHeads.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const BudgetHeadIdArr = e.map((row) => row.id);
                          const BudgetHeadIds = BudgetHeadIdArr.join(",");
                          setValue("budgetHead", BudgetHeadIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="proposedWork">Proposed Work</Label>
                  <Controller
                    id="proposedWork"
                    control={control}
                    name="proposedWork"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={options.proposedWorkOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.proposed_work}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.proposedWorkOption?.filter((row) => {
                          const proposedWorks =
                            watch("proposedWork").split(",");
                          return proposedWorks.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const proposedWorkIdArr = e.map((row) => row.id);
                          const proposedWorkIds = proposedWorkIdArr.join(",");
                          setValue("proposedWork", proposedWorkIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="workStatus">Work Status</Label>
                  <Controller
                    id="workStatus"
                    control={control}
                    name="workStatus"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.workStatusOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.status}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.workStatusOption?.filter((row) => {
                          const workStatuss = watch("workStatus").split(",");
                          return workStatuss.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const workStatusIdArr = e.map((row) => row.id);
                          const workStatusIds = workStatusIdArr.join(",");
                          setValue("workStatus", workStatusIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="stage">Stage</Label>
                  <Controller
                    id="stage"
                    control={control}
                    name="stage"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={stageOption}
                        classNamePrefix="select"
                        className="react-select"
                        value={stageOption?.filter((row) => {
                          const stages = watch("stage").split(",");
                          return stages.includes(`${row.value}`);
                        })}
                        onChange={(e) => {
                          const stageIdArr = e.map((row) => row.value);
                          const stageIds = stageIdArr.join(",");
                          setValue("stage", stageIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="period">Period</Label>
                  <Controller
                    id="period"
                    control={control}
                    name="period"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={periodOption}
                        classNamePrefix="select"
                        className="react-select"
                        value={periodOption?.filter((row) => {
                          const BudgetHeads = watch("period").split(",");
                          return BudgetHeads.includes(`${row.value}`);
                        })}
                        onChange={(e) => {
                          const BudgetHeadIdArr = e.map((row) => row?.value);
                          const BudgetHeadIds = BudgetHeadIdArr.join(",");
                          setValue("period", BudgetHeadIds);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100"
                outline
                onClick={() =>
                  setCanvasOpenData({
                    isOpen: false,
                  })
                }
              >
                Cancel
              </Button>
            </Form>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default React.memo(LandAcquisitionFilter);
