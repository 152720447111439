import { HomeSvg, ReportsSvg } from "../../assets/images";
import { ROLE } from "../../common/utils";
export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <HomeSvg size={20} />,
    navLink: "/ce/dashboard",
    roles: [ROLE.CE, ROLE.SECRETORY],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <ReportsSvg size={20} />,
    navLink: "/reports",
    roles: [ROLE.CE, ROLE.SECRETORY],
  },
];
