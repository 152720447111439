/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardBody,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Excel from "../../../../../assets/images/excel.png";
import Print from "../../../../../assets/images/print.png";
import ColumnsVisibility from "../../../../../assets/images/ColumnsVisibility.png";
import Abstract from "../../../../../assets/images/Abstract.png";
// import { toast } from "react-toastify";

import {
  EditPenSvg,
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../../assets/images";
import ReactPaginate from "react-paginate";
import {
  ROLE,
  SelectCustomStyles,
  dateFormate,
} from "../../../../../common/utils";
import { getPreConstructionWorksData } from "../../../../../services/reportsServices";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import PreConstructionWorksFilter from "./PreConstructionActivityWorksFilter";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  columnChanges,
  getColumnlist,
} from "../../../../../services/adminDashboard";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import {
  addDuplicateForObjectWithSpecificValue,
  columnsList,
  getFormattedDate,
  getHeaderColumnsByRole,
} from "./PreConstructionActivityWorksUtils";
import EditWorkModal from "../../../../EditWorkModal/EditWorkModal";
import ReactToPrint from "react-to-print";
import PrintTable from "../../../PrintTable";
import moment from "moment";
import PreConstructionActivityWorksAbstract from "./Abstract/PreConstructionActivityWorksAbstract";
// **  Column Freeze as per Login Role
const columnsFreeze = new Map([
  [ROLE.SECRETORY, 7],
  [ROLE.CE, 7],
  [ROLE.SE, 6],
  [ROLE.EE, 5],
  [ROLE.DEE, 5],
]);
const PreConstructionActivityWorks = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.auth.userData.data);
  const [searchParams] = useSearchParams({});
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValues, setPaginationValues] = useState({
    currentPage: 1,
    rowPerPage: 50,
    AllPages: 1,
  });
  const [filterKeys, setFilterKeys] = useState({});
  const [editWork, setEditWork] = useState({
    isOpen: false,
    data: "",
  });
  const [canvasOpenData, setCanvasOpenData] = useState({
    isOpen: false,
  });
  const [engGujState, setEngGujState] = useState(false);
  // Abstract modal ==========
  const [abstractModal, setAbstractModal] = useState({
    isOpen: false,
  });

  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: "all", label: "All" },
  ];

  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData?.map((item) => ({
      id: item.id,
      srno: item.sr_no,
      unique_code: item.unique_code,
      name_of_circle: item.circle_name,
      nameOfDivision: item.division_name,
      sub_division: item.sub_division_name,
      budget_head: item.budget_heads_name,
      name_of_work_title: item.name_of_work_title,
      name_of_work: engGujState
        ? `${item.name_of_work_title}\n${item.name_of_work_gujarati}`
        : `${item.name_of_work_title}\n${item.name_of_work_english}`,
      chainage_from_to: item.chainage_from_to,
      lengthInKM: item.length_in_km,
      land_acquisition_required:
        item.land_acqusition_required === null
          ? "-"
          : item.land_acqusition_required,
      latest_status_of_LA:
        item.land_acqusition_required === "yes" ? item.la_remakas : "-",

      forest_clearance_required:
        item.forest_clearance_required === null
          ? "-"
          : item.forest_clearance_required,
      latest_status_of_forest_clearance:
        item.forest_clearance_required === "yes" ? item.forest_remakas : "-",
      utility_shifting_required:
        item.utility_shifting_required === null
          ? "-"
          : item.utility_shifting_required,
      // latest_status_of_utility_shifting pending *****
      latest_status_of_utility_shifting:
        item?.utility_siftingni_aajroj_antim_paristhti,
      paa_amount: item.paa_amount,
      paa_date:
        item.paa_date === null
          ? ""
          : item.paa_date === "--"
          ? item.paa_date
          : getFormattedDate(item.paa_date),
      tentativetotal: item.tender_total_project_cost,
      expen_incu_upto: item.tender_expen_incured_last_fy_year,
      spillOver_currentYear:
        item.tender_spill_over_this_fy_year_n_current_year_approved_work,
      currentYearExpen: item.tender_current_year_upto_today,
      totalExpen: item.tender_total_expenditure_as_on_today_on_this_project,
      workStatus: item.work_status,
      status_of_work_in_detail: item.workstatus_detail,
    }));
  }, [tableData, engGujState]);

  // ** Edit WORK
  const handleEditStage = (data) => {
    const url = `/update-work/${data?.id}`;
    window.open(url, "_blank");
  };
  // ** Columns For Table
  const columns = useMemo(
    () => getHeaderColumnsByRole(engGujState, handleEditStage, setEditWork),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  const handleApi = React.useCallback(() => {
    setIsLoading(true);
    const payload = {
      ...filterKeys,
      page: paginationValues.currentPage,
      per_page: paginationValues.rowPerPage,
    };
    getPreConstructionWorksData(payload)
      .then((res) => {
        setPaginationValues({
          ...paginationValues,
          AllPages: res.data.last_page,
        });

        const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
        console.log("res?.data?.data:>>>>>>>>", res?.data?.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err--->>>", err);
        setIsLoading(false);
      });
  }, [paginationValues.currentPage, paginationValues.rowPerPage, filterKeys]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterOffset = () => {
    setCanvasOpenData({ isOpen: true });
  };

  const handleAbstract = () => {
    setAbstractModal({ isOpen: true });
  };

  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
    navigate({
      pathname: "/report_pre_cons_work_activity",
      search: createSearchParams({
        lang: engGujState ? "0" : "1",
      }).toString(),
    });
  };

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (!hiddenColumns.includes(column.accessor)) {
        left += column.minWidth ? parseInt(column.minWidth, 10) : 120;
      }
    }
    return left;
  }

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUser?.user_id,
      module_name: "preConsWorksActivityReport",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const getHiddenColumnsIndex = (columns) => {
    const data = [];
    for (const row of columns) {
      const getIndex = columnsList.indexOf(row);
      data.push(getIndex);
    }
    return data;
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columns) => {
    const data = [];
    const hiddenCol = columns.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const getValue = columnsList.at(row);
        data.push(getValue);
      }
    }

    return data;
  };

  useEffect(() => {
    const watchRoute = searchParams.get("lang");
    watchRoute === "1" ? setEngGujState(true) : setEngGujState(false);
  }, [searchParams.get("lang")]);

  useEffect(() => {
    handleApi();
  }, [paginationValues.currentPage, paginationValues.rowPerPage, filterKeys]);

  useEffect(() => {
    const updateData = {
      user_id: loginUser?.user_id,
      module_name: "preConsWorksActivityReport",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  }, []);

  // if (tableData.length === 0) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <div className="table-title ee_new_work_title">
        <h2 className="text-black">PRE CONSTRUCTION ACTIVITY WORKS</h2>
        {dateFormate()}
      </div>
      <Card>
        <CardBody className="basic_tab p-0">
          <div className="dt-action-button btn_search_box d-flex">
            <div>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">Excel</span>
              </Button.Ripple>
              <ReactToPrint
                trigger={() => (
                  <Button.Ripple
                    color="primary"
                    className="projects_btn me-25"
                    outline
                  >
                    <img src={Print} alt="icon" className="me-1" />
                    <span className="align-middle">Print</span>
                  </Button.Ripple>
                )}
                content={() => {
                  const props = {
                    title: `PRE CONSTRUCTION ACTIVITY WORKS, Dt.${moment().format(
                      "DD-MM-YYYY"
                    )}(Rs in Lacs)`,
                    getTableProps,
                    headerGroups,
                    hiddenColumns,
                    frozenColumn,
                    getFrozenColumnLeft,
                    getTableBodyProps,
                    rows,
                    prepareRow,
                  };
                  PrintTable(props);
                }}
              />
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">PDF</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
                onClick={() => handleAbstract()}
              >
                <img src={Abstract} alt="icon" className="me-1" />
                <span className="align-middle">Abstract</span>
              </Button.Ripple>
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="projects_btn me-25"
              >
                <DropdownToggle color="primary" outline caret>
                  <img src={ColumnsVisibility} alt="icon" className="me-1" />
                  <span className="align-middle">Columns Visibility</span>
                </DropdownToggle>
                <DropdownMenu className="p-40 vb-drop column_visibility">
                  {dropdownOptions.map((option, index) => (
                    <Button.Ripple
                      key={option.value}
                      color="primary"
                      onClick={() =>
                        handleToggleHiddenColumns(index, option.value)
                      }
                      className={
                        !hiddenColumns.includes(option.value)
                          ? "active w-100 visibility-Option"
                          : "w-100 visibility-Option bg-white"
                      }
                    >
                      {option.label}
                    </Button.Ripple>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button.Ripple
                className="me-25"
                onClick={() => handleGujaratiEnglish()}
              >
                <span className="align-middle ms-25">Guj. / Eng.</span>
              </Button.Ripple>
            </div>
            <div className="search_Box d-flex">
              <input
                type="text"
                placeholder="Search"
                className="form-control search_bar"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <Button
                color="primary"
                className="filter-btn-width"
                onClick={() => handleFilterOffset()}
              >
                <FilterSvg />
                <span className="ps-25">Filters</span>
              </Button>
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id ||
                        columnIndex < columnsFreeze.get(loginUser?.role);

                      // Use classnames to apply multiple classes conditionally
                      const headerCellClass = classNames({
                        "dtfc-fixed-left": isFrozen,
                        "dtfc-cell": !isFrozen,
                        desc: column.isSorted && column.isSortedDesc,
                        asc: column.isSorted && !column.isSortedDesc,
                      });
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={headerCellClass}
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="scrollable-body">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );
                        const isFrozen =
                          frozenColumn === cell.column.id ||
                          cellIndex < columnsFreeze.get(loginUser?.role);
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                            }
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                              // background: "white",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: paginationValues.rowPerPage,
                  label:
                    paginationValues.rowPerPage === "all"
                      ? "All"
                      : paginationValues.rowPerPage,
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPaginationValues({
                    ...paginationValues,
                    currentPage: 1,
                    rowPerPage: selectedOption.value,
                  });
                  // setPageSize(Number(selectedOption.value));
                  // setPageSizeForAPI(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>
            <ReactPaginate
              nextLabel=""
              pageCount={paginationValues.AllPages}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => {
                setPaginationValues({
                  ...paginationValues,
                  currentPage: selected.selected + 1,
                });
                // gotoPage(selected.selected)
              }}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
            {/* ReactPaginate End */}
          </div>
        </CardBody>
      </Card>

      <PreConstructionActivityWorksAbstract
        abstractModal={abstractModal}
        setAbstractModal={setAbstractModal}
      />
      <PreConstructionWorksFilter
        canvasOpenData={canvasOpenData}
        setCanvasOpenData={setCanvasOpenData}
        setFilterKeys={setFilterKeys}
      />

      {editWork.isOpen && (
        <EditWorkModal editWork={editWork} setEditWork={setEditWork} />
      )}
    </>
  );
};

export default PreConstructionActivityWorks;
