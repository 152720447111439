/* eslint-disable object-shorthand */
import { ROLE } from "../../../../../common/utils";

const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

/* eslint-disable multiline-ternary */
export const getAbstractHeaderColumnsByRole = (engGujState) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "sr_no",
      show: true,
      // minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Constituency",
      accessor: "name_of_constituency",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની યોજના / બજેટ આઈટમનું નામ"
        : "Total No of Works",
      accessor: "total_works",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Length in KM",
      accessor: "length_in_km",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Amount(Lacs)",
      accessor: "totalAmount",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "No. of Not Started Works",
      accessor: "not_started_work_no",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "No. of In Progress Works",
      accessor: "in_progress_work_no",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "No. of Phy. Completed Works",
      accessor: "phy_completed_work_no",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "No. of Completed Works",
      accessor: "completed_work_no",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  return columnsArr.filter((row) => {
    if (row?.roles?.includes(currentUserRole)) return row;
  });
};

export const getAbstractData = (data) => {
  const output = [];
  let sr_no = 1;

  for (const constituency in data) {
    const budgetHeadsData = data[constituency][0];
    output.push({
      sr_no: sr_no++,
      ...budgetHeadsData,
    });
  }

  return output;
};
