import { createUrlFromObject } from "../utility/Utils";
import authHttp from "./authHttp";

// **Dashboard

//
// * Get Division Without ID
export const getDivisionListWOID = async () => {
  const url = `/api/division`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getCEDashboardDataList = async (value) => {
  const url = createUrlFromObject(value, "/api/ceDashboard");
  const { data } = await authHttp.get(url);
  return data;
};

export const getCESEDashboardDataList = async (id, value) => {
  const url = createUrlFromObject(value, `/api/seDashboard/${id}`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getCEEEDashboardDataList = async (id, value) => {
  const url = createUrlFromObject(value, `/api/eeDashboard/${id}`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getCEDashboardHandwiseDatalist = async (value) => {
  const url = `/api/expenditureHeadwise/?circle_id=${value.circle_id}&division_id=${value.division_id}&budgethead_id=${value.budgethead_id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getCircles = async () => {
  const url = `/api/circles`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getPaaYearList = async () => {
  const url = `/api/paaFinancialYears`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getAAYearList = async () => {
  const url = "/api/aaFinancialYears";
  const { data } = await authHttp.get(url);
  return data;
};

export const getCompletionYear = async () => {
  const url = "/api/projectComplationFYear";
  const { data } = await authHttp.get(url);
  return data;
};
