import { lazy } from "react";
import { ROLE } from "../../common/utils";
import MainWorkExpenditure from "../../views/EERole/ExpenditureEntry/MainWork/MainWork";

const EEDashboard = lazy(() => import("../../views/EERole/EEDashboard"));
const CreatePackageTabs = lazy(() =>
  import("../../views/EERole/CreatePackage/CreatePackageTabs")
);

const Consultancy = lazy(() =>
  import(
    "../../views/EERole/ExpenditureEntry/consultancyexpenditure/Consultancy"
  )
);

const EERoutes = [
  {
    path: "/ee/dashboard",
    element: <EEDashboard />,
    roles: [ROLE.EE],
  },
  {
    path: "/ee/create-package",
    element: <CreatePackageTabs />,
    roles: [ROLE.EE],
  },
  {
    path: "/ee/expenditure-entry",
    element: <MainWorkExpenditure />,
    roles: [ROLE.EE],
  },
  {
    path: "/ee/consultancy-expenditure",
    element: <Consultancy />,
    roles: [ROLE.EE],
  },
  // {
  //   path: "/se/work",
  //   element: <NewWork />,
  //   roles: [ROLE.EE],
  // },
  // {
  //   path: "/se/dropdown",
  //   element: <DropDown />,
  //   roles: [ROLE.EE],
  // },
  // {
  //   path: "/se/allprojects",
  //   element: <Allprojects />,
  //   roles: [ROLE.EE],
  // },
];

export default EERoutes;
