import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDivisionbyCircle,
  getSchemeOfBudget,
} from "../../../../../services/seServices";
// import { useSelector } from "react-redux";
import { getUserData } from "../../../../../utility/Utils";
import FilterInputManageRole from "../../../../Reports/common/FilterInputManageRole";
import { ROLE } from "../../../../../common/utils";
import { getCircles } from "../../../../../services/ceServices";
import {
  getBudgetHeadList,
  getProposedWorkList,
} from "../../../../../services/adminDashboard";
import { getSubDivisionByDivision } from "../../../../../services/reportsServices";

const AddGovernmentUsersData = yup.object().shape({
  // start data
  division_id: yup.string().required("Select division_id"),
});

const defaultValues = {
  circle: "",
  division: "",
  subDivision: "",
  budgetHead: "",
  schemeOfBudget: "",
  proposedWork: "",
};

const PreConstFilter = ({
  canvasOpenData,
  setCanvasOpenData,
  setFilterKeys,
}) => {
  const { control, setValue, watch, reset } = useForm({
    defaultValues,
    resolver: yupResolver(AddGovernmentUsersData),
  });

  const [options, setOptions] = useState({
    circlesOption: [],
    divisionOption: [],
    subDivisionOption: [],
    budgetHeadOption: [],
    schemeOfBudgetOption: [],
    proposedWorkOption: [],
  });

  const allValues = watch();
  const selectedcirclesIds = watch("circle");
  const selectedDivisionIds = watch("division");
  const selectedBudgetHeadIds = watch("budgetHead");
  const currentUserRole = getUserData()?.role;
  const userData = getUserData();

  // Circles-Option
  useEffect(() => {
    getCircles()
      .then((res) =>
        setOptions((prev) => ({ ...prev, circlesOption: res?.data?.allData }))
      )
      .catch((err) => console.log(err));
  }, []);

  // division-Option
  useEffect(() => {
    if (currentUserRole === ROLE.CE) {
      if (selectedcirclesIds) {
        getDivisionbyCircle(selectedcirclesIds)
          .then((res) =>
            setOptions((prev) => ({ ...prev, divisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    } else {
      if (userData.data.circle_id) {
        getDivisionbyCircle(userData.data.circle_id)
          .then((res) =>
            setOptions((prev) => ({ ...prev, divisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    }

    if (!selectedcirclesIds && currentUserRole === ROLE.CE) {
      setOptions((prev) => ({
        ...prev,
        divisionOption: [],
        subDivisionOption: [],
      }));
      setValue("division", "");
      setValue("subDivision", "");
    }
  }, [selectedcirclesIds]);

  // sub-Division-Option
  useEffect(() => {
    if (currentUserRole === ROLE.EE) {
      if (userData.data.division_id) {
        getSubDivisionByDivision(userData.data.division_id)
          .then((res) =>
            setOptions((prev) => ({ ...prev, subDivisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    } else {
      if (selectedDivisionIds) {
        getSubDivisionByDivision(selectedDivisionIds)
          .then((res) =>
            setOptions((prev) => ({ ...prev, subDivisionOption: res?.data }))
          )
          .catch((err) => console.log(err));
      }
    }

    if (!selectedDivisionIds) {
      setOptions((prev) => ({
        ...prev,
        subDivisionOption: [],
      }));
      setValue("subDivision", "");
    }
  }, [selectedDivisionIds]);

  // BudgetHead-Option
  useEffect(() => {
    getBudgetHeadList()
      .then((res) =>
        setOptions((prev) => ({ ...prev, budgetHeadOption: res?.data }))
      )
      .catch((err) => console.log(err));
  }, []);

  // SchemeofBudget-Option
  useEffect(() => {
    if (selectedBudgetHeadIds) {
      getSchemeOfBudget(selectedBudgetHeadIds)
        .then((res) =>
          setOptions((prev) => ({ ...prev, schemeOfBudgetOption: res?.data }))
        )
        .catch((err) => console.log(err));
    }

    if (!allValues?.budgetHead) {
      setValue("budgetHead", "");
      setValue("schemeOfBudget", "");
      setOptions((prev) => ({
        ...prev,
        schemeOfBudgetOption: [],
      }));
    }
  }, [selectedBudgetHeadIds]);

  //ProposedWork-Option
  useEffect(() => {
    Promise.allSettled([getProposedWorkList()])
      .then((res) => {
        setOptions((prev) => ({
          ...prev,
          proposedWorkOption: res[0].value?.data,
        }));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleFieldHasValue = React.useCallback(() => {
    const {
      circle: circle_id,
      division: divsion_id,
      subDivision: subdivision_id,
      budgetHead: budgethead_id,
      schemeOfBudget: schemeOfBudget_id,
      proposedWork: proposedwork_id,
    } = allValues;

    const value = {
      circle_id,
      divsion_id,
      subdivision_id,
      budgethead_id,
      schemeOfBudget_id,
      proposedwork_id,
    };

    // const isAllKeysEmpty = Object.values(value).every((value) => value === "");

    // eslint-disable-next-line no-unused-expressions
    // !isAllKeysEmpty &&
    setFilterKeys(value);
  }, [
    allValues.circle,
    allValues.division,
    allValues.subDivision,
    allValues.budgetHead,
    allValues.schemeOfBudget,
    allValues.proposedWork,
  ]);

  React.useEffect(() => {
    handleFieldHasValue();
  }, [
    allValues.circle,
    allValues.division,
    allValues.subDivision,
    allValues.budgetHead,
    allValues.schemeOfBudget,
    allValues.proposedWork,

    handleFieldHasValue,
  ]);

  return (
    <>
      <Offcanvas
        direction={"end"}
        isOpen={canvasOpenData?.isOpen}
        toggle={() =>
          setCanvasOpenData({
            isOpen: false,
          })
        }
        className="canvas-main-body"
      >
        <OffcanvasHeader
          toggle={() =>
            setCanvasOpenData({
              isOpen: false,
            })
          }
          className="for-offcanvas"
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">Filters</h5>
            <Button color="primary" outline onClick={() => reset()}>
              Reset filters
            </Button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div>
            <Form>
              <Row className="gy-1 pt-1">
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="circle">Circle</Label>
                    <Controller
                      id="circle"
                      control={control}
                      name="circle"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={options.circlesOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={options.circlesOption.filter((row) => {
                            const circles = watch("circle").split(",");
                            return circles.includes(`${row.id}`);
                          })}
                          onChange={(e) => {
                            const circleIdArr = e.map((row) => row.id);
                            const circleIds = circleIdArr.join(",");
                            setValue("circle", circleIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE, ROLE.SE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="division">Division</Label>
                    <Controller
                      id="division"
                      control={control}
                      name="division"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={options.divisionOption}
                          getOptionValue={(opt) => opt.division_id}
                          getOptionLabel={(opt) => opt.division_name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={options.divisionOption.filter((row) => {
                            const divisions = watch("division").split(",");
                            return divisions.includes(`${row.division_id}`);
                          })}
                          onChange={(e) => {
                            const divisionIdArr = e.map(
                              (row) => row.division_id
                            );
                            const divisionIds = divisionIdArr.join(",");
                            setValue("division", divisionIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="subDivision">Sub Division</Label>
                  <Controller
                    id="subDivision"
                    control={control}
                    name="subDivision"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.subDivisionOption}
                        getOptionValue={(opt) => opt.sub_division_id}
                        getOptionLabel={(opt) => opt.sub_division_name}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.subDivisionOption.filter((row) => {
                          const subDivisions = watch("subDivision").split(",");
                          return subDivisions.includes(
                            `${row.sub_division_id}`
                          );
                        })}
                        onChange={(e) => {
                          const subDivisionIdArr = e.map(
                            (row) => row.sub_division_id
                          );
                          const subDivisionIds = subDivisionIdArr.join(",");
                          setValue("subDivision", subDivisionIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="budgetHead">Budget Head</Label>
                  <Controller
                    id="budgetHead"
                    control={control}
                    name="budgetHead"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.budgetHeadOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.budgethead}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.budgetHeadOption.filter((row) => {
                          const BudgetHeads = watch("budgetHead").split(",");
                          return BudgetHeads.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const BudgetHeadIdArr = e.map((row) => row.id);
                          const BudgetHeadIds = BudgetHeadIdArr.join(",");
                          setValue("budgetHead", BudgetHeadIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="schemeOfBudget">Scheme of Budget</Label>
                  <Controller
                    id="schemeOfBudget"
                    control={control}
                    name="schemeOfBudget"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.schemeOfBudgetOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.scheme_gujarati}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.schemeOfBudgetOption.filter((row) => {
                          const schemeOfBudgets =
                            watch("schemeOfBudget").split(",");
                          return schemeOfBudgets.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const schemeOfBudgetIdArr = e.map((row) => row.id);
                          const schemeOfBudgetIds =
                            schemeOfBudgetIdArr.join(",");
                          setValue("schemeOfBudget", schemeOfBudgetIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="proposedWork">Proposed Work</Label>
                  <Controller
                    id="proposedWork"
                    control={control}
                    name="proposedWork"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={options.proposedWorkOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.proposed_work}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={options.proposedWorkOption.filter((row) => {
                          const proposedWorks =
                            watch("proposedWork").split(",");
                          return proposedWorks.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const proposedWorkIdArr = e.map((row) => row.id);
                          const proposedWorkIds = proposedWorkIdArr.join(",");
                          setValue("proposedWork", proposedWorkIds);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100"
                outline
                onClick={() =>
                  setCanvasOpenData({
                    isOpen: false,
                  })
                }
              >
                Cancel
              </Button>
            </Form>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default PreConstFilter;
