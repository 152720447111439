/* eslint-disable object-shorthand */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
// // ** Custom Components
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import RNBLoader from "../../../../@core/components/spinner/RNBLoader";
import UILoader from "@components/ui-loader";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import Delete from "../../../../assets/images/delete.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { YMDdate, getFinancialYearDates, ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import {
  createTimeLimitExtraExcess,
  deleteExtraExcess,
  getApproveExtraAccess,
} from "../../../../services/reportsServices";
import DeleteModal from "../../../superAdmin/SAModals/DeleteModal";
import { getWorkTabsData } from "../../../../redux/WorkRedux/getWorkSlice";
import { Plus } from "react-feather";
import { getErrorMsg } from "../../../../common/ErrorMessage";

const defaultValues = {
  approved: [
    {
      approved_excess_extra_date: "",
      approved_excess_extra_amount: "",
    },
  ],
};

const TimeLimit_ExtraExcessData = yup.object().shape({
  date_of_completion_as_per_approved_timelimit: yup
    .string()
    .when("timelimit_extension_approved", {
      is: "yes",
      then: yup.string().required("Enter Date").nullable(),
    })
    .nullable(),
  reason_time_limit: yup
    .string()
    .when("timelimit_extension_approved", {
      is: "yes",
      then: yup.string().required("Enter reason time limit").nullable(),
    })
    .nullable(),
  star_rate_amount: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
      "Star Rate Amount should not exceed 10 digits."
    )
    .nullable(),
  price_variation_amount: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
      "Price Variation Amount should not exceed 10 digits."
    )
    .nullable(),
  any_other_amount_to_be_paid_on_project: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
      "This field should not exceed 10 digits."
    )
    .nullable(),

  // test 1
  // approved: yup.array().of(
  //   yup.object().shape({
  //     approved_excess_extra_date: yup
  //       .string()
  //       .when("extra_excess_approved", {
  //         is: "yes",
  //         then: yup.string().required("Select Approved Date").nullable(),
  //       })
  //       .nullable(),
  //     approved_excess_extra_amount: yup
  //       .string()
  //       .when("extra_excess_approved", {
  //         is: "yes",
  //         then: yup
  //           .string()
  //           .matches(
  //             /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
  //             "Approved Amount (LACS) should not exceed 10 digits"
  //           )
  //           .required("Enter Approved Amount")
  //           .nullable(),
  //       })
  //       .nullable(),
  //   })
  // ),

  // // test 2
  // approved: yup
  //   .array()
  //   .when("timelimit_extension_approved", {
  //     is: "yes",
  //     then: yup.array.of(
  //       yup.object().shape({
  //         approved_excess_extra_date: yup
  //           .string()
  //           .required("Select Approved Date")
  //           .nullable(),
  //         approved_excess_extra_amount: yup
  //           .string()
  //           .matches(
  //             /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
  //             "Approved Amount (LACS) should not exceed 10 digits"
  //           )
  //           .required("Enter Approved Amount")
  //           .nullable(),
  //       })
  //     ),
  //   })
  //   .nullable(),

  //First
  approved: yup.array().of(
    yup.object().shape({
      approved_excess_extra_date: yup
        .string()
        .required("Select Approved Date")
        .nullable(),
      approved_excess_extra_amount: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "Approved Amount (LACS) should not exceed 10 digits"
        )
        .required("Enter Approved Amount")
        .nullable(),
    })
  ),
});

const TimeLimit_ExtraExcess = ({ userData }) => {
  // ** Hooks
  const workData = useSelector((state) => state?.getWork);
  const currentUserRole = getUserData()?.role;
  console.log("workData>>>>", workData?.data);
  const dispatch = useDispatch();
  const [DateCompletion, setDateCompletion] = useState();
  const [ApproveExtraAccess, setApproveExtraAccess] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    data: "",
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(TimeLimit_ExtraExcessData),
  });
  // console.log("errors>>>>>>>", errors, watch("extra_excess_approved"));

  const approvedArray = useFieldArray({ control, name: "approved" });

  const {
    ref: extra_excess_approved_radio,
    ...extra_excess_approved_radiotest
  } = register("extra_excess_approved", {
    required: true,
  });

  const {
    ref: timelimit_extension_approved_radio,
    ...timelimit_extension_approved_radiotest
  } = register("timelimit_extension_approved", {
    required: true,
  });

  const handleDateChange = (date) => {
    setDateCompletion(date);
    // For validation msg and set value
    if (date === null) {
      setValue("date_of_completion_as_per_approved_timelimit", null);
      setError("date_of_completion_as_per_approved_timelimit", {
        type: "manual",
        message: "Enter Date",
      });
    } else {
      setValue(
        "date_of_completion_as_per_approved_timelimit",
        moment(date).format("YYYY-MM-DD")
      );
      setError("date_of_completion_as_per_approved_timelimit", "");
    }
  };

  const handleDelete = (index) => {
    approvedArray.remove(index);
  };

  const handleAddNew = () => {
    // console.log("handleAddNew:>>>", handleAddNew);
    approvedArray.append({
      approved_excess_extra_date: null,
      approved_excess_extra_amount: "",
    });
  };

  const onSubmit = async (data) => {
    // if (Object.values(data).every((field) => field.length > 0)) {
    //   console.log("data:>>", data);
    // }
    // console.log("circleData: ", data);
    // if (Object.keys(errors).length === 0) {
    const {
      timelimit_extension_approved,
      date_of_completion_as_per_approved_timelimit,
      reason_time_limit,
      star_rate_amount,
      price_variation_amount,
      any_other_amount_to_be_paid_on_project,
      extra_excess_approved,
      approved,
    } = data;

    const udata = approved?.map((r) => {
      return {
        ...r,
        approved_excess_extra_date: YMDdate(r.approved_excess_extra_date),
      };
    });

    try {
      const payload = {
        work_id: workData?.data.id,
        timelimit_extension_approved,
        date_of_completion_as_per_approved_timelimit,
        reason_time_limit,
        star_rate_amount,
        price_variation_amount,
        any_other_amount_to_be_paid_on_project,
        extra_excess_approved,
        approved_excess_extra: JSON.stringify(udata),
      };
      const res = await createTimeLimitExtraExcess(payload);
      toast.success(res.message);
      dispatch(getWorkTabsData(userData));
      getApproveExtraAccess(workData.data.id)
        .then((res) => {
          setApproveExtraAccess(res?.data);
        })
        .catch((err) => {
          console.log("ExtraAccess err--->>>", err);
        });
    } catch (err) {
      console.log("err--->>>", err);
    }
  };

  const getFinancialYearDate = getFinancialYearDates();
  console.log("getFinancialYearDate: ", getFinancialYearDate);

  useEffect(() => {
    if (workData?.data) {
      console.log(
        "workData?.data: ",
        workData?.data.timelimit_extension_approved
      );
      reset({
        date_of_completion_as_per_approved_timelimit: workData?.data
          ?.date_of_completion_as_per_approved_timelimit
          ? workData?.data?.date_of_completion_as_per_approved_timelimit
          : "",
        reason_time_limit: workData?.data?.reason_time_limit
          ? workData?.data?.reason_time_limit
          : "",
        star_rate_amount: workData?.data?.star_rate_amount
          ? workData?.data?.star_rate_amount
          : "",
        price_variation_amount: workData?.data?.price_variation_amount
          ? workData?.data?.price_variation_amount
          : "",
        any_other_amount_to_be_paid_on_project: workData?.data
          ?.any_other_amount_to_be_paid_on_project
          ? workData?.data?.any_other_amount_to_be_paid_on_project
          : "",
        extra_excess_approved: workData?.data?.extra_excess_approved || "no",
        timelimit_extension_approved: workData?.data
          ?.timelimit_extension_approved
          ? workData?.data?.timelimit_extension_approved
          : "no",
      });
      if (workData?.data?.date_of_completion_as_per_approved_timelimit) {
        setDateCompletion(
          new Date(workData?.data?.date_of_completion_as_per_approved_timelimit)
        );
      }
    }
  }, [workData?.data]);

  useEffect(() => {
    if (workData?.data?.id) {
      getApproveExtraAccess(workData.data.id)
        .then((res) => {
          setApproveExtraAccess(res?.data);
          const arr = res.data.map((row) => ({
            approved_excess_extra_date: row.approved_excess_extra_date
              ? new Date(row.approved_excess_extra_date)
              : null,
            approved_excess_extra_amount: row.approved_excess_extra_amount
              ? row.approved_excess_extra_amount
              : "",
          }));

          // if (arr.length === 0) {
          //   arr.push({
          //     approved_excess_extra_date: null,
          //     approved_excess_extra_amount: null,
          //   });
          // }
          // console.log("Console.log", res.data);

          setValue("approved", arr);
        })
        .catch((err) => {
          console.log("err--->>>", err);
        });
    }
  }, [workData.data]);

  const idsArray = ApproveExtraAccess?.map((item) => item?.id);
  //  ** Delete ExtraExcess
  const handleDeleteClick = () => {
    const sendAPIData = {
      id: idsArray,
      work_id: deleteModal?.data,
    };
    console.log("sendAPIData:>>>>", sendAPIData);
    deleteExtraExcess(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
        setDeleteModal({
          isOpen: false,
          data: "",
        });
        dispatch(getWorkTabsData(userData));
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  };

  useEffect(() => {
    if (watch("timelimit_extension_approved") === "no") {
      setDateCompletion("");
      setValue("reason_time_limit", "");
    }
  }, [watch("timelimit_extension_approved")]);

  const isFieldsetDisabled =
    workData?.data?.basic_details_of_work_id === null ||
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.paa_aa_id === null) ||
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.paa_aa_id !== null &&
      workData?.data?.ts_dtp_id === null) ||
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.ts_dtp_id !== null &&
      workData?.data?.tender_to_work_order_stage_id === null) ||
    currentUserRole === ROLE.CE;
  return (
    <>
      <UILoader blocking={workData?.isLoading} loader={<RNBLoader />}>
        <Card className="app-card p-2">
          {/* <CardBody className="basic_tab"> */}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isFieldsetDisabled}>
              <Row className="gy-1">
                <>
                  {workData?.data?.basic_details_of_work_id === null &&
                  workData?.data?.paa_aa_id === null &&
                  workData?.data?.ts_dtp_id === null &&
                  workData?.data?.tender_to_work_order_stage_id === null ? (
                    <span className="error help-inline m-1 text-center">
                      Please fill up Basic details, PAA_AA, TS-DTP & Tender to
                      work order stage Tab
                    </span>
                  ) : null}
                  {workData?.data?.basic_details_of_work_id !== null &&
                  workData?.data?.paa_aa_id === null &&
                  workData?.data?.ts_dtp_id === null &&
                  workData?.data?.tender_to_work_order_stage_id === null ? (
                    <span className="error help-inline m-1 text-center">
                      Please fill up PAA_AA, TS-DTP & Tender to work order stage
                      Tab
                    </span>
                  ) : null}

                  {workData?.data?.basic_details_of_work_id !== null &&
                  workData?.data?.paa_aa_id !== null &&
                  workData?.data?.ts_dtp_id === null &&
                  workData?.data?.tender_to_work_order_stage_id === null ? (
                    <span className="error help-inline m-1 text-center">
                      Please fill up TS-DTP & Tender to work order stage Tab
                    </span>
                  ) : null}

                  {workData?.data?.basic_details_of_work_id !== null &&
                  workData?.data?.ts_dtp_id !== null &&
                  workData?.data?.tender_to_work_order_stage_id === null ? (
                    <span className="error help-inline m-1 text-center">
                      Please fill up Tender to work order stage tab
                    </span>
                  ) : null}
                </>
                <Col md={6} xs={12} className="mb-1">
                  <Card className="mb-0">
                    <CardBody className="p-0 box-shadow-0">
                      <div className="card-header d-flex tab-card-header">
                        <h5 className="card-title">Time Limit</h5>
                      </div>
                      <div className="card-content p-2">
                        <Row>
                          <Col>
                            <Label for="pragati_gcode">
                              Timelimit extension Approved?
                            </Label>
                            <div className="demo-inline-spacing checkbox">
                              <div className="custom-control custom-radio">
                                <Input
                                  innerRef={timelimit_extension_approved_radio}
                                  {...timelimit_extension_approved_radiotest}
                                  type="radio"
                                  name="timelimit_extension_approved"
                                  id="timelimit_extension_approved_yes"
                                  value="yes"
                                  className="form-check-input"
                                  disabled={moment(
                                    workData?.data?.date_of_completion_as_per_wo
                                  ).isAfter(moment().format("YYYY-MM-DD"))}
                                />
                                <Label
                                  for="timelimit_extension_approved_yes"
                                  className="ms-50 custom-control-label line-height-inherit form-label form-check-label"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="custom-control custom-radio">
                                <Input
                                  innerRef={timelimit_extension_approved_radio}
                                  {...timelimit_extension_approved_radiotest}
                                  type="radio"
                                  name="timelimit_extension_approved"
                                  id="timelimit_extension_approved_no"
                                  value="no"
                                  className="form-check-input"
                                  disabled={moment(
                                    workData?.data?.date_of_completion_as_per_wo
                                  ).isAfter(moment().format("YYYY-MM-DD"))}
                                />
                                <Label
                                  for="timelimit_extension_approved_no"
                                  className="ms-50 form-check-label custom-control-label line-height-inherit form-label"
                                >
                                  No
                                </Label>
                              </div>
                              <Button
                                color="primary"
                                outline
                                disabled={moment(
                                  workData?.data?.date_of_completion_as_per_wo
                                ).isAfter(moment().format("YYYY-MM-DD"))}
                                onClick={() => {
                                  setValue(
                                    "timelimit_extension_approved",
                                    null
                                  );
                                  setDateCompletion(null);
                                  setValue("reason_time_limit", "");
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </Col>
                          {/* Date piker pending for (TS Date) --------------------------------------------- */}
                          <Col md={6} xs={12} className="DatePicker-w100">
                            <Label for="date_of_completion_as_per_approved_timelimit">
                              Date of Completion As per Timelimit
                            </Label>
                            <Controller
                              control={control}
                              id="date_of_completion_as_per_approved_timelimit"
                              name="date_of_completion_as_per_approved_timelimit"
                              render={({ field }) => {
                                return (
                                  <ReactDatePicker
                                    {...field}
                                    selected={DateCompletion}
                                    onChange={handleDateChange}
                                    // disabled={
                                    //   watch("timelimit_extension_approved") ===
                                    //     "no" ||
                                    //   watch("timelimit_extension_approved") ===
                                    //     null
                                    // }
                                    disabled={
                                      moment(
                                        workData?.data
                                          ?.date_of_completion_as_per_wo
                                      ).isAfter(
                                        moment().format("YYYY-MM-DD")
                                      ) ||
                                      watch("timelimit_extension_approved") !==
                                        "yes"
                                    }
                                  />
                                );
                              }}
                            />
                            {errors.date_of_completion_as_per_approved_timelimit && (
                              <FormFeedback className="d-block">
                                {
                                  errors
                                    .date_of_completion_as_per_approved_timelimit
                                    .message
                                }
                              </FormFeedback>
                            )}
                          </Col>
                          <Col md={12} className="mt-1">
                            <Label for="reason_time_limit">
                              Reason for Time Limit Extention in Gujarati
                            </Label>
                            <Controller
                              control={control}
                              name="reason_time_limit"
                              render={({ field }) => {
                                return (
                                  <Input
                                    type="textarea"
                                    rows="3"
                                    className="gujarati-bg"
                                    {...field}
                                    id="reason_time_limit"
                                    // disabled={
                                    //   watch("timelimit_extension_approved") ===
                                    //     "no" ||
                                    //   watch("timelimit_extension_approved") ===
                                    //     null
                                    // }
                                    disabled={
                                      moment(
                                        workData?.data
                                          ?.date_of_completion_as_per_wo
                                      ).isAfter(
                                        moment().format("YYYY-MM-DD")
                                      ) ||
                                      watch("timelimit_extension_approved") !==
                                        "yes"
                                    }
                                    // value={field.value}
                                    invalid={errors.reason_time_limit && true}
                                  />
                                );
                              }}
                            />
                            {errors.reason_time_limit && (
                              <FormFeedback>
                                {errors.reason_time_limit.message}
                              </FormFeedback>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} xs={12} className="mb-1">
                  <Card>
                    <CardBody className="p-0 box-shadow-0">
                      <div className="card-header d-flex tab-card-header">
                        <h5 className="card-title">Additional Expenditure</h5>
                      </div>
                      <div className="card-content p-2">
                        <Row>
                          <Col md={6} xs={12}>
                            <Label for="star_rate_amount">
                              Star Rate Amount(Lacs)
                            </Label>
                            <Controller
                              control={control}
                              name="star_rate_amount"
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    id="star_rate_amount"
                                    // value={field.value}
                                    invalid={errors.star_rate_amount && true}
                                    onChange={(e) => {
                                      const pattern =
                                        /^(?!0(\.0{1,2})?$)\d{0,12}(\.\d{0,2})?$/;
                                      if (pattern.test(e.target.value)) {
                                        field.onChange(e);
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.star_rate_amount && (
                              <FormFeedback>
                                {errors.star_rate_amount.message}
                              </FormFeedback>
                            )}
                          </Col>
                          <Col md={6} xs={12}>
                            <Label for="price_variation_amount">
                              Price Variation Amount(Lacs)
                            </Label>
                            <Controller
                              control={control}
                              name="price_variation_amount"
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    id="price_variation_amount"
                                    // value={field.value}
                                    onChange={(e) => {
                                      const pattern =
                                        /^(?!0(\.0{1,2})?$)\d{0,12}(\.\d{0,2})?$/;
                                      if (pattern.test(e.target.value)) {
                                        field.onChange(e);
                                      }
                                    }}
                                    invalid={
                                      errors.price_variation_amount && true
                                    }
                                    // onChange={(e) => {
                                    //   const pattern =
                                    //     /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                    //   if (pattern.test(e.target.value)) {
                                    //     field.onChange(e);
                                    //   }
                                    // }}
                                  />
                                );
                              }}
                            />
                            {errors.price_variation_amount && (
                              <FormFeedback>
                                {errors.price_variation_amount.message}
                              </FormFeedback>
                            )}
                          </Col>
                          {/* Date piker pending for (DTP Approval Date) --------------------------------------------- */}
                          <Col md={12} className="mt-1">
                            <Label for="any_other_amount_to_be_paid_on_project">
                              Any Other Amount to be Paid on project(Lacs)
                            </Label>
                            <Controller
                              control={control}
                              name="any_other_amount_to_be_paid_on_project"
                              render={({ field }) => {
                                return (
                                  <Input
                                    {...field}
                                    id="any_other_amount_to_be_paid_on_project"
                                    // value={field.value}
                                    invalid={
                                      errors.any_other_amount_to_be_paid_on_project &&
                                      true
                                    }
                                    onChange={(e) => {
                                      const pattern =
                                        /^(?!0(\.0{1,2})?$)\d{0,12}(\.\d{0,2})?$/;
                                      if (pattern.test(e.target.value)) {
                                        field.onChange(e);
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.any_other_amount_to_be_paid_on_project && (
                              <FormFeedback>
                                {
                                  errors.any_other_amount_to_be_paid_on_project
                                    .message
                                }
                              </FormFeedback>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                {/* {console.log(
                  "extra_excess_approved>>>",
                  watch("extra_excess_approved")
                )} */}
                <Col md={6} xs={12} className="">
                  <Card>
                    <CardBody className="p-0 box-shadow-0">
                      <div className="card-header d-flex tab-card-header">
                        <h5 className="card-title">Extra / Excess</h5>
                      </div>
                      <div className="card-content p-2">
                        <Row>
                          <Col className="mt-1">
                            <Label>Extra / Excess Approved?</Label>
                            <div>
                              <div className="demo-inline-spacing checkbox">
                                <div className="custom-control custom-radio">
                                  <Input
                                    innerRef={extra_excess_approved_radio}
                                    {...extra_excess_approved_radiotest}
                                    type="radio"
                                    name="extra_excess_approved"
                                    id="extra_excess_approved_yes"
                                    value="yes"
                                    className="form-check-input"
                                  />
                                  <Label
                                    for="extra_excess_approved_yes"
                                    className="ms-50 custom-control-label line-height-inherit form-label form-check-label"
                                  >
                                    Yes
                                  </Label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <Input
                                    innerRef={extra_excess_approved_radio}
                                    {...extra_excess_approved_radiotest}
                                    type="radio"
                                    name="extra_excess_approved"
                                    id="extra_excess_approved_no"
                                    value="no"
                                    className="form-check-input"
                                    onClick={() => {
                                      if (ApproveExtraAccess.length !== 0) {
                                        setDeleteModal({
                                          isOpen: true,
                                          data: workData?.data?.id,
                                        });
                                      }
                                    }}
                                  />
                                  <Label
                                    for="extra_excess_approved_no"
                                    className="ms-50 form-check-label custom-control-label line-height-inherit form-label"
                                  >
                                    No
                                  </Label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <Button
                                    onClick={() => {
                                      setValue("extra_excess_approved", null);
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bg-header-css card-content py-1 mt-1">
                        <Row>
                          <Col md={6} xs={12}>
                            <label className="line-height-inherit text-uppercase">
                              Approved Date
                            </label>
                          </Col>
                          <Col md={6} xs={12} className="ps-0">
                            <label className="line-height-inherit text-uppercase">
                              APPROVED AMOUNT (LACS)
                            </label>
                          </Col>
                        </Row>
                      </div>
                      {/* repeater=================== */}
                      {/* <Repeater count={count}></Repeater> */}

                      {/* {console.log("value: 1 ", approvedArray.fields)} */}
                      <div className="card-content pe-2 ps-2">
                        {approvedArray?.fields.map((value, index) => {
                          return (
                            <Row key={value.id}>
                              <Col className="mt-1 DatePicker-w100">
                                {/* {console.log("value: 2 ", value)} */}
                                <Controller
                                  // id={`approved.${index}.approved_excess_extra_date`}
                                  name={`approved.${index}.approved_excess_extra_date`}
                                  control={control}
                                  render={({ field }) => (
                                    <ReactDatePicker
                                      {...field}
                                      onChange={(date) => field.onChange(date)}
                                      selected={field.value}
                                    />
                                  )}
                                />

                                {errors.approved?.[index]
                                  ?.approved_excess_extra_date && (
                                  <FormFeedback className="d-block">
                                    {
                                      errors.approved[index]
                                        .approved_excess_extra_date.message
                                    }
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col className="mt-1">
                                <Controller
                                  // id={`approved.${index}.approved_excess_extra_amoun11t`}
                                  name={`approved.${index}.approved_excess_extra_amount`}
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      type="number"
                                      name={`approved.${index}.approved_excess_extra_amount`}
                                      onChange={(e) => {
                                        const pattern =
                                          /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                        if (pattern.test(e.target.value)) {
                                          field.onChange(e);
                                        }
                                      }}
                                    />
                                  )}
                                />

                                {errors.approved?.[index]
                                  ?.approved_excess_extra_amount && (
                                  <FormFeedback className="d-block">
                                    {
                                      errors.approved[index]
                                        .approved_excess_extra_amount.message
                                    }
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col className="col-auto dlt-btn pe-0">
                                <Button
                                  className="d-flex mt-2"
                                  onClick={() => handleDelete(index)}
                                  style={{
                                    padding: "0 10px",
                                  }}
                                >
                                  <img src={Delete} alt="icon" />
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      <Row>
                        <Col
                          md={12}
                          className="pb-2 ms-2 pt-2 align-content-center d-flex"
                        >
                          <Button
                            color="primary"
                            disabled={watch("extra_excess_approved") === "no"}
                            onClick={() => handleAddNew()}
                          >
                            <Plus size={16} />
                            <span className="ms-50">Add New</span>
                          </Button>
                        </Col>

                        {approvedArray?.fields?.length === 0 &&
                          watch("extra_excess_approved") === "yes" && (
                            <FormFeedback className="d-block m-1 ">
                              Add Any Extra / Excess Approved
                            </FormFeedback>
                          )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} className=" mt-0">
                  <Button
                    className="btn btn-outline-dark waves-effect me-25"
                    type="reset"
                    outline
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      approvedArray?.fields?.length === 0 &&
                      watch("extra_excess_approved") === "yes"
                    }
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
          {/* </CardBody> */}

          {deleteModal?.isOpen && (
            <DeleteModal
              deleteModal={deleteModal}
              handleClick={handleDeleteClick}
              setDeleteModal={setDeleteModal}
              DeleteWarning={"Delete Extra Excess?"}
              SubWarning={
                "It will not be possible to restore this record after delete."
              }
            />

            // <Fragment>
            //   <Modal
            //     isOpen={deleteModal?.isOpen}
            //     toggle={() =>
            //       setDeleteModal({
            //         isOpen: false,
            //         data: "",
            //       })
            //     }
            //     className="modal-dialog-centered modal-sm"
            //   >
            //     <ModalHeader
            //       className="bg-transparent p-1"
            //       toggle={() =>
            //         setDeleteModal({
            //           isOpen: false,
            //           data: "",
            //         })
            //       }
            //     ></ModalHeader>
            //     <ModalBody className="py-0 ">
            //       <div className="text-center mb-2 me-1">
            //         <h1 className="mb-1">Delete Extra Excess?</h1>
            //       </div>
            //       <Row className="gy-1 pt-1">
            //         <Col xs={12} className=" mt-0">
            //           <p>
            //             It will not be possible to restore this record after
            //             delete.
            //           </p>
            //         </Col>
            //         <Col xs={12} className="modal-footer mt-0">
            //           <Button
            //             className="btn btn-outline-dark waves-effect"
            //             type="reset"
            //             outline
            //             onClick={() => {
            //               setValue("extra_excess_approved", "yes");
            //               setDeleteModal({
            //                 isOpen: false,
            //                 data: "",
            //               });
            //             }}
            //           >
            //             Cancel
            //           </Button>
            //           <Button color="primary" onClick={handleDeleteClick}>
            //             Delete
            //           </Button>
            //         </Col>
            //       </Row>
            //     </ModalBody>
            //   </Modal>
            // </Fragment>
          )}
        </Card>
      </UILoader>
    </>
  );
};

export default TimeLimit_ExtraExcess;
