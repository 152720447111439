/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import {
  FinancialProgressOption,
  ROLE,
  TimeLimitOption,
} from "../../../../../common/utils";
import {
  getAgencyList,
  getBudgetHeadList,
  getProposedWorkList,
} from "../../../../../services/adminDashboard";
import { getCircles } from "../../../../../services/ceServices";
import { getSubDivisionByDivision } from "../../../../../services/reportsServices";
import {
  getDivisionbyCircle,
  getSchemeOfBudget,
} from "../../../../../services/seServices";
import { getUserData } from "../../../../../utility/Utils";
import FilterInputManageRole from "../../../common/FilterInputManageRole";
import { useSelector } from "react-redux";

const defaultValues = {
  circle: "",
  division: "",
  subDivision: "",
  budgetHead: "",
  schemeOfBudget: "",
  proposedWork: "",
  agency: "",
  time_limit_per: "",
  physical_progress: "",
  financialProgress: "",
};

const FilterInProgressInTIme = ({
  canvasOpenData,
  setCanvasOpenData,
  getFilterData,
}) => {
  const loginUser = useSelector((state) => state.auth.userData.data);
  const { reset, control, setValue, watch } = useForm({
    defaultValues,
  });

  const [circlesOption, setCirclesOption] = useState([]);
  const [divisionOption, setDivisionOption] = useState([]);
  const [subDivisionOption, setSubDivisionOption] = useState([]);
  const [BudgetHeadOption, setBudgetHeadOption] = useState([]);
  const [schemeOfBudgetOption, setSchemeOfBudgetOption] = useState([]);
  const [proposedWorkOption, SetProposedWorkOption] = useState([]);
  const [agencyOption, SetAgencyOption] = useState([]);

  const allValues = watch();
  const selectedcirclesIds = watch("circle");
  const selectedDivisionIds = watch("division");
  const selectedBudgetHeadIds = watch("budgetHead");
  const currentUserRole = getUserData()?.role;
  const userData = getUserData();

  // * Circles-Option
  useEffect(() => {
    getCircles()
      .then((res) => setCirclesOption(res?.data?.allData))
      .catch((err) => console.log(err));
  }, []);

  // * division-Option
  useEffect(() => {
    if (currentUserRole === ROLE.CE) {
      if (selectedcirclesIds) {
        getDivisionbyCircle(selectedcirclesIds)
          .then((res) => setDivisionOption(res?.data))
          .catch((err) => console.log(err));
      }
    } else {
      if (userData.data.circle_id) {
        getDivisionbyCircle(userData.data.circle_id)
          .then((res) => setDivisionOption(res?.data))
          .catch((err) => console.log(err));
      }
    }
    if (!selectedcirclesIds && currentUserRole === ROLE.CE) {
      setDivisionOption([]);
      setSubDivisionOption([]);
      setValue("division", "");
      setValue("subDivision", "");
    }
  }, [selectedcirclesIds]);

  // * sub-Division-Option
  useEffect(() => {
    const divisionIdToFetch =
      loginUser?.role === ROLE.EE || loginUser?.role === ROLE.DEE
        ? loginUser?.division_id
        : selectedDivisionIds;

    if (divisionIdToFetch) {
      getSubDivisionByDivision(divisionIdToFetch)
        .then((res) => setSubDivisionOption(res?.data))
        .catch((err) => getErrorMsg(err));
    }
    if (!selectedDivisionIds) {
      setSubDivisionOption([]);
      setValue("subDivision", "");
    }
  }, [loginUser, selectedDivisionIds]);

  // * BudgetHead-Option
  useEffect(() => {
    getBudgetHeadList()
      .then((res) => setBudgetHeadOption(res?.data))
      .catch((err) => console.log(err));
  }, []);

  // * Fetch Scheme of Budget Options
  useEffect(() => {
    if (selectedBudgetHeadIds) {
      getSchemeOfBudget(selectedBudgetHeadIds)
        .then((res) => setSchemeOfBudgetOption(res?.data))
        .catch((err) => getErrorMsg(err));
    }
    if (!allValues?.budgetHead) {
      setValue("budgetHead", "");
      setValue("schemeOfBudget", "");
      setSchemeOfBudgetOption([]);
    }
  }, [allValues.budgetHead]);

  // * ProposedWork-Option
  useEffect(() => {
    getProposedWorkList()
      .then((res) => SetProposedWorkOption(res?.data))
      .catch((err) => console.log(err));

    getAgencyList()
      .then((res) => {
        SetAgencyOption(res?.data);
      })
      .catch((err) => getErrorMsg(err));
  }, []);

  const handleFieldHasValue = React.useCallback(() => {
    const {
      circle: circle_id,
      division: divsion_id,
      subDivision: subdivision_id,
      budgetHead: budgethead_id,
      schemeOfBudget: schemeOfBudget_id,
      proposedWork: proposedwork_id,
      agency: agency_ids,
      time_limit_per,
      physical_progress,
      financialProgress: financial_progress,
    } = allValues;

    const value = {
      circle_id,
      divsion_id,
      subdivision_id,
      budgethead_id,
      schemeOfBudget_id,
      proposedwork_id,
      agency_ids,
      time_limit_per,
      physical_progress,
      financial_progress,
    };

    // eslint-disable-next-line no-unused-expressions
    getFilterData(value);
  }, [allValues]);

  React.useEffect(() => {
    handleFieldHasValue();
  }, [allValues, handleFieldHasValue]);

  return (
    <>
      <Offcanvas
        direction={"end"}
        isOpen={canvasOpenData?.isOpen}
        toggle={() =>
          setCanvasOpenData({
            isOpen: false,
          })
        }
        className="canvas-main-body"
      >
        <OffcanvasHeader
          toggle={() =>
            setCanvasOpenData({
              isOpen: false,
            })
          }
          className="for-offcanvas"
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">Filters</h5>
            <Button
              color="primary"
              outline
              onClick={() => {
                reset({});
              }}
            >
              Reset filters
            </Button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div>
            <Form>
              <Row className="gy-1 pt-1">
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="circle">Circle</Label>
                    <Controller
                      id="circle"
                      control={control}
                      name="circle"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={circlesOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={circlesOption.filter((row) => {
                            const circles = watch("circle").split(",");
                            return circles.includes(`${row.id}`);
                          })}
                          onChange={(e) => {
                            const circleIdArr = e.map((row) => row.id);
                            const circleIds = circleIdArr.join(",");
                            setValue("circle", circleIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <FilterInputManageRole
                  currentRole={currentUserRole}
                  accessibleRoles={[ROLE.CE, ROLE.SE]}
                >
                  <Col xs={12} className="mb-1 mt-0 form-custom-input">
                    <Label for="division">Division</Label>
                    <Controller
                      id="division"
                      control={control}
                      name="division"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          options={divisionOption}
                          getOptionValue={(opt) => opt.division_id}
                          getOptionLabel={(opt) => opt.division_name}
                          classNamePrefix="select"
                          className="react-select"
                          isMulti
                          value={divisionOption.filter((row) => {
                            const divisions = watch("division").split(",");
                            return divisions.includes(`${row.division_id}`);
                          })}
                          onChange={(e) => {
                            const divisionIdArr = e.map(
                              (row) => row.division_id
                            );
                            const divisionIds = divisionIdArr.join(",");
                            setValue("division", divisionIds);
                          }}
                        />
                      )}
                    />
                  </Col>
                </FilterInputManageRole>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="subDivision">Sub Division</Label>
                  <Controller
                    id="subDivision"
                    control={control}
                    name="subDivision"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={subDivisionOption}
                        getOptionValue={(opt) => opt.sub_division_id}
                        getOptionLabel={(opt) => opt.sub_division_name}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={subDivisionOption.filter((row) => {
                          const subDivisions = watch("subDivision").split(",");
                          return subDivisions.includes(
                            `${row.sub_division_id}`
                          );
                        })}
                        onChange={(e) => {
                          const subDivisionIdArr = e.map(
                            (row) => row.sub_division_id
                          );
                          const subDivisionIds = subDivisionIdArr.join(",");
                          setValue("subDivision", subDivisionIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="budgetHead">Budget Head</Label>
                  <Controller
                    id="budgetHead"
                    control={control}
                    name="budgetHead"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        options={BudgetHeadOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.budgethead}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={BudgetHeadOption.filter((row) => {
                          const BudgetHeads = watch("budgetHead").split(",");
                          return BudgetHeads.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const BudgetHeadIdArr = e.map((row) => row.id);
                          const BudgetHeadIds = BudgetHeadIdArr.join(",");
                          setValue("budgetHead", BudgetHeadIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="schemeOfBudget">Scheme of Budget</Label>
                  <Controller
                    id="schemeOfBudget"
                    control={control}
                    name="schemeOfBudget"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={schemeOfBudgetOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.scheme_gujarati}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={schemeOfBudgetOption.filter((row) => {
                          const schemeOfBudgets =
                            watch("schemeOfBudget").split(",");
                          return schemeOfBudgets.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const schemeOfBudgetIdArr = e.map((row) => row.id);
                          const schemeOfBudgetIds =
                            schemeOfBudgetIdArr.join(",");
                          setValue("schemeOfBudget", schemeOfBudgetIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="agency">Agency</Label>
                  <Controller
                    id="agency"
                    control={control}
                    name="agency"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={agencyOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.agency}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={agencyOption.filter((row) => {
                          const agencys = watch("agency")?.split(",");
                          return agencys?.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const agencyIdArr = e.map((row) => row.id);
                          const agencyIds = agencyIdArr?.join(",");
                          setValue("agency", agencyIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="proposedWork">Proposed Work</Label>
                  <Controller
                    id="proposedWork"
                    control={control}
                    name="proposedWork"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={proposedWorkOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.proposed_work}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={proposedWorkOption.filter((row) => {
                          const proposedWorks =
                            watch("proposedWork").split(",");
                          return proposedWorks.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const proposedWorkIdArr = e.map((row) => row.id);
                          const proposedWorkIds = proposedWorkIdArr.join(",");
                          setValue("proposedWork", proposedWorkIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="time_limit_per">Time Limit(%)</Label>
                  <Controller
                    id="time_limit_per"
                    control={control}
                    name="time_limit_per"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isMulti
                        isClearable
                        options={TimeLimitOption}
                        classNamePrefix="select"
                        className="react-select"
                        value={
                          TimeLimitOption.find(
                            (row) => row.value === watch("time_limit_per")
                          ) || null
                        }
                        onChange={(e) => {
                          // if (e?.value) {
                          setValue("time_limit_per", e?.value);
                          // }
                        }}
                      />
                    )}
                  />
                </Col>
                {/* physical_progress */}
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="physical_progress">Physical Progress(%)</Label>
                  <Controller
                    id="physical_progress"
                    control={control}
                    name="physical_progress"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isMulti
                        isClearable
                        options={FinancialProgressOption}
                        classNamePrefix="select"
                        className="react-select"
                        value={
                          FinancialProgressOption.find(
                            (row) => row.value === watch("physical_progress")
                          ) || null
                        }
                        onChange={(e) => {
                          // if (e?.value) {
                          setValue("physical_progress", e?.value);
                          // }
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="financialProgress">Financial Progress(%)</Label>
                  <Controller
                    id="financialProgress"
                    control={control}
                    name="financialProgress"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        // isMulti
                        options={FinancialProgressOption}
                        classNamePrefix="select"
                        className="react-select"
                        value={
                          FinancialProgressOption.find(
                            (row) => row.value === watch("financialProgress")
                          ) || null
                        }
                        onChange={(e) => {
                          // if (e?.value) {
                          setValue("financialProgress", e?.value);
                          // }
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100"
                outline
                onClick={() =>
                  setCanvasOpenData({
                    isOpen: false,
                  })
                }
              >
                Cancel
              </Button>
            </Form>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default React.memo(FilterInProgressInTIme);
