import { getUserData } from "../../../../utility/Utils";

import { ROLE, TodayDate } from "../../../../common/utils";

/* eslint-disable multiline-ternary */

export const getHeaderColumnsByRole = (
  engGujState,
  handleEditStage
  // setEditWork
) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Circle",
      accessor: "name_of_circle",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division",
      accessor: "nameOfDivision",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    // {
    //   Header: engGujState ? "પેટા વિભાગનું નામ" : "Name of Sub Division",
    //   accessor: "sub_division",
    //   show: true,
    //   minWidth: "135px",
    //   roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    // },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામની યોજના / બજેટ આઈટમનું નામ" : "Name of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "287px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));

        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Length in KM",
      accessor: "lengthInKM",
      show: true,
      minWidth: "112px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "Details of Proposed Work (Treatment)",
      accessor: "proposedWork",
      show: true,
      minWidth: "112px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "વહીવટી મંજુરી રકમ" : "AA Amount",
      accessor: "aaAmount",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વહીવટી મંજુરી તારીખ" : "AA Date",
      accessor: "aaDate",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની રકમ" : "Tender App. Amount",
      accessor: "tender_app_amount",
      show: true,
      minWidth: "137px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની તારીખ" : "Tender App. Date",
      accessor: "tender_app_date",
      show: true,
      minWidth: "127px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? " % ઉંચા / નીચા" : "% Above / Below",
      accessor: "above_below",
      show: true,
      minWidth: "107px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div className="celllll" style={{ whiteSpace: "pre-wrap" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency(ENG)",
      accessor: "name_of_agency_eng",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency(GUJ)",
      accessor: "name_of_agency_guj",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "વર્ક ઓર્ડરનીતારીખ / કામ શરૂ કર્યા તારીખ"
        : "Work Order Date & Date of Completion As per W.O.",
      accessor: "workOrderDate",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "વર્ક ઓર્ડરનીતારીખ / કામ શરૂ કર્યા તારીખ"
        : "Likely Date of Completion",
      accessor: "likely_date_of_completion",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "સંભવિત કૂલ પ્રોજેક્ટ રકમ"
        : "Tentative Total Project Cost",
      accessor: "tentativetotal",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `શરૂઆતથી ${TodayDate()} સુધી થયેલ કુલ ખર્ચ`
        : `Total Expen. occu. till ${TodayDate()} on Project`,
      accessor: "totalExpen",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "સને 2023-24 નો કુલ સંભવિત ખર્ચ"
        : "Current Spill Over of Project",
      accessor: "currSpillOver",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "નાણાંકીય પ્રગતિ ટકાવારીમાં"
        : "Financial Progress in %",
      accessor: "financialprogress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "ભૌતિક પ્રગતિ ટકાવારીમાં"
        : "Physical Progress in %",
      accessor: "physicalProgress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "ભૌતિક પ્રગતિ ટકાવારીમાં" : "Work Status",
      accessor: "workStatus",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ ટુંકમાં(ગુજરાતીમાં)"
        : "Status of Work in Detail",
      accessor: "status_of_work_in_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            cursor: "pointer",
          }}
        >
          {cell.value}
        </div>
      ),
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};
