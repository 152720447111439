import { createUrlFromObject } from "../utility/Utils";
import authHttp from "./authHttp";

// **Dashboard

export const getSEDashboardDataList = async (id) => {
  const url = `/api/seDashboard/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getSEDashboardHandwiseDatalist = async (value) => {
  const url = `/api/expenditureHeadwise/?circle_id=${value.id}&division_id=all&budgethead_id=all`;
  const { data } = await authHttp.get(url);
  return data;
};

//

// ** Edit permission
export const getEditPermissionData = async () => {
  const url = `/api/editPermission`;
  const { data } = await authHttp.get(url);
  return data;
};

export const UpdateEditPermission = async (value) => {
  const url = `/api/updatePermission`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** New Work API

export const AddNewWork = async (value) => {
  const url = `/api/createWork`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getDivisionbyCircle = async (id) => {
  const url = `/api/getDivisionbyCircleID?id=${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getSchemeOfBudget = async (id) => {
  const url = `/api/schemeOfBudget_/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getWorkList = async (id) => {
  const url = `/api/worklisting?id=${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteWork = async (id) => {
  const url = `/api/deletework/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateWork = async (value) => {
  const url = `/api/updateWork`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** DropDown

export const getTalukaListData = async (id) => {
  const url = `/api/taluka/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteTaluka = async (id) => {
  const url = `/api/deletetalukaapi/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const AddNewTaluka = async (value) => {
  const url = `/api/createtaluka`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const UpdateTaluka = async (value) => {
  const url = `/api/updatetalukaapi`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** Old Work
export const getListOldCompletedWorks = async (value) => {
  const url = createUrlFromObject(value, `/api/getMovedCompletedWork`);
  const { data } = await authHttp.get(url);
  return data;
};

export const getMovedToOldCompletedWorksList = async (payload) => {
  const url = createUrlFromObject(payload, "/api/getCompletedWork");
  const { data } = await authHttp.get(url);
  return data;
};

export const getRemovedMovedCompeletedWork = async (id) => {
  const url = `/api/removeMovedCompletedWork/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const OldMovedWork = async (value) => {
  const url = `/api/oldMovedWork`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// export const getPDF = async () => {
//   const url = `/api/allProjectListingFE?page=1&per_page=20&circle_id&divsion_id&subdivision_id&budgethead_id&schemeOfBudget_id&agency_ids&work_status_ids&paa_years&aa_years&proposedwork_id&export_type=pdf&type=1&module_name=allProjectListing`;
//   const { data } = await authHttp.get(url);
//   return data;
// };

// export const getPDF = async () => {
//   const url = `/api/allProjectListingFE?page=1&per_page=20&circle_id&divsion_id&subdivision_id&budgethead_id&schemeOfBudget_id&agency_ids&work_status_ids&paa_years&aa_years&proposedwork_id&export_type=pdf&type=1&module_name=allProjectListing`;
//   const response = await authHttp.get(url);
//   console.log("API Response:", response.data); // Log the API response
//   return response.data;
// };

// export const getPDF = async () => {
//   const url = `/api/allProjectListingFE?page=1&per_page=20&circle_id&divsion_id&subdivision_id&budgethead_id&schemeOfBudget_id&agency_ids&work_status_ids&paa_years&aa_years&proposedwork_id&export_type=pdf&type=1&module_name=allProjectListing`;
//   const { data } = await authHttp.get(url);
//   return data.previewUrl; // Assuming 'previewUrl' is the key for the preview URL in the API response
// };

// export const getExportsAllProjects = async (payload) => {
//   const exportUrl = `/api/allProjectListingFE?page=${payload.page}&per_page=${payload.per_page}&circle_id=${payload.circle_id}&divsion_id=${payload.divsion_id}&subdivision_id=${payload.subdivision_id}&budgethead_id=${payload.budgethead_id}&schemeOfBudget_id${payload.schemeOfBudget_id}&agency_ids=${payload.agency_ids}&work_status_ids=${payload.work_status_ids}&paa_years=${payload.paa_years}&aa_years=${payload.aa_years}&proposedwork_id=${payload.proposedwork_id}&export_type=${payload.export_type}&type=${payload.type}&module_name=${payload.module_name}`;

//   authHttp.get(exportUrl, { responseType: "blob" }).then(async (res) => {
//     const url = window.URL.createObjectURL(new Blob([res.data]));
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", `${payload.title}.pdf`);
//     document.body.appendChild(link);
//     link.click();
//     link.remove();
//     URL.revokeObjectURL(link.href);
//   });
// };

export const getExportsAllProjects = async (payload) => {
  const queryParams = new URLSearchParams({
    page: payload.page,
    per_page: payload.per_page,
    circle_id: payload.circle_id,
    divsion_id: payload.divsion_id,
    subdivision_id: payload.subdivision_id,
    budgethead_id: payload.budgethead_id,
    schemeOfBudget_id: payload.schemeOfBudget_id,
    agency_ids: payload.agency_ids,
    work_status_ids: payload.work_status_ids,
    paa_years: payload.paa_years,
    aa_years: payload.aa_years,
    proposedwork_id: payload.proposedwork_id,
    export_type: payload.export_type,
    type: payload.type,
    module_name: payload.module_name,
  });

  try {
    const response = await authHttp.get(
      `/api/allProjectListingFE?${queryParams}`,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${payload.title}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error fetching export data:", error);
  }
};
