import { getUserData } from "../../../../utility/Utils";

import {
  FYOnlyYears,
  ROLE,
  TodayDate,
  TomorrowDate,
  getFinancialYearDates,
} from "../../../../common/utils";

/* eslint-disable multiline-ternary */

export const getHeaderColumnsByRole = (
  engGujState,
  handleEditStage
  // setEditWork
) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division",
      accessor: "nameOfDivision",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "પેટા વિભાગનું નામ" : "Name of Sub Division",
      accessor: "sub_division",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામની યોજના / બજેટ આઈટમનું નામ" : "Name of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "287px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));
        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },

    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "PAA Amount",
      accessor: "paaAmount",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "PAA Date",
      accessor: "paaDate",
      show: true,
      minWidth: "160px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState ? "તાંત્રીક મંજુરી રકમ" : "TS Amount",
      accessor: "ts_amount",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "તાંત્રીક મંજુરી તારીખ" : "TS Date",
      accessor: "ts_date",
      show: true,
      minWidth: "173px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "ડીટીપી મંજુરી રકમ" : "DTP Amount",
      accessor: "dtp_amount",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "ડીટીપી મંજુરી તારીખ" : "DTP Date",
      accessor: "dtp_approval_date",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની રકમ" : "Tender App. Amount",
      accessor: "tender_app_amount",
      show: true,
      minWidth: "137px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની તારીખ" : "Tender App. Date",
      accessor: "tender_app_date",
      show: true,
      minWidth: "127px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? " % ઉંચા / નીચા" : "% Above / Below",
      accessor: "above_below",
      show: true,
      minWidth: "107px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{cell.value}</div>
      ),
    },
    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency",
      accessor: "name_of_agency",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "વર્ક ઓર્ડરનીતારીખ / કામ શરૂ કર્યા તારીખ"
        : "Work Order Date",
      accessor: "workOrderDate",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામ પુર્ણ થવાની તારીખ (વર્ક ઓર્ડર મુજબ)"
        : "Date of Completion As per W.O.",
      accessor: "dateofCompletion",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામ પૂર્ણ થવાની સંભવિત તારીખ"
        : "Likely Date of Completion",
      accessor: "likelyDateofCompletion",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `${getFinancialYearDates()?.lastYear?.end} સુધીનો ખર્ચ`
        : `Expen. incu. upto ${
            getFinancialYearDates()?.lastYear?.end
          } (LAST F.Y.)`,
      accessor: "expenincu",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState
        ? `તા.${
            getFinancialYearDates()?.currentYear?.start
          } ની સ્પીલ ઓવર તથા ચાલુ વર્ષનાં મંજૂર થયેલ કામની સ્પીલઓવર`
        : `SPILL OVER AS ON ${
            getFinancialYearDates()?.currentYear?.start
          }  & OF CURRENT YEAR APP. WORK`,
      accessor: "Spillover",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState
        ? `સને ${FYOnlyYears()} નો કુલ સંભવિત ખર્ચ`
        : `Target Expen. of Year ${FYOnlyYears()}`,
      accessor: "targetExpen",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState
        ? `તા. ${
            getFinancialYearDates()?.currentYear?.start
          } થી ${TodayDate()} સુધીનો ખર્ચ (ફક્ત ચાલુ વર્ષ નો ખર્ચ)`
        : `Current Year Expen. From ${
            getFinancialYearDates()?.currentYear?.start
          } to till ${TodayDate()}`,
      accessor: "Expenincurrentyear",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState
        ? `આજ ${TodayDate()} થી તા.${
            getFinancialYearDates()?.currentYear?.end
          } સુધીનો સંભવિત ખર્ચ વર્ષ ${FYOnlyYears()}`
        : `Probable Expen. from ${TomorrowDate()} to ${
            getFinancialYearDates()?.currentYear?.end
          } for year ${FYOnlyYears()}`,
      accessor: "probableExpen",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ ટુંકમાં(ગુજરાતીમાં)"
        : "Status of Work in Detail",
      accessor: "status_of_work_in_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],

      Cell: (cell) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            cursor: "pointer",
          }}
        >
          {cell.value}
        </div>
      ),
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};
