// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Row, Col, Modal, Button, ModalBody, ModalHeader } from "reactstrap";

const DeleteModal = ({
  DeleteWarning,
  SubWarning,
  deleteModal,
  setDeleteModal,
  handleClick,
}) => {
  return (
    <Fragment>
      <Modal
        isOpen={deleteModal?.isOpen}
        toggle={() =>
          setDeleteModal({
            isOpen: false,
            data: "",
          })
        }
        className="modal-dialog-centered modal-sm"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() =>
            setDeleteModal({
              isOpen: false,
              data: "",
            })
          }
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">{DeleteWarning}</h1>
          </div>
          <Row className="gy-1 pt-1">
            <Col xs={12} className=" mt-0">
              <p>{SubWarning}</p>
            </Col>
            <Col xs={12} className="modal-footer mt-0">
              <Button
                className="btn btn-outline-dark waves-effect"
                type="reset"
                outline
                onClick={() =>
                  setDeleteModal({
                    isOpen: false,
                    data: "",
                  })
                }
              >
                Cancel
              </Button>
              <Button color="primary" onClick={handleClick}>
                Delete
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DeleteModal;
