import React from "react";

const MainTitle = ({ title, tpc }) => {
  return (
    <div className="table-title ee_new_work_title">
      <h2 className="mb-2 mt-2">{title}</h2>
      {tpc ? <p>Tentative TPC (in Lacs) = {tpc} </p> : ""}
    </div>
  );
};

export default MainTitle;
