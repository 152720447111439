/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "@styles/react/libs/react-select/_react-select.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { updateTsDtpWork } from "../../../../services/reportsServices";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import { getWorkTabsData } from "../../../../redux/WorkRedux/getWorkSlice";
import RNBLoader from "../../../../@core/components/spinner/RNBLoader";
import UILoader from "@components/ui-loader";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const TS_DTPData = yup.object().shape({
  // * TS Left Card
  ts_amount: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
      "TS Amount should not exceed 10 digits"
    )
    .required("Enter TS Amount")
    .nullable(),
  ts_date: yup
    .string()
    .when("ts_amount", {
      is: (val) => val !== null,
      then: yup.string().required("Select TS Dates").nullable(),
    })
    .nullable(),

  // ** Radio Btn
  govtplan_bytender: yup.string().nullable(),

  // * tender >  Government Plant

  // ? 1 Govt. Plant Amount(Lacs)
  govplan_amount: yup
    .string()
    .when("govtplan_bytender", {
      is: "govt",
      then: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "Govt. Plant Amount should not exceed 10 digits"
        )
        .required("Enter Govt. Plant Amount")
        .nullable(),
    })
    .nullable(),

  // ? 2  Govt. Plant Approval Date
  govplan_date: yup
    .string()
    .when("govplan_amount", {
      is: (val) => val !== null && val !== undefined && val !== "",
      then: yup
        .string()
        .required("Select Govt. Plant Approval Date")
        .nullable(),
    })
    .nullable(),

  // * tender >  Furniture & Misc. Work

  //  ? DTP Amount(Lacs)

  dtp_amount: yup
    .string()
    .when("govtplan_bytender", {
      is: "govt",
      then: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "DTP Amount (Lacs) should not exceed 10 digits"
        )
        // .required("Enter DTP Amount")
        .nullable(),
    })
    .nullable(),

  // ? Not Put To Tender Amount(Lacs)
  not_put_tender_amount: yup
    .string()
    .when("dtp_amount", {
      is: (val) => val !== null && val !== undefined && val !== "",
      then: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "Not Put To Tender Amount(Lacs)should not exceed 10 digits"
        )
        .required("Enter Not Put To Tender Amount")
        .nullable(),
    })
    .nullable(),

  // ? DTP Approval Date
  dtp_approval_date: yup
    .string()
    .when("dtp_amount", {
      is: (val) => val !== null && val !== undefined && val !== "",
      then: yup
        .string()

        .required("Select DTP Approval Date")
        .nullable(),
    })
    .nullable(),

  // * tender > DTP

  // 1
  for_dtp_amount: yup
    .string()
    .when("govtplan_bytender", {
      is: "tender",
      then: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "DTP Amount (Lacs) should not exceed 10 digits"
        )
        // .required("Enter DTP Amount")
        .nullable(),
    })
    .nullable(),

  for_not_put_tender_amount: yup
    .string()
    .when("for_dtp_amount", {
      is: (val) => val !== null && val !== undefined && val !== "",
      then: yup
        .string()
        .matches(
          /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
          "Not Put To Tender Amount(Lacs)should not exceed 10 digits"
        )
        .required("Enter Not Put To Tender Amount")
        .nullable(),
    })
    .nullable(),

  for_dtp_approval_date: yup
    .string()
    .when("for_dtp_amount", {
      is: (val) => val !== null && val !== undefined && val !== "",
      then: yup.string().required("Select DTP Approval Date").nullable(),
    })
    .nullable(),
});

const TS_DTP = ({ userData }) => {
  const dispatch = useDispatch();
  const workData = useSelector((state) => state?.getWork);
  const [tsDate, setTsDate] = useState();
  const [dtpApprovalDate, setDtpApprovalDate] = useState();
  const [forDtpApprovalDate, setForDtpApprovalDate] = useState();
  const [govtPlantApprovalDate, setGovtPlantApprovalDate] = useState();
  const currentUserRole = getUserData()?.role;
  // ** Hooks
  const {
    reset,
    setValue,
    watch,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange", resolver: yupResolver(TS_DTPData) });

  console.log("errors>>>>>>>>", errors);
  const { ref: govtplan_bytender_radio, ...govtplan_bytender_radiotest } =
    register("govtplan_bytender", {
      required: true,
    });

  // ** TS Date
  const handleTsDateChange = (date) => {
    setTsDate(date);
    setValue("ts_date", moment(date).format("YYYY-MM-DD"));
    if (date === null) {
      setError("ts_date", {
        type: "manual",
        message: "Select TS Date",
      });
    } else {
      setError("ts_date", "");
    }
  };
  // ** Govt. Plant Approval Date
  const handleGovtPlantApprovalDateChange = (date) => {
    setGovtPlantApprovalDate(date);
    setValue("govplan_date", moment(date).format("YYYY-MM-DD"));
    if (date === null) {
      setError("govplan_date", {
        type: "manual",
        message: "Select Govt. Plant Approval Date",
      });
    } else {
      setError("govplan_date", "");
    }
  };
  // ** DTP Approval Date
  const handleDtpApprovalDateChange = (date) => {
    setDtpApprovalDate(date);
    setValue("dtp_approval_date", moment(date).format("YYYY-MM-DD"));
    if (date === null) {
      setError("dtp_approval_date", {
        type: "manual",
        message: "Select DTP Approval Date",
      });
    } else {
      setError("dtp_approval_date", "");
    }
  };
  // ** ForDTP Approval Date
  const handleForDtpApprovalDateChange = (date) => {
    setForDtpApprovalDate(date);

    if (date === null) {
      setValue("for_dtp_approval_date", date);
    } else {
      setValue("for_dtp_approval_date", moment(date).format("YYYY-MM-DD"));
    }
    if (date === null) {
      setError("for_dtp_approval_date", {
        type: "manual",
        message: "Select DTP Date",
      });
    } else {
      setError("for_dtp_approval_date", "");
    }
  };

  // * API OnSubmit
  const onSubmit = (data) => {
    const sendData = {
      // *  Only TS data
      package_id: workData?.data?.package_id,
      work_id: workData?.data?.id,
      ts_amount: data?.ts_amount,
      ts_date: data?.ts_date,
      govtplan_bytender: data?.govtplan_bytender,
      //By GovtPlant data
      govplan_amount:
        data?.govtplan_bytender === "govt" ? data?.govplan_amount : null,
      govplan_date:
        data?.govtplan_bytender === "govt" ? data?.govplan_date : null,
      //By Tender data
      dtp_amount:
        data?.govtplan_bytender === "govt"
          ? data?.dtp_amount
          : data?.govtplan_bytender === "tender"
          ? data?.for_dtp_amount
          : null,
      not_put_tender_amount:
        data?.govtplan_bytender === "govt"
          ? data?.not_put_tender_amount
          : data?.govtplan_bytender === "tender"
          ? data?.for_not_put_tender_amount
          : null,
      dtp_approval_date:
        data?.govtplan_bytender === "govt"
          ? data?.dtp_approval_date
          : data?.govtplan_bytender === "tender"
          ? data?.for_dtp_approval_date === null
            ? null
            : data?.for_dtp_approval_date
          : null,
    };
    console.log("sendData>>>>", sendData);

    // // By Tender data
    // const ByTender = {
    //   work_id: workData?.data?.id,
    //   ts_amount: data?.ts_amount,
    //   ts_date: data?.ts_date,
    //   govtplan_bytender: data?.govtplan_bytender,
    //   //
    //   dtp_amount: data?.for_dtp_amount,
    //   not_put_tender_amount: data?.for_not_put_tender_amount,
    //   dtp_approval_date: data?.for_dtp_approval_date,
    // };
    // console.log("ByTender: ", ByTender);
    // // ByGovtPlant/ByTender = null data
    // const dataNull = {
    //   work_id: workData?.data?.id,
    //   ts_amount: data?.ts_amount,
    //   ts_date: data?.ts_date,
    // };
    // console.log("Reset-DataNull: ", dataNull);

    updateTsDtpWork(sendData)
      .then((res) => {
        toast.success(res?.message);
        dispatch(getWorkTabsData(userData));
      })
      .catch((err) => getErrorMsg(err));
  };

  useEffect(() => {
    if (workData?.data?.govtplan_bytender === "govt") {
      reset({
        ts_amount: workData?.data?.ts_amount,
        govtplan_bytender: workData?.data?.govtplan_bytender,
        govplan_amount: workData?.data?.govplan_amount,
        dtp_amount: workData?.data?.dtp_amount,
        not_put_tender_amount: workData?.data?.not_put_tender_amount,
      });
    } else if (workData?.data?.govtplan_bytender === "tender") {
      reset({
        ts_amount: workData?.data?.ts_amount,
        govtplan_bytender: workData?.data?.govtplan_bytender,
        for_dtp_amount: workData?.data?.dtp_amount,
        for_not_put_tender_amount: workData?.data?.not_put_tender_amount,
      });
    } else {
      reset({
        ts_amount: workData?.data?.ts_amount,
        govtplan_bytender: workData?.data?.govtplan_bytender,
      });
    }

    if (workData?.data?.ts_date) {
      setTsDate(new Date(workData?.data?.ts_date));
      setValue("ts_date", moment(workData?.data?.ts_date).format("YYYY-MM-DD"));
    }
    if (workData?.data?.govplan_date) {
      setGovtPlantApprovalDate(new Date(workData?.data?.govplan_date));
      setValue(
        "govplan_date",
        moment(workData?.data?.govplan_date).format("YYYY-MM-DD")
      );
    }
    // ** DTP and Furniture & Misc. Work
    if (
      workData?.data?.dtp_approval_date &&
      workData?.data?.govtplan_bytender === "govt"
    ) {
      setDtpApprovalDate(new Date(workData?.data?.dtp_approval_date));
      setValue(
        "dtp_approval_date",
        moment(workData?.data?.dtp_approval_date).format("YYYY-MM-DD")
      );
    } else if (workData?.data?.dtp_approval_date) {
      setForDtpApprovalDate(new Date(workData?.data?.dtp_approval_date));
      setValue(
        "for_dtp_approval_date",
        moment(workData?.data?.dtp_approval_date).format("YYYY-MM-DD")
      );
    }
  }, [workData]);

  const isFieldsetDisabled =
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.paa_aa_id === null) ||
    (workData?.data?.basic_details_of_work_id === null &&
      workData?.data?.paa_aa_id === null) ||
    currentUserRole === ROLE.CE;

  return (
    <>
      <UILoader blocking={workData?.isLoading} loader={<RNBLoader />}>
        <Card>
          <>
            {workData?.data?.basic_details_of_work_id !== null &&
              workData?.data?.paa_aa_id === null && (
                <span
                  className="error help-inline m-1 text-center"
                  id="ts_dtp_error"
                >
                  Please fill up PAA_AA Tab details
                </span>
              )}
            {workData?.data?.basic_details_of_work_id === null &&
              workData?.data?.paa_aa_id === null && (
                <span
                  className="error help-inline m-1 text-center"
                  id="ts_dtp_error"
                >
                  Please fill up Basic details & PAA_AA Tab details
                </span>
              )}
          </>
          <CardBody className="basic_tab">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={isFieldsetDisabled}>
                <Row className="gy-1">
                  <Col md={6} xs={12} className=" mt-0">
                    <Card>
                      <CardBody className="p-0 box-shadow-0">
                        <div className="card-header d-flex tab-card-header">
                          <h5 className="card-title">TS</h5>
                        </div>
                        <div className="card-content p-2">
                          <Row>
                            <Col md={6} xs={12}>
                              <lable className="fp-default" for="ts_amount">
                                TS Amount(Lacs)
                              </lable>
                              <Controller
                                control={control}
                                name="ts_amount"
                                render={({ field }) => {
                                  return (
                                    <Input
                                      type="text"
                                      {...field}
                                      id="ts_amount"
                                      invalid={errors.ts_amount && true}
                                      onChange={(e) => {
                                        const pattern =
                                          /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                        if (pattern.test(e.target.value)) {
                                          field.onChange(e);
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />
                              {errors.ts_amount && (
                                <FormFeedback>
                                  {errors.ts_amount.message}
                                </FormFeedback>
                              )}
                            </Col>

                            <Col md={6} xs={12} className="DatePicker-w100">
                              <lable className="fp-default" for="ts_date">
                                TS Date
                              </lable>
                              <Controller
                                control={control}
                                id="ts_date"
                                name="ts_date"
                                render={({ field }) => (
                                  <ReactDatePicker
                                    {...field}
                                    // isClearable={true}
                                    selected={tsDate}
                                    onChange={handleTsDateChange}
                                    disabled={!watch("ts_amount")}
                                  />
                                )}
                              />
                              {errors.ts_date && (
                                <FormFeedback className="d-block">
                                  {errors.ts_date.message}
                                </FormFeedback>
                              )}
                            </Col>
                            <Col className="mt-1">
                              <lable
                                className="fp-default"
                                for="govtplan_bytender"
                              >
                                By Govt. Plant / By Tender
                              </lable>
                              <div>
                                <div className="demo-inline-spacing checkbox">
                                  <div className="custom-control custom-radio">
                                    <Input
                                      innerRef={govtplan_bytender_radio}
                                      {...govtplan_bytender_radiotest}
                                      type="radio"
                                      name="govtplan_bytender"
                                      id="govtplan_bytender_yes"
                                      value="govt"
                                      className="form-check-input"
                                      // disabled={!watch("ts_amount")}
                                    />
                                    <Label
                                      for="govtplan_bytender_yes"
                                      className="ms-50 form-check-label"
                                    >
                                      By Govt. Plant
                                    </Label>
                                  </div>
                                  <div className="custom-control custom-radio">
                                    <Input
                                      innerRef={govtplan_bytender_radio}
                                      {...govtplan_bytender_radiotest}
                                      type="radio"
                                      name="govtplan_bytender"
                                      id="govtplan_bytender_no"
                                      value="tender"
                                      className="form-check-input"
                                      // disabled={!watch("ts_amount")}
                                    />
                                    <Label
                                      for="govtplan_bytender_no"
                                      className="ms-50 form-check-label"
                                    >
                                      By Tender
                                    </Label>
                                  </div>
                                  <div className="custom-control custom-radio">
                                    <Button
                                      // disabled={!watch("ts_amount")}
                                      onClick={() => {
                                        setValue("govtplan_bytender", null);
                                      }}
                                    >
                                      Reset
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* DTP_DTP */}
                  <Col md="6" className="mt-0">
                    {watch("govtplan_bytender") === "tender" && (
                      <Col xs={12} className="mt-0">
                        <Card>
                          <CardBody className="p-0 box-shadow-0">
                            <div className="card-header d-flex tab-card-header">
                              <h5 className="card-title">DTP</h5>
                            </div>
                            <div className="card-content p-2">
                              <Row>
                                <Col md={6} xs={12}>
                                  <lable
                                    className="fp-default"
                                    for="for_dtp_amount"
                                  >
                                    DTP Amount(Lacs)
                                  </lable>
                                  <Controller
                                    control={control}
                                    name="for_dtp_amount"
                                    render={({ field }) => {
                                      return (
                                        <Input
                                          {...field}
                                          type="text"
                                          id="for_dtp_amount"
                                          invalid={
                                            errors.for_dtp_amount && true
                                          }
                                          onChange={(e) => {
                                            const pattern =
                                              /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                            if (pattern.test(e.target.value)) {
                                              field.onChange(e);
                                            }
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  {errors.for_dtp_amount && (
                                    <FormFeedback className="d-block">
                                      {errors.for_dtp_amount.message}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={6} xs={12}>
                                  <lable
                                    className="fp-default"
                                    for="for_not_put_tender_amount"
                                  >
                                    Not Put To Tender Amount(Lacs)
                                  </lable>
                                  <Controller
                                    control={control}
                                    name="for_not_put_tender_amount"
                                    render={({ field }) => {
                                      return (
                                        <Input
                                          {...field}
                                          disabled={!watch("for_dtp_amount")}
                                          id="for_not_put_tender_amount"
                                          type="text" // value={field.value}
                                          invalid={
                                            errors.for_not_put_tender_amount &&
                                            true
                                          }
                                          onChange={(e) => {
                                            const pattern =
                                              /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                            if (pattern.test(e.target.value)) {
                                              field.onChange(e);
                                            }
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  {errors.for_not_put_tender_amount && (
                                    <FormFeedback>
                                      {errors.for_not_put_tender_amount.message}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col
                                  md={6}
                                  xs={12}
                                  className="mt-1 DatePicker-w100"
                                >
                                  <lable
                                    className="fp-default"
                                    for="for_dtp_approval_date"
                                  >
                                    DTP Approval Date
                                  </lable>
                                  <Controller
                                    control={control}
                                    id="for_dtp_approval_date"
                                    name="for_dtp_approval_date"
                                    render={({ field }) => (
                                      <ReactDatePicker
                                        {...field}
                                        selected={forDtpApprovalDate}
                                        disabled={!watch("for_dtp_amount")}
                                        onChange={
                                          handleForDtpApprovalDateChange
                                        }
                                      />
                                    )}
                                  />
                                  {errors.for_dtp_approval_date && (
                                    <FormFeedback className="d-block">
                                      {errors.for_dtp_approval_date.message}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    )}
                    {watch("govtplan_bytender") === "govt" && (
                      <>
                        <Col xs={12} className="mt-0">
                          <Card>
                            <CardBody className="p-0 box-shadow-0">
                              <div className="card-header d-flex tab-card-header">
                                <h5 className="card-title">Government Plant</h5>
                              </div>
                              <div className="card-content p-2">
                                <Row>
                                  <Col md={6} xs={12}>
                                    <lable
                                      className="fp-default"
                                      for="govplan_amount"
                                    >
                                      Govt. Plant Amount(Lacs)
                                    </lable>
                                    <Controller
                                      control={control}
                                      name="govplan_amount"
                                      render={({ field }) => {
                                        return (
                                          <Input
                                            {...field}
                                            id="govplan_amount"
                                            disabled={!watch("ts_amount")}
                                            invalid={
                                              errors.govplan_amount && true
                                            }
                                            type="text"
                                            onChange={(e) => {
                                              const pattern =
                                                /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                              if (
                                                pattern.test(e.target.value)
                                              ) {
                                                field.onChange(e);
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                    {errors.govplan_amount && (
                                      <FormFeedback>
                                        {errors.govplan_amount.message}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                  <Col
                                    md={6}
                                    xs={12}
                                    className="DatePicker-w100"
                                  >
                                    <lable
                                      className="fp-default"
                                      for="govplan_date"
                                    >
                                      Govt. Plant Approval Date
                                    </lable>
                                    <Controller
                                      control={control}
                                      id="govplan_date"
                                      name="govplan_date"
                                      render={({ field }) => (
                                        <ReactDatePicker
                                          {...field}
                                          disabled={!watch("govplan_amount")}
                                          selected={govtPlantApprovalDate}
                                          onChange={
                                            handleGovtPlantApprovalDateChange
                                          }
                                        />
                                      )}
                                    />
                                    {errors.govplan_date && (
                                      <FormFeedback className="d-block">
                                        {errors.govplan_date.message}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs={12} className="mt-0">
                          <Card>
                            <CardBody className="p-0 box-shadow-0">
                              <div className="card-header d-flex tab-card-header">
                                <h5 className="card-title">
                                  Furniture & Misc. Work
                                </h5>
                              </div>
                              <div className="card-content p-2">
                                <Row>
                                  <Col md={6} xs={12}>
                                    <lable
                                      className="fp-default"
                                      for="dtp_amount"
                                    >
                                      DTP Amount(Lacs)
                                    </lable>
                                    <Controller
                                      control={control}
                                      name="dtp_amount"
                                      render={({ field }) => {
                                        return (
                                          <Input
                                            {...field}
                                            id="dtp_amount"
                                            type="text"
                                            // value={field.value}
                                            invalid={errors.dtp_amount && true}
                                            onChange={(e) => {
                                              const pattern =
                                                /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                                              if (
                                                pattern.test(e.target.value)
                                              ) {
                                                field.onChange(e);
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                    {errors.dtp_amount && (
                                      <FormFeedback>
                                        {errors.dtp_amount.message}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <lable
                                      className="fp-default"
                                      for="not_put_tender_amount"
                                    >
                                      Not Put To Tender Amount(Lacs)
                                    </lable>
                                    <Controller
                                      control={control}
                                      name="not_put_tender_amount"
                                      render={({ field }) => {
                                        return (
                                          <Input
                                            {...field}
                                            id="not_put_tender_amount"
                                            type="text"
                                            disabled={!watch("dtp_amount")}
                                            invalid={
                                              errors.not_put_tender_amount &&
                                              true
                                            }
                                            onChange={(e) => {
                                              const pattern =
                                                /^(?!0(\.0{1,2})?$)\d{0,12}(\.\d{0,2})?$/;
                                              if (
                                                pattern.test(e.target.value)
                                              ) {
                                                field.onChange(e);
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                    {errors.not_put_tender_amount && (
                                      <FormFeedback>
                                        {errors.not_put_tender_amount.message}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                  <Col
                                    md={6}
                                    xs={12}
                                    className="mt-1 DatePicker-w100"
                                  >
                                    <lable
                                      className="fp-default"
                                      for="dtp_approval_date"
                                    >
                                      DTP Approval Date
                                    </lable>
                                    <Controller
                                      control={control}
                                      id="dtp_approval_date"
                                      name="dtp_approval_date"
                                      render={({ field }) => (
                                        <ReactDatePicker
                                          {...field}
                                          disabled={!watch("dtp_amount")}
                                          selected={dtpApprovalDate}
                                          onChange={handleDtpApprovalDateChange}
                                        />
                                      )}
                                    />
                                    {errors.dtp_approval_date && (
                                      <FormFeedback className="d-block">
                                        {errors.dtp_approval_date.message}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    )}
                  </Col>

                  <Col xs={12} className=" mt-0 basic_detail_btn">
                    <Button
                      className="btn btn-outline-dark waves-effect me-25"
                      type="reset"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </Form>
          </CardBody>
        </Card>
      </UILoader>
    </>
  );
};

export default TS_DTP;
