import { getUserData } from "../../../../../utility/Utils";
import { ROLE, TodayDate } from "../../../../../common/utils";

const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

// SUPERADMIN: "1",
// SECRETORY: "2",
// CE: "3",
// SE: "4",
// EE: "5",
// DEE: "6",
// export const columnsList = [
//   "sr_no",
//   "name_Of_circle",
//   "uniqueCode",
//   "name_Of_Division",
//   "budget_head",
//   "name_of_work",
//   "lengthInKM",
//   "aaAmount",
//   "aaDate",
//   "tenderAppAmount",
//   "tenderAppDate",
//   "above_below",
//   "nameofAgency",
//   "workOrderDate",
//   "likelyDateCompletion",
//   "tentativeProjectCost",
//   "totalExpentillProject",
//   "currentSpillOver",
//   "financialProgress",
//   "physicalProgress",
//   "status_of_work_in_detail",
// ];

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = (engGujState, handleEditStage) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Circle",
      accessor: "name_Of_circle",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: engGujState ? "યુનિક કોડ" : "Unique Code ",
      accessor: "uniqueCode",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામનું નામ" : "Name Of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "303px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));
        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Length in KM",
      accessor: "lengthInKM",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "AA Amount",
      accessor: "aaAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "AA Date",
      accessor: "aaDate",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની રકમ" : "Tender App. Amount",
      accessor: "tenderAppAmount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની તારીખ" : "Tender App. Date",
      accessor: "tenderAppDate",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "% ઉંચા / નીચા" : "% Above / Below",
      accessor: "above_below",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{cell.value}</div>
      ),
    },

    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency",
      accessor: "nameofAgency",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "વર્ક ઓર્ડરની તારીખ / કામ શરૂ કર્યા તારીખ & કામ પુર્ણ થવાની તારીખ(વર્ક ઓર્ડર મુજબ)"
        : " Work Order Date & Date of Completion As per W.O.",
      accessor: "workOrderDate",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામ પૂર્ણ થવાની સંભવિત તારીખ"
        : "Likely Date of Completion",
      accessor: "likelyDateCompletion",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "સંભવિત કૂલ પ્રોજેક્ટ રકમ"
        : "Tentative Total Project Cost",
      accessor: "tentativeProjectCost",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `શરૂઆતથી ${TodayDate()} સુધી થયેલ કુલ ખર્ચ`
        : `Total Expen. occu. till ${TodayDate()} on Project`,
      accessor: "totalExpentillProject",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "હાલની સ્પીલ ઓવર" : "Current Spill Over of Project",
      accessor: "currentSpillOver",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div
          className={`${cell.value < 0 ? "text-danger" : ""}`}
          // style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
        >
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState ? "નાણાંકીય પ્રગતિ % " : "Financial Progress in (%)",
      accessor: "financialProgress",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "ભૌતિક પ્રગતિ %" : "Physical Progress in (%)",
      accessor: "physicalProgress",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "Status of Work in Detail",
      accessor: "status_of_work_in_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export const getAbstractHeaderColumnsByRole = (engGujState) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "sr_no",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Circle ",
      accessor: "name_Of_circle",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની યોજના / બજેટ આઈટમનું નામ"
        : "Total No of Works in Progress",
      accessor: "total_works",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Length in KM",
      accessor: "length_in_km",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Project Cost",
      accessor: "tentative_tpc",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  return columnsArr.filter((row) => {
    if (row?.roles?.includes(currentUserRole)) return row;
  });
};

export const getAbstractData = (data) => {
  const output = [];
  let last_sum_total_works = 0;
  let last_sum_length_in_km = 0;
  let last_sum_tentative_tpc = 0;

  let sr_no = 1;

  if (currentUserRole === ROLE.CE) {
    for (const circle in data) {
      let circle_name;
      let c_sum_total_works = 0;
      let c_sum_length_in_km = 0;
      let c_sum_tentative_tpc = 0;

      for (const division in data[circle]) {
        let d_sum_total_works = 0;
        let d_sum_length_in_km = 0;
        let d_sum_tentative_tpc = 0;

        for (const schemeOfBudget in data[circle][division]) {
          const budgetHeadsData = data[circle][division][schemeOfBudget][0];

          output.push({
            name_of_circle: circle,
            name_of_division: division,
            scheme_of_budget: schemeOfBudget,
            sr_no: sr_no++,
            ...budgetHeadsData,
          });

          d_sum_total_works += parseFloat(budgetHeadsData.total_works);
          d_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);
          d_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);
          circle_name = budgetHeadsData.circle_name;
        }

        output.push({
          bg_color: "#ffc0cb",
          total_works: parseFloat(d_sum_total_works.toFixed(2)),
          length_in_km: parseFloat(d_sum_length_in_km.toFixed(2)),
          tentative_tpc: parseFloat(d_sum_tentative_tpc.toFixed(2)),

          sr_no: "Total",
        });

        c_sum_total_works += d_sum_total_works;
        c_sum_length_in_km += d_sum_length_in_km;
        c_sum_tentative_tpc += d_sum_tentative_tpc;
      }

      output.push({
        bg_color: "#98fb98",
        total_works: parseFloat(c_sum_total_works.toFixed(2)),
        length_in_km: parseFloat(c_sum_length_in_km.toFixed(2)),
        tentative_tpc: parseFloat(c_sum_tentative_tpc.toFixed(2)),

        sr_no: circle_name,
      });

      last_sum_total_works += c_sum_total_works;
      last_sum_length_in_km += c_sum_length_in_km;
      last_sum_tentative_tpc += c_sum_tentative_tpc;
    }

    output.push({
      bg_color: "#FFFF00",
      total_works: last_sum_total_works,
      length_in_km: parseFloat(last_sum_length_in_km.toFixed(2)),
      tentative_tpc: parseFloat(last_sum_tentative_tpc.toFixed(2)),

      sr_no: "All Circle Total",
    });

    return output;
  } else if (currentUserRole === ROLE.SE) {
    let last_sum_total_works = 0;
    let last_sum_length_in_km = 0;
    let last_sum_tentative_tpc = 0;
    let circle_name;

    for (const division in data) {
      let d_sum_total_works = 0;
      let d_sum_length_in_km = 0;
      let d_sum_tentative_tpc = 0;

      for (const schemeOfBudget in data[division]) {
        const budgetHeadsData = data[division][schemeOfBudget][0];
        output.push({
          name_of_division: division,
          scheme_of_budget: schemeOfBudget,
          sr_no: sr_no++,
          ...budgetHeadsData,
        });

        d_sum_total_works += parseFloat(budgetHeadsData.total_works);

        d_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);

        d_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);
        circle_name = budgetHeadsData.circle_name;
      }

      output.push({
        bg_color: "#ffc0cb",
        total_works: d_sum_total_works,
        length_in_km: d_sum_length_in_km,
        tentative_tpc: d_sum_tentative_tpc,
        sr_no: "Total",
      });

      last_sum_total_works += parseFloat(d_sum_total_works);
      last_sum_length_in_km += parseFloat(d_sum_length_in_km);
      last_sum_tentative_tpc += parseFloat(d_sum_tentative_tpc);
    }
    output.push({
      bg_color: "#98fb98",
      total_works: last_sum_total_works,
      length_in_km: last_sum_length_in_km,
      tentative_tpc: last_sum_tentative_tpc,
      sr_no: circle_name,
    });

    return output;
  } else if (currentUserRole === ROLE.EE) {
    let last_sum_total_works = 0;
    let last_sum_length_in_km = 0;
    let last_sum_tentative_tpc = 0;

    for (const BudgetHead in data) {
      const budgetHeadsData = data[BudgetHead][0];

      output.push({
        sr_no: sr_no++,
        scheme_of_budget: BudgetHead,
        ...budgetHeadsData,
      });

      last_sum_total_works += parseFloat(budgetHeadsData.total_works);
      last_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);
      last_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);
    }

    output.push({
      bg_color: "#ffc0cb",
      sr_no: "Total",
      total_works: Floatvalue(last_sum_total_works.toFixed(2)),
      length_in_km: Floatvalue(last_sum_length_in_km.toFixed(2)),
      tentative_tpc: Floatvalue(last_sum_tentative_tpc.toFixed(2)),
    });

    return output;
  }
};
