// ** React Imports
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Utils
import { isUserLoggedIn } from "@utils";

// ** Store & Actions
import { useDispatch } from "react-redux";
import { handleLogout } from "@store/authentication";

// ** Third Party Components
import { User, Power } from "react-feather";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { ROLE } from "../../../../common/utils";

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** State
  const [userData, setUserData] = useState(null);

  const [DropDownOpen, setDropDownOpen] = useState(false);
  console.log("DropDownOpen: ", DropDownOpen);

  const getRoleString = (roleValue) => {
    switch (roleValue) {
      case ROLE.SUPERADMIN:
        return "S A";
      case ROLE.SECRETORY:
        return "C E";
      case ROLE.CE:
        return "C E";
      case ROLE.SE:
        return "S E";
      case ROLE.EE:
        return "E E";
      case ROLE.DEE:
        return "D E E";
      default:
        return "";
    }
  };

  const roleString = userData ? getRoleString(userData.data.role) : "";

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    }
  }, []);

  return (
    <>
      <Dropdown
        isOpen={DropDownOpen}
        toggle={() => setDropDownOpen(!DropDownOpen)}
        className="dropdown-user nav-item border-0"
        onMouseEnter={() => setDropDownOpen(true)}
      >
        <DropdownToggle className="nav-link dropdown-user-link p-0">
          <Avatar
            color="light-secondary"
            content={roleString}
            initials
            size="lg"
          />
        </DropdownToggle>
        <DropdownMenu end onMouseLeave={() => setDropDownOpen(false)}>
          <h4 className="user-login-info">
            Welcome <br />
            {userData ? userData?.data?.nick_name : ""} - user
          </h4>
          <DropdownItem tag={Link} to="/edit-profile">
            <User size={14} className="me-75" />
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/login"
            onClick={() => dispatch(handleLogout())}
          >
            <Power size={14} className="me-75" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
          <div>
            <ul className="flex-row nav navbar-nav">
              <li className="nav-item active">
                <button className="nav-link btn">ROADS</button>
              </li>
              <li className="nav-item">
                <button className="nav-link opacity-50 btn ">BUILDINGS</button>
              </li>
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>

      {/* <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link p-0"
          onClick={(e) => e.preventDefault()}
        >
          <Avatar
            color="light-secondary"
            content={roleString}
            initials
            size="lg"
          />
        </DropdownToggle>

        <DropdownMenu end>
          <h4 className="user-login-info">
            Welcome <br />
            {userData ? userData?.data?.nick_name : ""} - user
          </h4>
          <DropdownItem tag={Link} to="/edit-profile">
            <User size={14} className="me-75" />
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/login"
            onClick={() => dispatch(handleLogout())}
          >
            <Power size={14} className="me-75" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
          <div>
            <ul className="flex-row nav navbar-nav">
              <li className="nav-item active">
                <button className="nav-link btn">ROADS</button>
              </li>
              <li className="nav-item">
                <button className="nav-link opacity-50 btn ">BUILDINGS</button>
              </li>
            </ul>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown> */}
    </>
  );
};

export default UserDropdown;
