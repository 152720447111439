/* eslint-disable multiline-ternary */
import ReactDOMServer from "react-dom/server";
const getPageMargins = () => {
  return `
  table th {
    border: 1px solid #b8efef;
    background-color: #b8efef;
    padding: 5px 0;
  }
  span.cursor-pointer.PasswordKey {
    display: block;
  }
  span.cursor-pointer {
    display: none;
  }
  table td {
      color: #6e6b7b;
      word-wrap: break-word;
      max-width: 1px;
      width: auto;
  }
    @page {
    }
    @media print {
      .hide-in-print {
            display: none;
        }
    }`;
};

const PrintTable = ({
  title,
  getTableProps,
  headerGroups,
  hiddenColumns,
  frozenColumn,
  getFrozenColumnLeft,
  getTableBodyProps,
  rows,
  prepareRow,
}) => {
  const contentToPrint = (
    <>
      <h1>{title}</h1>
      <table
        {...getTableProps()}
        style={{
          minWidth: "100%",
          tableLayout: "fixed",
        }}
        className="dataTable table-bordered"
      >
        <colgroup>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column, columnIndex) => {
              const isVisible = !hiddenColumns.includes(column.id);
              return (
                <col
                  key={columnIndex}
                  style={{
                    visibility: isVisible ? "visible" : "collapse",
                  }}
                />
              );
            })
          )}
        </colgroup>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => {
                console.log("column:>>>>>", column.minWidth);
                const isVisible = !hiddenColumns.includes(column.id);
                const isFrozen = frozenColumn === column.id || columnIndex < 0;

                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      (isFrozen ? "dtfc-fixed-left" : "dtfc-cell",
                      column.isSorted
                        ? column.isSortedDesc
                          ? "desc"
                          : "asc"
                        : "",
                      column.hideaction === "hideaction"
                        ? ""
                        : column.Header === "Action"
                        ? "hide-in-print"
                        : "")
                    }
                    style={{
                      visibility: isVisible ? "visible" : "collapse",
                      position: isFrozen ? "sticky" : "static",
                      left: isFrozen
                        ? `${getFrozenColumnLeft(columnIndex)}px`
                        : "auto",
                      zIndex: 1,
                      color: "#222936",
                      border: "1px solid #ebe9f1",
                      textTransform: "uppercase",
                      minWidth: `${column.minWidth}`,
                    }}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {isVisible && column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  const isVisible = !hiddenColumns.includes(cell.column.id);
                  const isFrozen =
                    frozenColumn === cell.column.id || cellIndex < 0;
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        (isFrozen ? "dtfc-fixed-left" : "dtfc-cell",
                        // cell.column.Header === "Action" ? "hide-in-print" : ""
                        cell.column.hideaction === "hideaction"
                          ? ""
                          : cell.column.Header === "Action"
                          ? "hide-in-print"
                          : "")
                      }
                      style={{
                        visibility: isVisible ? "visible" : "collapse",
                        position: isFrozen ? "sticky" : "static",
                        left: isFrozen
                          ? `${getFrozenColumnLeft(cellIndex)}px`
                          : "auto",
                        zIndex: 0,
                        border: "1px solid #ebe9f1",
                        borderBottomWidth: "0",
                        borderLeftWidth: "0",

                        textAlign: "center",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );

  const printWindow = window.open("", "_blank");

  printWindow.document.write(
    `<html><head><style>${getPageMargins()}</style><title>${title}</title></head><body>`
  );

  printWindow.document.write(
    ReactDOMServer.renderToStaticMarkup(contentToPrint)
  );
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  printWindow.print();
  setTimeout(() => {
    printWindow.close();
  }, 1000); // Adjust the delay (in milliseconds) as needed
};

export default PrintTable;
