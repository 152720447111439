/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import ReactDatePicker from "../../../../components/ReactDatePicker/index";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import { updateLandAcquisitionWork } from "../../../../../services/reportsServices";

const Land_Acquisition_Data = yup.object().shape({
  la_kul_setrafal: yup.string().required("This field is required"),
  la_araji_karel_kacheri: yup
    .string()
    .when("la_kul_setrafal", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  la_araji_karel_date: yup
    .string()
    .required("This field is required")
    .nullable(),
  la_remakas: yup.string().required("This field is required"),
  la_kalam_ten_date: yup
    .string()
    .when("la_kalam_ten", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  la_kalam_eleven_date: yup
    .string()
    .when("la_kalam_eleven", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  la_kalam_thirteen_date: yup
    .string()
    .when("la_kalam_thirteen", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  la_kalam_nineteen_date: yup
    .string()
    .when("la_kalam_nineteen", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  la_kalam_twenty_three_date: yup
    .string()
    .when("la_kalam_twenty_three", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  la_jamin_kabajo_malel_chhe_date: yup
    .string()
    .when("la_jamin_kabajo_malel_chhe", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  la_jmin_sampadan_over_thayel_chhe_date: yup
    .string()
    .when("la_jmin_sampadan_over_thayel_chhe", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
});

const LandAcquisition = ({ land_acqusition_required }) => {
  console.log("land_acqusition_required:>>>>>>> ", land_acqusition_required);
  const workData = useSelector((state) => state?.getWork);
  console.log("workData ::-->>", workData);
  const [arajiKarelDate, setArajiKarelDate] = useState();
  const [kalamTenDate, setkalamTenDate] = useState();
  const [kalamEelevenDate, setKalamEelevenDate] = useState();
  const [kalamThirteenDate, setKalamThirteenDate] = useState();
  const [kalamNineteenDate, setKalamNineteenDate] = useState();
  const [kalamTwentyThreeDate, setkalamTwentyThreeDate] = useState();
  const [jaminKabajoMalelChheDate, setJaminKabajoMalelChheDate] = useState();
  const [jminSampadanOverThayelChheDate, setJminSampadanOverThayelChheDate] =
    useState();
  const {
    reset,
    setValue,
    watch,
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Land_Acquisition_Data) });
  console.log("watch:test Checkbox", watch());
  console.log("errors: ", errors);

  // ** Start Date
  // અરજી કરેલ તારીખ Date
  const handleLaArajiKarelDateChange = (date) => {
    setArajiKarelDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_araji_karel_date", null);
      setError("la_araji_karel_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_araji_karel_date", moment(date).format("YYYY-MM-DD"));
      setError("la_araji_karel_date", "");
    }

    // For સમયગાળો || la_samaygalo set value onChnage
    const daysToTarget = moment().diff(date, "days");
    const years = Math.floor(daysToTarget / 365);
    const months = Math.floor((daysToTarget % 365) / 30);
    const days = (daysToTarget % 365) % 30;
    if (years !== 0 && months !== 0 && days > 0) {
      setValue("la_samaygalo", `${years} Years, ${months} Months, ${days} Day`);
    } else if (years === 0 && months !== 0 && days > 0) {
      setValue("la_samaygalo", `${months} Months, ${days} Day`);
    } else if (years === 0 && months === 0 && days > 0) {
      setValue("la_samaygalo", `${days} Day`);
    } else {
      setValue("la_samaygalo", "0");
    }
  };
  // ** કલમ - ૧૦ Date
  const handlekalamTenDateChange = (date) => {
    setkalamTenDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_kalam_ten_date", null);
      setError("la_kalam_ten_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_kalam_ten_date", moment(date).format("YYYY-MM-DD"));
      setError("la_kalam_ten_date", "");
    }
  };
  //  કલમ - ૧૧ Date
  const handlelaKalamEelevenDateChange = (date) => {
    setKalamEelevenDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_kalam_eleven_date", null);
      setError("la_kalam_eleven_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_kalam_eleven_date", moment(date).format("YYYY-MM-DD"));
      setError("la_kalam_eleven_date", "");
    }
  };
  // કલમ - ૧૩ Date
  const handleKalamThirteenDateChange = (date) => {
    setKalamThirteenDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_kalam_thirteen_date", null);
      setError("la_kalam_thirteen_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_kalam_thirteen_date", moment(date).format("YYYY-MM-DD"));
      setError("la_kalam_thirteen_date", "");
    }
  };
  // કલમ - ૧૯ (એવોર્ડ)
  const handleKalamNineteenDateChange = (date) => {
    setKalamNineteenDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_kalam_nineteen_date", null);
      setError("la_kalam_nineteen_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_kalam_nineteen_date", moment(date).format("YYYY-MM-DD"));
      setError("la_kalam_nineteen_date", "");
    }
  };
  // કલમ - ૨૩ Date
  const handlekalamTwentyThreeDateChange = (date) => {
    setkalamTwentyThreeDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_kalam_twenty_three_date", null);
      setError("la_kalam_twenty_three_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue("la_kalam_twenty_three_date", moment(date).format("YYYY-MM-DD"));
      setError("la_kalam_twenty_three_date", "");
    }
  };
  //  જમીનનો કબજો મળેલ છે. Date
  const handlejaminKabajoMalelChheDateChange = (date) => {
    setJaminKabajoMalelChheDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_jamin_kabajo_malel_chhe_date", null);
      setError("la_jamin_kabajo_malel_chhe_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue(
        "la_jamin_kabajo_malel_chhe_date",
        moment(date).format("YYYY-MM-DD")
      );
      setError("la_jamin_kabajo_malel_chhe_date", "");
    }
  };
  //  જમીન સંપાદન પૂર્ણ થયેલ છે. Date
  const handleJminSampadanOverThayelChheDateChange = (date) => {
    setJminSampadanOverThayelChheDate(date);
    // For validation msg and set value
    if (date === null) {
      setValue("la_jmin_sampadan_over_thayel_chhe_date", null);
      setError("la_jmin_sampadan_over_thayel_chhe_date", {
        type: "manual",
        message: "This field is required",
      });
    } else {
      setValue(
        "la_jmin_sampadan_over_thayel_chhe_date",
        moment(date).format("YYYY-MM-DD")
      );
      setError("la_jmin_sampadan_over_thayel_chhe_date", "");
    }
  };
  // ** End Date

  // ** Start CheckBox
  //  કલમ - ૧૦
  const { ref: la_kalam_ten_checkbox, ...la_kalam_ten_checkboxtest } = register(
    "la_kalam_ten",
    {
      required: true,
    }
  );
  //  કલમ - ૧૧
  const { ref: la_kalam_eleven_checkbox, ...la_kalam_eleven_checkboxtest } =
    register("la_kalam_eleven", {
      required: true,
    });
  //  કલમ - ૧૩
  const { ref: la_kalam_thirteen_checkbox, ...la_kalam_thirteen_checkboxtest } =
    register("la_kalam_thirteen", {
      required: true,
    });
  //  કલમ - ૧૩
  const { ref: la_kalam_nineteen_checkbox, ...la_kalam_nineteen_checkboxtest } =
    register("la_kalam_nineteen", {
      required: true,
    });
  //   કલમ - ૨૩
  const {
    ref: la_kalam_twenty_three_checkbox,
    ...la_kalam_twenty_three_checkboxtest
  } = register("la_kalam_twenty_three", {
    required: true,
  });
  //  જમીનનો કબજો મળેલ છે.
  const {
    ref: la_jamin_kabajo_malel_chhe_checkbox,
    ...la_jamin_kabajo_malel_chhe_checkboxtest
  } = register("la_jamin_kabajo_malel_chhe", {
    required: true,
  });
  // જમીન સંપાદન પૂર્ણ થયેલ છે.
  const {
    ref: la_jmin_sampadan_over_thayel_chhe_checkbox,
    ...la_jmin_sampadan_over_thayel_chhe_checkboxtest
  } = register("la_jmin_sampadan_over_thayel_chhe", {
    required: true,
  });
  // ** End CheckBox

  // OnSubmit Data
  const onSubmit = (data) => {
    const sendData = {
      work_id: workData?.data?.id,
      // ** land_acqusition_required key is Pending (for API testing temporary static value send)-----------------------
      land_acqusition_required: "yes",
      la_kul_setrafal: data?.la_kul_setrafal,
      la_araji_karel_kacheri: data?.la_araji_karel_kacheri,
      // Same date double key
      la_araji_karel_date_submit: data?.la_araji_karel_date,
      la_araji_karel_date: moment(data?.la_araji_karel_date).format(
        "DD-MM-YYYY"
      ),
      la_samaygalo: data?.la_samaygalo,
      la_remakas: data?.la_remakas,
      // કલમ - ૧૦
      la_kalam_ten: data?.la_kalam_ten === "yes" ? "yes" : "no",
      la_kalam_ten_date_submit:
        data?.la_kalam_ten === "yes" ? data?.la_kalam_ten_date : null,
      la_kalam_ten_date:
        data?.la_kalam_ten === "yes"
          ? moment(data?.la_kalam_ten_date).format("DD-MM-YYYY")
          : null,
      // કલમ - ૧૧
      la_kalam_eleven: data?.la_kalam_eleven === "yes" ? "yes" : "no",
      la_kalam_eleven_date_submit:
        data?.la_kalam_eleven === "yes" ? data?.la_kalam_eleven_date : null,
      la_kalam_eleven_date:
        data?.la_kalam_eleven === "yes"
          ? moment(data?.la_kalam_eleven_date).format("DD-MM-YYYY")
          : null,
      // કલમ - ૧૩
      la_kalam_thirteen: data?.la_kalam_thirteen === "yes" ? "yes" : "no",
      la_kalam_thirteen_date_submit:
        data?.la_kalam_thirteen === "yes" ? data?.la_kalam_thirteen_date : null,
      la_kalam_thirteen_date:
        data?.la_kalam_thirteen === "yes"
          ? moment(data?.la_kalam_thirteen_date).format("DD-MM-YYYY")
          : null,
      // કલમ - ૧૯ (એવોર્ડ)
      la_kalam_nineteen: data?.la_kalam_nineteen === "yes" ? "yes" : "no",
      la_kalam_nineteen_date_submit:
        data?.la_kalam_nineteen === "yes" ? data?.la_kalam_nineteen_date : null,
      la_kalam_nineteen_date:
        data?.la_kalam_nineteen === "yes"
          ? moment(data?.la_kalam_nineteen_date).format("DD-MM-YYYY")
          : null,
      //  કલમ - ૨૩
      la_kalam_twenty_three:
        data?.la_kalam_twenty_three === "yes" ? "yes" : "no",
      la_kalam_twenty_three_date_submit:
        data?.la_kalam_twenty_three === "yes"
          ? data?.la_kalam_twenty_three_date
          : null,
      la_kalam_twenty_three_date:
        data?.la_kalam_twenty_three === "yes"
          ? moment(data?.la_kalam_twenty_three_date).format("DD-MM-YYYY")
          : null,
      // જમીનનો કબજો મળેલ છે.
      la_jamin_kabajo_malel_chhe:
        data?.la_jamin_kabajo_malel_chhe === "yes" ? "yes" : "no",
      la_jamin_kabajo_malel_chhe_date_submit:
        data?.la_jamin_kabajo_malel_chhe === "yes"
          ? data?.la_jamin_kabajo_malel_chhe_date
          : null,
      la_jamin_kabajo_malel_chhe_date:
        data?.la_jamin_kabajo_malel_chhe === "yes"
          ? moment(data?.la_jamin_kabajo_malel_chhe_date).format("DD-MM-YYYY")
          : null,
      //  જમીન સંપાદન પૂર્ણ થયેલ છે.
      la_jmin_sampadan_over_thayel_chhe:
        data?.la_jmin_sampadan_over_thayel_chhe === "yes" ? "yes" : "no",
      la_jmin_sampadan_over_thayel_chhe_date_submit:
        data?.la_jmin_sampadan_over_thayel_chhe === "yes"
          ? data?.la_jmin_sampadan_over_thayel_chhe_date
          : null,
      la_jmin_sampadan_over_thayel_chhe_date:
        data?.la_jmin_sampadan_over_thayel_chhe === "yes"
          ? moment(data?.la_jmin_sampadan_over_thayel_chhe_date).format(
              "DD-MM-YYYY"
            )
          : null,
    };
    // console.log("SendAPI Data--->>> ", sendData);
    //Update Data
    updateLandAcquisitionWork(sendData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };

  //** Set Defalt data
  useEffect(() => {
    if (workData?.data) {
      reset({
        circle_name: workData?.data?.circle_name,
        division_name: workData?.data?.division_name,
        name_of_work_gujarati: workData?.data?.name_of_work_gujarati,
        la_kul_setrafal: workData?.data?.la_kul_setrafal,
        la_araji_karel_kacheri: workData?.data?.la_araji_karel_kacheri,
        la_remakas: workData?.data?.la_remakas,
        la_samaygalo: workData?.data?.la_samaygalo,
        la_kalam_ten: workData?.data?.la_kalam_ten === "yes" ? "yes" : false,
        la_kalam_eleven:
          workData?.data?.la_kalam_eleven === "yes" ? "yes" : false,
        la_kalam_thirteen:
          workData?.data?.la_kalam_thirteen === "yes" ? "yes" : false,
        la_kalam_nineteen:
          workData?.data?.la_kalam_nineteen === "yes" ? "yes" : false,
        la_kalam_twenty_three:
          workData?.data?.la_kalam_twenty_three === "yes" ? "yes" : false,
        la_jamin_kabajo_malel_chhe:
          workData?.data?.la_jamin_kabajo_malel_chhe === "yes" ? "yes" : false,
        la_jmin_sampadan_over_thayel_chhe:
          workData?.data?.la_jmin_sampadan_over_thayel_chhe === "yes"
            ? "yes"
            : false,
      });
    }
    // ** Start Date set
    // અરજી કરેલ તારીખ
    if (workData?.data?.la_araji_karel_date) {
      setArajiKarelDate(new Date(workData?.data?.la_araji_karel_date));
      setValue(
        "la_araji_karel_date",
        moment(workData?.data?.la_araji_karel_date).format("YYYY-MM-DD")
      );
    }
    // કલમ - ૧૦ Date
    if (workData?.data?.la_kalam_ten_date) {
      setkalamTenDate(new Date(workData?.data?.la_kalam_ten_date));
      setValue(
        "la_kalam_ten_date",
        moment(workData?.data?.la_kalam_ten_date).format("YYYY-MM-DD")
      );
    }
    // કલમ - ૧૧ Date
    if (workData?.data?.la_kalam_eleven_date) {
      setKalamEelevenDate(new Date(workData?.data?.la_kalam_eleven_date));
      setValue(
        "la_kalam_eleven_date",
        moment(workData?.data?.la_kalam_eleven_date).format("YYYY-MM-DD")
      );
    }
    // કલમ - ૧૩ Date
    if (workData?.data?.la_kalam_thirteen_date) {
      setKalamThirteenDate(new Date(workData?.data?.la_kalam_thirteen_date));
      setValue(
        "la_kalam_thirteen_date",
        moment(workData?.data?.la_kalam_thirteen_date).format("YYYY-MM-DD")
      );
    }
    // કલમ - ૧૯ (એવોર્ડ)
    if (workData?.data?.la_kalam_nineteen_date) {
      setKalamNineteenDate(new Date(workData?.data?.la_kalam_nineteen_date));
      setValue(
        "la_kalam_nineteen_date",
        moment(workData?.data?.la_kalam_nineteen_date).format("YYYY-MM-DD")
      );
    }
    // કલમ - ૨૩ Date
    if (workData?.data?.la_kalam_twenty_three_date) {
      setkalamTwentyThreeDate(
        new Date(workData?.data?.la_kalam_twenty_three_date)
      );
      setValue(
        "la_kalam_twenty_three_date",
        moment(workData?.data?.la_kalam_twenty_three_date).format("YYYY-MM-DD")
      );
    }
    // જમીનનો કબજો મળેલ છે. Date
    if (workData?.data?.la_jamin_kabajo_malel_chhe_date) {
      setJaminKabajoMalelChheDate(
        new Date(workData?.data?.la_jamin_kabajo_malel_chhe_date)
      );
      setValue(
        "la_jamin_kabajo_malel_chhe_date",
        moment(workData?.data?.la_jamin_kabajo_malel_chhe_date).format(
          "YYYY-MM-DD"
        )
      );
    }
    //  જમીન સંપાદન પૂર્ણ થયેલ છે. Date
    if (workData?.data?.la_jmin_sampadan_over_thayel_chhe_date) {
      setJminSampadanOverThayelChheDate(
        new Date(workData?.data?.la_jmin_sampadan_over_thayel_chhe_date)
      );
      setValue(
        "la_jmin_sampadan_over_thayel_chhe_date",
        moment(workData?.data?.la_jmin_sampadan_over_thayel_chhe_date).format(
          "YYYY-MM-DD"
        )
      );
    }
    // ** End Date set
  }, [workData]);

  return (
    <div className="mt-2">
      {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
      <Form>
        <Row className="gy-1">
          <Col xs={6} className="mt-1">
            <Label className="form-label" for="circle_name">
              વર્તુળનું નામ
            </Label>
            <Controller
              control={control}
              name="circle_name"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="circle_name"
                    disabled
                    invalid={errors.circle_name && true}
                  />
                );
              }}
            />
            {errors.circle_name && (
              <FormFeedback>{errors.circle_name.message}</FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col xs={6} className="mt-1">
            <Label className="form-label" for="division_name">
              જિલ્લો
            </Label>
            <Controller
              control={control}
              name="division_name"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="division_name"
                    disabled
                    invalid={errors.division_name && true}
                  />
                );
              }}
            />
            {/* {errors.division_name && (
              <FormFeedback>{errors.division_name.message}</FormFeedback>
            )} */}
          </Col>
          {/* Complete */}
          <Col xs={6} className="mt-1">
            <Label className="form-label" for="name_of_work_gujarati">
              રસ્તાનું નામ
            </Label>
            <Controller
              control={control}
              name="name_of_work_gujarati"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="name_of_work_gujarati"
                    disabled
                    invalid={errors.name_of_work_gujarati && true}
                  />
                );
              }}
            />
            {errors.name_of_work_gujarati && (
              <FormFeedback>
                {errors.name_of_work_gujarati.message}
              </FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col xs={6} className="mt-1">
            <Label className="form-label" for="la_kul_setrafal">
              કુલ ક્ષેત્રફળ (હે-આરે-ચો.મી.)
            </Label>
            <Controller
              control={control}
              name="la_kul_setrafal"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="la_kul_setrafal"
                    invalid={errors.la_kul_setrafal && true}
                  />
                );
              }}
            />
            {errors.la_kul_setrafal && (
              <FormFeedback>{errors.la_kul_setrafal.message}</FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col xs={4} className="mt-1">
            <Label className="form-label" for="la_araji_karel_kacheri">
              અરજી કરેલ કચેરી
            </Label>
            <Controller
              control={control}
              name="la_araji_karel_kacheri"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="la_araji_karel_kacheri"
                    invalid={errors.la_araji_karel_kacheri && true}
                  />
                );
              }}
            />
            {errors.la_araji_karel_kacheri && (
              <FormFeedback>
                {errors.la_araji_karel_kacheri.message}
              </FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col xs={4} className="mt-1 DatePicker-w100">
            <Label className="form-label" for="la_araji_karel_date">
              અરજી કરેલ તારીખ
            </Label>
            <Controller
              control={control}
              id="la_araji_karel_date"
              name="la_araji_karel_date"
              render={({ field }) => (
                <ReactDatePicker
                  {...field}
                  // isClearable={true}
                  maxDate={moment().toDate()}
                  selected={arajiKarelDate}
                  onChange={handleLaArajiKarelDateChange}
                  disabled={!watch("la_araji_karel_kacheri")}
                />
              )}
            />
            {errors.la_araji_karel_date && (
              <FormFeedback className="d-block">
                {errors.la_araji_karel_date.message}
              </FormFeedback>
            )}
          </Col>
          {/* pending onChange value-get */}
          <Col xs={4} className="mt-1">
            <Label className="form-label" for="la_samaygalo">
              સમયગાળો
            </Label>
            <Controller
              control={control}
              name="la_samaygalo"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    disabled
                    id="la_samaygalo"
                    invalid={errors.la_samaygalo && true}
                  />
                );
              }}
            />
            {errors.la_samaygalo && (
              <FormFeedback>{errors.la_samaygalo.message}</FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col xs={12} className="mt-1">
            <Label className="form-label" for="la_remakas">
              રીમાકર્સ (લેટેસ્‍ટ પોઝીશન આ૫વી)
            </Label>
            <Controller
              control={control}
              name="la_remakas"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="la_remakas"
                    invalid={errors.la_remakas && true}
                    row={3}
                    type="textarea"
                  />
                );
              }}
            />
            {errors.la_remakas && (
              <FormFeedback>{errors.la_remakas.message}</FormFeedback>
            )}
          </Col>
          {/* Complete All checkbox */}
          <Col xs={12} className="mb-1 ">
            <Card className="mb-0 app-card">
              <CardBody className="p-0 box-shadow-0">
                <div className="card-header d-flex tab-card-header">
                  <h5 className="card-title">કયા તબકકે પડતર છે?</h5>
                </div>
                <div className="card-content p-2">
                  <Row>
                    {/* Complete */}
                    <Col md={4} xs={12} className="DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_kalam_ten_checkbox}
                          {...la_kalam_ten_checkboxtest}
                          type="checkbox"
                          name="la_kalam_ten"
                          id="la_kalam_ten_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_kalam_ten_yes"
                          className="ms-50 form-check-label"
                        >
                          કલમ - ૧૦
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_kalam_ten_date"
                        name="la_kalam_ten_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={kalamTenDate}
                            onChange={handlekalamTenDateChange}
                            disabled={!watch("la_kalam_ten")}
                          />
                        )}
                      />
                      {errors.la_kalam_ten_date && (
                        <FormFeedback className="d-block">
                          {errors.la_kalam_ten_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_kalam_eleven_checkbox}
                          {...la_kalam_eleven_checkboxtest}
                          type="checkbox"
                          name="la_kalam_eleven"
                          id="la_kalam_eleven_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_kalam_eleven_yes"
                          className="ms-50 form-check-label"
                        >
                          કલમ - ૧૧
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_kalam_eleven_date"
                        name="la_kalam_eleven_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={kalamEelevenDate}
                            onChange={handlelaKalamEelevenDateChange}
                            disabled={!watch("la_kalam_eleven")}
                          />
                        )}
                      />
                      {errors.la_kalam_eleven_date && (
                        <FormFeedback className="d-block">
                          {errors.la_kalam_eleven_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_kalam_thirteen_checkbox}
                          {...la_kalam_thirteen_checkboxtest}
                          type="checkbox"
                          name="la_kalam_thirteen"
                          id="la_kalam_thirteen_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_kalam_thirteen_yes"
                          className="ms-50 form-check-label"
                        >
                          કલમ - ૧૩
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_kalam_thirteen_date"
                        name="la_kalam_thirteen_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={kalamThirteenDate}
                            onChange={handleKalamThirteenDateChange}
                            disabled={!watch("la_kalam_thirteen")}
                          />
                        )}
                      />
                      {errors.la_kalam_thirteen_date && (
                        <FormFeedback className="d-block">
                          {errors.la_kalam_thirteen_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="mt-1 DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_kalam_nineteen_checkbox}
                          {...la_kalam_nineteen_checkboxtest}
                          type="checkbox"
                          name="la_kalam_nineteen"
                          id="la_kalam_nineteen_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_kalam_nineteen_yes"
                          className="ms-50 form-check-label"
                        >
                          કલમ - ૧૯ (એવોર્ડ)
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_kalam_nineteen_date"
                        name="la_kalam_nineteen_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={kalamNineteenDate}
                            disabled={!watch("la_kalam_nineteen")}
                            onChange={handleKalamNineteenDateChange}
                          />
                        )}
                      />
                      {errors.la_kalam_nineteen_date && (
                        <FormFeedback className="d-block">
                          {errors.la_kalam_nineteen_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="mt-1 DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_kalam_twenty_three_checkbox}
                          {...la_kalam_twenty_three_checkboxtest}
                          type="checkbox"
                          name="la_kalam_twenty_three"
                          id="la_kalam_twenty_three_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_kalam_twenty_three_yes"
                          className="ms-50 form-check-label"
                        >
                          કલમ - ૨૩
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_kalam_twenty_three_date"
                        name="la_kalam_twenty_three_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={kalamTwentyThreeDate}
                            disabled={!watch("la_kalam_twenty_three")}
                            onChange={handlekalamTwentyThreeDateChange}
                          />
                        )}
                      />
                      {errors.la_kalam_twenty_three_date && (
                        <FormFeedback className="d-block">
                          {errors.la_kalam_twenty_three_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="mt-1 DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_jamin_kabajo_malel_chhe_checkbox}
                          {...la_jamin_kabajo_malel_chhe_checkboxtest}
                          type="checkbox"
                          name="la_jamin_kabajo_malel_chhe"
                          id="la_jamin_kabajo_malel_chhe_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_jamin_kabajo_malel_chhe_yes"
                          className="ms-50 form-check-label"
                        >
                          જમીનનો કબજો મળેલ છે.
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_jamin_kabajo_malel_chhe_date"
                        name="la_jamin_kabajo_malel_chhe_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={jaminKabajoMalelChheDate}
                            disabled={!watch("la_jamin_kabajo_malel_chhe")}
                            onChange={handlejaminKabajoMalelChheDateChange}
                          />
                        )}
                      />
                      {errors.la_jamin_kabajo_malel_chhe_date && (
                        <FormFeedback className="d-block">
                          {errors.la_jamin_kabajo_malel_chhe_date.message}
                        </FormFeedback>
                      )}
                    </Col>
                    {/* Complete */}
                    <Col md={4} xs={12} className="mt-1 DatePicker-w100">
                      <div className="custom-control custom-checkbox mb-1">
                        <Input
                          innerRef={la_jmin_sampadan_over_thayel_chhe_checkbox}
                          {...la_jmin_sampadan_over_thayel_chhe_checkboxtest}
                          type="checkbox"
                          name="la_jmin_sampadan_over_thayel_chhe"
                          id="la_jmin_sampadan_over_thayel_chhe_yes"
                          value="yes"
                          className="form-check-input"
                        />
                        <Label
                          for="la_jmin_sampadan_over_thayel_chhe_yes"
                          className="ms-50 form-check-label"
                        >
                          જમીન સંપાદન પૂર્ણ થયેલ છે.
                        </Label>
                      </div>
                      <Controller
                        control={control}
                        id="la_jmin_sampadan_over_thayel_chhe_date"
                        name="la_jmin_sampadan_over_thayel_chhe_date"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            // isClearable={true}
                            selected={jminSampadanOverThayelChheDate}
                            disabled={
                              !watch("la_jmin_sampadan_over_thayel_chhe")
                            }
                            onChange={
                              handleJminSampadanOverThayelChheDateChange
                            }
                          />
                        )}
                      />
                      {errors.la_jmin_sampadan_over_thayel_chhe_date && (
                        <FormFeedback className="d-block">
                          {
                            errors.la_jmin_sampadan_over_thayel_chhe_date
                              .message
                          }
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} className="mb-2">
            <Button
              className="btn btn-outline-dark waves-effect me-25"
              type="reset"
              outline
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LandAcquisition;
