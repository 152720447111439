import { getUserData } from "../../../../utility/Utils";
import { ROLE } from "../../../../common/utils";

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = () => {
  const columnsArr = [
    {
      Header: "ક્રમ",
      accessor: "sr_no",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: "વર્તુળ",
      accessor: "circle_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: "જિલ્લો",
      accessor: "division_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },

    {
      Header: "રસ્તાનું નામ",
      accessor: "road_name",
      show: true,
      minWidth: "100px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: "બજેટ સદર",
      accessor: "budget_heads_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE, ROLE.DEE],
    },
    {
      Header: "કુલ ક્ષેત્રફળ (હે-આરે-ચો.મી.)",
      accessor: "la_kul_setrafal",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "અરજી કરેલ કચેરી",
      accessor: "la_araji_karel_kacheri",
      show: true,
      minWidth: "245px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "અરજી કરેલ તારીખ",
      accessor: "la_araji_karel_date",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: "કયા તબકકે પડતર છે?",
      accessor: "la_padtar_chhe",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "સમયગાળો",
      accessor: "la_samaygalo",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: "રીમાકર્સ",
      accessor: "la_remakas",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    //
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};
