// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { User, Check, X } from "react-feather";
import { useForm, Controller } from "react-hook-form";

// ** Utils
// import { selectThemeColors } from '@utils'

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
const AddGovernmentUserData = yup.object().shape({
  contact: yup
    .number()
    .min(10, "Please enter at least 10 characters.")
    .required("Enter Contact")
    .typeError("Please enter only digits."),
  username: yup.string().required("Enter Username").typeError(""),
  fullname: yup.string().required("Enter Username").typeError(""),
  email: yup
    .string()
    .email()
    .required("Enter Email")
    .typeError("Enter valid email"),
  password: yup
    .string()
    .min(6, "Please enter at least 6 characters.")
    .required("Enter Password")
    .typeError("Please enter at least 6 characters."),
  nick_name: yup.string().required("Enter sobriquete").typeError(""),
  ce_role: yup.string().required("Enter ce role").typeError(""),
});
const AddGovernmentUser = () => {
  // ** States
  const [show, setShow] = useState(false);

  // ** Hooks
  const {
    // reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddGovernmentUserData),
  });

  const ceRoleOptions = [
    { value: "NH", label: "NH" },
    { value: "STATE", label: "STATE" },
    { value: "ALL", label: "ALL" },
  ];

  const onSubmit = (data) => {
    // if (Object.values(data).every((field) => field.length > 0)) {
    //   console.log("data:>>", data);
    // }
    console.log("AddGovernmentUser>>", data);
  };
  return (
    <Fragment>
      <Card>
        <CardBody className="text-center">
          <User className="font-large-2 mb-1" />
          <CardTitle tag="h5">Add Government User</CardTitle>
          <Button color="primary" onClick={() => setShow(true)}>
            Show
          </Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-css"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">Add Government User</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row
              // tag="form"
              className="gy-1 pt-1"
              // onSubmit={handleSubmit(onSubmit)}
            >
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="fullname">
                  Full name
                </Label>
                <Controller
                  control={control}
                  name="fullname"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="fullname"
                        placeholder="Full name"
                        // value={field.value}
                        invalid={errors.fullname && true}
                      />
                    );
                  }}
                />
                {errors.fullname && (
                  <FormFeedback>{errors.fullname.message}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="contact">
                  Contact
                </Label>
                <Controller
                  control={control}
                  name="contact"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="contact"
                        placeholder="Contact"
                        // value={field.value}
                        invalid={errors.contact && true}
                      />
                    );
                  }}
                />
                {errors.contact && (
                  <FormFeedback>{errors.contact.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="email"
                        placeholder="Email"
                        // value={field.value}
                        invalid={errors.email && true}
                      />
                    );
                  }}
                />
                {errors.email && (
                  <FormFeedback>{errors.email.message}</FormFeedback>
                )}
              </Col>{" "}
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="username">
                  Username
                </Label>
                <Controller
                  control={control}
                  name="username"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="username"
                        placeholder="Username"
                        invalid={errors.username && true}
                      />
                    );
                  }}
                />
                {errors.username && (
                  <FormFeedback>{errors.username.message}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="password">
                  Password
                </Label>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="password"
                        placeholder="Password"
                        // value={field.value}
                        invalid={errors.password && true}
                      />
                    );
                  }}
                />
                {console.log("errors: ", errors)}
                {errors.password && (
                  <FormFeedback>{errors.password.message}</FormFeedback>
                )}
              </Col>{" "}
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="nick_name">
                  Sobriquete
                </Label>
                <Controller
                  control={control}
                  name="nick_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="nick_name"
                        placeholder="Sobriquete"
                        invalid={errors.nick_name && true}
                      />
                    );
                  }}
                />
                {errors.nick_name && (
                  <FormFeedback>{errors.nick_name.message}</FormFeedback>
                )}
              </Col>
              <Col xs={6} className="mb-1 mt-0">
                <Label className="form-label" for="ce_role">
                  Ce Role
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="ce_role"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={ceRoleOptions}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      placeholder="Please Select"
                      // value={field.value}
                      invalid={errors.ce_role && true}
                    />
                  )}
                />
                {errors.ce_role && (
                  <FormFeedback>{errors.ce_role.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="modal-footer mt-0">
                <Button
                  className="btn btn-outline-dark waves-effect"
                  type="reset"
                  outline
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Create user
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddGovernmentUser;
