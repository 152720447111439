/* eslint-disable no-unused-expressions */
import { getUserData } from "../../../../utility/Utils";

import { ROLE } from "../../../../common/utils";
import moment from "moment";

/* eslint-disable multiline-ternary */

export const getHeaderColumnsByRole = (
  engGujState,
  handleEditStage
  //   setEditWork
) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Circle",
      accessor: "name_of_circle",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },

    {
      Header: engGujState ? "બજેટ સદર" : "Pragati G-Code",
      accessor: "pragatiGCode",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામની યોજના / બજેટ આઈટમનું નામ" : "Name of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "287px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));

        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },

    {
      Header: engGujState ? "વહીવટી મંજુરી રકમ" : "AA Amount",
      accessor: "aaAmount",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વહીવટી મંજુરી તારીખ" : "AA Date",
      accessor: "aaDate",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "વર્ક ઓર્ડરનીતારીખ" : "Work Order Date",
      accessor: "workOrderDate",
      show: true,
      minWidth: "107px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામ શરૂ કર્યા તારીખ"
        : "Date of Completion As per W.O.",
      accessor: "completion_per_wo_date",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "સંભવિત કૂલ પ્રોજેક્ટ રકમ" : "Work Status",
      accessor: "workStatus",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "સને 2023-24 નો કુલ સંભવિત ખર્ચ"
        : "Date of Completion As per Approved Timelimit",
      accessor: "completion_per_approved_timelimit_date",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "વર્ક ઓર્ડરનીતારીખ / કામ શરૂ કર્યા તારીખ"
        : "Likely Date of Completion",
      accessor: "likely_date_of_completion",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division",
      accessor: "nameOfDivision",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },

    {
      Header: engGujState
        ? "નાણાંકીય પ્રગતિ ટકાવારીમાં"
        : "Financial Progress in %",
      accessor: "financialprogress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "ભૌતિક પ્રગતિ ટકાવારીમાં"
        : "Physical Progress in %",
      accessor: "physicalProgress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ ટુંકમાં(ગુજરાતીમાં)"
        : "Status of Work in Detail",
      accessor: "status_of_work_in_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ ટુંકમાં(ગુજરાતીમાં)"
        : "Reason : Time overrun & Due to Department / Other",
      accessor: "reason_due_department",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ ટુંકમાં(ગુજરાતીમાં)"
        : "Reason : Time overrun & Due to Agency",
      accessor: "reason_due_agency",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency",
      accessor: "name_of_agency",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export function addDuplicateForObjectWithSpecificValue(dataArray) {
  const result = [];
  // Remove duplicate package ids
  const seenPackageIds = {};
  const data = dataArray.filter((item) => {
    if (item.package_id > 0) {
      if (!seenPackageIds[item.package_id]) {
        seenPackageIds[item.package_id] = true;
        return true;
      }
      return false;
    } else {
      return true;
    }
  });

  let sr_no = 1;
  for (const obj of data) {
    if (obj.govtplan_bytender === "govt") {
      result.push({
        ...obj,
        name_of_work_title: "Government Plant",
        dtp_approval_date: obj.govplan_date,
        dtp_amount: obj.govplan_amount,
        tentative_tpc: obj.govt_total_project_cost,
        tender_app_amount:
          obj.govplan_amount === null ? "--" : obj.govplan_amount,
        tender_app_date: obj.govplan_date,
        sr_no: sr_no++,
        // above_below: "--",
        agency_gujarati: "સરકારી પ્લાન્ટ",
        agency: "Govt. Plant",
        // work_order_date: "--",
        // date_of_completion_as_per_wo: "--",
        // likely_date_of_completion: "--",
        // date_of_completion_as_per_approved_timelimit: "--",
        tender_expen_incured_last_fy_year: obj.govt_expen_incured_last_fy_year,
        tender_spill_over_this_fy_year_n_current_year_approved_work:
          obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
        tender_target_expenditure_of_current_fy_year:
          obj.govt_target_expenditure_of_current_fy_year,
        tender_current_year_upto_today: obj.govt_current_year_upto_today,
        tender_probable_expen_from_today_to_end_of_this_fy_year:
          obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
        tender_total_expenditure_as_on_today_on_this_project:
          obj.govt_total_expenditure_as_on_today_on_this_project,
        workstatus_detail: obj.govt_plan_work_detail,

        tender_current_spillover_of_project:
          obj.govt_current_spillover_of_project,
      });
      result.push({
        ...obj,
        id: "",
        name_of_work_title: "Furniture+Misc.",
        circle_name: "--",
        unique_code: "--",
        pragati_gcode: "--",
        division_name: "--",
        subdivision_name: "--",
        budget_heads_name: "--",

        sr_no: "",
        tentative_tpc: obj.tender_total_project_cost,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
    } else if (obj.package_id !== 0 && obj.package_id !== null) {
      result.push({
        ...obj,
        name_of_work_title: "Package",
        sr_no: sr_no++,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
      obj.sub_work &&
        obj.sub_work.map((row) =>
          result.push({
            ...row,
            name_of_work_title: "",
            sr_no: "",
            dtp_amount: "--",
            dtp_approval_date: "--",
            tender_opening_date: "--",
            tender_app_amount: "--",
            tender_app_date: "--",
            agency_gujarati: "--",
            agency: "--",
            work_order_date: "--",
            date_of_completion_as_per_wo: "--",
            likely_date_of_completion: "--",
            date_of_completion_as_per_approved_timelimit: "--",
            tentative_tpc: "--",
            tender_expen_incured_last_fy_year: "--",
            tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
            tender_target_expenditure_of_current_fy_year: "--",
            tender_current_year_upto_today: "--",
            tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
            tender_total_expenditure_as_on_today_on_this_project: "--",
            financial_progress: "--",
            physical_progress: "--",
            workstatus_detail: "--",
            work_status: "--",
            above_below: "--",
            stopped_due_to_department_other: "--",
            stopped_due_to_agency: "--",
            stopped_action_taken_on_agency: "--",
          })
        );
      // }
    } else {
      result.push({
        ...obj,
        name_of_work_title: "",
        sr_no: sr_no++,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
    }
  }
  return result;
}

export const getFormattedDate = (date) => moment(date).format("DD-MM-YYYY");
