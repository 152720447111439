/* eslint-disable no-unneeded-ternary */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
// import Select from "react-select";
// ** Utils
// import { selectThemeColors } from '@utils'

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import { updatePaaAaWork } from "../../../../services/reportsServices";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { getWorkTabsData } from "../../../../redux/WorkRedux/getWorkSlice";
// import { handleKeyPress } from "../../../../utility/Utils";

const PAA_AAData = yup.object().shape(
  {
    paa_block_estimates_submitted_to_govt: yup
      .string()
      .required("Select Block Estimate Submitted to Govt")
      .nullable(),
    paa_block_estimate_amount: yup
      .string()
      .when("paa_block_estimates_submitted_to_govt", {
        is: (value) => !!value && value === "yes",
        then: yup
          .string()
          .matches(
            /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
            "Block Estimated Amount (Lacs) should not exceed 10 digits"
          )
          .required("Enter Block Estimate Amount.")
          .nullable(),
      })
      .nullable(),
    paa_block_estimate_submission_date: yup
      .string()
      .when(
        ["paa_block_estimates_submitted_to_govt", "paa_block_estimate_amount"],
        {
          is: (value, paa_block_estimate_amount) =>
            !!value && value === "yes" && !!paa_block_estimate_amount,
          then: yup
            .string()
            .required("Select Block Estimate Submission Date")
            .nullable(),
        }
      )
      .nullable(),
    paa_aa_letter_no: yup
      .string()
      .when(
        [
          "paa_block_estimate_submission_date",
          "paa_block_estimates_submitted_to_govt",
          "paa_block_estimate_amount",
        ],
        {
          is: (
            paa_block_estimate_amount,
            paa_block_estimates_submitted_to_govt,
            paa_block_estimate_submission_date
          ) =>
            !!paa_block_estimates_submitted_to_govt &&
            paa_block_estimates_submitted_to_govt === "yes" &&
            !!paa_block_estimate_submission_date &&
            !!paa_block_estimate_amount,
          then: yup
            .string()
            .max(50, "AA Letter No. should not exceed 50 characters")
            // .required("Enter AA Letter No.")
            .nullable(),
        }
      )
      .nullable(),
    paa_aa_amount: yup
      .string()
      .when(["paa_block_estimates_submitted_to_govt", "paa_aa_letter_no"], {
        is: (paa_block_estimates_submitted_to_govt, paa_aa_letter_no) =>
          !!paa_block_estimates_submitted_to_govt &&
          paa_block_estimates_submitted_to_govt === "yes" &&
          paa_aa_letter_no !== "",
        then: yup
          // .string()
          // .max(10, "AA Amount(Lacs) should not exceed 10 digits")
          // .matches(/^[1-9]\d*(\.\d+)?$/, "Please enter a Only number.")
          // .required("Enter AA Amount")
          // .nullable(),
          .string()
          .matches(
            /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
            "AA Amount(Lacs) should not exceed 10 digits"
          )
          .required("Enter AA Amount")
          .nullable(),
      })
      .nullable(),
    paa_aa_date: yup
      .string()
      .when(["paa_block_estimates_submitted_to_govt", "paa_aa_amount"], {
        is: (paa_block_estimates_submitted_to_govt, paa_aa_amount) =>
          !!paa_block_estimates_submitted_to_govt &&
          paa_block_estimates_submitted_to_govt === "yes" &&
          paa_aa_amount !== "",
        then: yup.string().required("Select AA Date").nullable(),
      })
      .nullable(),
  },
  [
    ["paa_block_estimates_submitted_to_govt", "paa_block_estimate_amount"],
    [
      "paa_block_estimate_submission_date",
      "paa_block_estimates_submitted_to_govt",
      "paa_block_estimate_amount",
    ],
    ["paa_block_estimates_submitted_to_govt", "paa_aa_letter_no"],
    ["paa_block_estimates_submitted_to_govt", "paa_aa_amount"],
  ]
);
// const PAA_AAData = yup.object().shape({});
const PAA_AA = ({ userData }) => {
  const workData = useSelector((state) => state?.getWork);
  console.log("workData:--->>", workData?.data);
  const currentUserRole = getUserData()?.role;
  const dispatch = useDispatch();
  const [BlockEstimateSubDate, setBlockEstimateSubDate] = useState();
  const [AaDate, setAaDate] = useState();
  const [AaFinancialYear, setAaFinancialYear] = useState("");
  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    setError,
    // getValues,
    watch,
    register,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(PAA_AAData) });
  // for radio button
  const { ref: forest, ...foresttest } = register(
    "paa_block_estimates_submitted_to_govt",
    {
      required: true,
      onChange: (e) => {
        console.log("forestTest radio>>", e.target.value, e?.target?.checked);

        if (e.target.value === "yes" && e?.target?.checked === true) {
          reset({
            paa_block_estimate_amount: "",
            paa_block_estimate_submission_date: null,
            paa_aa_letter_no: "",
            paa_aa_amount: "",
            paa_aa_date: null,
            paa_aa_app_finacial_year: "",
            paa_block_estimates_submitted_to_govt: "yes",
          });
          setBlockEstimateSubDate(null);
          setAaDate(null);
        } else if (e.target.value === "no" && e?.target?.checked === true) {
          reset({
            paa_block_estimate_amount: "",
            paa_block_estimate_submission_date: null,
            paa_aa_letter_no: "",
            paa_aa_amount: "",
            paa_aa_date: null,
            paa_aa_app_finacial_year: "",
            paa_block_estimates_submitted_to_govt: "no",
          });
          setBlockEstimateSubDate(null);
          setAaDate(null);
        } else if (e.target.value === "na" && e?.target?.checked === true) {
          reset({
            paa_block_estimate_amount: "",
            paa_block_estimate_submission_date: null,
            paa_aa_letter_no: workData?.data?.paa_letter_no,
            paa_aa_amount: workData?.data?.paa_amount,
            paa_aa_date: workData?.data?.paa_date,
            paa_aa_app_finacial_year: workData?.data?.paa_financial_year,
            paa_block_estimates_submitted_to_govt: "na",
          });
          setBlockEstimateSubDate(null);
          setAaDate(new Date(workData?.data?.paa_date));
        }
      },
    }
  );
  console.log(
    "watch Date----->>",
    AaDate,
    watch("paa_date"),
    watch("paa_letter_no"),
    watch("paa_date"),
    watch("paa_amount"),
    watch("paa_block_estimates_submitted_to_govt")
  );
  // For DatePiker
  const handleEstimateDateChange = (date) => {
    if (date === null) {
      setValue("paa_block_estimate_submission_date", null);
      setBlockEstimateSubDate(null);
    } else {
      setValue(
        "paa_block_estimate_submission_date",
        moment(date).format("YYYY-MM-DD")
      );
      setBlockEstimateSubDate(date);
      setError("paa_block_estimate_submission_date", "");
    }
  };
  const handleAaDateChange = (date) => {
    if (date === null) {
      setValue("paa_aa_date", null);
      setAaDate(null);
    } else {
      setValue("paa_aa_date", moment(date).format("YYYY-MM-DD"));
      setAaDate(date);
      setError("paa_aa_date", "");
    }
  };

  // for AA Financial Year
  useEffect(() => {
    if (AaDate) {
      if (parseInt(moment(AaDate).format("MM")) < 4) {
        setAaFinancialYear(
          `${parseInt(moment(AaDate).format("YYYY") - 1)}/${moment(
            AaDate
          ).format("YY")}`
        );
      } else {
        setAaFinancialYear(
          `${moment(AaDate).format("YYYY")}/${
            parseInt(moment(AaDate).format("YY")) + 1
          }`
        );
      }
      setValue("paa_aa_app_finacial_year", AaFinancialYear);
    }
  }, [AaDate, AaFinancialYear]);

  // console.log("value >>>> 1", errors?.paa_block_estimate_amount?.message);
  // BlockEstimateAmount ****************
  // const BlockEstimateAmount = (e) => {
  //   console.log("value >>>> 2", e.target.value);
  //   if (e.target.value.toString().split(".")[0].length >= 11) {
  //     console.log(
  //       "value >>>> 3",
  //       e.target.value.toString().split(".")[0].length
  //     );
  //     setError("paa_block_estimate_amount", {
  //       type: "manual",
  //       message: "Block Estimated Amount (Lacs) should not exceed 10 digits",
  //     });
  //   }
  // };

  const onSubmit = (data) => {
    const sendAPIData = {
      work_id: workData?.data?.id,
      paa_block_estimates_submitted_to_govt:
        data?.paa_block_estimates_submitted_to_govt,
      paa_block_estimate_amount:
        data?.paa_block_estimates_submitted_to_govt === "na"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimate_amount,
      paa_block_estimate_submission_date:
        data?.paa_block_estimates_submitted_to_govt === "na"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimate_submission_date,
      paa_aa_letter_no:
        data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "na"
          ? workData?.data?.paa_letter_no
          : data?.paa_aa_letter_no,
      paa_aa_amount:
        data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "na"
          ? workData?.data?.paa_amount
          : data?.paa_aa_amount,
      paa_aa_date:
        data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "na"
          ? workData?.data?.paa_date
          : data?.paa_aa_date,
      paa_aa_app_finacial_year:
        data?.paa_block_estimates_submitted_to_govt === "no"
          ? null
          : data?.paa_block_estimates_submitted_to_govt === "na"
          ? workData?.data?.paa_financial_year
          : data?.paa_aa_app_finacial_year,
    };
    console.log("sendAPIData:--->>", sendAPIData);
    // updatePaaAaWork
    updatePaaAaWork(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
        dispatch(getWorkTabsData(userData));
      })
      .catch((err) => getErrorMsg(err));
  };
  useEffect(() => {
    if (workData?.data) {
      // check box set value
      if (workData?.data?.paa_block_estimates_submitted_to_govt === "yes") {
        // setBlockEstimate("yes");
        if (workData?.data?.paa_aa_date === null) {
          setAaDate(null);
        } else {
          setAaDate(new Date(workData?.data?.paa_aa_date));
        }

        if (workData?.data?.paa_block_estimate_submission_date === null) {
          setBlockEstimateSubDate(null);
        } else {
          setBlockEstimateSubDate(
            new Date(workData?.data?.paa_block_estimate_submission_date)
          );
        }
        reset({
          paa_letter_no: workData?.data?.paa_letter_no
            ? workData?.data?.paa_letter_no
            : "",
          paa_amount: workData?.data?.paa_amount
            ? workData?.data?.paa_amount
            : "",
          paa_date: workData?.data?.paa_date
            ? moment(workData?.data?.paa_date).format("DD-MM-YYYY")
            : "",
          paa_financial_year: workData?.data?.paa_financial_year
            ? workData?.data?.paa_financial_year
            : "",
          paa_block_estimate_amount: workData?.data?.paa_block_estimate_amount
            ? workData?.data?.paa_block_estimate_amount
            : "",
          paa_block_estimate_submission_date: workData?.data
            ?.paa_block_estimate_submission_date
            ? workData?.data?.paa_block_estimate_submission_date
            : "",
          // **
          paa_block_estimates_submitted_to_govt: workData?.data
            ?.paa_block_estimates_submitted_to_govt
            ? workData?.data?.paa_block_estimates_submitted_to_govt
            : "",
          paa_aa_letter_no: workData?.data?.paa_aa_letter_no
            ? workData?.data?.paa_aa_letter_no
            : "",
          paa_aa_amount: workData?.data?.paa_aa_amount
            ? workData?.data?.paa_aa_amount
            : "",
          paa_aa_app_finacial_year: workData?.data?.paa_aa_app_finacial_year
            ? workData?.data?.paa_aa_app_finacial_year
            : "",
          paa_aa_date: workData?.data?.paa_aa_date
            ? workData?.data?.paa_aa_date
            : "",
        });
      } else if (
        workData?.data?.paa_block_estimates_submitted_to_govt === "no"
      ) {
        // setBlockEstimate("no");
        reset({
          paa_letter_no: workData?.data?.paa_letter_no
            ? workData?.data?.paa_letter_no
            : "",
          paa_amount: workData?.data?.paa_amount
            ? workData?.data?.paa_amount
            : "",
          paa_date: workData?.data?.paa_date
            ? moment(workData?.data?.paa_date).format("DD-MM-YYYY")
            : "",
          paa_financial_year: workData?.data?.paa_financial_year
            ? workData?.data?.paa_financial_year
            : "",
          paa_block_estimates_submitted_to_govt: workData?.data
            ?.paa_block_estimates_submitted_to_govt
            ? workData?.data?.paa_block_estimates_submitted_to_govt
            : "",
          paa_block_estimate_amount: "",
          paa_block_estimate_submission_date: "",
          paa_aa_letter_no: "",
          paa_aa_amount: "",
          paa_aa_app_finacial_year: "",
          paa_aa_date: "",
        });
      } else if (
        workData?.data?.paa_block_estimates_submitted_to_govt === "na"
      ) {
        // setBlockEstimate("na");
        // setAaDate(new Date(workData?.data?.paa_aa_date));
        if (workData?.data?.paa_aa_date === null) {
          setAaDate(null);
        } else {
          setAaDate(new Date(workData?.data?.paa_aa_date));
        }
        reset({
          paa_letter_no: workData?.data?.paa_letter_no
            ? workData?.data?.paa_letter_no
            : "",
          paa_amount: workData?.data?.paa_amount
            ? workData?.data?.paa_amount
            : "",
          paa_date: workData?.data?.paa_date
            ? moment(workData?.data?.paa_date).format("DD-MM-YYYY")
            : "",
          paa_financial_year: workData?.data?.paa_financial_year
            ? workData?.data?.paa_financial_year
            : "",
          paa_block_estimate_amount: workData?.data?.paa_block_estimate_amount
            ? workData?.data?.paa_block_estimate_amount
            : "",
          paa_block_estimate_submission_date: workData?.data
            ?.paa_block_estimate_submission_date
            ? workData?.data?.paa_block_estimate_submission_date
            : "",
          paa_aa_letter_no: workData?.data?.paa_aa_letter_no
            ? workData?.data?.paa_aa_letter_no
            : "",
          paa_block_estimates_submitted_to_govt: workData?.data
            ?.paa_block_estimates_submitted_to_govt
            ? workData?.data?.paa_block_estimates_submitted_to_govt
            : "",
          paa_aa_amount: workData?.data?.paa_aa_amount
            ? workData?.data?.paa_aa_amount
            : "",
          paa_aa_app_finacial_year: workData?.data?.paa_aa_app_finacial_year
            ? workData?.data?.paa_aa_app_finacial_year
            : "",
          paa_aa_date: workData?.data?.paa_aa_date
            ? workData?.data?.paa_aa_date
            : "",
        });
      } else if (
        workData?.data?.paa_block_estimates_submitted_to_govt === null
      ) {
        // setAaDate(new Date(workData?.data?.paa_date));
        reset({
          paa_letter_no: workData?.data?.paa_letter_no
            ? workData?.data?.paa_letter_no
            : "",
          paa_amount: workData?.data?.paa_amount
            ? workData?.data?.paa_amount
            : "",
          paa_date: workData?.data?.paa_date
            ? moment(workData?.data?.paa_date).format("DD-MM-YYYY")
            : "",
          paa_financial_year: workData?.data?.paa_financial_year
            ? workData?.data?.paa_financial_year
            : "",
          // paa_block_estimates_submitted_to_govt: "na",
          // paa_aa_letter_no: workData?.data?.paa_letter_no
          //   ? workData?.data?.paa_letter_no
          //   : "",
          // paa_aa_amount: workData?.data?.paa_amount
          //   ? workData?.data?.paa_amount
          //   : "",
          // paa_aa_app_finacial_year: workData?.data?.paa_date
          //   ? moment(workData?.data?.paa_date).format("DD-MM-YYYY")
          //   : "",
          // paa_aa_date: workData?.data?.paa_financial_year
          //   ? workData?.data?.paa_financial_year
          //   : "",
        });
      }
    }
  }, [workData?.data]);

  const isFieldsetDisabled =
    workData?.data?.basic_details_of_work_id === null ||
    currentUserRole === ROLE.CE;
  return (
    <>
      <Card>
        <>
          {workData?.data?.basic_details_of_work_id === null && (
            <span
              className="error help-inline m-1 text-center"
              id="ts_dtp_error"
            >
              Please fill up Basic details
            </span>
          )}
        </>
        <CardBody className="basic_tab">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isFieldsetDisabled}>
              <Row
                // tag="form"
                className="gy-1"
              >
                <Col md={4} xs={12} className="mt-0">
                  <Label className="form-label" for="paa_letter_no">
                    PAA Letter No.
                  </Label>
                  <Controller
                    control={control}
                    name="paa_letter_no"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          id="paa_letter_no"
                          // value={field.value}
                          invalid={errors.paa_letter_no && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_letter_no && (
                    <FormFeedback>{errors.paa_letter_no.message}</FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mt-0">
                  <Label className="form-label" for="paa_amount">
                    PAA Amount(Lacs)
                  </Label>
                  <Controller
                    control={control}
                    name="paa_amount"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          id="paa_amount"
                          // value={field.value}
                          invalid={errors.paa_amount && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_amount && (
                    <FormFeedback>{errors.paa_amount.message}</FormFeedback>
                  )}
                  {console.log("errors:>>>", errors)}
                </Col>
                <Col md={4} xs={12} className="mt-0">
                  <Label className="form-label" for="paa_date">
                    PAA Date
                  </Label>
                  <Controller
                    control={control}
                    name="paa_date"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          id="paa_date"
                          //   placeholder="Enter your Email id/Username"
                          // value={field.value}
                          invalid={errors.paa_date && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_date && (
                    <FormFeedback>{errors.paa_date.message}</FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1 ">
                  <Label className="form-label" for="paa_financial_year">
                    PAA Financial Year
                  </Label>
                  <Controller
                    control={control}
                    name="paa_financial_year"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          id="paa_financial_year"
                          //   placeholder="Enter your Email id/Username"
                          // value={field.value}
                          invalid={errors.paa_financial_year && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_financial_year && (
                    <FormFeedback>
                      {errors.paa_financial_year.message}
                    </FormFeedback>
                  )}
                </Col>
                <hr />
                <Col md={4} xs={12} className="mt-0 mb-1">
                  <Label
                    className="form-label"
                    for="paa_block_estimates_submitted_to_govt"
                  >
                    Block Estimate Submitted to Govt.?
                  </Label>
                  {/* ----------------- */}
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest}
                        {...foresttest}
                        type="radio"
                        name="paa_block_estimates_submitted_to_govt"
                        id="paa_block_estimates_submitted_to_govt_na"
                        value="na"
                        className="form-check-input"
                        // onChange={(value) => FieldDisabled(value.target.value)}
                      />
                      <Label
                        for="paa_block_estimates_submitted_to_govt_na"
                        className="ms-50 form-check-label"
                      >
                        NA
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest}
                        {...foresttest}
                        type="radio"
                        name="paa_block_estimates_submitted_to_govt"
                        id="paa_block_estimates_submitted_to_govt_yes"
                        value="yes"
                        // onChange={(value) => FieldDisabled(value.target.value)}
                        className="form-check-input"
                      />
                      <Label
                        for="paa_block_estimates_submitted_to_govt_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest}
                        {...foresttest}
                        type="radio"
                        name="paa_block_estimates_submitted_to_govt"
                        id="paa_block_estimates_submitted_to_govt_no"
                        value="no"
                        // onChange={(value) => FieldDisabled(value.target.value)}
                        className="form-check-input"
                      />
                      <Label
                        for="paa_block_estimates_submitted_to_govt_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Button
                        onClick={() => {
                          reset({
                            paa_block_estimate_amount: "",
                            paa_block_estimate_submission_date: "",
                            paa_aa_letter_no: "",
                            paa_aa_amount: "",
                            paa_aa_date: "",
                            paa_aa_app_finacial_year: "",
                            paa_block_estimates_submitted_to_govt: null,
                          });
                          setBlockEstimateSubDate(null);
                          setAaDate(null);
                          // setValue(
                          //   "paa_block_estimates_submitted_to_govt",
                          //   null
                          // );
                        }}
                      >
                        Reset
                      </Button>
                    </div>
                    <span>
                      {errors?.paa_block_estimates_submitted_to_govt && (
                        <FormFeedback className="d-block mt-0">
                          {
                            errors?.paa_block_estimates_submitted_to_govt
                              ?.message
                          }
                        </FormFeedback>
                      )}
                    </span>
                  </div>
                  {/* ----------------- */}
                </Col>
                <Col md={4} xs={12} className="mt-0  mb-1">
                  <Label className="form-label" for="paa_block_estimate_amount">
                    Block Estimate Amount(Lacs)
                  </Label>
                  <Controller
                    control={control}
                    name="paa_block_estimate_amount"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="paa_block_estimate_amount"
                          // value={field.value}
                          // onKeyPress={handleKeyPress}
                          disabled={
                            watch("paa_block_estimates_submitted_to_govt") ===
                              "na" ||
                            watch("paa_block_estimates_submitted_to_govt") ===
                              "no" ||
                            watch("paa_block_estimates_submitted_to_govt") ===
                              null
                          }
                          invalid={errors.paa_block_estimate_amount && true}
                          // onChange={(e) => {
                          //   field.onChange(e);
                          //   BlockEstimateAmount(e);
                          // }}
                          onChange={(e) => {
                            const pattern =
                              /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                            if (pattern.test(e.target.value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {errors.paa_block_estimate_amount && (
                    <FormFeedback className="d-block">
                      {errors.paa_block_estimate_amount.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mt-0 mb-1 new-work-date">
                  <Label
                    className="form-label"
                    for="paa_block_estimate_submission_date"
                  >
                    Block Estimate Submission Date
                  </Label>
                  <Controller
                    control={control}
                    id="paa_block_estimate_submission_date"
                    name="paa_block_estimate_submission_date"
                    className={
                      errors.paa_block_estimate_submission_date
                        ? "is-invalid"
                        : ""
                    }
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={
                          !watch("paa_block_estimate_amount") ||
                          watch("paa_block_estimates_submitted_to_govt") ===
                            "na" ||
                          watch("paa_block_estimates_submitted_to_govt") ===
                            "no"
                        }
                        selected={BlockEstimateSubDate}
                        onChange={handleEstimateDateChange}
                      />
                    )}
                  />
                  {errors.paa_block_estimate_submission_date && (
                    <FormFeedback className="d-block">
                      {errors.paa_block_estimate_submission_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <hr />
                <Col md={4} xs={12} className="mb-1  mt-0">
                  <Label className="form-label" for="paa_aa_letter_no">
                    AA Letter No.
                  </Label>
                  <Controller
                    control={control}
                    name="paa_aa_letter_no"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="paa_aa_letter_no"
                          // value={field.value}
                          disabled={
                            (watch("paa_block_estimates_submitted_to_govt") ===
                              "na" &&
                              watch("paa_block_estimate_submission_date")) ||
                            !watch("paa_block_estimate_submission_date") ||
                            watch("paa_block_estimates_submitted_to_govt") ===
                              "no"
                          }
                          invalid={errors.paa_aa_letter_no && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_aa_letter_no && (
                    <FormFeedback>
                      {errors.paa_aa_letter_no.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1  mt-0">
                  <Label className="form-label" for="paa_aa_amount">
                    AA Amount(Lacs)
                  </Label>
                  <Controller
                    control={control}
                    name="paa_aa_amount"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="paa_aa_amount"
                          // value={field.value}
                          disabled={
                            (watch("paa_block_estimates_submitted_to_govt") ===
                              "na" &&
                              watch("paa_aa_letter_no")) ||
                            !watch("paa_aa_letter_no") ||
                            watch("paa_block_estimates_submitted_to_govt") ===
                              "no"
                          }
                          invalid={errors.paa_aa_amount && true}
                          onChange={(e) => {
                            const pattern =
                              /^(?!0(\.0{1,2})?$)\d{0,15}(\.\d{0,2})?$/;
                            if (pattern.test(e.target.value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {errors.paa_aa_amount && (
                    <FormFeedback>{errors.paa_aa_amount.message}</FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mt-0 mb-1 new-work-date">
                  <Label className="form-label" for="paa_aa_date">
                    AA Date
                  </Label>
                  <Controller
                    control={control}
                    id="paa_aa_date"
                    name="paa_aa_date"
                    // className={errors.paa_aa_date ? "is-invalid" : ""}
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={AaDate}
                        onChange={handleAaDateChange}
                        disabled={
                          !watch("paa_aa_amount") ||
                          !watch("paa_aa_letter_no") ||
                          watch("paa_block_estimates_submitted_to_govt") ===
                            "na" ||
                          watch("paa_block_estimates_submitted_to_govt") ===
                            "no"
                        }
                      />
                    )}
                  />
                  {errors.paa_aa_date && (
                    <FormFeedback className="d-block">
                      {errors.paa_aa_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="paa_aa_app_finacial_year">
                    AA Financial Year
                  </Label>
                  <Controller
                    control={control}
                    name="paa_aa_app_finacial_year"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="paa_aa_app_finacial_year"
                          disabled
                          // value={field.value}
                          invalid={errors.paa_aa_app_finacial_year && true}
                        />
                      );
                    }}
                  />
                  {errors.paa_aa_app_finacial_year && (
                    <FormFeedback>
                      {errors.paa_aa_app_finacial_year.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col xs={12}>
                  <Button
                    className="btn btn-outline-dark waves-effect me-25"
                    type="reset"
                    outline
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default PAA_AA;
