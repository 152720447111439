import * as yup from "yup";

export const UtilityShiftingSchema = yup.object().shape({
  //  **
  electric_line_pole: yup.boolean(), // Validation for electric_line_pole
  e_utility_araji_karel_kacheri: yup
    .string()
    .when("electric_line_pole", {
      is: true, // Only apply validation when electric_line_pole is true
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  e_utility_araji_karel_date: yup
    .string()
    .when("e_utility_araji_karel_kacheri", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  e_utility_siftingni_aajroj_antim_paristhti: yup
    .string()
    .when("electric_line_pole", {
      is: true,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  e_utility_sifting_puran_thayel_chhe: yup.string(),

  e_utility_sifting_puran_thayel_date: yup
    .string()
    .when("e_utility_sifting_puran_thayel_chhe", {
      is: "yes", // Only apply validation when e_utility_sifting_puran_thayel_chhe is "yes"
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  // **  water supply
  water_supply: yup.boolean(), // Validation for water_supply
  w_utility_araji_karel_kacheri: yup
    .string()
    .when("water_supply", {
      is: true, // Only apply validation when water_supply is true
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  w_utility_araji_karel_date: yup
    .string()
    .when("w_utility_araji_karel_kacheri", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  w_utility_siftingni_aajroj_antim_paristhti: yup
    .string()
    .when("water_supply", {
      is: true,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  w_utility_sifting_puran_thayel_chhe: yup.string(),

  w_utility_sifting_puran_thayel_date: yup
    .string()
    .when("w_utility_sifting_puran_thayel_chhe", {
      is: "yes", // Only apply validation when e_utility_sifting_puran_thayel_chhe is "yes"
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  // ** GAS

  gas_pipeline: yup.boolean(),

  g_utility_araji_karel_kacheri: yup
    .string()
    .when("gas_pipeline", {
      is: true, // Only apply validation when gas_pipeline is true
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  g_utility_araji_karel_date: yup
    .string()
    .when("g_utility_araji_karel_kacheri", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  g_utility_siftingni_aajroj_antim_paristhti: yup
    .string()
    .when("gas_pipeline", {
      is: true,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  g_utility_sifting_puran_thayel_chhe: yup.string(),

  g_utility_sifting_puran_thayel_date: yup
    .string()
    .when("g_utility_sifting_puran_thayel_chhe", {
      is: "yes", // Only apply validation when e_utility_sifting_puran_thayel_chhe is "yes"
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  // **  water supply
  optical_fiber: yup.boolean(), // Validation for water_supply
  op_utility_araji_karel_kacheri: yup
    .string()
    .when("optical_fiber", {
      is: true, // Only apply validation when water_supply is true
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  op_utility_araji_karel_date: yup
    .string()
    .when("op_utility_araji_karel_kacheri", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  op_utility_siftingni_aajroj_antim_paristhti: yup
    .string()
    .when("optical_fiber", {
      is: true,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  op_utility_sifting_puran_thayel_chhe: yup.string(),

  op_utility_sifting_puran_thayel_date: yup
    .string()
    .when("op_utility_sifting_puran_thayel_chhe", {
      is: "yes", // Only apply validation when e_utility_sifting_puran_thayel_chhe is "yes"
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  other: yup.boolean(),

  ot_utility_araji_karel_kacheri: yup
    .string()
    .when("other", {
      is: true, // Only apply validation when water_supply is true
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  ot_utility_araji_karel_date: yup
    .string()
    .when("ot_utility_araji_karel_kacheri", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  ot_utility_siftingni_aajroj_antim_paristhti: yup
    .string()
    .when("other", {
      is: true,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),

  ot_utility_sifting_puran_thayel_chhe: yup.string(),

  ot_utility_sifting_puran_thayel_date: yup
    .string()
    .when("ot_utility_sifting_puran_thayel_chhe", {
      is: "yes", // Only apply validation when e_utility_sifting_puran_thayel_chhe is "yes"
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
});
