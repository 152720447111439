import {
  FYOnlyYears,
  ROLE,
  TomorrowDate,
  getFinancialYearDates,
} from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = (engGujState, isSwitchOn) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },

    {
      Header: engGujState ? "કામનું નામ" : "TOTAL NO. OF WORKS",
      accessor: "number_of_work",
      show: true,
      minWidth: "303px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "PAA / AA AMOUNT",
      accessor: "paaAmount",
      show: true,
      minWidth: "98px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "TS AMOUNT",
      accessor: "tsAmount",
      show: true,
      minWidth: "145px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "TENDER AMOUNT",
      accessor: "tenderAmount",
      show: true,
      minWidth: "145px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? `${getFinancialYearDates()?.lastYear?.end} સુધીનો ખર્ચ`
        : `Expen. incu. upto ${
            getFinancialYearDates()?.lastYear?.end
          } (LAST F.Y.)`,
      accessor: "expenditureIncurredUpto",
      show: true,
      minWidth: "145px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "વહીવટી મંજુરી તારીખ"
        : `SPILL OVER AS ON .${getFinancialYearDates()?.currentYear?.start}`,
      accessor: "spillOverAs",
      show: true,
      minWidth: "145px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "SPILL OVER OF CURRENT YEAR APPROVED WORKS",
      accessor: "spillOverOfCurrentYearApprovedWorks",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? `તા.${
            getFinancialYearDates()?.currentYear?.start
          } ની સ્પીલ ઓવર તથા ચાલુ વર્ષનાં મંજૂર થયેલ કામની સ્પીલઓવર`
        : `SPILL OVER AS ON ${
            getFinancialYearDates()?.currentYear?.start
          }  & OF CURRENT YEAR APP. WORK`,
      accessor: "spillOver_spillOverOfCurrentYearApprovedWork",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? `સને ${FYOnlyYears()} નો કુલ સંભવિત ખર્ચ`
        : `Target Expen. of Year ${FYOnlyYears()}`,
      accessor: "targetExpenditureOfYear",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : `MODIFIED TARGET EXPENDITURE OF YEAR ${FYOnlyYears()} `,
      accessor: "modifiedTargetExpenditureOfYear",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "EXPENDITURE IN CURRENT YEAR",
      accessor: "expenditureInCurrentYear",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : `PROBABLE EXPENDITURE FROM ${TomorrowDate()} TO ${
            getFinancialYearDates().currentYear.end
          }`,
      accessor: "probableExpenditure",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "CURRENT SPILL OVER",
      accessor: "currentSpillOver",
      show: true,
      minWidth: "387px",
      Cell: (cell) => {
        // Convert the value to crores or lacs based on the switch state
        const formattedValue = isSwitchOn
          ? cell.value / 100
          : Number(cell.value);

        return <div>{formattedValue?.toFixed(2)}</div>;
      },
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export const getAbstractData = (data) => {
  const output = [];
  let srno = 1;

  if (currentUserRole === ROLE.CE) {
    for (const budgetHead in data) {
      let last_sum_total_works = 0;
      let last_sum_paa_amount_total = 0;
      let last_sum_ts_amount_total = 0;
      let last_sum_tender_amount_total = 0;
      let last_sum_total_prev_year_expense_total = 0;
      let last_sum_total_prev_year_approved_spill_over = 0;
      let last_sum_total_current_year_approved_spill_over = 0;
      let last_sum_total_approved_spill_over = 0;
      let last_sum_total_target_expenditure = 0;
      let last_sum_total_modified_target_expenditure = 0;
      let last_sum_total_current_year_expenditure = 0;
      let last_sum_total_probable_expenditure = 0;
      let last_sum_total_spill_over = 0;

      output.push({
        budget_heads_name: budgetHead,
        sr_no: srno++,
        colspan: 15,
      });

      for (const circle in data[budgetHead]) {
        let c_sum_total_works = 0;
        let c_sum_paa_amount_total = 0;
        let c_sum_ts_amount_total = 0;
        let c_sum_tender_amount_total = 0;
        let c_sum_total_prev_year_expense_total = 0;
        let c_sum_total_prev_year_approved_spill_over = 0;
        let c_sum_total_current_year_approved_spill_over = 0;
        let c_sum_total_approved_spill_over = 0;
        let c_sum_total_target_expenditure = 0;
        let c_sum_total_modified_target_expenditure = 0;
        let c_sum_total_current_year_expenditure = 0;
        let c_sum_total_probable_expenditure = 0;
        let c_sum_total_spill_over = 0;

        for (const division in data[budgetHead][circle]) {
          const budgetHeadsData = data[budgetHead][circle][division][0];

          output.push({
            name_of_circle: circle,
            name_of_division: division,
            budget_heads_name: "",
            total_work: 3,
            division_id: 5,
            colspan: 3,
            ...budgetHeadsData,
          });

          c_sum_total_works += budgetHeadsData.total_works;
          c_sum_paa_amount_total += parseInt(
            budgetHeadsData.total_paa_aa_amount
          );
          c_sum_ts_amount_total += parseInt(
            budgetHeadsData.total_ts_amount || 0
          );

          c_sum_tender_amount_total += parseInt(
            budgetHeadsData.total_tender_app_amount || 0
          );
          c_sum_total_prev_year_expense_total += parseInt(
            budgetHeadsData.total_prev_year_expense || 0
          );

          c_sum_total_prev_year_approved_spill_over += parseInt(
            budgetHeadsData.total_prev_year_approved_spill_over
          );
          c_sum_total_current_year_approved_spill_over += parseInt(
            budgetHeadsData.total_current_year_approved_spill_over
          );
          c_sum_total_approved_spill_over += parseInt(
            budgetHeadsData.total_approved_spill_over
          );
          c_sum_total_target_expenditure += parseInt(
            budgetHeadsData.total_target_expenditure
          );
          c_sum_total_modified_target_expenditure += parseInt(
            budgetHeadsData.total_modified_target_expenditure
          );
          c_sum_total_current_year_expenditure += parseInt(
            budgetHeadsData.total_current_year_expenditure
          );
          c_sum_total_probable_expenditure += parseInt(
            budgetHeadsData.total_probable_expenditure
          );
          c_sum_total_spill_over += parseInt(budgetHeadsData.total_spill_over);
        }

        output.push({
          bg_color: "#00c778",
          colspan: 3,
          fontweight: 600,
          name_of_division: `${circle} Total`,
          total_works: c_sum_total_works,
          total_paa_aa_amount: c_sum_paa_amount_total.toFixed(2),
          total_ts_amount: c_sum_ts_amount_total.toFixed(2),
          total_tender_app_amount: c_sum_tender_amount_total.toFixed(2),
          total_prev_year_expense:
            c_sum_total_prev_year_expense_total.toFixed(2),

          total_prev_year_approved_spill_over:
            c_sum_total_prev_year_approved_spill_over.toFixed(2),
          total_current_year_approved_spill_over:
            c_sum_total_current_year_approved_spill_over.toFixed(2),
          total_approved_spill_over: c_sum_total_approved_spill_over.toFixed(2),
          total_target_expenditure: c_sum_total_target_expenditure.toFixed(2),
          total_modified_target_expenditure:
            c_sum_total_modified_target_expenditure.toFixed(2),
          total_current_year_expenditure:
            c_sum_total_current_year_expenditure.toFixed(2),
          total_probable_expenditure:
            c_sum_total_probable_expenditure.toFixed(2),
          total_spill_over: c_sum_total_spill_over.toFixed(2),
        });

        last_sum_total_works += c_sum_total_works;
        last_sum_paa_amount_total += c_sum_paa_amount_total;
        last_sum_ts_amount_total += c_sum_ts_amount_total;
        last_sum_tender_amount_total += c_sum_tender_amount_total;
        last_sum_total_prev_year_expense_total +=
          c_sum_total_prev_year_expense_total;

        last_sum_total_prev_year_approved_spill_over +=
          c_sum_total_prev_year_approved_spill_over;
        last_sum_total_current_year_approved_spill_over +=
          c_sum_total_current_year_approved_spill_over;
        last_sum_total_approved_spill_over += c_sum_total_approved_spill_over;
        last_sum_total_target_expenditure += c_sum_total_target_expenditure;
        last_sum_total_modified_target_expenditure +=
          c_sum_total_modified_target_expenditure;
        last_sum_total_current_year_expenditure +=
          c_sum_total_current_year_expenditure;
        last_sum_total_probable_expenditure += c_sum_total_probable_expenditure;
        last_sum_total_spill_over += c_sum_total_spill_over;
      }

      output.push({
        bg_color: "#ff9c52",
        colspan: 3,
        fontweight: 600,
        name_of_division: "Total",
        total_works: last_sum_total_works,
        total_paa_aa_amount: last_sum_paa_amount_total.toFixed(2),
        total_ts_amount: last_sum_ts_amount_total.toFixed(2),
        total_tender_app_amount: last_sum_tender_amount_total.toFixed(2),
        total_prev_year_expense:
          last_sum_total_prev_year_expense_total.toFixed(2),

        total_prev_year_approved_spill_over:
          last_sum_total_prev_year_approved_spill_over.toFixed(2),
        total_current_year_approved_spill_over:
          last_sum_total_current_year_approved_spill_over.toFixed(2),
        total_approved_spill_over:
          last_sum_total_approved_spill_over.toFixed(2),
        total_target_expenditure: last_sum_total_target_expenditure.toFixed(2),
        total_modified_target_expenditure:
          last_sum_total_modified_target_expenditure.toFixed(2),
        total_current_year_expenditure:
          last_sum_total_current_year_expenditure.toFixed(2),
        total_probable_expenditure:
          last_sum_total_probable_expenditure.toFixed(2),
        total_spill_over: last_sum_total_spill_over.toFixed(2),
      });
    }

    return output;
  } else if (currentUserRole === ROLE.SE) {
    for (const budgetHead in data) {
      let last_sum_total_works = 0;
      let last_sum_paa_amount_total = 0;
      let last_sum_ts_amount_total = 0;
      let last_sum_tender_amount_total = 0;
      let last_sum_total_prev_year_expense_total = 0;
      let last_sum_total_prev_year_approved_spill_over = 0;
      let last_sum_total_current_year_approved_spill_over = 0;
      let last_sum_total_approved_spill_over = 0;
      let last_sum_total_target_expenditure = 0;
      let last_sum_total_modified_target_expenditure = 0;
      let last_sum_total_current_year_expenditure = 0;
      let last_sum_total_probable_expenditure = 0;
      let last_sum_total_spill_over = 0;

      output.push({
        budget_heads_name: budgetHead,
        sr_no: srno++,
        colspan: 15,
      });

      for (const division in data[budgetHead]) {
        const budgetHeadsData = data[budgetHead][division][0];

        output.push({
          name_of_division: division,
          budget_heads_name: "",
          total_work: 3,
          division_id: 5,
          colspan: 3,
          ...budgetHeadsData,
        });

        last_sum_total_works += budgetHeadsData.total_works;
        last_sum_paa_amount_total += parseInt(
          budgetHeadsData.total_paa_aa_amount
        );
        last_sum_ts_amount_total += parseInt(
          budgetHeadsData.total_ts_amount || 0
        );

        last_sum_tender_amount_total += parseInt(
          budgetHeadsData.total_tender_app_amount || 0
        );
        last_sum_total_prev_year_expense_total += parseInt(
          budgetHeadsData.total_prev_year_expense || 0
        );

        last_sum_total_prev_year_approved_spill_over += parseInt(
          budgetHeadsData.total_prev_year_approved_spill_over
        );
        last_sum_total_current_year_approved_spill_over += parseInt(
          budgetHeadsData.total_current_year_approved_spill_over
        );
        last_sum_total_approved_spill_over += parseInt(
          budgetHeadsData.total_approved_spill_over
        );
        last_sum_total_target_expenditure += parseInt(
          budgetHeadsData.total_target_expenditure
        );
        last_sum_total_modified_target_expenditure += parseInt(
          budgetHeadsData.total_modified_target_expenditure
        );
        last_sum_total_current_year_expenditure += parseInt(
          budgetHeadsData.total_current_year_expenditure
        );
        last_sum_total_probable_expenditure += parseInt(
          budgetHeadsData.total_probable_expenditure
        );
        last_sum_total_spill_over += parseInt(budgetHeadsData.total_spill_over);
      }

      output.push({
        // bg_color: "#ff9c52",
        colspan: 3,
        name_of_division: "Total",
        total_works: last_sum_total_works,
        total_paa_aa_amount: last_sum_paa_amount_total.toFixed(2),
        total_ts_amount: last_sum_ts_amount_total.toFixed(2),
        total_tender_app_amount: last_sum_tender_amount_total.toFixed(2),
        total_prev_year_expense:
          last_sum_total_prev_year_expense_total.toFixed(2),

        total_prev_year_approved_spill_over:
          last_sum_total_prev_year_approved_spill_over.toFixed(2),
        total_current_year_approved_spill_over:
          last_sum_total_current_year_approved_spill_over.toFixed(2),
        total_approved_spill_over:
          last_sum_total_approved_spill_over.toFixed(2),
        total_target_expenditure: last_sum_total_target_expenditure.toFixed(2),
        total_modified_target_expenditure:
          last_sum_total_modified_target_expenditure.toFixed(2),
        total_current_year_expenditure:
          last_sum_total_current_year_expenditure.toFixed(2),
        total_probable_expenditure:
          last_sum_total_probable_expenditure.toFixed(2),
        total_spill_over: last_sum_total_spill_over.toFixed(2),
      });
    }

    return output;
  } else if (currentUserRole === ROLE.EE) {
    for (const item of data) {
      output.push({
        sr_no: srno++,
        ...item,
      });
    }

    return output;
  }
};
