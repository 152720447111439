// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { User, Check, X } from "react-feather";
import { useForm, Controller } from "react-hook-form";

// ** Utils
// import { selectThemeColors } from '@utils'

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
const AddSchemeOfBudgetData = yup.object().shape({
  budget_head: yup.string().required("Enter budget head").typeError(""),
  scheme_name: yup.string().required("Enter scheme gujarati").typeError(""),
  sort_order: yup.string().required("Enter sort order").typeError(""),
});

const AddSchemeOfBudget = () => {
  // ** States
  const [show, setShow] = useState(false);

  // ** Hooks
  const {
    // reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddSchemeOfBudgetData),
  });
  const ceRoleOptions = [
    { value: "NH", label: "NH" },
    { value: "STATE", label: "STATE" },
    { value: "ALL", label: "ALL" },
  ];
  const onSubmit = (data) => {
    console.log("AddSchemeOfBudget>>", data);
  };
  return (
    <Fragment>
      <Card>
        <CardBody className="text-center">
          <User className="font-large-2 mb-1" />
          <CardTitle tag="h5">Add Scheme of Budget</CardTitle>
          <Button color="primary" onClick={() => setShow(true)}>
            Show
          </Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-css"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">Add Scheme of Budget</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row
              // tag="form"
              className="gy-1 pt-1"
              // onSubmit={handleSubmit(onSubmit)}
            >
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="budget_head">
                  Budget Head
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="budget_head"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={ceRoleOptions}
                      classNamePrefix="select"
                      className="react-select"
                      invalid={errors.budget_head && true}
                      placeholder="Select Budget Head"
                      {...field}
                    />
                  )}
                />
                {errors.budget_head && (
                  <FormFeedback>{errors.budget_head.message}</FormFeedback>
                )}
              </Col>

              {console.log("errors: ", errors)}

              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="scheme_name">
                  Scheme
                </Label>
                <Controller
                  control={control}
                  name="scheme_name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="scheme_name"
                        placeholder="Enter Scheme Name"
                        // value={field.value}
                        invalid={errors.scheme_name && true}
                      />
                    );
                  }}
                />
                {errors.scheme_name && (
                  <FormFeedback>{errors.scheme_name.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="sort_order">
                  Sort Order
                </Label>
                <Controller
                  control={control}
                  name="sort_order"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="sort_order"
                        // value={field.value}
                        placeholder="Enter Sort Order"
                        invalid={errors.sort_order && true}
                      />
                    );
                  }}
                />
                {errors.sort_order && (
                  <FormFeedback>{errors.sort_order.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="modal-footer mt-0">
                <Button
                  className="btn btn-outline-dark waves-effect"
                  type="reset"
                  outline
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddSchemeOfBudget;
