/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Abstract from "../../../../../assets/images/Abstract.png";
import ColumnsVisibility from "../../../../../assets/images/ColumnsVisibility.png";
import Excel from "../../../../../assets/images/excel.png";
import Print from "../../../../../assets/images/print.png";

import ReactPaginate from "react-paginate";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../../assets/images";
import {
  ROLE,
  SelectCustomStyles,
  dateFormate,
} from "../../../../../common/utils";
import { getInProgressInTimeWorksReport } from "../../../../../services/reportsServices";
import classNames from "classnames";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactToPrint from "react-to-print";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import {
  columnChanges,
  getColumnlist,
} from "../../../../../services/adminDashboard";
import PrintTable from "../../../PrintTable";
import FilterInProgressAll from "./FilterInProgressInTIme";
import InProgressInTImeAbstractModal from "./InProgressInTImeAbstractModal";
import { getHeaderColumnsByRole } from "./InProgressInTImeUtils";
import UILoader from "@components/ui-loader";
import RNBLoader from "../../../../../@core/components/spinner/RNBLoader";
import {
  setLoading,
  unsetLoading,
} from "../../../../../redux/LoadingRedux/loadingSlice";

// **  Column Freeze as per Login Role
const columnsFreeze = new Map([
  [ROLE.SECRETORY, 6],
  [ROLE.CE, 6],
  [ROLE.SE, 5],
  [ROLE.EE, 4],
  [ROLE.DEE, 4],
]);

const InProgressInTIme = () => {
  const loginUser = useSelector((state) => state.auth.userData.data);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const dispatch = useDispatch();

  // ** Function for change Data Structure and show in table
  function addDuplicateForObjectWithSpecificValue(dataArray) {
    const result = [];

    // * Remove duplicate package ids
    const seenPackageIds = {};
    const data = dataArray.filter((item) => {
      if (item.package_id > 0) {
        if (!seenPackageIds[item.package_id]) {
          seenPackageIds[item.package_id] = true;
          return true;
        }
        return false;
      } else {
        return true;
      }
    });

    let sr_no = 1;
    for (const obj of data) {
      if (obj.govtplan_bytender === "govt") {
        result.push({
          ...obj,
          name_of_work_title: "Government Plant",
          dtp_approval_date: obj.govplan_date,
          dtp_amount: obj.govplan_amount,
          tentative_tpc: obj.govt_total_project_cost,
          tender_app_amount:
            obj.govplan_amount === null ? "--" : obj.govplan_amount,
          tender_app_date: obj.govplan_date,
          sr_no: sr_no++,
          above_below: "--",
          agency_gujarati: "સરકારી પ્લાન્ટ",
          agency: "Govt. Plant",
          work_order_date: "--",
          date_of_completion_as_per_wo: "--",
          likely_date_of_completion: "--",
          date_of_completion_as_per_approved_timelimit: "--",
          tender_expen_incured_last_fy_year:
            obj.govt_expen_incured_last_fy_year,
          tender_spill_over_this_fy_year_n_current_year_approved_work:
            obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
          tender_target_expenditure_of_current_fy_year:
            obj.govt_target_expenditure_of_current_fy_year,
          tender_current_year_upto_today: obj.govt_current_year_upto_today,
          tender_probable_expen_from_today_to_end_of_this_fy_year:
            obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
          tender_total_expenditure_as_on_today_on_this_project:
            obj.govt_total_expenditure_as_on_today_on_this_project,
          workstatus_detail: obj.govt_plan_work_detail,
        });
        result.push({
          ...obj,
          //   id: "",
          name_of_work_title: "Furniture+Misc.",
          circle_name: "--",
          unique_code: "--",
          division_name: "--",
          subdivision_name: "--",
          budget_heads_name: "--",
          scheme_of_budgets_name: "--",
          chainage_from_to: "--",
          length_in_km: "--",
          proposed_work: "--",
          widening_cw: "--",
          aa_amount: "--",
          aa_date: "--",
          paa_aa_amount: "--",
          paa_aa_date: "--",
          ts_amount: "--",
          ts_date: "--",
          sr_no: "",
          tentative_tpc: obj.tender_total_project_cost,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
      } else if (obj.package_id !== 0 && obj.package_id !== null) {
        result.push({
          ...obj,
          name_of_work_title: "Package",
          sr_no: sr_no++,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
        obj.sub_work &&
          obj.sub_work.map((row) =>
            result.push({
              ...row,
              name_of_work_title: "",
              sr_no: "",
              dtp_amount: "--",
              dtp_approval_date: "--",
              tender_opening_date: "--",
              tender_app_amount: "--",
              tender_app_date: "--",
              agency_gujarati: "--",
              agency: "--",
              work_order_date: "--",
              date_of_completion_as_per_wo: "--",
              likely_date_of_completion: "--",
              date_of_completion_as_per_approved_timelimit: "--",
              tentative_tpc: "--",
              tender_expen_incured_last_fy_year: "--",
              tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
              tender_target_expenditure_of_current_fy_year: "--",
              tender_current_year_upto_today: "--",
              tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
              tender_total_expenditure_as_on_today_on_this_project: "--",
              financial_progress: "--",
              physical_progress: "--",
              workstatus_detail: "--",
              work_status: "--",
              above_below: "--",
            })
          );
        // }
      } else {
        result.push({
          ...obj,

          name_of_work_title: "",
          sr_no: sr_no++,
          above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
      }
    }
    return result;
  }

  //**  States

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  // * Pagination
  const [paginationValues, setPaginationValues] = useState({
    currentPage: 1,
    rowPerPage: 50,
    AllPages: 1,
  });

  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: "all", label: "All" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // ** Get List Of Tender Type

  useEffect(() => {
    // handleApi();
    dispatch(setLoading());
    // setIsLoading(true);
    const val = {
      page: paginationValues.currentPage,
      per_page: paginationValues.rowPerPage,
    };
    getInProgressInTimeWorksReport(val)
      .then((res) => {
        setPaginationValues({
          ...paginationValues,
          AllPages: res.data.last_page,
        });

        const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
        setTableData(data);
        dispatch(unsetLoading());
      })
      .catch((err) => {
        getErrorMsg(err);
        dispatch(unsetLoading());
      });
  }, [paginationValues.currentPage, paginationValues.rowPerPage]);

  const getFilterData = React.useCallback(
    (data) => {
      const value = {
        ...data,
        page: paginationValues.currentPage,
        per_page: paginationValues.rowPerPage,
      };

      getInProgressInTimeWorksReport(value)
        .then((res) => {
          const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
          setTableData(data);
        })
        .catch((err) => {
          console.log("data>>>>> 1", err);
          getErrorMsg(err);
        });
    },
    [getInProgressInTimeWorksReport]
  );

  // * Abstract modal ==========
  const [abstractModal, setAbstractModal] = useState({
    isOpen: false,
  });

  const handleAbstract = () => {
    setAbstractModal({ isOpen: true });
  };

  // * For filter DATA ===========
  const [canvasOpenData, setCanvasOpenData] = useState({
    isOpen: false,
  });
  //  * Filter Canvas Open
  const handleFilterOffset = () => {
    setCanvasOpenData({ isOpen: true });
  };

  // * For english/gujrati button
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [engGujState, setEngGujState] = useState(false);

  useEffect(() => {
    const watchRoute = searchParams.get("lang");
    watchRoute === "1" ? setEngGujState(true) : setEngGujState(false);
  }, [searchParams.get("lang")]);

  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
    navigate({
      pathname: "/report_work_in_progress_in_time",
      search: createSearchParams({
        lang: engGujState ? "0" : "1",
      }).toString(),
    });
  };

  // ** Edit WORK
  const handleEditStage = (data) => {
    const url = `/update-work/${data?.id}`;
    window.open(url, "_blank");
  };

  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData.map((item) => ({
      id: item.id,
      srno: item.sr_no,
      name_Of_circle: item.circle_name,
      uniqueCode: item.unique_code,
      name_Of_Division: item.division_name,
      budget_head: item.budget_heads_name,
      name_of_work: engGujState
        ? `${item.name_of_work_title}\n${item.name_of_work_gujarati}`
        : `${item.name_of_work_title}\n${item.name_of_work_english}`,
      lengthInKM: item.length_in_km,
      aaAmount: item.aa_amount,
      aaDate:
        item.aa_date === null
          ? ""
          : item.aa_date === "--"
          ? item.aa_date
          : moment(item.aa_date).format("DD-MM-YYYY"),
      tenderAppAmount: item.tender_app_amount,

      tenderAppDate:
        item.tender_app_date === null
          ? ""
          : item.tender_app_date === "--"
          ? item.tender_app_date
          : moment(item.tender_app_date).format("DD-MM-YYYY"),

      above_below: item.above_below,
      nameofAgency: engGujState ? item.agency_gujarati : item.agency,

      workOrderDate:
        item.work_order_date === null
          ? ""
          : item.work_order_date === "--"
          ? item.work_order_date
          : moment(item.work_order_date).format("DD-MM-YYYY"),

      likelyDateCompletion:
        item.likely_date_of_completion === null
          ? ""
          : item.likely_date_of_completion === "--"
          ? item.likely_date_of_completion
          : moment(item.likely_date_of_completion).format("DD-MM-YYYY"),

      tentativeProjectCost: item.tentative_tpc,
      totalExpentillProject:
        item.tender_total_expenditure_as_on_today_on_this_project,

      currentSpillOver: item.tender_current_spillover_of_project,
      financialProgress: item.financial_progress,
      physicalProgress: item.physical_progress,
      status_of_work_in_detail: item.workstatus_detail,
    }));
  }, [tableData, engGujState]);

  // ** Columns For Table
  const columns = useMemo(
    () => getHeaderColumnsByRole(engGujState, handleEditStage),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      // initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column

  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (!hiddenColumns.includes(column.accessor)) {
        left += column.minWidth ? parseInt(column.minWidth, 10) : 120;
      }
    }
    return left;
  }

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "inProgressInTimeWorks",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      columns.forEach((item, index) => {
        if (row === item.accessor) {
          data.push(index);
        }
      });
    }
    return data;
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];
    const hiddenCol = columnsArr.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const item = columns[row];
        data.push(item.accessor);
      }
    }
    return data;
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "inProgressInTimeWorks",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  }, []);

  return (
    <>
      <UILoader blocking={isLoading} loader={<RNBLoader />}>
        <div className="table-title  ee_new_work_title">
          <h2 className="text-black">{`IN PROGRESS - IN TIME WORKS`}</h2>

          {dateFormate()}
        </div>
        <Card>
          <CardBody className="basic_tab p-0">
            <div className="dt-action-button btn_search_box d-flex">
              <div>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">Excel</span>
                </Button.Ripple>
                <ReactToPrint
                  trigger={() => (
                    <Button.Ripple
                      color="primary"
                      className="projects_btn me-25"
                      outline
                    >
                      <img src={Print} alt="icon" className="me-1" />
                      <span className="align-middle">Print</span>
                    </Button.Ripple>
                  )}
                  content={() => {
                    const props = {
                      title: `IN PROGRESS - IN TIME WORKS, Dt.${moment().format(
                        "DD-MM-YYYY"
                      )}(Rs in Lacs)`,
                      getTableProps,
                      headerGroups,
                      hiddenColumns,
                      frozenColumn,
                      getFrozenColumnLeft,
                      getTableBodyProps,
                      rows,
                      prepareRow,
                    };
                    PrintTable(props);
                  }}
                />
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">PDF</span>
                </Button.Ripple>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                  onClick={() => handleAbstract()}
                >
                  <img src={Abstract} alt="icon" className="me-1" />
                  <span className="align-middle">Abstract</span>
                </Button.Ripple>
                <ButtonDropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  className="projects_btn me-25"
                >
                  <DropdownToggle color="primary" outline caret>
                    <img src={ColumnsVisibility} alt="icon" className="me-1" />
                    <span className="align-middle">Columns Visibility</span>
                  </DropdownToggle>
                  <DropdownMenu className="p-40 vb-drop column_visibility">
                    {dropdownOptions.map((option, index) => (
                      <Button.Ripple
                        key={option.value}
                        color="primary"
                        onClick={() =>
                          handleToggleHiddenColumns(index, option.value)
                        }
                        className={
                          !hiddenColumns.includes(option.value)
                            ? "active w-100 visibility-Option"
                            : "w-100 visibility-Option bg-white"
                        }
                      >
                        {option.label}
                      </Button.Ripple>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <Button.Ripple
                  className="me-25"
                  onClick={() => handleGujaratiEnglish()}
                >
                  <span className="align-middle ms-25">Guj. / Eng.</span>
                </Button.Ripple>
              </div>
              <div className="search_Box d-flex">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control search_bar"
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
                <Button
                  color="primary"
                  className="filter-btn-width"
                  onClick={() => handleFilterOffset()}
                >
                  <FilterSvg />
                  <span className="ps-25">Filters</span>
                </Button>
              </div>
            </div>
            <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
              <table
                {...getTableProps()}
                style={{ minWidth: "100%" }}
                className="dataTable table-bordered sticky-header"
              >
                <colgroup>
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      return (
                        <col
                          key={columnIndex}
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            minWidth: column.minWidth
                              ? column.minWidth
                              : "120px",
                          }}
                        />
                      );
                    })
                  )}
                </colgroup>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, columnIndex) => {
                        const isVisible = !hiddenColumns.includes(column.id);
                        const isFrozen =
                          frozenColumn === column.id ||
                          columnIndex < columnsFreeze.get(loginUser?.role);
                        const headerCellClass = classNames({
                          "dtfc-fixed-left": isFrozen,
                          "dtfc-cell": !isFrozen,
                          desc: column.isSorted && column.isSortedDesc,
                          asc: column.isSorted && !column.isSortedDesc,
                        });
                        return (
                          <th
                            {...column.getHeaderProps()}
                            className={headerCellClass}
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(columnIndex)}px`
                                : "auto",
                              zIndex: 1,
                            }}
                          >
                            <div
                              {...column.getSortByToggleProps()}
                              className="columnSort"
                            >
                              {isVisible && column.render("Header")}
                              <span className="dataSort">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortArrowDown />
                                  ) : (
                                    <SortArrowtop />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) => {
                          const isVisible = !hiddenColumns.includes(
                            cell.column.id
                          );
                          const isFrozen =
                            frozenColumn === cell.column.id ||
                            cellIndex < columnsFreeze.get(loginUser?.role);
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={
                                isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                              }
                              style={{
                                visibility: isVisible ? "visible" : "collapse",
                                position: isFrozen ? "sticky" : "static",
                                left: isFrozen
                                  ? `${getFrozenColumnLeft(cellIndex)}px`
                                  : "auto",
                                zIndex: 0,
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {isLoading && (
                <div className="nodata-loading-div">
                  <p className="nodata-loading">Loading...</p>
                </div>
              )}

              {!isLoading && !rows.length && (
                <div className="nodata-loading-div">
                  <p className="nodata-loading">No matching records found</p>
                </div>
              )}
            </div>
            <div className="dt_footer">
              <div className="dt_pg_info">
                <span>Show</span>
                <Select
                  value={{
                    value: paginationValues.rowPerPage,
                    label:
                      paginationValues.rowPerPage === "all"
                        ? "All"
                        : paginationValues.rowPerPage,
                  }}
                  styles={SelectCustomStyles}
                  options={options}
                  onChange={(selectedOption) => {
                    setPaginationValues({
                      ...paginationValues,
                      currentPage: 1,
                      rowPerPage: selectedOption.value,
                    });
                  }}
                  className="dt_pg_select"
                  menuPortalTarget={document.body}
                />

                <span>entries</span>
              </div>

              {/* Paginate*/}
              <ReactPaginate
                nextLabel=""
                pageCount={paginationValues.AllPages}
                previousLabel=""
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={(selected) => {
                  setPaginationValues({
                    ...paginationValues,
                    currentPage: selected.selected + 1,
                  });
                }}
                activeClassName="active"
                pageClassName="page-item"
                breakClassName="page-item"
                pageLinkClassName="page-link"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextClassName="page-item next-item"
                previousClassName="page-item prev-item"
                containerClassName="pagination react-paginate"
              />
            </div>
          </CardBody>
        </Card>
      </UILoader>

      {/* Abstract Modal  */}
      {abstractModal?.isOpen && (
        <InProgressInTImeAbstractModal
          abstractModal={abstractModal}
          loginUser={loginUser}
          setAbstractModal={setAbstractModal}
        />
      )}

      {/* Filter Canvas Open  */}
      {/* {canvasOpenData?.isOpen && ( */}
      <FilterInProgressAll
        canvasOpenData={canvasOpenData}
        setCanvasOpenData={setCanvasOpenData}
        getFilterData={getFilterData}
      />
      {/* )} */}
    </>
  );
};

export default InProgressInTIme;
