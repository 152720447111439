// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { User, Check, X } from "react-feather";
import { useForm, Controller } from "react-hook-form";

// ** Utils
// import { selectThemeColors } from '@utils'

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
const AddBudgetHeadData = yup.object().shape({
    budget_head: yup
    .string()
    .required("Enter budget head")
    .typeError(""),
    sort_order: yup
    .string()
    .required("Enter Sort Order")
    .typeError(""),
    ce_role: yup
    .string()
    .required("Enter ce role")
    .typeError(""),
});

const AddBudgetHead = () => {
  // ** States
  const [show, setShow] = useState(false);

  // ** Hooks
  const {
    // reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddBudgetHeadData),
  });
  const ceRoleOptions = [
    { value: "NH", label: "NH" },
    { value: "STATE", label: "STATE" },
    { value: "ALL", label: "ALL" },
  ];
  const onSubmit = (data) => {
    console.log("AddBudgetHead>>", data);
  };
  return (
    <Fragment>
      <Card>
        <CardBody className="text-center">
          <User className="font-large-2 mb-1" />
          <CardTitle tag="h5">Add Budget Head</CardTitle>
          <Button color="primary" onClick={() => setShow(true)}>
            Show
          </Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-css"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">Add Budget Head</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row
              // tag="form"
              className="gy-1 pt-1"
              // onSubmit={handleSubmit(onSubmit)}
            >
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="budget_head">
                  Budget Head
                </Label>
                <Controller
                  control={control}
                  name="budget_head"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="budget_head"
                        // value={field.value}
                        placeholder="Enter Budget Head"
                        invalid={errors.budget_head && true}
                      />
                    );
                  }}
                />
                {errors.budget_head && (
                  <FormFeedback>{errors.budget_head.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="sort_order">
                  Sort Order
                </Label>
                <Controller
                  control={control}
                  name="sort_order"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="sort_order"
                        placeholder="Enter Sort Order"
                        // value={field.value}
                        invalid={errors.sort_order && true}
                      />
                    );
                  }}
                />
                {errors.sort_order && (
                  <FormFeedback>
                    {errors.sort_order.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="ce_role">
                  Ce Role
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="ce_role"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={ceRoleOptions}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      // value={field.value}
                      invalid={errors.ce_role && true}
                    />
                  )}
                />
                {errors.ce_role && (
                  <FormFeedback>{errors.ce_role.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="modal-footer mt-0">
                <Button
                  className="btn btn-outline-dark waves-effect"
                  type="reset"
                  outline
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddBudgetHead;
