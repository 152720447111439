// ** React Imports
import { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import AutoComplete from "@components/autocomplete";
import { toast } from "react-toastify";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Modal,
  Label,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";
import { getErrorMsg } from "../../../common/ErrorMessage";

import { addAgency, updateAgency } from "../../../services/adminDashboard";

const AgencyModal = ({ agencyModal, setAgencyModal, tableData, role }) => {
  const navigate = useNavigate();
  // ** Loacl State
  const [inputValue, setInputValue] = useState(
    agencyModal?.isEdit ? agencyModal?.data?.agency : ""
  );
  const [agencyGujaratiValue, setAgencyGujaratiValue] = useState(
    agencyModal?.isEdit ? agencyModal?.data?.agencygujarati : ""
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputError, setInputError] = useState("");
  const [agencyGujaratiError, setAgencyGujaratiError] = useState("");

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    setInputError("");
  };

  const handleOnChangetwo = (e) => {
    setAgencyGujaratiValue(e.target.value);
    setAgencyGujaratiError("");
  };
  const handleInputFocus = () => {
    setShowSuggestions(true);
  };
  const handleSuggestionItemClick = (selectedSuggestion, filterKey) => {
    console.log("Selected Suggestion:", selectedSuggestion, filterKey);

    const suggestionExists = tableData.some(
      (item) => item[filterKey] === selectedSuggestion[filterKey]
    );

    if (suggestionExists) {
      if (filterKey === "agency") {
        setInputError("Agency Name already exists.");
        setAgencyGujaratiError(""); // Clear the Gujarati error
      } else if (filterKey === "agency_gujarati") {
        setAgencyGujaratiError("Agency Name in Gujarati already exists.");
        setInputError("");
      }
    } else {
      setInputError("");
      setAgencyGujaratiError("");

      if (filterKey === "agency") {
        setInputValue(selectedSuggestion[filterKey]);
      } else if (filterKey === "agency_gujarati") {
        setAgencyGujaratiValue(selectedSuggestion[filterKey]);
      }
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!inputValue) {
      setInputError("Please enter Agency Name in English.");
    }
    if (!agencyGujaratiValue) {
      setAgencyGujaratiError("Please enter Agency Name in Gujarati.");
    }

    if (!inputError && !agencyGujaratiError) {
      const data = {
        agency_english: inputValue,
        agency_gujarati: agencyGujaratiValue,
      };

      const sendData = {
        agency_english: inputValue,
        agency_gujarati: agencyGujaratiValue,
        id: agencyModal?.data?.id,
      };

      if (agencyModal?.isEdit) {
        updateAgency(sendData)
          .then((res) => {
            toast.success(res?.message);
            // Optionally, you can reset the form fields here
            setInputValue("");
            setAgencyGujaratiValue("");
            setInputError("");
            setAgencyGujaratiError("");
            setAgencyModal({ isOpen: false, data: "" });
            navigate("/sa/agency");
          })
          .catch((err) => getErrorMsg(err));
      } else {
        addAgency(data)
          .then((res) => {
            toast.success(res?.message);
            // Optionally, you can reset the form fields here
            setInputValue("");
            setAgencyGujaratiValue("");
            setInputError("");
            setAgencyGujaratiError("");
            setAgencyModal({ isOpen: false, data: "" });
            if (role === "se") {
              navigate("/se/dropdown");
            } else {
              navigate("/sa/agency");
            }
          })
          .catch((err) => getErrorMsg(err));
      }
    }
  };

  // useEffect(() => {
  //   if (agencyModal?.isEdit) {
  //     setInputValue(agencyModal?.data?.agency);
  //     setAgencyGujaratiValue(agencyModal?.data?.agencygujarati);
  //   }
  // }, []);

  return (
    <Fragment>
      <Modal
        isOpen={agencyModal?.isOpen}
        toggle={() => setAgencyModal(!agencyModal?.isOpen)}
        className="modal-dialog-centered modal-css"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => setAgencyModal(!agencyModal?.isOpen)}
        ></ModalHeader>
        <ModalBody className="py-0 ">
          <div className="text-center mb-2 me-1">
            <h1 className="mb-1">
              {agencyModal?.isEdit ? "Edit Agency" : "Add Agency"}
            </h1>
          </div>
          <Row className="gy-1 pt-1">
            <Col xs={12} className="mb-1 mt-0">
              <Label className="form-label" for="name_english">
                Agency Name in English
              </Label>

              <AutoComplete
                suggestions={tableData}
                filterKey="agency"
                className="form-control"
                onChange={handleOnChange}
                onFocus={handleInputFocus}
                showSuggestions={showSuggestions}
                value={inputValue}
                onInputChange={setInputValue}
                onSuggestionItemClick={(selectedSuggestion) =>
                  handleSuggestionItemClick(selectedSuggestion, "agency")
                }
                placeholder="Enter Agency Name in English"
              />
              {inputError && (
                <FormFeedback className="d-block">{inputError}</FormFeedback>
              )}
            </Col>{" "}
            <Col xs={12} className="mb-1 mt-0">
              <Label className="form-label" for="name_gujarati">
                Agency Name in Gujarati
              </Label>

              <AutoComplete
                suggestions={tableData}
                filterKey="agency_gujarati"
                className="form-control gujarati-bg"
                onChange={handleOnChangetwo}
                value={agencyGujaratiValue}
                onInputChange={setAgencyGujaratiValue}
                onSuggestionItemClick={(selectedSuggestion) =>
                  handleSuggestionItemClick(
                    selectedSuggestion,
                    "agency_gujarati"
                  )
                }
                placeholder="Enter Agency Name in Gujarati"
              />

              {agencyGujaratiError && (
                <FormFeedback className="d-block">
                  {agencyGujaratiError}
                </FormFeedback>
              )}
            </Col>
            <Col xs={12} className="modal-footer mt-0">
              <Button
                className="btn btn-outline-dark waves-effect"
                type="reset"
                outline
                onClick={() => setAgencyModal(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                on
                onClick={(e) => onSubmit(e)}
                disabled={!inputValue || !agencyGujaratiValue}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgencyModal;
