// ** Navigation imports
// import apps from "./apps";
import pages from "./pages";
import forms from "./forms";
import tables from "./tables";
import others from "./others";
import charts from "./charts";
import dashboards from "./dashboards";
import uiElements from "./ui-elements";

// ** R&B
import superAdmin from "./superAdmin";
import SERoutes from "./SENavigation";
import EENavigation from "./EENavigation";
import CENavigation from "./CENavigation";
import reportsNavigation from "./reportsNavigation";

// ** Merge & Export
export default [
  ...superAdmin,
  ...SERoutes,
  ...EENavigation,
  ...CENavigation,
  ...reportsNavigation,
  ...dashboards,
  ...pages,
  ...uiElements,
  ...forms,
  ...tables,
  ...charts,
  ...others,
];
