import { lazy } from "react";
import { ROLE } from "../../common/utils";
import WorkDetailsTabs from "../../views/Reports/WorkDetails/WorkDetailsTabs";
import HeadwiseExpenditureAbstract from "../../views/Reports/ExpenditureReview/HeadwiseExpenditureAbstract/HeadwiseExpenditureAbstract";

// import TimeOverRun from "../../views/Reports/ProjectReview/InProgressStopped/TimeOverRun/TimeOverRunTable";
import Stopped from "../../views/Reports/ProjectReview/InProgressStopped/Stopped/Stopped";
import PreConstructionActivityWorks from "../../views/Reports/ProjectReview/InProgressStopped/PreConstructionActivityWorks/PreConstructionActivityWorks";
import TimeOverRun12Months from "../../views/Reports/ProjectReview/InProgressStopped/TimeOverRun12Months/TimeOverRun12Months";
import PhysicallyCompleted from "../../views/Reports/ProjectReview/Completed/PhysicallyCompleted/PhysicallyCompleted";
import Completed from "../../views/Reports/ProjectReview/Completed/Completed/Completed";
import OldCompleted from "../../views/Reports/ProjectReview/Completed/OldCompleted/OldCompleted";
import DroppedWork from "../../views/Reports/ProjectReview/Completed/DroppedWork/DroppedWork";

import TimeOverRunP2 from "../../views/Reports/ExpenditureReview/TimeOverRunP2/TimeOverRunP2";
import InTimeP3 from "../../views/Reports/ExpenditureReview/InTimeP3/InTimeP3";

import PhysicallyCompletedWorksP4 from "../../views/Reports/ExpenditureReview/PhysicallyCompletedWorksP4/PhysicallyCompletedWorksP4";
import CompletedDuringYearP5 from "../../views/Reports/ExpenditureReview/CompletedDuringYearP5/CompletedDuringYearP5";
import StoppedP6 from "../../views/Reports/ExpenditureReview/StoppedP6/StoppedP6";

import PragatiG from "../../views/Reports/Others/PragatiG/PragatiG";
import MpWise from "../../views/Reports/Others/MpWise/MpWise";
import MlaWise from "../../views/Reports/Others/MlaWise/MlaWise";
import YearWise from "../../views/Reports/Others/YearWise/YearWise";

import UtilityShifting from "../../views/Reports/Others/UtilityShifting/UtilityShifting";
import LandAcquisition from "../../views/Reports/Others/LandAcquisition/LandAcquisition";

import AgencyWise from "../../views/Reports/Others/AgencyWise/AgencyWise";
import InProgressInTIme from "../../views/Reports/ProjectReview/InProgressStopped/InProgressInTIme/InProgressInTIme";

const Reports = lazy(() => import("../../views/Reports/Reports"));
const DepositCallLetter = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/DepositCallLetter/DepositCallLetter"
  )
);
const NotStartedAll = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/NotStartedAll/NotStartedAll"
  )
);
const PaaReceivedAaNotReceived = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/PaaReceivedAaNotReceived/PaaReceivedAaNotReceived"
  )
);
const PreExecutionMonths = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/PreExecutionMonths/PreExecutionMonths"
  )
);
const DtpLevel = lazy(() =>
  import("../../views/Reports/ProjectReview/NotStarted/dtpLevel/DtpLevel")
);
const TenderOnline = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/TenderOnline/TenderOnline"
  )
);
const TenderUnderApproval = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/NotStarted/TenderUnderApproval/TenderUnderApproval"
  )
);
const Allprojects = lazy(() =>
  import("../../views/SERole/Allprojects/Allprojects")
);

const TSLevelReport = lazy(() =>
  import("../../views/Reports/ProjectReview/NotStarted/TSLevel/TSLevel")
);

const InProgressAll = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/InProgressStopped/InProgressAll/InProgressAll"
  )
);

const InProgressTLFiftyPhyTwentyFive = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/InProgressStopped/inProgressTLFiftyPhyTwentyFive/index"
  )
);

const TimeOverRunTable = lazy(() =>
  import(
    "../../views/Reports/ProjectReview/InProgressStopped/TimeOverRun/TimeOverRunTable"
  )
);

const NotStartedWorks = lazy(() =>
  import(
    "../../views/Reports/ExpenditureReview/NotStartedWorks/NotStartedWorks"
  )
);

const ReportsRouters = [
  {
    path: "/allproject",
    element: <Allprojects />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reports",
    element: <Reports />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_paa_received_aa_not_received",
    element: <PaaReceivedAaNotReceived />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report-not-started-all",
    element: <NotStartedAll />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_in_pre_execution",
    element: <PreExecutionMonths />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_dtp_level",
    element: <DtpLevel />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_tender_online",
    element: <TenderOnline />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_tender_under_approval",
    element: <TenderUnderApproval />,
    roles: [ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_deposit_call_letter_level",
    element: <DepositCallLetter />,
    roles: [ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/reason_ts_level",
    element: <TSLevelReport />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_work_in_progress",
    element: <InProgressAll />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_in_progress_in_time_limit_greater_than_50_progress_0_25",
    element: <InProgressTLFiftyPhyTwentyFive />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_work_in_progress_in_time",
    element: <InProgressInTIme />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_time_over_run_work",
    element: <TimeOverRunTable />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  //

  {
    path: "/update-work/:id",
    element: <WorkDetailsTabs />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/headwise_expenditure_abstract",
    element: <HeadwiseExpenditureAbstract />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_works_stopped",
    element: <Stopped />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_pre_cons_work_activity",
    element: <PreConstructionActivityWorks />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_time_over_run_greater_than_twelve_months",
    element: <TimeOverRun12Months />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/physically_completed_report",
    element: <PhysicallyCompleted />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_works_completed",
    element: <Completed />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_works_completed_old",
    element: <OldCompleted />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_dropped_work",
    element: <DroppedWork />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  // *  EXPENDITURE REVIEW Route Start
  {
    path: "/report_not_started_p_1",
    element: <NotStartedWorks />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_time_over_run_p_2",
    element: <TimeOverRunP2 />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_in_time_p_3",
    element: <InTimeP3 />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_physically_completed_p_4",
    element: <PhysicallyCompletedWorksP4 />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/physically_completed_in_current_year_p_5",
    element: <CompletedDuringYearP5 />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_stopped_p_6",
    element: <StoppedP6 />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  // *  EXPENDITURE REVIEW  End

  {
    path: "/report_pragati_g",
    element: <PragatiG />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_utility_shifting",
    element: <UtilityShifting />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_mp_wise",
    element: <MpWise />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_mla_wise",
    element: <MlaWise />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
  {
    path: "/report_year_wise",
    element: <YearWise />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_agency_wise",
    element: <AgencyWise />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },

  {
    path: "/report_land_acquisition",
    element: <LandAcquisition />,
    roles: [ROLE.SECRETORY, ROLE.CE, ROLE.SE, ROLE.EE],
  },
];

//

export default ReportsRouters;
