/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import {
  getAgencyList,
  getTenderTypeList,
} from "../../../../services/adminDashboard";
import {
  updateTenderToWorkOrderStageExtendedWork,
  updateTenderToWorkOrderStageWork,
} from "../../../../services/reportsServices";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

// ** Validation
const TenderToWorkOrderStageData = yup.object().shape({
  // tendertype_id: yup.string().required("Select Tender Type").nullable(),
  tender_online_invitation_date: yup
    .string()
    .when("tendertype_id", {
      is: (value) => value !== null,
      then: yup
        .string()
        .required("Select Tender Online Invitation Date")
        .nullable(),
    })
    .nullable(),
  tender_opening_date: yup
    .string()
    .when("tender_online_invitation_date", {
      is: (value) => value !== null,
      then: yup.string().required("Select Tender Opening Date").nullable(),
    })
    .nullable(),
  tender_re_invitation_date: yup
    .string()
    .when("tender_re_invitation_required", {
      is: "yes",
      then: yup
        .string()
        .required("Select Tender Re-Invitation Date")
        .nullable(),
    })
    .nullable(),
  tender_re_invitation_opening_date: yup
    .string()
    // .required("Select Re-Invited Tender Opening Date")
    .when("tender_re_invitation_required", {
      is: "yes",
      then: yup
        .string()
        .required("Select Re-Invited Tender Opening Date")
        .nullable(),
    })
    .nullable(),
  technical_bid_opening_date: yup
    .string()
    .when("p_q", {
      is: "yes",
      then: yup
        .string()
        .required("Select Technical Bid Opening Date")
        .nullable(),
    })
    .nullable(),
  tender_price_bid_opening_date: yup
    .string()
    .when("p_q", {
      is: "yes",
      then: yup
        .string()
        .required("Select Tender Price Bid Opening Date")
        .nullable(),
    })
    .nullable(),
  tender_app_amount: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
      "Tender App. Amount should not exceed 10 digits"
    )
    .required("Enter valid Tender App. Amount")
    .nullable(),
  per_above_below: yup
    .string()
    .required("Enter Above / Below in Percentage")
    .nullable(),
  agency_english_id: yup
    .string()
    .required("Agency (English)")
    .typeError("")
    .nullable(),
  agency_gujarati_id: yup.string().typeError("").nullable(),
  time_limit: yup
    .string()
    .matches(/^[0-9]*$/, "Enter valid Time Limit (Months)"),
});

const TenderToWorkOrderStage = () => {
  const workData = useSelector((state) => state?.getWork);
  console.log("workData>>>>", workData?.data?.dtp_amount);
  const currentUserRole = getUserData()?.role;
  // ** useState
  const [tenderOnlineInvitationDate, setTenderOnlineInvitationDate] =
    useState();
  const [tenderOpeningDate, setTenderOpeningDate] = useState();
  const [tenderReInvitationDate, setTenderReInvitationDate] = useState();
  const [reInvitedTenderOpeningDate, setReInvitedTenderOpeningDate] =
    useState();
  const [technicalBidOpeningDate, setTechnicalBidOpeningDate] = useState();
  const [tenderPriceBidOpeningDate, setTenderPriceBidOpeningDate] = useState();
  // const [tenderBidValidityTillDate, SetTenderBidValidityTillDate] = useState();
  // console.log("tenderBidValidityTillDate: ", tenderBidValidityTillDate);
  const [tenderAppDate, setTenderAppDate] = useState();
  const [depositCallLetterIssueDate, setDepositCallLetterIssueDate] =
    useState();
  const [workOrderDate, setWorkOrderDate] = useState();
  const [likelyDateofCompletion, setLikelyDateofCompletion] = useState();
  const [extendedTenderBidValidityDate, setExtendedTenderBidValidityDate] =
    useState();
  const [tenderTypeDropDownOption, setTenderTypeDropDownOption] = useState();
  const [agencyDropDownOption, setAgencyDropDownOption] = useState();

  useEffect(() => {
    //** Get Tender-Type DropDown Option
    getTenderTypeList()
      .then((res) => {
        setTenderTypeDropDownOption(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //** Get Agency DropDown Option
    getAgencyList()
      .then((res) => {
        setAgencyDropDownOption(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [workData]);

  // ** Hooks
  const {
    // reset,
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(TenderToWorkOrderStageData),
  });
  console.log("errors>> ", errors);

  console.log(
    "watch_value_tender_opening_date",
    watch("tender_opening_date"),
    workData?.data?.tender_opening_date
  );

  // ** START DATE
  // Tender Online Invitation Date
  const handleTenderOnlineInvitationDateChange = (date) => {
    if (date === null) {
      setValue("tender_online_invitation_date", null);
      setTenderOnlineInvitationDate(null);
    } else {
      setValue(
        "tender_online_invitation_date",
        moment(date).format("YYYY-MM-DD")
      );
      setTenderOnlineInvitationDate(date);
      setError("tender_online_invitation_date", "");
    }
  };
  // Tender Opening Date (Preliminary Stage)
  const handleTenderOpeningDateChange = (date) => {
    if (date === null) {
      setValue("tender_opening_date", null);
      setTenderOpeningDate(null);
      setError("tender_opening_date", {
        type: "manual",
        message: "Select Tender Opening Date",
      });
    } else if (
      moment(tenderOnlineInvitationDate).isAfter(date, "YYYY-MM-DD") === true
    ) {
      setValue("tender_opening_date", null);
      setTenderOpeningDate(date);
      setError("tender_opening_date", {
        type: "manual",
        message:
          "Tender opening date should be after Tender Online invitation date",
      });
    } else {
      setValue("tender_opening_date", moment(date).format("YYYY-MM-DD"));
      setTenderOpeningDate(date);
      setError("tender_opening_date", "");
    }
  };

  // Tender Re-Invitation Date
  const handleTenderReInvitationDateChange = (date) => {
    if (date === null) {
      setValue("tender_re_invitation_date", null);
      setTenderReInvitationDate(null);
      setError("tender_re_invitation_date", {
        type: "manual",
        message: "Select Tender Re-Invitation Date",
      });
    } else if (moment(tenderOpeningDate).isAfter(date, "YYYY-MM-DD") === true) {
      setValue("tender_re_invitation_date", null);
      setTenderReInvitationDate(date);
      setError("tender_re_invitation_date", {
        type: "manual",
        message:
          "Tender Re-Invitation Date should  be after Tender Opening Date",
      });
    } else {
      setValue("tender_re_invitation_date", moment(date).format("YYYY-MM-DD"));
      setTenderReInvitationDate(date);
      setError("tender_re_invitation_date", "");
    }

    // if (
    //   watch("tender_re_invitation_required") === "yes" ||
    //   tenderReInvitationDate !== null
    // ) {
    //   setError("tender_re_invitation_date", {
    //     type: "manual",
    //     message: "Select Tender Re-Invitation Date",
    //   });
    // }

    // if (!tenderReInvitationDate) {
    //   setError("tender_re_invitation_date", "");
    // }
  };

  //  Re - Invited Tender Opening Date
  const handleReInvitedTenderOpeningDateChange = (date) => {
    if (date === null) {
      setValue("tender_re_invitation_opening_date", null);
      setReInvitedTenderOpeningDate(null);
      setError("tender_re_invitation_opening_date", {
        type: "manual",
        message: "Select Re-Invited Tender Opening Date",
      });
    } else if (
      moment(tenderReInvitationDate).isAfter(date, "YYYY-MM-DD") === true
    ) {
      setValue("tender_re_invitation_opening_date", null);
      setReInvitedTenderOpeningDate(date);
      setError("tender_re_invitation_opening_date", {
        type: "manual",
        message:
          "Re-Invited Tender Opening Date should be after Tender Re-Invitation Date",
      });
    } else {
      setValue(
        "tender_re_invitation_opening_date",
        moment(date).format("YYYY-MM-DD")
      );
      setReInvitedTenderOpeningDate(date);
      setError("tender_re_invitation_opening_date", "");
    }
  };
  // Technical Bid Opening Date
  const handleTechnicalBidOpeningDateChange = (date) => {
    if (date === null) {
      setValue("technical_bid_opening_date", null);
      setTechnicalBidOpeningDate(null);
      setError("technical_bid_opening_date", {
        type: "manual",
        message: "Select Technical Bid Opening Date",
      });
    } else if (
      moment(reInvitedTenderOpeningDate).isAfter(date, "YYYY-MM-DD") === true
    ) {
      setValue("technical_bid_opening_date", null);
      setTechnicalBidOpeningDate(date);
      setError("technical_bid_opening_date", {
        type: "manual",
        message:
          "Technical Bid opening date should be after the Re-Invited Tender Opening Date",
      });
    } else {
      setValue("technical_bid_opening_date", moment(date).format("YYYY-MM-DD"));
      setTechnicalBidOpeningDate(date);
      setError("technical_bid_opening_date", "");
    }

    // if (watch("p_q") === "yes") {
    //   const newDate = moment(technicalBidOpeningDate).add(90, "day");
    //   setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
    //   // SetTenderBidValidityTillDate(newDate.format("DD-MM-YYYY"));
    // } else {
    //   const newDate = moment(tenderOpeningDate).add(90, "day");
    //   setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
    // }

    // if (workData?.data?.dtp_amount <= 10) {
    //   setValue(
    //     "tender_bid_validity_date",
    //     moment(date).add(90, "days").format("YYYY-MM-DD")
    //   );
    //   SetTenderBidValidityTillDate(
    //     moment(date).add(90, "days").format("YYYY-MM-DD")
    //   );
    // } else {
    //   setValue(
    //     "tender_bid_validity_date",
    //     moment(date).add(120, "days").format("YYYY-MM-DD")
    //   );
    //   SetTenderBidValidityTillDate(
    //     moment(date).add(120, "days").format("YYYY-MM-DD")
    //   );
    // }
  };
  // Tender Price Bid Opening Date
  const handleTenderPriceBidOpeningDateChange = (date) => {
    if (date === null) {
      setValue("tender_price_bid_opening_date", null);
      setTenderPriceBidOpeningDate(null);
      setError("tender_price_bid_opening_date", {
        type: "manual",
        message: "Select Tender Price Bid Opening Date",
      });
    } else if (
      moment(tenderPriceBidOpeningDate).isAfter(date, "YYYY-MM-DD") === true
    ) {
      setValue("tender_price_bid_opening_date", null);
      setTenderPriceBidOpeningDate(date);
      setError("tender_price_bid_opening_date", {
        type: "manual",
        message:
          "Tender Price Bid Opening Date should be after the Technical Bid Opening Date",
      });
    } else {
      setValue(
        "tender_price_bid_opening_date",
        moment(date).format("YYYY-MM-DD")
      );
      setTenderPriceBidOpeningDate(date);
      setError("tender_price_bid_opening_date", "");
    }
  };
  // Tender Bid Validity Till Date
  // const handleTenderBidValidityTillDateChange = () => {
  //   // tenderPriceBidOpeningDate
  //   // const datePriceBid = moment(watch("work_order_date"))
  //   //   .add(e?.target?.value, "M")
  //   //   .subtract(1, "days")
  //   //   .format("YYYY-MM-DD");
  //   // if (workData?.data?.dtp_amount <= 10) {
  //   //   setValue(
  //   //     "tender_bid_validity_date",
  //   //     moment(watch("technical_bid_opening_date"))
  //   //       .add(90, "days")
  //   //       .format("YYYY-MM-DD")
  //   //   );
  //   // } else {
  //   //   setValue(
  //   //     "tender_bid_validity_date",
  //   //     moment(watch("technical_bid_opening_date"))
  //   //       .add(120, "days")
  //   //       .format("YYYY-MM-DD")
  //   //   );
  //   // }
  //   SetTenderBidValidityTillDate(date);
  //   setValue("tender_bid_validity_date", moment(date).format("YYYY-MM-DD"));
  //   setError("tender_bid_validity_date", "");
  // };
  // Tender App. Date
  const handleTenderAppDateChange = (date) => {
    setTenderAppDate(date);
    setValue("tender_app_date", moment(date).format("YYYY-MM-DD"));
    setError("tender_app_date", "");
    if (date === null) {
      setValue("tender_app_date", null);
      setTenderAppDate(date);
      setError("tender_app_date", {
        type: "manual",
        message: "Select Tender App. Date",
      });
    }
  };
  // Deposit Call letter issue Date
  const handleDepositCallLetterIssueDateChange = (date) => {
    setDepositCallLetterIssueDate(date);
    setValue(
      "deposit_call_letter_issue_date",
      moment(date).format("YYYY-MM-DD")
    );
    setError("deposit_call_letter_issue_date", "");
  };
  // Work Order Date
  const handleWorkOrderDateChange = (date) => {
    setWorkOrderDate(date);
    setValue("work_order_date", moment(date).format("YYYY-MM-DD"));
    setError("work_order_date", "");
  };
  // Likely Date of Completion
  const handleLikelyDateofCompletionChange = (date) => {
    setLikelyDateofCompletion(date);
    setValue("likely_date_of_completion", moment(date).format("YYYY-MM-DD"));
    setError("likely_date_of_completion", "");
  };
  //
  const handleExtendedTenderBidValidityDateChange = (date) => {
    setExtendedTenderBidValidityDate(date);
    setValue("extended_date", moment(date).format("YYYY-MM-DD"));
    setError("extended_date", "");
  };
  // ** END DATE

  // ** Start Radio Button
  // Tender Re-Invitation Required?
  const {
    ref: tender_re_invitation_required_radio,
    ...tender_re_invitation_required_radiotest
  } = register("tender_re_invitation_required", {
    required: true,
  });
  // P. Q.
  const { ref: p_q_radio, ...p_q_radiotest } = register("p_q", {
    required: true,
  });

  console.log(watch("p_q"), "p_q");

  // % Above / Below
  const { ref: above_below_radio, ...above_below_radiotest } = register(
    "above_below",
    {
      required: true,
    }
  );
  // Tender validity Extended OR Not?
  const { ref: extended_required_radio, ...extended_required_radiotest } =
    register("extended_required", {
      required: true,
    });
  // ** END Radio Button

  // ** Time Limit (Months) count onChange getValue in Date of Completion As per W.O.
  const handelTimeLimitcalculate = (e) => {
    const futureMonth = moment(watch("work_order_date"))
      .add(e?.target?.value, "M")
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    if (e.target.value === "") {
      setValue("date_of_completion_as_per_wo", "");
    } else {
      setValue(
        "date_of_completion_as_per_wo",
        moment(futureMonth).format("DD-MM-YYYY")
      );
    }
  };

  // ** onTenderYesNoChange
  // const onTenderYesNoChange = (e) => {
  //   if (e.target.value === "no") {
  //     setTenderReInvitationDate(null);
  //     setValue("tender_re_invitation_date", null);
  //     setReInvitedTenderOpeningDate(null);
  //     setValue("tender_re_invitation_required", null);
  //   }
  // };

  // ** Update-API
  const onSubmit = (data) => {
    const sendData = {
      package_id: workData?.data?.package_id,
      work_id: workData?.data?.id,
      tender_type: data?.tendertype_id,
      tender_online_invitation_date:
        data?.tender_online_invitation_date === undefined
          ? null
          : data?.tender_online_invitation_date,
      tender_opening_date:
        data?.tender_opening_date === undefined
          ? null
          : data?.tender_opening_date,
      tender_re_invitation_required: data?.tender_re_invitation_required,
      tender_re_invitation_opening_date:
        data?.tender_re_invitation_date === undefined
          ? null
          : data?.tender_re_invitation_date,
      tender_re_invitation_closing_date:
        data?.tender_re_invitation_opening_date === undefined
          ? null
          : data?.tender_re_invitation_opening_date,
      p_q: data?.p_q,
      technical_bid_opening_date:
        data?.technical_bid_opening_date === undefined
          ? null
          : data?.technical_bid_opening_date,
      tender_price_bid_opening_date:
        data?.tender_price_bid_opening_date === null
          ? null
          : data?.tender_price_bid_opening_date,
      tender_bid_validity_date:
        data?.tender_bid_validity_date === undefined
          ? null
          : data?.tender_bid_validity_date,
      tender_app_amount: data?.tender_app_amount,
      tender_app_date:
        data?.tender_app_date === undefined ? null : data?.tender_app_date,
      above_below: data?.above_below,
      per_above_below: data?.per_above_below,
      agency_english_id: parseInt(data?.agency_english_id),
      agency_gujarati_id: parseInt(data?.agency_gujarati_id),
      deposit_call_letter_issue_date:
        data?.deposit_call_letter_issue_date === undefined
          ? null
          : data?.deposit_call_letter_issue_date,
      aggrement_no: data?.aggrement_no,
      work_order_date:
        data?.work_order_date === undefined ? null : data?.work_order_date,
      time_limit: data?.time_limit,
      date_of_completion_as_per_wo:
        data?.date_of_completion_as_per_wo === undefined
          ? null
          : data?.date_of_completion_as_per_wo,
      likely_date_of_completion:
        data?.likely_date_of_completion === undefined
          ? null
          : data?.likely_date_of_completion,
    };
    console.log("sendData>>", sendData);
    // Update API tender to work order stage tab
    updateTenderToWorkOrderStageWork(sendData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };

  // ** Update-API Tender validity Extende
  const onRadioSubmit = (data) => {
    const sendData = {
      work_id: workData?.data?.id,
      extended_required: data?.extended_required,
      extended_date:
        data?.extended_required === "yes" ? data?.extended_date : null,
    };

    // Update API tender to work order stage extend
    updateTenderToWorkOrderStageExtendedWork(sendData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };
  // ** Set-Value
  useEffect(() => {
    if (workData?.data) {
      reset({
        tender_re_invitation_required:
          workData?.data?.tender_re_invitation_required === null &&
          workData?.data?.tender_opening_date === null
            ? "no"
            : workData?.data?.tender_re_invitation_required,
        p_q: workData?.data?.p_q,
        tender_app_amount: workData?.data?.tender_app_amount,
        above_below: workData?.data?.above_below,
        per_above_below: workData?.data?.per_above_below,
        aggrement_no: workData?.data?.aggrement_no,
        time_limit: workData?.data?.time_limit,
        date_of_completion_as_per_wo: workData?.data
          ?.date_of_completion_as_per_wo
          ? moment(workData?.data?.date_of_completion_as_per_wo).format(
              "DD-MM-YYYY"
            )
          : "",
        extended_required: workData?.data?.extended_required,
        tendertype_id: workData?.data?.tendertype_id,
        agency_english_id: workData?.data?.agency_english_id,
        agency_gujarati_id: workData?.data?.agency_gujarati_id,
      });
    }
    // Tender Online Invitation Date
    if (workData?.data?.tender_online_invitation_date === null) {
      setTenderOnlineInvitationDate(null);
      setValue("tender_online_invitation_date", null);
    } else if (workData?.data?.tender_online_invitation_date) {
      setTenderOnlineInvitationDate(
        new Date(workData?.data?.tender_online_invitation_date)
      );
      setValue(
        "tender_online_invitation_date",
        moment(workData?.data?.tender_online_invitation_date).format(
          "YYYY-MM-DD"
        )
      );
    }
    // Tender Opening Date (Preliminary Stage)
    if (workData?.data?.tender_opening_date) {
      setTenderOpeningDate(new Date(workData?.data?.tender_opening_date));
      setValue(
        "tender_opening_date",
        moment(workData?.data?.tender_opening_date).format("YYYY-MM-DD")
      );
    } else if (workData?.data?.tender_opening_date === null) {
      setTenderOpeningDate(null);
      setValue("tender_opening_date", null);
    }
    // Tender Re-Invitation Date
    if (workData?.data?.tender_re_invitation_opening_date) {
      setTenderReInvitationDate(
        new Date(workData?.data?.tender_re_invitation_opening_date)
      );
      setValue(
        "tender_re_invitation_date",
        moment(workData?.data?.tender_re_invitation_opening_date).format(
          "YYYY-MM-DD"
        )
      );
    } else if (workData?.data?.tender_re_invitation_opening_date === null) {
      setTenderReInvitationDate(null);
      setValue("tender_re_invitation_opening_date", null);
    }
    //  Re - Invited Tender Opening Date
    if (workData?.data?.tender_re_invitation_closing_date) {
      setReInvitedTenderOpeningDate(
        new Date(workData?.data?.tender_re_invitation_closing_date)
      );
      setValue(
        "tender_re_invitation_opening_date",
        moment(workData?.data?.tender_re_invitation_closing_date).format(
          "YYYY-MM-DD"
        )
      );
    } else if (workData?.data?.tender_re_invitation_closing_date === null) {
      setReInvitedTenderOpeningDate(null);
      setValue("tender_re_invitation_closing_date", null);
    }

    // Technical Bid Opening Date
    if (workData?.data?.technical_bid_opening_date) {
      setTechnicalBidOpeningDate(
        new Date(workData?.data?.technical_bid_opening_date)
      );
      setValue(
        "technical_bid_opening_date",
        moment(workData?.data?.technical_bid_opening_date).format("YYYY-MM-DD")
      );
    } else if (workData?.data?.technical_bid_opening_date === null) {
      setTechnicalBidOpeningDate(null);
      setValue("technical_bid_opening_date", null);
    }
    // Tender Price Bid Opening Date
    if (workData?.data?.tender_price_bid_opening_date) {
      setTenderPriceBidOpeningDate(
        new Date(workData?.data?.tender_price_bid_opening_date)
      );
      setValue(
        "tender_price_bid_opening_date",
        moment(workData?.data?.tender_price_bid_opening_date).format(
          "YYYY-MM-DD"
        )
      );
    } else if (workData?.data?.tender_price_bid_opening_date === null) {
      setTenderPriceBidOpeningDate(null);
      setValue("tender_price_bid_opening_date", null);
    }
    // Tender Bid Validity Till Date
    if (workData?.data?.tender_bid_validity_date) {
      // SetTenderBidValidityTillDate(
      //   new Date(workData?.data?.tender_bid_validity_date)
      // );
      setValue(
        "tender_bid_validity_date",
        moment(workData?.data?.tender_bid_validity_date).format("DD-MM-YYYY")
      );
    }
    // Tender App. Date
    if (workData?.data?.tender_app_date) {
      setTenderAppDate(new Date(workData?.data?.tender_app_date));
      setValue(
        "tender_app_date",
        moment(workData?.data?.tender_app_date).format("YYYY-MM-DD")
      );
    }
    // Deposit Call letter issue Date
    if (workData?.data?.deposit_call_letter_issue_date) {
      setDepositCallLetterIssueDate(
        new Date(workData?.data?.deposit_call_letter_issue_date)
      );
      setValue(
        "deposit_call_letter_issue_date",
        moment(workData?.data?.deposit_call_letter_issue_date).format(
          "YYYY-MM-DD"
        )
      );
    }
    // Work Order Date
    if (workData?.data?.work_order_date) {
      setWorkOrderDate(new Date(workData?.data?.work_order_date));
      setValue(
        "work_order_date",
        moment(workData?.data?.work_order_date).format("YYYY-MM-DD")
      );
    }
    // Likely Date of Completion
    if (workData?.data?.likely_date_of_completion) {
      setLikelyDateofCompletion(
        new Date(workData?.data?.likely_date_of_completion)
      );
      setValue(
        "likely_date_of_completion",
        moment(workData?.data?.likely_date_of_completion).format("YYYY-MM-DD")
      );
    }
    // Tender validity Extended OR Not?
    if (workData?.data?.extended_date) {
      setExtendedTenderBidValidityDate(new Date(workData?.data?.extended_date));
      setValue(
        "extended_date",
        moment(workData?.data?.extended_date).format("YYYY-MM-DD")
      );
    }
  }, [workData]);

  useEffect(() => {
    console.log(workData?.data?.dtp_amount < 10, watch("p_q"), "ne3w");

    if (workData?.data?.dtp_amount < 100000) {
      // DTP amount is 10 or less (Lacs)
      if (watch("p_q") === "yes") {
        console.log(
          technicalBidOpeningDate,
          "technicalBidOpeningDate",
          moment(technicalBidOpeningDate).format("DD-MM-YYYY")
        );
        const newDate = moment(technicalBidOpeningDate).add(90, "days");
        setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
      }
      if (watch("p_q") === "no") {
        console.log(
          tenderOpeningDate,
          "tenderOpeningDate",
          moment(tenderOpeningDate).format("DD-MM-YYYY")
        );
        const newDate = moment(tenderOpeningDate).add(90, "days");

        setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
      }
    } else {
      // DTP amount is more than 10 (Lacs)
      if (watch("p_q") === "yes") {
        const newDate = moment(technicalBidOpeningDate).add(120, "days");
        setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
      }
      if (watch("p_q") === "no") {
        const newDate = moment(tenderOpeningDate).add(120, "days");
        setValue("tender_bid_validity_date", newDate.format("DD-MM-YYYY"));
      }
    }
  }, [watch("p_q"), technicalBidOpeningDate, tenderOpeningDate]);

  const isFieldsetDisabled =
    workData?.data?.basic_details_of_work_id === null ||
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.paa_aa_id === null) ||
    (workData?.data?.basic_details_of_work_id !== null &&
      workData?.data?.paa_aa_id !== null &&
      workData?.data?.ts_dtp_id === null) ||
    currentUserRole === ROLE.CE;

  return (
    <>
      <Card>
        <CardBody className="basic_tab">
          <Form>
            <fieldset disabled={isFieldsetDisabled}>
              <Row className="gy-1">
                <>
                  {workData?.data?.basic_details_of_work_id === null &&
                    workData?.data?.paa_aa_id === null &&
                    workData?.data?.ts_dtp_id === null && (
                      <span className="error help-inline m-1 text-center">
                        Please fill up Basic details & PAA_AA & TS-DTP Tab
                      </span>
                    )}
                  {workData?.data?.basic_details_of_work_id !== null &&
                    workData?.data?.paa_aa_id === null &&
                    workData?.data?.ts_dtp_id === null && (
                      <span className="error help-inline m-1 text-center">
                        Please fill up PAA_AA & TS-DTP Tab
                      </span>
                    )}
                  {workData?.data?.basic_details_of_work_id !== null &&
                    workData?.data?.paa_aa_id !== null &&
                    workData?.data?.ts_dtp_id === null && (
                      <span className="error help-inline m-1 text-center">
                        Please fill up TS-DTP Tab
                      </span>
                    )}
                </>
                <Col md={4} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="tendertype_id">
                    Tender Type
                  </Label>
                  <Controller
                    id="tendertype_id"
                    control={control}
                    name="tendertype_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={tenderTypeDropDownOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.type}
                        classNamePrefix="select"
                        className="react-select"
                        value={tenderTypeDropDownOption?.find(
                          (item) => item?.id === watch("tendertype_id")
                        )}
                        onChange={(e) => {
                          field.onChange(e?.id);
                        }}
                        isDisabled={isFieldsetDisabled}
                      />
                    )}
                  />
                  {errors.tendertype_id && (
                    <FormFeedback>{errors.tendertype_id.message}</FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label
                    className="form-label"
                    for="tender_online_invitation_date"
                  >
                    Tender Online Invitation Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_online_invitation_date"
                    name="tender_online_invitation_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={!watch("tendertype_id")}
                        selected={tenderOnlineInvitationDate}
                        onChange={handleTenderOnlineInvitationDateChange}
                      />
                    )}
                  />
                  {errors.tender_online_invitation_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_online_invitation_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label className="form-label" for="tender_opening_date">
                    Tender Opening Date (Preliminary Stage)
                  </Label>
                  <Controller
                    control={control}
                    id="tender_opening_date"
                    name="tender_opening_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={tenderOpeningDate}
                        disabled={!watch("tender_online_invitation_date")}
                        onChange={handleTenderOpeningDateChange}
                      />
                    )}
                  />
                  {errors.tender_opening_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_opening_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mt-0">
                  <Label
                    className="form-label"
                    for="tender_re_invitation_required"
                  >
                    Tender Re-Invitation Required?
                  </Label>
                  <div>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={tender_re_invitation_required_radio}
                          {...tender_re_invitation_required_radiotest}
                          type="radio"
                          name="tender_re_invitation_required"
                          id="tender_re_invitation_required_yes"
                          value="yes"
                          disabled={!watch("tender_opening_date")}
                          className="form-check-input"
                          onChange={(e) => {
                            tender_re_invitation_required_radiotest.onChange(e);
                            // console.log("e:>>>>>>>", e.target.value);

                            if (
                              watch("tender_re_invitation_required") === "yes"
                            ) {
                              setValue("p_q", null);
                            }
                          }}
                        />
                        <Label
                          for="tender_re_invitation_required_yes"
                          className="ms-50 form-check-label"
                        >
                          Yes
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={tender_re_invitation_required_radio}
                          {...tender_re_invitation_required_radiotest}
                          type="radio"
                          name="tender_re_invitation_required"
                          id="tender_re_invitation_required_no"
                          value="no"
                          className="form-check-input"
                          disabled={!watch("tender_opening_date")}
                          onChange={(e) => {
                            tender_re_invitation_required_radiotest.onChange(e);

                            setTenderReInvitationDate(null);
                            setReInvitedTenderOpeningDate(null);
                            // console.log("e:>>>>>>>", e.target.value);
                            // onTenderYesNoChange(e);
                          }}
                        />
                        <Label
                          for="tender_re_invitation_required_no"
                          className="ms-50 form-check-label"
                        >
                          No
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Button
                          disabled={!watch("tender_opening_date")}
                          onClick={() => {
                            setValue("tender_re_invitation_required", null);
                            setTenderReInvitationDate(null);
                            setReInvitedTenderOpeningDate(null);
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label className="form-label" for="tender_re_invitation_date">
                    Tender Re-Invitation Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_re_invitation_date"
                    name="tender_re_invitation_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={
                          watch("tender_re_invitation_required") === "no" ||
                          watch("tender_re_invitation_required") === null
                        }
                        selected={tenderReInvitationDate}
                        onChange={handleTenderReInvitationDateChange}
                      />
                    )}
                  />
                  {errors.tender_re_invitation_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_re_invitation_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={4} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label
                    className="form-label"
                    for="tender_re_invitation_opening_date"
                  >
                    Re - Invited Tender Opening Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_re_invitation_opening_date"
                    name="tender_re_invitation_opening_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={
                          watch("tender_re_invitation_required") === "no" ||
                          watch("tender_re_invitation_required") === null
                        }
                        selected={reInvitedTenderOpeningDate}
                        onChange={handleReInvitedTenderOpeningDateChange}
                      />
                    )}
                  />
                  {errors.tender_re_invitation_opening_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_re_invitation_opening_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <hr className="mt-0" />
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="p_q">
                    P.Q.
                  </Label>
                  <div>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={p_q_radio}
                          {...p_q_radiotest}
                          type="radio"
                          name="p_q"
                          id="p_q_yes"
                          value="yes"
                          className="form-check-input"
                          disabled={!watch("tender_opening_date")}
                          // disabled={
                          //   watch("tender_re_invitation_required") === "yes"
                          // }
                          onChange={(e) => {
                            p_q_radiotest.onChange(e);
                          }}
                        />
                        <Label for="p_q_yes" className="ms-50 form-check-label">
                          Yes
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={p_q_radio}
                          {...p_q_radiotest}
                          type="radio"
                          name="p_q"
                          id="p_q_no"
                          value="no"
                          className="form-check-input"
                          disabled={!watch("tender_opening_date")}
                          // disabled={
                          //   watch("tender_re_invitation_required") === "yes"
                          // }
                          onChange={(e) => {
                            p_q_radiotest.onChange(e);
                          }}
                        />
                        <Label for="p_q_no" className="ms-50 form-check-label">
                          No
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Button
                          disabled={!watch("tender_opening_date")}
                          onClick={() => {
                            setValue("p_q", null);
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label
                    className="form-label"
                    for="technical_bid_opening_date"
                  >
                    Technical Bid Opening Date
                  </Label>
                  <Controller
                    control={control}
                    id="technical_bid_opening_date"
                    name="technical_bid_opening_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={
                          watch("p_q") === "no" || watch("p_q") === null
                        }
                        selected={technicalBidOpeningDate}
                        onChange={handleTechnicalBidOpeningDateChange}
                      />
                    )}
                  />
                  {errors.technical_bid_opening_date && (
                    <FormFeedback className="d-block">
                      {errors.technical_bid_opening_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label
                    className="form-label"
                    for="tender_price_bid_opening_date"
                  >
                    Tender Price Bid Opening Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_price_bid_opening_date"
                    name="tender_price_bid_opening_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        disabled={
                          watch("p_q") === "yes" || watch("p_q") === null
                        }
                        selected={tenderPriceBidOpeningDate}
                        onChange={handleTenderPriceBidOpeningDateChange}
                      />
                    )}
                  />
                  {errors.tender_price_bid_opening_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_price_bid_opening_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label className="form-label" for="tender_bid_validity_date">
                    Tender Bid Validity Till Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_bid_validity_date"
                    name="tender_bid_validity_date"
                    // render={({ field }) => (
                    //   <ReactDatePicker
                    //     {...field}
                    //     // disabled
                    //     selected={tenderBidValidityTillDate}
                    //     onChange={handleTenderBidValidityTillDateChange}
                    //   />
                    // )}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          id="tender_bid_validity_date"
                          invalid={errors.tender_bid_validity_date && true}
                        />
                      );
                    }}
                  />
                  {errors.tender_bid_validity_date && (
                    <FormFeedback>
                      {errors.tender_bid_validity_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="tender_app_amount">
                    {console.log(
                      "tender_app_amount:>>>",
                      watch("tender_app_amount")
                    )}
                    Tender App. Amount (Lacs)
                  </Label>
                  <Controller
                    control={control}
                    name="tender_app_amount"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="tender_app_amount"
                          invalid={errors.tender_app_amount && true}
                          onChange={(e) => {
                            const pattern =
                              /^(?!0(\.0{1,2})?$)\d{0,12}(\.\d{0,2})?$/;
                            if (pattern.test(e.target.value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  />
                  {errors.tender_app_amount && (
                    <FormFeedback>
                      {errors.tender_app_amount.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0  DatePicker-w100">
                  <Label className="form-label" for="tender_app_date">
                    Tender App. Date
                  </Label>
                  <Controller
                    control={control}
                    id="tender_app_date"
                    name="tender_app_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={tenderAppDate}
                        onChange={handleTenderAppDateChange}
                      />
                    )}
                  />
                  {errors.tender_app_date && (
                    <FormFeedback className="d-block">
                      {errors.tender_app_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="above_below">
                    % Above / Below
                  </Label>
                  <div>
                    <div className="demo-inline-spacing checkbox">
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={above_below_radio}
                          {...above_below_radiotest}
                          type="radio"
                          name="above_below"
                          id="above_below_above"
                          value="above"
                          className="form-check-input"
                        />
                        <Label
                          for="above_below_above"
                          className="ms-50 form-check-label"
                        >
                          Above
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Input
                          innerRef={above_below_radio}
                          {...above_below_radiotest}
                          type="radio"
                          name="above_below"
                          id="above_below_below"
                          value="below"
                          className="form-check-input"
                        />
                        <Label
                          for="above_below_below"
                          className="ms-50 form-check-label"
                        >
                          Below
                        </Label>
                      </div>
                      <div className="custom-control custom-radio">
                        <Button
                          onClick={() => {
                            setValue("above_below", null);
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="per_above_below">
                    Above / Below in Percentage
                  </Label>
                  <Controller
                    control={control}
                    name="per_above_below"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="per_above_below"
                          invalid={errors.per_above_below && true}
                        />
                      );
                    }}
                  />
                  {errors.per_above_below && (
                    <FormFeedback>
                      {errors.per_above_below.message}
                    </FormFeedback>
                  )}
                </Col>
                <hr className="mx-1" />
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="agency_english_id">
                    Agency (English)
                  </Label>
                  <Controller
                    id="agency_english_id"
                    control={control}
                    name="agency_english_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={agencyDropDownOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.agency}
                        classNamePrefix="select"
                        className="react-select"
                        value={agencyDropDownOption?.find(
                          (item) => item?.id === watch("agency_english_id")
                        )}
                        onChange={(e) => {
                          field.onChange(e?.id);
                        }}
                        isDisabled={isFieldsetDisabled}
                      />
                    )}
                  />
                  {errors.agency_english_id && (
                    <FormFeedback>
                      {errors.agency_english_id.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="agency_gujarati_id">
                    Agency (Gujarati)
                  </Label>
                  <Controller
                    id="agency_gujarati_id"
                    control={control}
                    name="agency_gujarati_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled
                        options={agencyDropDownOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.agency_gujarati}
                        classNamePrefix="select"
                        className="react-select"
                        value={agencyDropDownOption?.find(
                          (item) =>
                            item?.id === watch("agency_english_id")?.id ||
                            item?.id === watch("agency_english_id")
                        )}
                        onChange={(e) => {
                          field.onChange(e?.id);
                        }}
                      />
                    )}
                  />
                  {errors.agency_gujarati_id && (
                    <FormFeedback>
                      {errors.agency_gujarati_id.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label
                    className="form-label"
                    for="deposit_call_letter_issue_date"
                  >
                    Deposit Call letter issue Date
                  </Label>
                  <Controller
                    control={control}
                    id="deposit_call_letter_issue_date"
                    name="deposit_call_letter_issue_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={depositCallLetterIssueDate}
                        onChange={handleDepositCallLetterIssueDateChange}
                      />
                    )}
                  />
                  {errors.deposit_call_letter_issue_date && (
                    <FormFeedback>
                      {errors.deposit_call_letter_issue_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="aggrement_no">
                    Aggrement No.
                  </Label>
                  <Controller
                    control={control}
                    name="aggrement_no"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="aggrement_no"
                          invalid={errors.aggrement_no && true}
                        />
                      );
                    }}
                  />
                  {errors.aggrement_no && (
                    <FormFeedback>{errors.aggrement_no.message}</FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label className="form-label" for="work_order_date">
                    Work Order Date
                  </Label>
                  <Controller
                    control={control}
                    id="work_order_date"
                    name="work_order_date"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={workOrderDate}
                        onChange={handleWorkOrderDateChange}
                      />
                    )}
                  />
                  {errors.work_order_date && (
                    <FormFeedback>
                      {errors.work_order_date.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label className="form-label" for="time_limit">
                    Time Limit (Months)
                  </Label>
                  <Controller
                    control={control}
                    name="time_limit"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="time_limit"
                          // value={field.value}
                          invalid={errors.time_limit && true}
                          onChange={(e) => {
                            field.onChange(e);
                            handelTimeLimitcalculate(e);
                          }}
                        />
                      );
                    }}
                  />
                  {errors.time_limit && (
                    <FormFeedback>{errors.time_limit.message}</FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0">
                  <Label
                    className="form-label"
                    for="date_of_completion_as_per_wo"
                  >
                    Date of Completion As per W.O.
                  </Label>
                  <Controller
                    control={control}
                    name="date_of_completion_as_per_wo"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          id="date_of_completion_as_per_wo"
                          // value={field.value}
                          disabled
                          invalid={errors.date_of_completion_as_per_wo && true}
                        />
                      );
                    }}
                  />
                  {errors.date_of_completion_as_per_wo && (
                    <FormFeedback>
                      {errors.date_of_completion_as_per_wo.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={3} xs={12} className="mb-1 mt-0 DatePicker-w100">
                  <Label className="form-label" for="likely_date_of_completion">
                    Likely Date of Completion
                  </Label>
                  <Controller
                    control={control}
                    id="likely_date_of_completion"
                    name="likely_date_of_completion"
                    render={({ field }) => (
                      <ReactDatePicker
                        {...field}
                        selected={likelyDateofCompletion}
                        onChange={handleLikelyDateofCompletionChange}
                      />
                    )}
                  />
                  {errors.likely_date_of_completion && (
                    <FormFeedback>
                      {errors.likely_date_of_completion.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col xs={12} className=" my-2 py-1">
                  <Button
                    className="btn btn-outline-dark waves-effect me-25"
                    type="reset"
                    outline
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmit(onSubmit)}>
                    Save
                  </Button>
                </Col>
                <Col md={4} xs={12} className="mt-0">
                  <Label className="form-label" for="extended_required">
                    Tender validity Extended OR Not?
                  </Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={extended_required_radio}
                        {...extended_required_radiotest}
                        type="radio"
                        name="extended_required"
                        id="extended_required_yes"
                        value="yes"
                        className="form-check-input"
                      />
                      <Label
                        for="extended_required_yes"
                        className="ms-50 form-check-label"
                      >
                        Yes
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={extended_required_radio}
                        {...extended_required_radiotest}
                        type="radio"
                        name="extended_required"
                        id="extended_required_no"
                        value="no"
                        className="form-check-input"
                      />
                      <Label
                        for="extended_required_no"
                        className="ms-50 form-check-label"
                      >
                        No
                      </Label>
                    </div>
                  </div>
                </Col>
                {watch("extended_required") === "yes" && (
                  // complete
                  <Col md={4} xs={12} className="mb-1 mt-0 DatePicker-w100">
                    <Label className="form-label" for="extended_date">
                      Extended Tender Bid Validity Date
                    </Label>
                    <Controller
                      control={control}
                      id="extended_date"
                      name="extended_date"
                      render={({ field }) => (
                        <ReactDatePicker
                          {...field}
                          selected={extendedTenderBidValidityDate}
                          onChange={handleExtendedTenderBidValidityDateChange}
                        />
                      )}
                    />
                    {errors.extended_date && (
                      <FormFeedback>
                        {errors.extended_date.message}
                      </FormFeedback>
                    )}
                  </Col>
                )}
                <Col xs={12} className=" mt-50">
                  <Button
                    className="btn btn-outline-dark waves-effect me-25"
                    type="reset"
                    outline
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={handleSubmit(onRadioSubmit)}>
                    Save
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default TenderToWorkOrderStage;
