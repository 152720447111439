/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
// import ReactDatePicker from "../../../components/ReactDatePicker/index";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import ReactDate from "../../../../components/ReactDatePicker";
import moment from "moment";
import { updateForestClearanceWork } from "../../../../../services/reportsServices";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../../common/ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const Forest_Clearance_Data = yup.object().shape({
  forest_protected: yup.string().required("This field is required").nullable(),
  // Start નોન પ્રોટેક્ટેડ ફોરેસ્ટ
  forest_vrokasoni_sankhiya: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value && value === "no",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // End નોન પ્રોટેક્ટેડ ફોરેસ્ટ
  // Start પ્રોટેક્ટેડ ફોરેસ્ટ
  forest_online_web_praposal_number: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value && value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_jamin_setrafal_hekatarma: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value && value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_kona_level_pending_chhe: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value && value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // End પ્રોટેક્ટેડ ફોરેસ્ટ
  // Start Common Data
  forest_darkhast_krya_ptra_number: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_darkhast_krya_ptra_date: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_kai_kacherima_darkhast_karel_chhe: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_halma_kai_kacherima_padatar_chhe: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_kai_date_padatar_chhe: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  la_samaygalo: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_remakas: yup
    .string()
    .when("forest_protected", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // End Common Data

  // Start મંજૂરી મળેલ છે? || forest_manjuri_malel_chhe = yes / no
  forest_manjuri_malel_chhe: yup.string(),
  // 1st ઇન પ્રિન્સિપાલ મંજૂરી Start
  forest_in_principal_manjuri: yup
    .string()
    .when("forest_manjuri_malel_chhe", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe: yup
    .string()
    .when("forest_in_principal_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_in_principal_manjuri_malya_ptra_number: yup
    .string()
    .when("forest_in_principal_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_in_principal_manjuri_malya_ptra_date: yup
    .string()
    .when("forest_in_principal_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // 1st ઇન પ્રિન્સિપાલ મંજૂરી End
  // 2nd નાણાં ભરવાની વિગતો Start
  forest_rakam_rsma: yup
    .string()
    .when("forest_rs_bharavani_vigato", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_rs_bhariyani_date: yup
    .string()
    .when("forest_rs_bharavani_vigato", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // 2nd નાણાં ભરવાની વિગતો End
  // 3nd વર્કિંગ પરમીશન Start
  forest_working_permission_manjuri_malya_ptra_number: yup
    .string()
    .when("forest_working_permission", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_working_permission_manjuri_malya_ptra_date: yup
    .string()
    .when("forest_working_permission", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // 3nd વર્કિંગ પરમીશન End
  // 4nd સ્ટેજ - ૨ ની મંજૂરી Start
  forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe: yup
    .string()
    .when("forest_stage_twoni_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_stagetwoni_manjuri_manjuri_maliya_ptra_number: yup
    .string()
    .when("forest_stage_twoni_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_stagetwoni_manjuri_maliya_date: yup
    .string()
    .when("forest_stage_twoni_manjuri", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // 4nd સ્ટેજ - ૨ ની મંજૂરી End
  // **** Start Modal || વૃક્ષો કાપણીની વિગતો
  forest_kliyaransni_kamgiri_puran_thayel_chhe: yup
    .string()
    .when("forest_manjuri_malel_chhe", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_mam_vibhag_dwara: yup
    .string()
    .when("forest_kliyaransni_kamgiri_puran_thayel_chhe", {
      is: (value) => value === "yes",
      then: yup.string().required("This field is required").nullable(),
      // then: yup.object().required("This field is required").nullable(),
      // then: yup
      //   .array()
      //   .of(yup.string().required("This field is required").nullable()),
      // then: yup.array().required("This field is required").nullable(),
    })
    .nullable(),

  forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti: yup
    .string()
    .when("forest_mam_vibhag_dwara", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  forest_mam_vibhag_dwara_kapnai_puran_thaya_date: yup
    .string()
    .when("forest_mam_vibhag_dwara", {
      is: (value) => !!value,
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // **** End Modal || વૃક્ષો કાપણીની વિગતો
  la_kalam_eleven_date: yup
    .string()
    .when("la_kalam_eleven", {
      is: "yes",
      then: yup.string().required("This field is required").nullable(),
    })
    .nullable(),
  // End   મંજૂરી મળેલ છે? || forest_manjuri_malel_chhe = yes / no
});

const ForestClearance = () => {
  // ** Get Data From Store
  const workData = useSelector((state) => state?.getWork);

  // console.log("workdata>>>", workData?.data);
  const {
    reset,
    setValue,
    setError,
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Forest_Clearance_Data) });
  // console.log(
  //   "errors",
  //   watch("forest_in_principal_manjuri"),
  //   watch("forest_rs_bharavani_vigato"),
  //   watch("forest_working_permission"),
  //   watch("forest_stage_twoni_manjuri")
  // );

  //** START Date Const set
  const darkhastdate = watch("forest_darkhast_krya_ptra_date");
  const kaiDatePadatarDate = watch("forest_kai_date_padatar_chhe");
  const forestInPrincipalManjuriMalyaDate = watch(
    "forest_in_principal_manjuri_malya_ptra_date"
  );
  const forestRsBhariyaniDate = watch("forest_rs_bhariyani_date");
  const forestWorkingPermissionManjuriMalyaPtraDate = watch(
    "forest_working_permission_manjuri_malya_ptra_date"
  );
  const forestStagetwoniManjuriMaliyaDate = watch(
    "forest_stagetwoni_manjuri_maliya_date"
  );
  const forestMamVibhagDwaraKapnaiPuranThayaDate = watch(
    "forest_mam_vibhag_dwara_kapnai_puran_thaya_date"
  );
  const forestKliyaransniKamgiriPuranThayelDate = watch(
    "forest_kliyaransni_kamgiri_puran_thayel_date"
  );
  //** END Date Const set

  //** Start onChange Date
  const onChangeForestDarkhastKryaPtraDateSet = (date) => {
    if (date === null) {
      setValue("forest_darkhast_krya_ptra_date", null);
    } else {
      setValue("forest_darkhast_krya_ptra_date", date);
      setError("forest_darkhast_krya_ptra_date", "");
    }
    // For સમયગાળો || la_samaygalo set value onChnage
    const daysToTarget = moment().diff(date, "days");
    const years = Math.floor(daysToTarget / 365);
    const months = Math.floor((daysToTarget % 365) / 30);
    const days = (daysToTarget % 365) % 30;
    if (years !== 0 && months !== 0 && days > 0) {
      setValue("la_samaygalo", `${years} Years, ${months} Months, ${days} Day`);
    } else if (years === 0 && months !== 0 && days > 0) {
      setValue("la_samaygalo", `${months} Months, ${days} Day`);
    } else if (years === 0 && months === 0 && days > 0) {
      setValue("la_samaygalo", `${days} Day`);
    } else {
      setValue("la_samaygalo", "0");
    }
  };
  //** End onChange Date

  const { ref: protectedradio, ...protectedradiotest } = register(
    "forest_protected",
    {
      required: true,
      // test_fail_true
    }
  );
  const {
    ref: forest_one_kam_atakayel_chhe_radio,
    ...forest_one_kam_atakayel_chhe_radiotest
  } = register("forest_one_kam_atakayel_chhe", {
    required: true,
    // test_fail_true
  });

  const {
    ref: forest_kona_level_pending_chhe_radio,
    ...forest_kona_level_pending_chhe_radiotest
  } = register("forest_kona_level_pending_chhe", {
    required: true,
    // test_fail_true
  });

  const {
    ref: forest_manjuri_malel_chhe_radio,
    ...forest_manjuri_malel_chhe_radiotest
  } = register("forest_manjuri_malel_chhe", {
    required: true,
    // test_fail_true
  });

  const {
    ref: forest_kliyaransni_kamgiri_puran_thayel_chhe_radio,
    ...forest_kliyaransni_kamgiri_puran_thayel_chhe_radiotest
  } = register("forest_kliyaransni_kamgiri_puran_thayel_chhe", {
    required: true,
  });
  const [falseTrueForVrukhso, setFalseTrueForVrukhso] = useState();
  const {
    ref: forest_mam_vibhag_dwara_radio,
    ...forest_mam_vibhag_dwara_radiotest
  } = register("forest_mam_vibhag_dwara", {
    required: true,
    // test_fail_true
    onChange: (e) => {
      if (e.target.value === "yes" && e?.target?.checked === true) {
        setValue("forest_mam_vibhag_dwara", ["yes"]);
        setFalseTrueForVrukhso(false);
      } else if (e.target.value === "no" && e?.target?.checked === true) {
        setValue("forest_mam_vibhag_dwara", ["no"]);
        setFalseTrueForVrukhso(false);
      } else {
        setValue("forest_mam_vibhag_dwara", []);
        setFalseTrueForVrukhso(true);
      }
    },
  });
  const {
    ref: forest_working_permission_checkbox,
    ...forest_working_permission_checkboxtest
  } = register("forest_working_permission", {
    required: false,
  });
  const {
    ref: forest_stage_twoni_manjuri_checkbox,
    ...forest_stage_twoni_manjuri_checkboxtest
  } = register("forest_stage_twoni_manjuri", {
    required: false,
  });
  const {
    ref: forest_rs_bharavani_vigato_checkbox,
    ...forest_rs_bharavani_vigato_checkboxtest
  } = register("forest_rs_bharavani_vigato", {
    required: false,
    onChange: (e) => {
      if (e?.target?.checked === false) {
        setValue("forest_working_permission", false);
      }
    },
  });
  const {
    ref: forest_in_principal_manjuri_checkbox,
    ...forest_in_principal_manjuri_checkboxtest
  } = register("forest_in_principal_manjuri", {
    required: true,
    // test_fail_true
    onChange: (e) => {
      if (e?.target?.checked === false) {
        setValue("forest_rs_bharavani_vigato", false);
        setValue("forest_working_permission", false);
      }
    },
  });

  // OnSubmit Data _______________________________________________________________________________________________________________________________________
  const onSubmit = (data) => {
    console.log("All Data--->", data);
    const sendApiData = {
      work_id: workData?.data.id,
      // ** forest_clearance_required key is Pending (for API testing temporary static value send)
      forest_clearance_required: "yes",
      forest_protected: data?.forest_protected,

      // પ્રોટેક્ટેડ ફોરેસ્ટ is selected radio || forest_protected
      forest_online_web_praposal_number:
        data?.forest_protected === "yes"
          ? data?.forest_online_web_praposal_number
          : null,
      forest_jamin_setrafal_hekatarma:
        data?.forest_protected === "yes"
          ? data?.forest_jamin_setrafal_hekatarma
          : null,

      // નોન પ્રોટેક્ટેડ ફોરેસ્ટ is selected radio || forest_protected
      forest_vrokasoni_sankhiya:
        data?.forest_protected === "no"
          ? data?.forest_vrokasoni_sankhiya
          : null,

      // પ્રોટેક્ટેડ ફોરેસ્ટ || નોન પ્રોટેક્ટેડ ફોરેસ્ટ is selected radio || forest_protected common
      forest_darkhast_krya_ptra_number: data?.forest_darkhast_krya_ptra_number,
      forest_darkhast_krya_ptra_date:
        data?.forest_darkhast_krya_ptra_date === null
          ? null
          : moment(data?.forest_darkhast_krya_ptra_date).format("DD-MM-YYYY"),
      forest_darkhast_krya_ptra_date_submit:
        data?.forest_darkhast_krya_ptra_date === null
          ? null
          : moment(data?.forest_darkhast_krya_ptra_date).format("YYYY-MM-DD"),
      forest_kai_kacherima_darkhast_karel_chhe:
        data?.forest_kai_kacherima_darkhast_karel_chhe,
      forest_halma_kai_kacherima_padatar_chhe:
        data?.forest_halma_kai_kacherima_padatar_chhe,
      forest_kai_date_padatar_chhe:
        data?.forest_kai_date_padatar_chhe === null
          ? null
          : moment(data?.forest_kai_date_padatar_chhe).format("DD-MM-YYYY"),
      forest_kai_date_padatar_chhe_submit:
        data?.forest_kai_date_padatar_chhe === null
          ? null
          : moment(data?.forest_kai_date_padatar_chhe).format("YYYY-MM-DD"),
      la_samaygalo: data?.la_samaygalo,
      forest_one_kam_atakayel_chhe: data?.forest_one_kam_atakayel_chhe,
      forest_remakas: data?.forest_remakas,
      forest_kona_level_pending_chhe: data?.forest_kona_level_pending_chhe,

      // મંજૂરી મળેલ છે? === yes || forest_manjuri_malel_chhe
      forest_manjuri_malel_chhe: data?.forest_manjuri_malel_chhe,

      // check forest_in_principal_manjuri START
      forest_in_principal_manjuri:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_in_principal_manjuri === "yes"
          ? "yes"
          : "no",
      forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_in_principal_manjuri === "yes"
          ? data?.forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe
          : null,
      forest_in_principal_manjuri_malya_ptra_number:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_in_principal_manjuri === "yes"
          ? data?.forest_in_principal_manjuri_malya_ptra_number
          : null,
      forest_in_principal_manjuri_malya_ptra_date:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_in_principal_manjuri === "yes"
          ? data?.forest_in_principal_manjuri_malya_ptra_date === null
            ? null
            : moment(data?.forest_in_principal_manjuri_malya_ptra_date).format(
                "DD-MM-YYYY"
              )
          : null,
      forest_in_principal_manjuri_malya_ptra_date_submit:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_in_principal_manjuri === "yes"
          ? data?.forest_in_principal_manjuri_malya_ptra_date === null
            ? null
            : moment(data?.forest_in_principal_manjuri_malya_ptra_date).format(
                "YYYY-MM-DD"
              )
          : null,
      // check forest_in_principal_manjuri END

      // check forest_rs_bharavani_vigato START
      forest_rs_bharavani_vigato:
        data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_rs_bharavani_vigato
          ? "yes"
          : "no",
      forest_rakam_rsma:
        data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_rs_bharavani_vigato
          ? data?.forest_rakam_rsma
          : null,
      forest_rs_bhariyani_date:
        data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_rs_bharavani_vigato
          ? data?.forest_rs_bhariyani_date === null
            ? null
            : moment(data?.forest_rs_bhariyani_date).format("DD-MM-YYYY")
          : null,
      forest_rs_bhariyani_date_submit:
        data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_rs_bharavani_vigato
          ? data?.forest_rs_bhariyani_date === null
            ? null
            : moment(data?.forest_rs_bhariyani_date).format("YYYY-MM-DD")
          : null,
      // check forest_rs_bharavani_vigato END

      // check forest_working_permission Start
      forest_working_permission:
        data?.forest_protected === "no"
          ? null
          : data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_working_permission
          ? "yes"
          : "no",
      forest_working_permission_manjuri_malya_ptra_number:
        data?.forest_protected === "no"
          ? null
          : data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_working_permission
          ? data?.forest_working_permission_manjuri_malya_ptra_number
          : null,

      forest_working_permission_manjuri_malya_ptra_date:
        data?.forest_protected === "no"
          ? null
          : data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_working_permission
          ? data?.forest_working_permission_manjuri_malya_ptra_date === null
            ? null
            : moment(
                data?.forest_working_permission_manjuri_malya_ptra_date
              ).format("DD-MM-YYYY")
          : null,
      forest_working_permission_manjuri_malya_ptra_date_submit:
        data?.forest_protected === "no"
          ? null
          : data?.forest_in_principal_manjuri?.checked === false
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_working_permission
          ? data?.forest_working_permission_manjuri_malya_ptra_date === null
            ? null
            : moment(
                data?.forest_working_permission_manjuri_malya_ptra_date
              ).format("YYYY-MM-DD")
          : null,
      // check forest_working_permission END

      // check forest_stage_twoni_manjuri Start
      forest_stage_twoni_manjuri:
        data?.forest_protected === "no"
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_stage_twoni_manjuri
          ? "yes"
          : "no",
      forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe:
        data?.forest_protected === "no"
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_stage_twoni_manjuri
          ? data?.forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe
          : null,
      forest_stagetwoni_manjuri_manjuri_maliya_ptra_number:
        data?.forest_protected === "no"
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_stage_twoni_manjuri
          ? data?.forest_stagetwoni_manjuri_manjuri_maliya_ptra_number
          : null,

      forest_stagetwoni_manjuri_maliya_date:
        data?.forest_protected === "no"
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_stage_twoni_manjuri
          ? data?.forest_stagetwoni_manjuri_maliya_date === null
            ? null
            : moment(data?.forest_stagetwoni_manjuri_maliya_date).format(
                "DD-MM-YYYY"
              )
          : null,
      forest_stagetwoni_manjuri_maliya_date_submit:
        data?.forest_protected === "no"
          ? null
          : data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_stage_twoni_manjuri
          ? data?.forest_stagetwoni_manjuri_maliya_date === null
            ? null
            : moment(data?.forest_stagetwoni_manjuri_maliya_date).format(
                "YYYY-MM-DD"
              )
          : null,
      // check forest_stage_twoni_manjuri END

      // વૃક્ષો કાપણીની વિગતો START ------------------------------------------------------------------------------fixed
      forest_mam_vibhag_dwara:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? "no"
          : data?.forest_mam_vibhag_dwara === "no"
          ? "no"
          : data?.forest_mam_vibhag_dwara === "yes"
          ? "yes"
          : data?.forest_mam_vibhag_dwara[0] === "yes"
          ? "yes"
          : "no",
      //
      forest_van_vibhag_dwara:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? "no"
          : data?.forest_mam_vibhag_dwara === "no"
          ? "yes"
          : data?.forest_mam_vibhag_dwara === "yes"
          ? "no"
          : data?.forest_mam_vibhag_dwara[0] === "no"
          ? "yes"
          : "no",
      // વૃક્ષો કાપણીની વિગતો START ------------------------------------------------------------------------------fixed
      forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === "no" ||
            data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === null
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? null
          : data?.forest_mam_vibhag_dwara[0] === []
          ? null
          : data?.forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti,
      // Start || Issue **********************************************************************************************
      // forest_manjuri_malel_chhe = મંજૂરી મળેલ છે?
      //forest_kliyaransni_kamgiri_puran_thayel_chhe = ફોરેસ્ટ ક્લીયરન્સની કામગીરી પૂર્ણ થયેલ છે?
      // issue: {
      //   forest_manjuri_malel_chhe: data?.forest_manjuri_malel_chhe,
      //   forest_kliyaransni_kamgiri_puran_thayel_chhe:
      //     data?.forest_kliyaransni_kamgiri_puran_thayel_chhe,
      //   forest_mam_vibhag_dwara_kapnai_puran_thaya_date: moment(
      //     data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date
      //   ).format("DD-MM-YYYY"),
      // },
      // End   || Issue **********************************************************************************************
      forest_mam_vibhag_dwara_kapnai_puran_thaya_date:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === "no"
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? null
          : data?.forest_mam_vibhag_dwara[0] === []
          ? null
          : data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date === null
          ? null
          : moment(
              data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date
            ).format("DD-MM-YYYY"),
      forest_mam_vibhag_dwara_kapnai_puran_thaya_date_submit:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === "no" ||
            data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === null
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? null
          : data?.forest_mam_vibhag_dwara[0] === []
          ? null
          : data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date === null
          ? null
          : moment(
              data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date
            ).format("YYYY-MM-DD"),

      forest_kliyaransni_kamgiri_puran_thayel_chhe:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_chhe,
      forest_kliyaransni_kamgiri_puran_thayel_date:
        data?.forest_manjuri_malel_chhe === "no"
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === "no" ||
            data?.forest_kliyaransni_kamgiri_puran_thayel_chhe === null
          ? null
          : data?.forest_mam_vibhag_dwara === null
          ? null
          : data?.forest_mam_vibhag_dwara[0] === []
          ? null
          : data?.forest_kliyaransni_kamgiri_puran_thayel_date === null
          ? null
          : moment(data?.forest_kliyaransni_kamgiri_puran_thayel_date).format(
              "DD-MM-YYYY"
            ),
      // વૃક્ષો કાપણીની વિગતો END
    };
    console.log("send-API Data--->", sendApiData);

    // API sendData
    updateForestClearanceWork(sendApiData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };

  // Reset Value
  useEffect(() => {
    if (workData?.data) {
      reset({
        circle_name: workData?.data?.circle_name || "",
        division_name: workData?.data?.division_name || "",
        name_of_work_gujarati: workData?.data?.name_of_work_gujarati || "",
        budget_head_name: workData?.data?.budget_head_name || "",
        length_in_km: workData?.data?.length_in_km || "",
        forest_protected: workData?.data?.forest_protected || null,
        forest_online_web_praposal_number:
          workData?.data?.forest_online_web_praposal_number || null,
        forest_jamin_setrafal_hekatarma:
          workData?.data?.forest_jamin_setrafal_hekatarma || null,
        forest_vrokasoni_sankhiya:
          workData?.data?.forest_vrokasoni_sankhiya || null,
        forest_darkhast_krya_ptra_number:
          workData?.data?.forest_darkhast_krya_ptra_number || null,
        forest_darkhast_krya_ptra_date: workData?.data
          ?.forest_darkhast_krya_ptra_date
          ? new Date(workData?.data?.forest_darkhast_krya_ptra_date)
          : null,
        forest_kai_kacherima_darkhast_karel_chhe:
          workData?.data?.forest_kai_kacherima_darkhast_karel_chhe || null,
        forest_halma_kai_kacherima_padatar_chhe:
          workData?.data?.forest_halma_kai_kacherima_padatar_chhe || null,

        forest_kai_date_padatar_chhe: workData?.data
          ?.forest_kai_date_padatar_chhe
          ? new Date(workData?.data?.forest_kai_date_padatar_chhe)
          : null,

        forest_one_kam_atakayel_chhe:
          workData?.data?.forest_one_kam_atakayel_chhe === null
            ? "no"
            : workData?.data?.forest_one_kam_atakayel_chhe === null,
        forest_remakas: workData?.data?.forest_remakas || null,
        forest_kona_level_pending_chhe:
          workData?.data?.forest_kona_level_pending_chhe || null,
        la_samaygalo: workData?.data?.la_samaygalo || null,

        forest_kona_level_pending_chhe:
          workData?.data?.forest_kona_level_pending_chhe || null,

        forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe:
          workData.data
            .forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe ||
          null,

        forest_in_principal_manjuri_malya_ptra_number:
          workData?.data?.forest_in_principal_manjuri_malya_ptra_number || null,

        forest_in_principal_manjuri_malya_ptra_date: workData?.data
          ?.forest_in_principal_manjuri_malya_ptra_date
          ? new Date(
              workData?.data?.forest_in_principal_manjuri_malya_ptra_date
            )
          : null,

        forest_rakam_rsma: workData?.data?.forest_rakam_rsma || null,

        forest_rs_bhariyani_date: workData?.data?.forest_rs_bhariyani_date
          ? new Date(workData?.data?.forest_rs_bhariyani_date)
          : null,

        forest_working_permission_manjuri_malya_ptra_number:
          workData.data.forest_working_permission_manjuri_malya_ptra_number ||
          null,

        forest_stagetwoni_manjuri_manjuri_maliya_ptra_number:
          workData.data.forest_stagetwoni_manjuri_manjuri_maliya_ptra_number ||
          null,

        forest_working_permission_manjuri_malya_ptra_date: workData?.data
          ?.forest_working_permission_manjuri_malya_ptra_date
          ? new Date(
              workData?.data?.forest_working_permission_manjuri_malya_ptra_date
            )
          : null,

        forest_stagetwoni_manjuri_maliya_date: workData?.data
          ?.forest_stagetwoni_manjuri_maliya_date
          ? new Date(workData?.data?.forest_stagetwoni_manjuri_maliya_date)
          : null,

        forest_manjuri_malel_chhe:
          workData?.data?.forest_manjuri_malel_chhe === null
            ? "no"
            : workData?.data?.forest_manjuri_malel_chhe,

        forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe:
          workData?.data
            ?.forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe ||
          null,

        forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti:
          workData?.data
            ?.forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti ||
          null,

        forest_mam_vibhag_dwara_kapnai_puran_thaya_date: workData?.data
          ?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date
          ? new Date(
              workData?.data?.forest_mam_vibhag_dwara_kapnai_puran_thaya_date
            )
          : null,

        forest_kliyaransni_kamgiri_puran_thayel_chhe:
          workData?.data?.forest_kliyaransni_kamgiri_puran_thayel_chhe || null,
        //** reset value pending
        // forest_mam_vibhag_dwara:
        //   workData?.data?.forest_mam_vibhag_dwara || null,
        //** reset value pending

        forest_kliyaransni_kamgiri_puran_thayel_date: workData?.data
          ?.forest_kliyaransni_kamgiri_puran_thayel_date
          ? new Date(
              workData?.data?.forest_kliyaransni_kamgiri_puran_thayel_date
            )
          : null,

        forest_working_permission:
          workData?.data?.forest_working_permission === "yes" ? "yes" : false,

        forest_in_principal_manjuri:
          // workData?.data?.forest_in_principal_manjuri,
          workData?.data?.forest_in_principal_manjuri === "yes" ? "yes" : null,

        forest_rs_bharavani_vigato:
          //  workData?.data?.forest_rs_bharavani_vigato,
          workData?.data?.forest_rs_bharavani_vigato === "yes" ? "yes" : false,
        forest_stage_twoni_manjuri:
          // workData?.data?.forest_stage_twoni_manjuri,
          workData?.data?.forest_stage_twoni_manjuri === "yes" ? "yes" : false,
      });
      if (workData?.data?.forest_mam_vibhag_dwara === "yes") {
        setValue("forest_mam_vibhag_dwara", "yes");
      } else if (workData?.data?.forest_van_vibhag_dwara === "yes") {
        setValue("forest_mam_vibhag_dwara", "no");
      } else {
        setValue("forest_mam_vibhag_dwara", null);
        setFalseTrueForVrukhso(true);
      }
    }
  }, [workData?.data]);

  return (
    <div className="mt-2">
      <Form>
        <Row className="gy-1">
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="circle_name">
              વર્તુળનું નામ
            </Label>
            <Controller
              control={control}
              name="circle_name"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="circle_name"
                    invalid={errors.circle_name && true}
                  />
                );
              }}
            />
            {/* {errors.circle_name && (
              <FormFeedback>{errors.circle_name.message}</FormFeedback>
            )} */}
          </Col>
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="division_name">
              વિભાગનું નામ
            </Label>
            <Controller
              control={control}
              name="division_name"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="division_name"
                    invalid={errors.division_name && true}
                  />
                );
              }}
            />
            {/* {errors.circle_name && (
              <FormFeedback>{errors.circle_name.message}</FormFeedback>
            )} */}
          </Col>
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="name_of_work_gujarati">
              કામ /રસ્તાનું નામ
            </Label>
            <Controller
              control={control}
              name="name_of_work_gujarati"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="name_of_work_gujarati"
                    invalid={errors.name_of_work_gujarati && true}
                  />
                );
              }}
            />
            {/* {errors.name_of_work_gujarati && (
              <FormFeedback>{errors.name_of_work_gujarati.message}</FormFeedback>
            )} */}
          </Col>
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="budget_head_name">
              કામ મંજુર થયેલ હોય તે યોજના
            </Label>
            <Controller
              control={control}
              name="budget_head_name"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="budget_head_name"
                    invalid={errors.budget_head_name && true}
                  />
                );
              }}
            />
            {/* {errors.budget_head_name && (
              <FormFeedback>{errors.budget_head_name.message}</FormFeedback>
            )} */}
          </Col>
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="length_in_km">
              રસ્‍તાની લંબાઈ કી.મી.
            </Label>
            <Controller
              control={control}
              name="length_in_km"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="length_in_km"
                    invalid={errors.length_in_km && true}
                  />
                );
              }}
            />
            {/* {errors.budget_head_name && (
              <FormFeedback>{errors.budget_head_name.message}</FormFeedback>
            )} */}
          </Col>
          {/* Start Field --------------------------------------------------------------------------------------------- */}
          {/* Complete */}
          <Col md={6} xs={12} className="mb-1 mt-0">
            <div>
              <Label></Label>
              <div className="demo-inline-spacing checkbox">
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={protectedradio}
                    {...protectedradiotest}
                    type="radio"
                    name="forest_protected"
                    id="forest_protected_yes"
                    value="yes"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_protected_yes"
                    className="ms-50 form-check-label"
                  >
                    પ્રોટેક્ટેડ ફોરેસ્ટ
                  </Label>
                </div>
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={protectedradio}
                    {...protectedradiotest}
                    type="radio"
                    name="forest_protected"
                    id="forest_protected_no"
                    value="no"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_protected_no"
                    className="ms-50 form-check-label"
                  >
                    નોન પ્રોટેક્ટેડ ફોરેસ્ટ
                  </Label>
                </div>
                <span>
                  {errors?.forest_protected && (
                    <FormFeedback className="d-block mt-0">
                      {errors?.forest_protected?.message}
                    </FormFeedback>
                  )}
                </span>
              </div>
            </div>
          </Col>
          {/* forest_protected === yes */}
          {watch("forest_protected") === "yes" && (
            <>
              {/* Complete */}
              <Col xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label"
                  for="forest_online_web_praposal_number"
                >
                  ઓન લાઈન વેબ પોર્ટલ ૫રની એપ્‍લીકેશન નંબર/ પ્રપ્રોઝલ નંબર
                </Label>
                <Controller
                  control={control}
                  name="forest_online_web_praposal_number"
                  render={({ field }) => {
                    return (
                      <Input
                        disabled={
                          watch("forest_protected") === null ||
                          watch("forest_protected") === "no"
                        }
                        {...field}
                        id="forest_online_web_praposal_number"
                        invalid={
                          errors.forest_online_web_praposal_number && true
                        }
                      />
                    );
                  }}
                />
                {errors.forest_online_web_praposal_number && (
                  <FormFeedback>
                    {errors.forest_online_web_praposal_number.message}
                  </FormFeedback>
                )}
              </Col>
              {/* Complete */}
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label"
                  for="forest_jamin_setrafal_hekatarma"
                >
                  જમીન ક્ષેત્રફળ હેકટરમાં
                </Label>
                <Controller
                  control={control}
                  name="forest_jamin_setrafal_hekatarma"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="forest_jamin_setrafal_hekatarma"
                        invalid={errors.forest_jamin_setrafal_hekatarma && true}
                      />
                    );
                  }}
                />
                {errors.forest_jamin_setrafal_hekatarma && (
                  <FormFeedback>
                    {errors.forest_jamin_setrafal_hekatarma.message}
                  </FormFeedback>
                )}
              </Col>
            </>
          )}
          {/* forest_protected === no */}
          {watch("forest_protected") === "no" && (
            <>
              {/* Complete */}
              <Col md={6} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="forest_vrokasoni_sankhiya">
                  વૃક્ષો ની સંખ્યા
                </Label>
                <Controller
                  control={control}
                  name="forest_vrokasoni_sankhiya"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="forest_vrokasoni_sankhiya"
                        invalid={errors.forest_vrokasoni_sankhiya && true}
                      />
                    );
                  }}
                />
                {errors.forest_vrokasoni_sankhiya && (
                  <FormFeedback>
                    {errors.forest_vrokasoni_sankhiya.message}
                  </FormFeedback>
                )}
              </Col>
            </>
          )}
          {/* Complete */}
          <Col md={6} xs={12} className="mb-1 mt-0">
            <Label
              className="form-label"
              for="forest_darkhast_krya_ptra_number"
            >
              દરખાસ્‍ત કર્યા ૫ત્ર નંબર
            </Label>
            <Controller
              control={control}
              name="forest_darkhast_krya_ptra_number"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    id="forest_darkhast_krya_ptra_number"
                    invalid={errors.forest_darkhast_krya_ptra_number && true}
                  />
                );
              }}
            />
            {errors.forest_darkhast_krya_ptra_number && (
              <FormFeedback>
                {errors.forest_darkhast_krya_ptra_number.message}
              </FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col md={6} xs={12} className="DatePicker-w100">
            <Label className="form-label" for="forest_darkhast_krya_ptra_date">
              દરખાસ્‍ત કર્યા તારીખ
            </Label>
            <Controller
              control={control}
              id="forest_darkhast_krya_ptra_date"
              name="forest_darkhast_krya_ptra_date"
              render={({ field }) => (
                <ReactDate
                  {...field}
                  maxDate={moment().toDate()}
                  selected={darkhastdate ? darkhastdate : null}
                  onChange={onChangeForestDarkhastKryaPtraDateSet}
                />
              )}
            />
            {errors.forest_darkhast_krya_ptra_date && (
              <FormFeedback className="d-block">
                {errors.forest_darkhast_krya_ptra_date.message}
              </FormFeedback>
            )}
          </Col>
          {/* complete */}
          <Col md={6} xs={12} className="mb-1">
            <Label
              className="form-label"
              for="forest_kai_kacherima_darkhast_karel_chhe"
            >
              કઈ કચેરીમાં દરખાસ્‍ત કરેલ છે?
            </Label>
            <Controller
              control={control}
              name="forest_kai_kacherima_darkhast_karel_chhe"
              render={({ field }) => {
                return (
                  <Input
                    // disabled
                    {...field}
                    id="forest_kai_kacherima_darkhast_karel_chhe"
                    invalid={
                      errors.forest_kai_kacherima_darkhast_karel_chhe && true
                    }
                  />
                );
              }}
            />
            {errors.forest_kai_kacherima_darkhast_karel_chhe && (
              <FormFeedback>
                {errors.forest_kai_kacherima_darkhast_karel_chhe.message}
              </FormFeedback>
            )}
          </Col>
          {/* complete */}
          <Col xs={12} className="mb-1 mt-1">
            <Label
              className="form-label"
              for="forest_halma_kai_kacherima_padatar_chhe"
            >
              હાલમાં કઈ કચેરીમાં દરખાસ્‍ત ૫ડતર છે?
            </Label>
            <Controller
              control={control}
              name="forest_halma_kai_kacherima_padatar_chhe"
              render={({ field }) => {
                return (
                  <Input
                    // disabled
                    {...field}
                    id="forest_halma_kai_kacherima_padatar_chhe"
                    invalid={
                      errors.forest_halma_kai_kacherima_padatar_chhe && true
                    }
                  />
                );
              }}
            />
            {errors.forest_halma_kai_kacherima_padatar_chhe && (
              <FormFeedback>
                {errors.forest_halma_kai_kacherima_padatar_chhe.message}
              </FormFeedback>
            )}
          </Col>
          {/* Complete */}
          <Col md={6} xs={12} className="mb-1 mt-1 DatePicker-w100">
            <Label className="form-label" for="forest_kai_date_padatar_chhe">
              કઈ તારીખથી ૫ડતર છે?
            </Label>
            <Controller
              control={control}
              id="forest_kai_date_padatar_chhe"
              name="forest_kai_date_padatar_chhe"
              render={({ field }) => (
                <ReactDate
                  {...field}
                  selected={kaiDatePadatarDate ? kaiDatePadatarDate : null}
                  maxDate={moment().toDate()}
                  onChange={(date) => {
                    if (date === null) {
                      setValue("forest_kai_date_padatar_chhe", null);
                    } else {
                      setValue("forest_kai_date_padatar_chhe", date);
                      setError("forest_kai_date_padatar_chhe", "");
                    }
                  }}
                />
              )}
            />
            {errors.forest_kai_date_padatar_chhe && (
              <FormFeedback className="d-block">
                {errors.forest_kai_date_padatar_chhe.message}
              </FormFeedback>
            )}
          </Col>
          {/* onChange pending */}
          <Col md={6} xs={12} className="mb-1 mt-1">
            <Label className="form-label" for="la_samaygalo">
              સમયગાળો
            </Label>
            <Controller
              control={control}
              name="la_samaygalo"
              render={({ field }) => {
                return (
                  <Input
                    disabled
                    {...field}
                    id="la_samaygalo"
                    invalid={errors.la_samaygalo && true}
                  />
                );
              }}
            />
            {errors.la_samaygalo && (
              <FormFeedback>{errors.la_samaygalo.message}</FormFeedback>
            )}
          </Col>
          {/* complete */}
          <Col md={6} xs={12} className="mb-1 mt-2">
            <div>
              <Label>કામ અટકાવેલ છે?</Label>
              <div className="demo-inline-spacing checkbox">
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={forest_one_kam_atakayel_chhe_radio}
                    {...forest_one_kam_atakayel_chhe_radiotest}
                    type="radio"
                    name="forest_one_kam_atakayel_chhe"
                    id="forest_one_kam_atakayel_chhe_yes"
                    value="yes"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_one_kam_atakayel_chhe_yes"
                    className="ms-50 form-check-label"
                  >
                    Yes
                  </Label>
                </div>
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={forest_one_kam_atakayel_chhe_radio}
                    {...forest_one_kam_atakayel_chhe_radiotest}
                    type="radio"
                    name="forest_one_kam_atakayel_chhe"
                    id="forest_one_kam_atakayel_chhe_no"
                    value="no"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_one_kam_atakayel_chhe_no"
                    className="ms-50 form-check-label"
                  >
                    No
                  </Label>
                </div>
              </div>
            </div>
          </Col>
          {/* Complete */}
          <Col md={12} xs={12} className="mb-1 mt-0">
            <Label className="form-label" for="forest_remakas">
              રીમાકર્સ (લેટેસ્‍ટ પોઝીશન આ૫વી)
            </Label>
            <Controller
              control={control}
              name="forest_remakas"
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    type="textarea"
                    rows="3"
                    id="forest_remakas"
                    className="custom-input-style"
                    invalid={errors.forest_remakas && true}
                  />
                );
              }}
            />
            {errors.forest_remakas && (
              <FormFeedback>{errors.forest_remakas.message}</FormFeedback>
            )}
          </Col>
          {/* forest_protected === yes */}
          {watch("forest_protected") === "yes" && (
            <>
              {/* complete */}
              <Col md={6} xs={12} className="mb-1 mt-0">
                <div>
                  <Label>કોના લેવલે પેન્ડીંગ છે?</Label>
                  <div className="demo-inline-spacing checkbox">
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest_kona_level_pending_chhe_radio}
                        {...forest_kona_level_pending_chhe_radiotest}
                        type="radio"
                        name="forest_kona_level_pending_chhe"
                        id="forest_kona_level_pending_chhe_regional"
                        value="regional"
                        className="form-check-input"
                      />
                      <Label
                        for="forest_kona_level_pending_chhe_regional"
                        className="ms-50 form-check-label"
                      >
                        Regional
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest_kona_level_pending_chhe_radio}
                        {...forest_kona_level_pending_chhe_radiotest}
                        type="radio"
                        name="forest_kona_level_pending_chhe"
                        id="forest_kona_level_pending_state"
                        value="state"
                        className="form-check-input"
                      />
                      <Label
                        for="forest_kona_level_pending_state"
                        className="ms-50 form-check-label"
                      >
                        State
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest_kona_level_pending_chhe_radio}
                        {...forest_kona_level_pending_chhe_radiotest}
                        type="radio"
                        name="forest_kona_level_pending_chhe"
                        id="forest_kona_level_pending_chhe_district"
                        value="district"
                        className="form-check-input"
                      />
                      <Label
                        for="forest_kona_level_pending_chhe_district"
                        className="ms-50 form-check-label"
                      >
                        District
                      </Label>
                    </div>
                    <div className="custom-control custom-radio">
                      <Input
                        innerRef={forest_kona_level_pending_chhe_radio}
                        {...forest_kona_level_pending_chhe_radiotest}
                        type="radio"
                        name="forest_kona_level_pending_chhe"
                        id="forest_kona_level_pending_chhe_none"
                        value="none"
                        className="form-check-input"
                      />
                      <Label
                        for="forest_kona_level_pending_chhe_none"
                        className="ms-50 form-check-label"
                      >
                        None
                      </Label>
                    </div>
                    <span>
                      {errors.forest_kona_level_pending_chhe && (
                        <FormFeedback className="d-block mt-0">
                          {errors.forest_kona_level_pending_chhe.message}
                        </FormFeedback>
                      )}
                    </span>
                  </div>
                </div>
              </Col>
            </>
          )}
          {/* complete */}
          <Col md={6} xs={12} className="mb-1 mt-0">
            <div>
              <Label>મંજૂરી મળેલ છે?</Label>
              <div className="demo-inline-spacing checkbox">
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={forest_manjuri_malel_chhe_radio}
                    {...forest_manjuri_malel_chhe_radiotest}
                    type="radio"
                    name="forest_manjuri_malel_chhe"
                    id="forest_manjuri_malel_chhe_yes"
                    value="yes"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_manjuri_malel_chhe_yes"
                    className="ms-50 form-check-label"
                  >
                    Yes
                  </Label>
                </div>
                <div className="custom-control custom-radio">
                  <Input
                    innerRef={forest_manjuri_malel_chhe_radio}
                    {...forest_manjuri_malel_chhe_radiotest}
                    type="radio"
                    name="forest_manjuri_malel_chhe"
                    id="forest_manjuri_malel_chhe_no"
                    value="no"
                    className="form-check-input"
                  />
                  <Label
                    for="forest_manjuri_malel_chhe_no"
                    className="ms-50 form-check-label"
                  >
                    No
                  </Label>
                </div>
              </div>
            </div>
          </Col>

          {watch("forest_manjuri_malel_chhe") === "yes" && (
            <>
              {/* 1st card */}
              <Col xs={12} className="mb-1">
                <Card className="mb-0 app-card">
                  <CardBody className="p-0 box-shadow-0">
                    <div className="card-header d-flex tab-card-header">
                      <h5 className="card-title">મંજૂરી મળ્યાની વિગતો</h5>
                    </div>
                    <div className="card-content p-2">
                      <Row>
                        {/* send Data Complete forest_in_principal_manjuri */}
                        <Col xs={12}>
                          <div className="custom-control custom-checkbox mb-0">
                            <Input
                              innerRef={forest_in_principal_manjuri_checkbox}
                              {...forest_in_principal_manjuri_checkboxtest}
                              type="checkbox"
                              name="forest_in_principal_manjuri"
                              id="forest_in_principal_manjuri_yes"
                              value="yes"
                              // disabled={!watch("forest_in_principal_manjuri")}
                              className="form-check-input"
                            />
                            <Label
                              for="forest_in_principal_manjuri_yes"
                              className="ms-50 form-check-label"
                            >
                              ઇન પ્રિન્સિપાલ મંજૂરી
                            </Label>
                          </div>
                          <span>
                            {errors?.forest_in_principal_manjuri && (
                              <FormFeedback className="d-block mt-0">
                                {errors?.forest_in_principal_manjuri?.message}
                              </FormFeedback>
                            )}
                          </span>
                        </Col>
                        <Col xs={4} className="mt-1">
                          <Label
                            className="form-label"
                            for="forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe"
                          >
                            કઈ કચેરીમાંથી મંજૂરી મળેલ છે?
                          </Label>
                          <Controller
                            control={control}
                            name="forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe"
                            render={({ field }) => {
                              return (
                                <Input
                                  {...field}
                                  disabled={
                                    !watch("forest_in_principal_manjuri")
                                  }
                                  id="forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe"
                                  invalid={
                                    errors.forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe &&
                                    true
                                  }
                                />
                              );
                            }}
                          />
                          {errors.forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe && (
                            <FormFeedback>
                              {
                                errors
                                  .forest_in_principal_manjuri_kai_kacherima_manjuri_malel_chhe
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                        <Col xs={4} className="mt-1">
                          <Label
                            className="form-label"
                            for="forest_in_principal_manjuri_malya_ptra_number"
                          >
                            મંજૂરી મળ્યા પત્ર નં.
                          </Label>
                          <Controller
                            control={control}
                            name="forest_in_principal_manjuri_malya_ptra_number"
                            render={({ field }) => {
                              return (
                                <Input
                                  disabled={
                                    !watch("forest_in_principal_manjuri")
                                  }
                                  {...field}
                                  id="forest_in_principal_manjuri_malya_ptra_number"
                                  invalid={
                                    errors.forest_in_principal_manjuri_malya_ptra_number &&
                                    true
                                  }
                                />
                              );
                            }}
                          />
                          {errors.forest_in_principal_manjuri_malya_ptra_number && (
                            <FormFeedback>
                              {
                                errors
                                  .forest_in_principal_manjuri_malya_ptra_number
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                        <Col xs={4} className="mt-1 DatePicker-w100">
                          <Label
                            className="form-label"
                            for="forest_in_principal_manjuri_malya_ptra_date"
                          >
                            મંજૂરી મળ્યા તારીખ
                          </Label>
                          <Controller
                            control={control}
                            id="forest_in_principal_manjuri_malya_ptra_date"
                            name="forest_in_principal_manjuri_malya_ptra_date"
                            render={({ field }) => (
                              <ReactDate
                                {...field}
                                maxDate={moment().toDate()}
                                selected={
                                  forestInPrincipalManjuriMalyaDate
                                    ? forestInPrincipalManjuriMalyaDate
                                    : null
                                }
                                disabled={!watch("forest_in_principal_manjuri")}
                                onChange={(date) => {
                                  if (date === null) {
                                    setValue(
                                      "forest_in_principal_manjuri_malya_ptra_date",
                                      null
                                    );
                                  } else {
                                    setValue(
                                      "forest_in_principal_manjuri_malya_ptra_date",
                                      date
                                    );
                                    setError(
                                      "forest_in_principal_manjuri_malya_ptra_date",
                                      ""
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.forest_in_principal_manjuri_malya_ptra_date && (
                            <FormFeedback className="d-block">
                              {
                                errors
                                  .forest_in_principal_manjuri_malya_ptra_date
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                        {/* send Data Complete forest_in_principal_manjuri */}

                        {/* send Data Complete forest_rs_bharavani_vigato */}
                        <Col xs={12} className="mt-3">
                          <div className="custom-control custom-checkbox mb-1">
                            <Input
                              innerRef={forest_rs_bharavani_vigato_checkbox}
                              {...forest_rs_bharavani_vigato_checkboxtest}
                              type="checkbox"
                              name="forest_rs_bharavani_vigato"
                              id="forest_rs_bharavani_vigato_yes"
                              value="yes"
                              disabled={!watch("forest_in_principal_manjuri")}
                              className="form-check-input"
                            />
                            <Label
                              for="forest_rs_bharavani_vigato_yes"
                              className="ms-50 form-check-label"
                            >
                              નાણાં ભરવાની વિગતો
                            </Label>
                          </div>
                          <span>
                            {errors?.forest_rs_bharavani_vigato && (
                              <FormFeedback className="d-block mt-0">
                                {errors?.forest_rs_bharavani_vigato?.message}
                              </FormFeedback>
                            )}
                          </span>
                        </Col>
                        <Col xs={4} className="mt-1">
                          <Label className="form-label" for="forest_rakam_rsma">
                            રકમ - રૂપિયામાં
                          </Label>
                          <Controller
                            control={control}
                            name="forest_rakam_rsma"
                            render={({ field }) => {
                              return (
                                <Input
                                  disabled={
                                    !watch("forest_rs_bharavani_vigato") ||
                                    !watch("forest_in_principal_manjuri")
                                  }
                                  {...field}
                                  id="forest_rakam_rsma"
                                  invalid={errors.forest_rakam_rsma && true}
                                />
                              );
                            }}
                          />
                          {errors.forest_rakam_rsma && (
                            <FormFeedback>
                              {errors.forest_rakam_rsma.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col xs={4} className="mt-1 DatePicker-w100">
                          <Label
                            className="form-label"
                            for="forest_rs_bhariyani_date"
                          >
                            નાણાં ભર્યા તારીખ
                          </Label>
                          <Controller
                            control={control}
                            id="forest_rs_bhariyani_date"
                            name="forest_rs_bhariyani_date"
                            render={({ field }) => (
                              <ReactDate
                                {...field}
                                maxDate={moment().toDate()}
                                selected={
                                  forestRsBhariyaniDate
                                    ? forestRsBhariyaniDate
                                    : null
                                }
                                disabled={
                                  !watch("forest_rs_bharavani_vigato") ||
                                  !watch("forest_in_principal_manjuri")
                                }
                                onChange={(date) => {
                                  if (date === null) {
                                    setValue("forest_rs_bhariyani_date", null);
                                  } else {
                                    setValue("forest_rs_bhariyani_date", date);
                                    setError("forest_rs_bhariyani_date", "");
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.forest_rs_bhariyani_date && (
                            <FormFeedback className="d-block">
                              {errors.forest_rs_bhariyani_date.message}
                            </FormFeedback>
                          )}
                        </Col>
                        {/* send Data Complete forest_rs_bharavani_vigato */}

                        {/* forest_protected === yes */}
                        {watch("forest_protected") === "yes" && (
                          <>
                            {/* 3rd */}
                            <Col xs={12} className="mt-3">
                              <div className="custom-control custom-checkbox mb-1">
                                <Input
                                  innerRef={forest_working_permission_checkbox}
                                  {...forest_working_permission_checkboxtest}
                                  type="checkbox"
                                  name="forest_working_permission"
                                  id="forest_working_permission_yes"
                                  value="yes"
                                  disabled={
                                    !watch("forest_rs_bharavani_vigato") ||
                                    !watch("forest_in_principal_manjuri")
                                  }
                                  className="form-check-input"
                                />
                                <Label
                                  for="forest_working_permission_yes"
                                  className="ms-50 form-check-label"
                                >
                                  વર્કિંગ પરમીશન
                                </Label>
                              </div>
                            </Col>
                            <Col xs={4} className="mt-1">
                              <Label
                                className="form-label"
                                for="forest_working_permission_manjuri_malya_ptra_number"
                              >
                                મંજૂરી મળ્યા પત્ર નં.
                              </Label>
                              <Controller
                                control={control}
                                name="forest_working_permission_manjuri_malya_ptra_number"
                                render={({ field }) => {
                                  return (
                                    <Input
                                      {...field}
                                      disabled={
                                        !watch("forest_rs_bharavani_vigato") ||
                                        !watch("forest_in_principal_manjuri") ||
                                        !watch("forest_working_permission")
                                      }
                                      id="forest_working_permission_manjuri_malya_ptra_number"
                                      invalid={
                                        errors.forest_working_permission_manjuri_malya_ptra_number &&
                                        true
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.forest_working_permission_manjuri_malya_ptra_number && (
                                <FormFeedback>
                                  {
                                    errors
                                      .forest_working_permission_manjuri_malya_ptra_number
                                      .message
                                  }
                                </FormFeedback>
                              )}
                            </Col>
                            {/* Complete */}
                            <Col xs={4} className="mt-1 DatePicker-w100">
                              <Label
                                className="form-label"
                                for="forest_working_permission_manjuri_malya_ptra_date"
                              >
                                મંજૂરી મળ્યા તારીખ
                              </Label>
                              <Controller
                                control={control}
                                id="forest_working_permission_manjuri_malya_ptra_date"
                                name="forest_working_permission_manjuri_malya_ptra_date"
                                render={({ field }) => (
                                  <ReactDate
                                    {...field}
                                    disabled={
                                      !watch("forest_rs_bharavani_vigato") ||
                                      !watch("forest_in_principal_manjuri") ||
                                      !watch("forest_working_permission")
                                    }
                                    selected={
                                      forestWorkingPermissionManjuriMalyaPtraDate
                                        ? forestWorkingPermissionManjuriMalyaPtraDate
                                        : null
                                    }
                                    maxDate={moment().toDate()}
                                    onChange={(date) => {
                                      if (date === null) {
                                        setValue(
                                          "forest_working_permission_manjuri_malya_ptra_date",
                                          null
                                        );
                                      } else {
                                        setValue(
                                          "forest_working_permission_manjuri_malya_ptra_date",
                                          date
                                        );
                                        setError(
                                          "forest_working_permission_manjuri_malya_ptra_date",
                                          ""
                                        );
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.forest_working_permission_manjuri_malya_ptra_date && (
                                <FormFeedback className="d-block">
                                  {
                                    errors
                                      .forest_working_permission_manjuri_malya_ptra_date
                                      .message
                                  }
                                </FormFeedback>
                              )}
                            </Col>
                            {/* 4th */}
                            <Col xs={12} className="mt-3">
                              <div className="custom-control custom-checkbox mb-1">
                                <Input
                                  innerRef={forest_stage_twoni_manjuri_checkbox}
                                  {...forest_stage_twoni_manjuri_checkboxtest}
                                  type="checkbox"
                                  name="forest_stage_twoni_manjuri"
                                  id="forest_stage_twoni_manjuri_yes"
                                  value="yes"
                                  className="form-check-input"
                                />
                                <Label
                                  for="forest_stage_twoni_manjuri_yes"
                                  className="ms-50 form-check-label"
                                >
                                  સ્ટેજ - ૨ ની મંજૂરી
                                </Label>
                              </div>
                            </Col>
                            <Col xs={4} className="mt-1">
                              <Label
                                className="form-label"
                                for="forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe"
                              >
                                કઈ કચેરીમાંથી મંજૂરી મળેલ છે?
                              </Label>
                              <Controller
                                control={control}
                                name="forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe"
                                render={({ field }) => {
                                  return (
                                    <Input
                                      {...field}
                                      disabled={
                                        !watch("forest_stage_twoni_manjuri")
                                      }
                                      id="forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe"
                                      invalid={
                                        errors.forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe &&
                                        true
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe && (
                                <FormFeedback>
                                  {
                                    errors
                                      .forest_stagetwoni_manjuri_kai_kacherima_manjuri_malel_chhe
                                      .message
                                  }
                                </FormFeedback>
                              )}
                            </Col>
                            <Col xs={4} className="mt-1">
                              <Label
                                className="form-label"
                                for="forest_stagetwoni_manjuri_manjuri_maliya_ptra_number"
                              >
                                મંજૂરી મળ્યા પત્ર નં.
                              </Label>
                              <Controller
                                control={control}
                                name="forest_stagetwoni_manjuri_manjuri_maliya_ptra_number"
                                render={({ field }) => {
                                  return (
                                    <Input
                                      {...field}
                                      disabled={
                                        !watch("forest_stage_twoni_manjuri")
                                      }
                                      id="forest_stagetwoni_manjuri_manjuri_maliya_ptra_number"
                                      invalid={
                                        errors.forest_stagetwoni_manjuri_manjuri_maliya_ptra_number &&
                                        true
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.forest_stagetwoni_manjuri_manjuri_maliya_ptra_number && (
                                <FormFeedback>
                                  {
                                    errors
                                      .forest_stagetwoni_manjuri_manjuri_maliya_ptra_number
                                      .message
                                  }
                                </FormFeedback>
                              )}
                            </Col>
                            {/* Complete */}
                            <Col xs={4} className="mt-1 DatePicker-w100">
                              <Label
                                className="form-label"
                                for="forest_stagetwoni_manjuri_maliya_date"
                              >
                                મંજૂરી મળ્યા તારીખ
                              </Label>
                              <Controller
                                control={control}
                                id="forest_stagetwoni_manjuri_maliya_date"
                                name="forest_stagetwoni_manjuri_maliya_date"
                                render={({ field }) => (
                                  <ReactDate
                                    {...field}
                                    selected={
                                      forestStagetwoniManjuriMaliyaDate
                                        ? forestStagetwoniManjuriMaliyaDate
                                        : null
                                    }
                                    disabled={
                                      !watch("forest_stage_twoni_manjuri")
                                    }
                                    maxDate={moment().toDate()}
                                    onChange={(date) => {
                                      if (date === null) {
                                        setValue(
                                          "forest_stagetwoni_manjuri_maliya_date",
                                          null
                                        );
                                      } else {
                                        setValue(
                                          "forest_stagetwoni_manjuri_maliya_date",
                                          date
                                        );
                                        setError(
                                          "forest_stagetwoni_manjuri_maliya_date",
                                          ""
                                        );
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.forest_stagetwoni_manjuri_maliya_date && (
                                <FormFeedback className="d-block">
                                  {
                                    errors.forest_stagetwoni_manjuri_maliya_date
                                      .message
                                  }
                                </FormFeedback>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* 2nd card */}
              <Col xs={12} className="mb-1 ">
                <Card className="mb-0 app-card">
                  <CardBody className="p-0 box-shadow-0">
                    <div className="card-header d-flex tab-card-header">
                      <h5 className="card-title">વૃક્ષો કાપણીની વિગતો</h5>
                    </div>
                    <div className="card-content p-2">
                      <Row>
                        <Col xs={12} className="d-flex">
                          <div className="custom-control custom-radio">
                            <Input
                              innerRef={forest_mam_vibhag_dwara_radio}
                              {...forest_mam_vibhag_dwara_radiotest}
                              type="checkbox"
                              name="forest_mam_vibhag_dwara"
                              id="forest_mam_vibhag_dwara_yes"
                              value="yes"
                              className="form-check-input"
                            />
                            <Label
                              for="forest_mam_vibhag_dwara_yes"
                              className="form-check-label ms-50"
                            >
                              વન વિભાગ ધ્વારા
                            </Label>
                          </div>
                          <div className="custom-control custom-radio ms-2">
                            <Input
                              innerRef={forest_mam_vibhag_dwara_radio}
                              {...forest_mam_vibhag_dwara_radiotest}
                              type="checkbox"
                              name="forest_mam_vibhag_dwara"
                              id="forest_mam_vibhag_dwara_no"
                              value="no"
                              className="form-check-input"
                            />
                            <Label
                              for="forest_mam_vibhag_dwara_no"
                              className="form-check-label ms-50"
                            >
                              મા.મ. વિભાગ ધ્વારા
                            </Label>
                          </div>
                          <span>
                            {errors?.forest_mam_vibhag_dwara && (
                              <FormFeedback className="d-block ms-2 mt-0">
                                {errors?.forest_mam_vibhag_dwara?.message}
                              </FormFeedback>
                            )}
                          </span>
                        </Col>
                        <Col md={12} xs={12} className="mt-1">
                          <Label
                            className="form-label"
                            for="forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti"
                          >
                            વૃક્ષો કાપણીની આજરોજ અંતિત પરિસ્થિતી
                          </Label>
                          <Controller
                            control={control}
                            name="forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti"
                            render={({ field }) => {
                              return (
                                <Input
                                  {...field}
                                  type="textarea"
                                  rows="3"
                                  className="custom-input-style"
                                  id="forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti"
                                  disabled={falseTrueForVrukhso}
                                  invalid={
                                    errors.forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti &&
                                    true
                                  }
                                />
                              );
                            }}
                          />
                          {errors.forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti && (
                            <FormFeedback>
                              {
                                errors
                                  .forest_mam_vibhag_dwara_vrokaso_kapanini_aajroj_antim_paristhti
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                        {/* complete */}
                        <Col xs={6} className="mt-1 DatePicker-w100">
                          <Label
                            className="form-label"
                            for="forest_mam_vibhag_dwara_kapnai_puran_thaya_date"
                          >
                            વૃક્ષો કાપણી પૂર્ણ થયા તારીખ
                          </Label>
                          <Controller
                            control={control}
                            id="forest_mam_vibhag_dwara_kapnai_puran_thaya_date"
                            name="forest_mam_vibhag_dwara_kapnai_puran_thaya_date"
                            render={({ field }) => (
                              <ReactDate
                                {...field}
                                maxDate={moment().toDate()}
                                selected={
                                  forestMamVibhagDwaraKapnaiPuranThayaDate
                                    ? forestMamVibhagDwaraKapnaiPuranThayaDate
                                    : null
                                }
                                disabled={falseTrueForVrukhso}
                                onChange={(date) => {
                                  setValue(
                                    "forest_mam_vibhag_dwara_kapnai_puran_thaya_date",
                                    date
                                  );
                                  setValue(
                                    "forest_kliyaransni_kamgiri_puran_thayel_date",
                                    date
                                  );
                                  if (date === null) {
                                    setValue(
                                      "forest_kliyaransni_kamgiri_puran_thayel_chhe",
                                      "no"
                                    );
                                  } else {
                                    setValue(
                                      "forest_kliyaransni_kamgiri_puran_thayel_chhe",
                                      "yes"
                                    );
                                    setError(
                                      "forest_mam_vibhag_dwara_kapnai_puran_thaya_date",
                                      ""
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                          {/* **************************************************** */}
                          {errors.forest_mam_vibhag_dwara_kapnai_puran_thaya_date && (
                            <FormFeedback className="d-block">
                              {
                                errors
                                  .forest_mam_vibhag_dwara_kapnai_puran_thaya_date
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={6} xs={12} className="mt-1">
                          <div>
                            <Label>
                              ફોરેસ્ટ ક્લીયરન્સની કામગીરી પૂર્ણ થયેલ છે?
                            </Label>
                            <div className="demo-inline-spacing checkbox">
                              <div className="custom-control custom-radio">
                                <Input
                                  innerRef={
                                    forest_kliyaransni_kamgiri_puran_thayel_chhe_radio
                                  }
                                  {...forest_kliyaransni_kamgiri_puran_thayel_chhe_radiotest}
                                  type="radio"
                                  name="forest_kliyaransni_kamgiri_puran_thayel_chhe"
                                  id="forest_kliyaransni_kamgiri_puran_thayel_chhe_yes"
                                  value="yes"
                                  className="form-check-input"
                                />
                                <Label
                                  for="forest_kliyaransni_kamgiri_puran_thayel_chhe_yes"
                                  className="ms-50 form-check-label"
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="custom-control custom-radio">
                                <Input
                                  innerRef={
                                    forest_kliyaransni_kamgiri_puran_thayel_chhe_radio
                                  }
                                  {...forest_kliyaransni_kamgiri_puran_thayel_chhe_radiotest}
                                  type="radio"
                                  name="forest_kliyaransni_kamgiri_puran_thayel_chhe"
                                  id="forest_kliyaransni_kamgiri_puran_thayel_chhe_no"
                                  value="no"
                                  className="form-check-input"
                                />
                                <Label
                                  for="forest_kliyaransni_kamgiri_puran_thayel_chhe_no"
                                  className="ms-50 form-check-label"
                                >
                                  No
                                </Label>
                              </div>
                              <span>
                                {errors?.forest_kliyaransni_kamgiri_puran_thayel_chhe && (
                                  <FormFeedback className="d-block mt-0">
                                    {
                                      errors
                                        ?.forest_kliyaransni_kamgiri_puran_thayel_chhe
                                        ?.message
                                    }
                                  </FormFeedback>
                                )}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} className="mt-3 DatePicker-w100">
                          <Label
                            className="form-label"
                            for="forest_kliyaransni_kamgiri_puran_thayel_date"
                          >
                            ફોરેસ્ટ ક્લીયરન્સની કામગીરી પૂર્ણ થયા તારીખ
                          </Label>
                          <Controller
                            control={control}
                            id="forest_kliyaransni_kamgiri_puran_thayel_date"
                            name="forest_kliyaransni_kamgiri_puran_thayel_date"
                            render={({ field }) => (
                              <ReactDate
                                {...field}
                                selected={
                                  forestKliyaransniKamgiriPuranThayelDate
                                    ? forestKliyaransniKamgiriPuranThayelDate
                                    : null
                                }
                                disabled
                                onChange={(date) => {
                                  setValue(
                                    "forest_kliyaransni_kamgiri_puran_thayel_date",
                                    date
                                  );
                                }}
                              />
                            )}
                          />
                          {errors.forest_kliyaransni_kamgiri_puran_thayel_date && (
                            <FormFeedback className="d-block">
                              {
                                errors
                                  .forest_kliyaransni_kamgiri_puran_thayel_date
                                  .message
                              }
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
          <Col xs={12} className="mb-2">
            <Button
              className="btn btn-outline-dark waves-effect me-25"
              type="reset"
              outline
            >
              Cancel
            </Button>
            <Button
              color="primary"
              // type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ForestClearance;
