/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardBody,
} from "reactstrap";
import {
  useTable,
  usePagination,
  // useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Excel from "../../../../assets/images/excel.png";
import Print from "../../../../assets/images/print.png";
import Abstract from "../../../../assets/images/Abstract.png";
import ColumnsVisibility from "../../../../assets/images/ColumnsVisibility.png";
// import AddBudgetHead from "../SAModals/AddBudgetHead";
// import { toast } from "react-toastify";

import {
  EditPenSvg,
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../assets/images";
import ReactPaginate from "react-paginate";
import { SelectCustomStyles, dateFormate } from "../../../../common/utils";

// import moment from "moment";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  columnChanges,
  getColumnlist,
} from "../../../../services/adminDashboard";
// import { getErrorMsg } from "../../../../common/ErrorMessage";
import { getColumns } from "./utils";
import { getExpenditureList } from "../../../../services/eeservices";

const MainWorkExpenditure = () => {
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValues, setPaginationValues] = useState({
    currentPage: 1,
    rowPerPage: 50,
    AllPages: 1,
  });
  const loginUserid = useSelector((state) => state.auth.userData.data.user_id);

  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: "all", label: "All" },
  ];

  // ** Get List Of Tender Type
  // const pageLength = 1;

  function addDuplicateForObjectWithSpecificValue(dataArray) {
    const result = [];
    // Remove duplicate package ids
    const seenPackageIds = {};
    const data = dataArray.filter((item) => {
      if (item.package_id > 0) {
        if (!seenPackageIds[item.package_id]) {
          seenPackageIds[item.package_id] = true;
          return true;
        }
        return false;
      } else {
        return true;
      }
    });

    let sr_no = 1;
    for (const obj of data) {
      if (obj.govtplan_bytender === "govt") {
        // obj.id === 1262 &&
        //   console.log(
        //     "updatedData: 2",
        //     obj.govt_target_expenditure_of_current_fy_year,
        //     obj.tender_target_expenditure_of_current_fy_year
        //   );

        result.push({
          ...obj,
          name_of_work_title: "Government Plant",
          dtp_approval_date: obj.govplan_date,
          dtp_amount: obj.govplan_amount,
          tentative_tpc: obj.govt_total_project_cost,
          tender_app_amount:
            obj.tender_app_amount === null ? "--" : obj.tender_app_amount,
          sr_no: sr_no++,

          tender_expen_incured_last_fy_year:
            obj.govt_expen_incured_last_fy_year,
          tender_spill_over_this_fy_year_n_current_year_approved_work:
            obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
          tender_target_expenditure_of_current_fy_year:
            obj.govt_target_expenditure_of_current_fy_year,
          tender_current_year_upto_today: obj.govt_current_year_upto_today,
          tender_probable_expen_from_today_to_end_of_this_fy_year:
            obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
          tender_total_expenditure_as_on_today_on_this_project:
            obj.govt_total_expenditure_as_on_today_on_this_project,

          prev_main_work_amt_tender: obj.prev_main_work_amt_gov,
          prev_forest_utility_misc_work_amt_tender:
            obj.prev_forest_utility_misc_work_amt_gov,
          curr_main_work_amt_tender: obj.curr_main_work_amt_gov,
          curr_forest_utility_misc_work_amt_tender:
            obj.curr_forest_utility_misc_work_amt_gov,

          tender_current_spillover_of_project:
            obj.govt_current_spillover_of_project,
          tender_modified_probeble_expen_from_today_to_fy_year:
            obj.govt_modified_probeble_expen_from_today_to_fy_year,
          tender_modification_in_target_expenditure_of_this_fy_year:
            obj.govt_modification_in_target_expenditure_of_this_fy_year,
          tender_final_modified_target_expenditure_of_this_fy_year:
            obj.govt_final_modified_target_expenditure_of_this_fy_year,

          prev_work_expense_id: obj.prev_work_expense_id_gov,
          curr_work_expense_id: obj.curr_work_expense_id_gov,
        });
        result.push({
          ...obj,
          isFurnitureRow: `${obj.id}@`,
          govtplan_bytender: "tender",
          name_of_work_title: "Furniture+Misc.",
          sr_no: "",
          tentative_tpc: obj.tender_total_project_cost,
          prev_work_expense_id: obj.prev_work_expense_id_tender,
          curr_work_expense_id: obj.curr_work_expense_id_tender,
        });
      } else if (obj.package_id !== 0 && obj.package_id !== null) {
        result.push({
          ...obj,
          name_of_work_title: "Package",
          sr_no: sr_no++,
          // prev_main_work_amt_tender: obj.prev_main_work_amt,
          // prev_forest_utility_misc_work_amt_tender:
          //   obj.prev_forest_utility_misc_work_amt,
          // curr_main_work_amt_tender: obj.curr_main_work_amt,
          // curr_forest_utility_misc_work_amt_tender:
          //   obj.curr_forest_utility_misc_work_amt,
          prev_work_expense_id: obj.prev_work_expense_id_tender,
          curr_work_expense_id: obj.curr_work_expense_id_tender,
        });
        obj.sub_work.map((row) =>
          result.push({
            ...row,
            name_of_work_title: "",
            sr_no: "",
            dtp_amount: "--",
            dtp_approval_date: "--",
            tender_opening_date: "--",
            tender_app_amount: "--",
            tender_app_date: "--",
            agency_gujarati: "--",
            agency_name: "--",
            work_order_date: "--",
            date_of_completion_as_per_wo: "--",
            likely_date_of_completion: "--",
            date_of_completion_as_per_approved_timelimit: "--",
            tentative_tpc: "--",
            tender_expen_incured_last_fy_year: "--",
            tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
            tender_target_expenditure_of_current_fy_year: "--",
            tender_current_year_upto_today: "--",
            tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
            tender_total_expenditure_as_on_today_on_this_project: "--",
            financial_progress: "--",
            physical_progress: "--",
            workstatus_detail: "--",
            work_status: "--",

            prev_main_work_amt_tender: "--",
            prev_forest_utility_misc_work_amt_tender: "--",
            curr_main_work_amt_tender: "--",
            curr_forest_utility_misc_work_amt_tender: "--",
            tender_current_spillover_of_project: "--",
            tender_modified_probeble_expen_from_today_to_fy_year: "--",
            tender_modification_in_target_expenditure_of_this_fy_year: "--",
            tender_final_modified_target_expenditure_of_this_fy_year: "--",
          })
        );
        // }
      } else {
        result.push({
          ...obj,
          name_of_work_title: "",
          sr_no: sr_no++,
          prev_work_expense_id: obj.prev_work_expense_id_tender,
          curr_work_expense_id: obj.curr_work_expense_id_tender,
        });
      }
    }
    return result;
  }

  const handleApi = React.useCallback(() => {
    setIsLoading(true);
    const payload = {
      page: paginationValues.currentPage,
      per_page: paginationValues.rowPerPage,
    };
    getExpenditureList(payload)
      .then((res) => {
        setPaginationValues({
          ...paginationValues,
          AllPages: res.data.last_page,
        });

        const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err--->>>", err);
        setIsLoading(false);
      });
  }, [paginationValues.currentPage, paginationValues.rowPerPage]);

  useEffect(() => {
    handleApi();
  }, [paginationValues.currentPage, paginationValues.rowPerPage]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // ** Edit WORK
  const handleEditStage = (data) => {
    const url = `/update-work/${data?.id}`;
    window.open(url, "_blank");
  };

  // setSearchParams({ workid: `workid=${data.id}` });
  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData?.map((item) => ({
      id: item.id,
      srno: item.sr_no,
      sub_division: item.subdivision_name,
      budget_head: item.budget_head_name,
      name_of_work: `${item.name_of_work_title}\n${item.name_of_work_english}`,
      tender_app_amount: item.tender_app_amount,
      name_of_agency: item.agency_name,
      tentativetotal: item.tentative_tpc,
      expenincu: item.tender_expen_incured_last_fy_year,
      Spillover:
        item.tender_spill_over_this_fy_year_n_current_year_approved_work,
      targetExpen: item.tender_target_expenditure_of_current_fy_year,
      Expenincurrentyear: item.tender_current_year_upto_today,
      probableExpen:
        item.tender_probable_expen_from_today_to_end_of_this_fy_year,
      totalExpen: item.tender_total_expenditure_as_on_today_on_this_project,

      prev_main_work: item.prev_main_work_amt_tender,
      prev_forest_utility: item.prev_forest_utility_misc_work_amt_tender,
      curr_main_work: item.curr_main_work_amt_tender,
      curr_forest_utility: item.curr_forest_utility_misc_work_amt_tender,
      currentSpillOverOfProject: item.tender_current_spillover_of_project,
      probableExpennInput:
        item.tender_modified_probeble_expen_from_today_to_fy_year,
      modificationTarget:
        item.tender_modification_in_target_expenditure_of_this_fy_year,
      finalModifiedTarget:
        item.tender_final_modified_target_expenditure_of_this_fy_year,

      modified_yes_no: item.modified_yes_no,
      target_yes_no: item.target_yes_no,
      packageId: item.package_id,
      govtPlanByTender: item.govtplan_bytender,
      prev_work_expense_id: item.prev_work_expense_id,
      curr_work_expense_id: item.curr_work_expense_id,
      isFurnitureRow: item.isFurnitureRow,
    }));
  }, [tableData]);

  // ** Columns For Table
  const columns = useMemo(() => getColumns(handleEditStage), []);

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    // state: { pageIndex, pageSize },
    // gotoPage,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    // setPageSize,
    // preGlobalFilteredRows,
    toggleHideColumn,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      // initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    // useSortBy,
    usePagination
  );

  // **
  // const dropdownOptions = columns.map((column) => ({
  //   value: column.accessor,
  //   label: column.Header,
  // }));

  let dropdownOptions = [];

  columns.forEach((row) => {
    const array = row.columns.map((item) => ({
      value: item.accessor,
      label: item.Header,
    }));
    dropdownOptions = [...dropdownOptions, ...array];
  });

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (!hiddenColumns.includes(column.accessor)) {
        left += column.minWidth ? parseInt(column.minWidth, 10) : 120;
      }
    }
    return left;
  }

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUserid,
      module_name: "allProjects",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      allColumns.forEach((item, index) => {
        if (row === item.id) {
          data.push(index);
        }
      });
    }

    return data;
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      toggleHideColumn(value, false);
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      toggleHideColumn(value, true);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];

    const hiddenCol = columnsArr.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const item = allColumns[row];
        toggleHideColumn(item.id, true);
        data.push(item.id);
      }
    }

    return data;
  };

  const onColumnUpdate = (
    { work_id, govtplan_bytender },
    data,
    isFurnitureRow,
    workExpenseIdKey
  ) => {
    const updatedData = [...tableData];
    let rowIndex;
    if (isFurnitureRow) {
      rowIndex = updatedData.findIndex(
        (item) => item.isFurnitureRow === isFurnitureRow
      );
    } else {
      rowIndex = updatedData.findIndex((item) => item.id === work_id);
    }

    if (workExpenseIdKey) {
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        ...data,
        id: data.work_id,
        [workExpenseIdKey]: data.work_expense_id,
      };
    } else {
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        ...data,
        id: data.work_id,
      };
    }

    if (govtplan_bytender === "govt" && !isFurnitureRow) {
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        // name_of_work_title: "Government Plant",
        dtp_approval_date: data.govplan_date,
        dtp_amount: data.govplan_amount,
        tentative_tpc: data.govt_total_project_cost,
        tender_app_amount:
          data.tender_app_amount === null ? "--" : data.tender_app_amount,

        prev_main_work_amt_tender: data.prev_main_work_amt_gov,
        prev_forest_utility_misc_work_amt_tender:
          data.prev_forest_utility_misc_work_amt_gov,
        curr_main_work_amt_tender: data.curr_main_work_amt_gov,
        curr_forest_utility_misc_work_amt_tender:
          data.curr_forest_utility_misc_work_amt_gov,

        tender_expen_incured_last_fy_year: data.govt_expen_incured_last_fy_year,
        tender_spill_over_this_fy_year_n_current_year_approved_work:
          data.govt_spill_over_this_fy_year_n_current_year_approved_work,
        tender_target_expenditure_of_current_fy_year:
          data.govt_target_expenditure_of_current_fy_year,
        tender_current_year_upto_today: data.govt_current_year_upto_today,
        tender_probable_expen_from_today_to_end_of_this_fy_year:
          data.govt_probable_expen_from_today_to_end_of_this_fy_year,

        tender_total_expenditure_as_on_today_on_this_project:
          data.govt_total_expenditure_as_on_today_on_this_project,
        tender_current_spillover_of_project:
          data.govt_current_spillover_of_project,
        tender_modified_probeble_expen_from_today_to_fy_year:
          data.govt_modified_probeble_expen_from_today_to_fy_year,
        tender_modification_in_target_expenditure_of_this_fy_year:
          data.govt_modification_in_target_expenditure_of_this_fy_year,
        tender_final_modified_target_expenditure_of_this_fy_year:
          data.govt_final_modified_target_expenditure_of_this_fy_year,
      };
    }

    setTableData(updatedData);
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUserid,
      module_name: "allProjects",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  }, []);

  // if (tableData.length === 0) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <div className="table-title ee_new_work_title">
        <h2 className="text-black">EXPENDITURE</h2>
        {dateFormate()}
      </div>
      <Card>
        <CardBody className="basic_tab p-0">
          <div className="dt-action-button btn_search_box d-flex">
            <div>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">Excel</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Print} alt="icon" className="me-1" />
                <span className="align-middle">Print</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">PDF</span>
              </Button.Ripple>

              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="projects_btn me-25"
              >
                <DropdownToggle color="primary" outline caret>
                  <img src={ColumnsVisibility} alt="icon" className="me-1" />
                  <span className="align-middle">Columns Visibility</span>
                </DropdownToggle>
                <DropdownMenu className="p-40 vb-drop column_visibility">
                  {dropdownOptions.map((option, index) => (
                    <Button.Ripple
                      key={option.value}
                      color="primary"
                      onClick={() =>
                        handleToggleHiddenColumns(index, option.value)
                      }
                      className={
                        !hiddenColumns.includes(option.value)
                          ? "active w-100 visibility-Option"
                          : "w-100 visibility-Option bg-white"
                      }
                    >
                      {option.label}
                    </Button.Ripple>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
            <div className="search_Box d-flex">
              <input
                type="text"
                placeholder="Search"
                className="form-control search_bar"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    // const isVisible = !hiddenColumns.includes(column.id);

                    return (
                      <col
                        key={columnIndex}
                        style={{
                          // visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      // const isVisible = !hiddenColumns.includes(column.id);

                      const isFrozen =
                        frozenColumn === column.id || columnIndex < 4;

                      // Use classnames to apply multiple classes conditionally
                      const headerCellClass = classNames({
                        "dtfc-fixed-left": isFrozen,
                        "dtfc-cell": !isFrozen,
                        // desc: column.isSorted && column.isSortedDesc,
                        // asc: column.isSorted && !column.isSortedDesc,
                      });
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={headerCellClass}
                          rowSpan={column.rowSpan ? column.rowSpan : ""}
                          style={{
                            display: column.isDisplayNone ? "none" : "",
                            // visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                          // {...column.getSortByToggleProps()}
                          // className="columnSort"
                          >
                            {column.render("Header")}
                            {/* <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span> */}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="scrollable-body">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        // const isVisible = !hiddenColumns.includes(
                        //   cell.column.id
                        // );

                        const isFrozen =
                          frozenColumn === cell.column.id || cellIndex < 4;
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                            }
                            style={{
                              color:
                                cell.column.id ===
                                  "currentSpillOverOfProject" &&
                                !isNaN(
                                  cell.row.values.currentSpillOverOfProject
                                ) &&
                                cell.row.values.currentSpillOverOfProject < 0
                                  ? "#ea5455"
                                  : "",
                              // visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                              // background: "white",
                            }}
                          >
                            {cell.render("Cell", { onColumnUpdate })}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: paginationValues.rowPerPage,
                  label:
                    paginationValues.rowPerPage === "all"
                      ? "All"
                      : paginationValues.rowPerPage,
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPaginationValues({
                    ...paginationValues,
                    currentPage: 1,
                    rowPerPage: selectedOption.value,
                  });
                  // setPageSize(Number(selectedOption.value));
                  // setPageSizeForAPI(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>
            <ReactPaginate
              nextLabel=""
              pageCount={paginationValues.AllPages}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => {
                setPaginationValues({
                  ...paginationValues,
                  currentPage: selected.selected + 1,
                });
                // gotoPage(selected.selected)
              }}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
            {/* ReactPaginate End */}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MainWorkExpenditure;
