/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Label,
  Input,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Excel from "../../../../assets/images/excel.png";
import Print from "../../../../assets/images/print.png";
import Abstract from "../../../../assets/images/Abstract.png";
import ColumnsVisibility from "../../../../assets/images/ColumnsVisibility.png";

import {
  EditPenSvg,
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../assets/images";
import ReactPaginate from "react-paginate";
import { SelectCustomStyles } from "../../../../common/utils";
import { getHeadwiseExpenditureAbstract } from "../../../../services/reportsServices";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ReportPaaReceivedAaNotReceivedModal from "../../ProjectReview/NotStarted/PaaReceivedAaNotReceived/ReportPaaReceivedAaNotReceivedModal";
import moment from "moment";
import { getAbstractData, getHeaderColumnsByRole } from "./utils";
import {
  columnChanges,
  getColumnlist,
} from "../../../../services/adminDashboard";
import { useSelector } from "react-redux";
import { getErrorMsg } from "../../../../common/ErrorMessage";

const HeadwiseExpenditureAbstract = () => {
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const loginUserid = useSelector((state) => state.auth.userData.data.user_id);
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: tableData?.length, label: "All" },
  ];
  // For english/gujrati button
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [engGujState, setEngGujState] = useState(false);

  // For Lacs To Crore
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // ** Get List Of Tender Type
  useEffect(() => {
    setIsLoading(true);
    getHeadwiseExpenditureAbstract()
      .then((res) => {
        const data = getAbstractData(res.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(console.log("data--->>> err", err));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const watchRoute = searchParams.get("lang");
    watchRoute === "1" ? setEngGujState(true) : setEngGujState(false);
  }, [searchParams.get("lang")]);

  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
    navigate({
      pathname: "/reason_ts_level",
      search: createSearchParams({
        lang: engGujState ? "0" : "1",
      }).toString(),
    });
  };

  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData.map((item) => ({
      id: item.id,
      srno: item.sr_no,
      bgColor: item.bg_color,
      colspan: item.colspan,
      fontweight: item.fontweight,
      budget_head: item.budget_heads_name,
      name_Of_Division: item.name_of_division,
      number_of_work: item.total_works,
      paaAmount: item.total_paa_aa_amount,
      tsAmount: item.total_ts_amount,
      tenderAmount: item.total_tender_app_amount,
      expenditureIncurredUpto: item.total_prev_year_expense,
      spillOverAs: item.total_prev_year_approved_spill_over,
      spillOverOfCurrentYearApprovedWorks:
        item.total_current_year_approved_spill_over,
      spillOver_spillOverOfCurrentYearApprovedWork:
        item.total_approved_spill_over,
      targetExpenditureOfYear: item.total_target_expenditure,
      modifiedTargetExpenditureOfYear: item.total_modified_target_expenditure,
      expenditureInCurrentYear: item.total_current_year_expenditure,
      probableExpenditure: item.total_probable_expenditure,
      currentSpillOver: item.total_spill_over,
    }));
  }, [tableData, engGujState, isSwitchOn]);

  // ** Columns For Table
  const columns = useMemo(
    () => getHeaderColumnsByRole(engGujState, isSwitchOn),
    [engGujState, isSwitchOn]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      if (!hiddenColumns.includes(columns[i].accessor)) {
        left += 120;
      }
    }
    return left;
  }

  // Start For columns hide -- API
  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      columns.forEach((item, index) => {
        if (row === item.accessor) {
          data.push(index);
        }
      });
    }
    return data;
  };

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUserid,
      module_name: "HeadWiseExpenditure",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];
    const hiddenCol = columnsArr.split(",");
    for (const row of hiddenCol) {
      if (row) {
        const item = columns[row];
        data.push(item.accessor);
      }
    }
    return data;
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUserid,
      module_name: "HeadWiseExpenditure",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        console.log("err-->>", err);
        getErrorMsg(err);
      });
  }, []);
  // End For columns hide -- API
  return (
    <>
      <div className="table-title  ee_new_work_title">
        <h2 className="text-black">HEADWISE EXPENDITURE ABSTRACT</h2>
        <p>
          Dt. {`${moment().format("DD-MM-YYYY")}`}
          <br />
          Rs. in Lacs
        </p>
      </div>
      <Card>
        <CardBody className="basic_tab p-0">
          <div className="dt-action-button btn_search_box d-flex">
            <div className="d-flex">
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">Excel</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Print} alt="icon" className="me-1" />
                <span className="align-middle">Print</span>
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">PDF</span>
              </Button.Ripple>
              {/* <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
                onClick={() => handleAbstract()}
              >
                <img src={Abstract} alt="icon" className="me-1" />
                <span className="align-middle">Abstract</span>
              </Button.Ripple> */}
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="projects_btn me-25"
              >
                <DropdownToggle color="primary" outline caret>
                  <img src={ColumnsVisibility} alt="icon" className="me-1" />
                  <span className="align-middle">Columns Visibility</span>
                </DropdownToggle>
                <DropdownMenu className="p-40 vb-drop column_visibility">
                  {dropdownOptions.map((option, index) => (
                    <Button.Ripple
                      key={option.value}
                      color="primary"
                      onClick={() =>
                        handleToggleHiddenColumns(index, option.value)
                      }
                      className={
                        !hiddenColumns.includes(option.value)
                          ? "active w-100 visibility-Option"
                          : "w-100 visibility-Option bg-white"
                      }
                    >
                      {option.label}
                    </Button.Ripple>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button.Ripple
                className="me-25"
                onClick={() => handleGujaratiEnglish()}
              >
                <span className="align-middle ms-25">Guj. / Eng.</span>
              </Button.Ripple>
              <div className="d-flex align-items-center">
                <Label for="switch-primary" className="form-check-label">
                  Rs. in Lacs
                </Label>
                <div className="form-switch form-check-primary">
                  <Input
                    type="switch"
                    id="switch-primary"
                    name="primary"
                    onChange={(e) => {
                      setIsSwitchOn(e.target.checked);
                    }}
                  />
                </div>
                <Label for="switch-primary" className="form-check-label">
                  Crore
                </Label>
              </div>
            </div>
            <div className="search_Box d-flex">
              <input
                type="text"
                placeholder="Search"
                className="form-control search_bar"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id || columnIndex < 0;
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={
                            (isFrozen ? "dtfc-fixed-left" : "dtfc-cell",
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : "")
                          }
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{ backgroundColor: row.original.bgColor }}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );

                        const isFrozen =
                          frozenColumn === cell.column.id || cellIndex < 0;
                        if (cell.row.original.colspan === 15 && cellIndex > 1) {
                          return null;
                        } else {
                          return (
                            <td
                              colSpan={
                                cell.row.original.colspan === 15
                                  ? cellIndex > 0 && cell.row.original.colspan
                                  : cellIndex < 3 && cell.row.original.colspan
                              }
                              {...cell.getCellProps()}
                              className={
                                isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                              }
                              style={{
                                visibility: isVisible ? "visible" : "collapse",
                                position: isFrozen ? "sticky" : "static",
                                left: isFrozen
                                  ? `${getFrozenColumnLeft(cellIndex)}px`
                                  : "auto",
                                zIndex: 0,
                                // background: "white",
                                textAlign:
                                  cell.row.original.colspan === 15
                                    ? cellIndex > 0 && "left"
                                    : cellIndex < 3 && "right",

                                fontWeight: cell.row.original.fontweight,

                                display:
                                  cell.row.original.colspan === 3 &&
                                  cellIndex < 2 &&
                                  "none",
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            )}
            {isLoading === false && tableData.length === 0 ? (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  No data available.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: pageSize,
                  label:
                    pageSize === tableData?.length
                      ? "All"
                      : pageSize.toString(),
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPageSize(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />
              <span>entries</span>
            </div>

            <ReactPaginate
              nextLabel=""
              pageCount={Math.ceil(preGlobalFilteredRows.length / pageSize)}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => gotoPage(selected.selected)}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />

            <span className="d-none">
              Page{" "}
              <strong>
                {pageIndex + 1} of{" "}
                {Math.ceil(preGlobalFilteredRows.length / pageSize)}
              </strong>
            </span>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default HeadwiseExpenditureAbstract;
