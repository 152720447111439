import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

import {
  getEEDashboardDataList,
  getEEDashboardHandwiseDatalist,
} from "../../services/eeservices";

export const eeDashboardSlice = createSlice({
  name: "eedashboard",
  initialState: {
    isLoading: false,
    data: [],
    handWiseLoading: false,
    expanditureHandwise: [],
    error: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startHandwiseLoading(state) {
      state.handWiseLoading = true;
    },
    isFectchSuccess(state) {
      state.isLoading = false;
    },
    isHandwiseFectchSuccess(state) {
      state.handWiseLoading = false;
    },
    // GET Cirlce
    getDashboardData: (state, action) => {
      state.data = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Expanditure Handwise
    expanditureHandwise: (state, action) => {
      state.expanditureHandwise = action.payload;
    },
  },
});

export const {
  startLoading,
  getDashboardData,
  isFectchSuccess,
  hasError,
  expanditureHandwise,
  startHandwiseLoading,
} = eeDashboardSlice.actions;

export default eeDashboardSlice.reducer;

export const getEEDashboardData = (id) => {
  return async () => {
    dispatch(eeDashboardSlice.actions.startLoading());
    try {
      const response = await getEEDashboardDataList(id);
      dispatch(eeDashboardSlice.actions.getDashboardData(response?.data));
      dispatch(eeDashboardSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(eeDashboardSlice.actions.hasError(error));
    }
  };
};

export const getEEDashboardHandwiseData = (value) => {
  return async () => {
    dispatch(eeDashboardSlice.actions.startHandwiseLoading());
    try {
      const response = await getEEDashboardHandwiseDatalist(value);
      dispatch(eeDashboardSlice.actions.expanditureHandwise(response?.data));
      dispatch(eeDashboardSlice.actions.isHandwiseFectchSuccess());
    } catch (error) {
      dispatch(eeDashboardSlice.actions.hasError(error));
    }
  };
};
