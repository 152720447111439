/* eslint-disable multiline-ternary */
// ** React Imports
import { Fragment, useEffect, useMemo, useState } from "react";
import "@styles/react/libs/react-select/_react-select.scss";
/* eslint-disable multiline-ternary */
import Select from "react-select";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Excel from "../../../../../../assets/images/excel.png";
import Print from "../../../../../../assets/images/print.png";
import ColumnsVisibility from "../../../../../../assets/images/ColumnsVisibility.png";
import { SortArrowDown, SortArrowtop } from "../../../../../../assets/images";
import ReactPaginate from "react-paginate";
import { ROLE, SelectCustomStyles } from "../../../../../../common/utils";
import moment from "moment";
import { getOldCompletedAbstractData } from "../../../../../../services/reportsServices";
import {
  getAbstractData,
  getAbstractHeaderColumnsByRole,
} from "./OldCompletedAbstractUtils";
import classNames from "classnames";
import PrintTable from "../../../../PrintTable";
import ReactToPrint from "react-to-print";

const OldCompletedAbstract = ({
  abstractModal,
  setAbstractModal,
  // loginUser,
}) => {
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: tableData?.length, label: "All" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    setIsLoading(true);
    getOldCompletedAbstractData()
      .then((res) => {
        const data = getAbstractData(res.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }, []);

  const [engGujState, setEngGujState] = useState(false);
  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
  };

  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData.map((item) => ({
      id: item.id,
      sr_no: item.sr_no,
      bgColor: item.bg_color,
      name_Of_circle: item.name_of_circle,
      name_Of_Division: item.division_name,
      nameOfSubDivision: item.sub_division_name,
      budget_head: item.budget_heads_name,
      scheme_of_budget: item.scheme_of_budgets_name,
      name_of_work: item.total_works,
      length_in_km: item.length_in_km,
      paaAmount: item.paa_amount_total,

      blockEstimatesSubmittedToGovernment:
        item.paa_block_estimates_submitted_to_govt_yes,
      blockEstimateAmount: item.paa_block_estimates_remaining_to_submit,
      blockEstimateSubmissionDate:
        item.paa_block_estimates_submitted_to_govt_yes,

      total_works: item.total_works,
      totalProjectCost: item.tentative_tpc,
      totalExpenditureOccurred: item.total_expenditure_occurred,
    }));
  }, [tableData, engGujState]);

  // ** Columns For Table
  const columns = useMemo(
    () => getAbstractHeaderColumnsByRole(engGujState),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    rows,
    setPageSize,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column
  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      if (!hiddenColumns.includes(columns[i].accessor)) {
        left += 120;
      }
    }
    return left;
  }

  const handleToggleHiddenColumns = (value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
    } else {
      setHiddenColumns([...hiddenColumns, value]);
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={abstractModal?.isOpen}
        toggle={() =>
          setAbstractModal({
            isOpen: false,
            data: "",
          })
        }
        className="modal-dialog-centered modal-xl"
      >
        <ModalHeader
          className="bg-transparent p-1"
          toggle={() => {
            setAbstractModal({
              isOpen: false,
              data: "",
            });
          }}
        ></ModalHeader>
        <ModalBody className="p-0 ">
          <div className="d-flex px-2 justify-content-between align-items-center pb-1">
            {/* <div> */}
            <h2 className="mb-0">Abstract - OLD COMPLETED WORKS</h2>
            {/* </div> */}
            <div className="dt-action-button pull-right abstract_dateinfo btn_search_box d-flex">
              <p>
                Dt. {`${moment().format("DD-MM-YYYY")}`} <br />
                Rs. in Lacs
              </p>
              <div>
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">Excel</span>
                </Button.Ripple>
                <ReactToPrint
                  trigger={() => (
                    <Button.Ripple
                      color="primary"
                      className="projects_btn me-25"
                      outline
                    >
                      <img src={Print} alt="icon" className="me-1" />
                      <span className="align-middle">Print</span>
                    </Button.Ripple>
                  )}
                  content={() => {
                    const props = {
                      title: `Abstract - TIME OVER RUN WORKS Dt.${moment().format(
                        "DD-MM-YYYY"
                      )}(Rs in Lacs)`,
                      getTableProps,
                      headerGroups,
                      hiddenColumns,
                      frozenColumn,
                      getFrozenColumnLeft,
                      getTableBodyProps,
                      rows,
                      prepareRow,
                    };
                    PrintTable(props);
                  }}
                />
                <Button.Ripple
                  color="primary"
                  className="projects_btn me-25"
                  outline
                >
                  <img src={Excel} alt="icon" className="me-1" />
                  <span className="align-middle">PDF</span>
                </Button.Ripple>
                <ButtonDropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  className="projects_btn me-25"
                >
                  <DropdownToggle color="primary" outline caret>
                    <img src={ColumnsVisibility} alt="icon" className="me-1" />
                    <span className="align-middle">Columns Visibility</span>
                  </DropdownToggle>
                  <DropdownMenu className="p-40 vb-drop column_visibility">
                    {dropdownOptions.map((option) => (
                      <Button.Ripple
                        key={option.value}
                        color="primary"
                        onClick={() => handleToggleHiddenColumns(option.value)}
                        className={
                          !hiddenColumns.includes(option.value)
                            ? "active w-100 visibility-Option"
                            : "w-100 visibility-Option bg-white"
                        }
                      >
                        {option.label}
                      </Button.Ripple>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <Button.Ripple
                  className="me-25"
                  onClick={() => handleGujaratiEnglish()}
                >
                  <span className="align-middle ms-25">Guj. / Eng.</span>
                </Button.Ripple>
              </div>
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id || columnIndex < 0;
                      const headerCellClass = classNames({
                        "dtfc-fixed-left": isFrozen,
                        "dtfc-cell": !isFrozen,
                        desc: column.isSorted && column.isSortedDesc,
                        asc: column.isSorted && !column.isSortedDesc,
                      });
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={headerCellClass}
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  // {rows.map((row) => {
                  //   prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{ backgroundColor: row.original.bgColor }}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );

                        const isFrozen =
                          frozenColumn === cell.column.id || cellIndex < 0;
                        // if (!isVisible && !isFrozen) {
                        //   return null; // Skip rendering cells for hidden non-frozen columns
                        // }
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                            }
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                              // background: "white",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            )}
            {(isLoading === false && tableData?.length === 0) ||
            // (loginUser.role === ROLE.EE
            //   ? tableData?.length >= 1
            //   : tableData?.length > 0))
            (isLoading === false && rows?.length === 0) ? (
              // (loginUser.role === ROLE.EE
              //   ? rows?.length > 1
              //   : rows?.length > 0)
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  No data available.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: pageSize,
                  label:
                    pageSize === tableData?.length
                      ? "All"
                      : pageSize.toString(),
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPageSize(Number(selectedOption.value));
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>
            <ReactPaginate
              nextLabel=""
              pageCount={Math.ceil(preGlobalFilteredRows.length / pageSize)}
              // breakLabel="..."
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => gotoPage(selected.selected)}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
            {/* ReactPaginate End */}

            <span className="d-none">
              Page{" "}
              <strong>
                {pageIndex + 1} of{" "}
                {Math.ceil(preGlobalFilteredRows.length / pageSize)}
              </strong>
            </span>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default OldCompletedAbstract;
