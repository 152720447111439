/* eslint-disable no-unused-expressions */
import { getUserData } from "../../../../../utility/Utils";

import { ROLE, TodayDate } from "../../../../../common/utils";
import moment from "moment";

/* eslint-disable multiline-ternary */
export const columnsList = [
  "srno",
  "name_of_circle",
  "unique_code",
  "nameOfDivision",
  "sub_division",
  "budget_head",
  "name_of_work",
  "lengthInKM",
  "aaAmount",
  "aaDate",
  "tender_app_amount",
  "tender_app_date",
  "above_below",
  "name_of_agency",
  "workOrderDate",
  "likely_date_of_completion",
  "tentativetotal",
  "totalExpen",
  "currSpillOver",
  "financialprogress",
  "physicalProgress",
  "status_of_work_in_detail",
  "reason_due_department",
  "reason_due_agency",
  "action_taken_by_department",
];

export const getHeaderColumnsByRole = (
  engGujState,
  handleEditStage
  //   setEditWork
) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "srno",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Circle",
      accessor: "name_of_circle",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },

    {
      Header: engGujState ? "યુનિક કોડ" : "Unique Code",
      accessor: "unique_code",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division",
      accessor: "nameOfDivision",
      show: true,
      minWidth: "147px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "પેટા વિભાગનું નામ" : "Name of Sub Division",
      accessor: "sub_division",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામનું નામ" : "Name of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "287px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));
        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Length in KM",
      accessor: "lengthInKM",
      show: true,
      minWidth: "112px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "AA Amount",
      accessor: "aaAmount",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "AA Date",
      accessor: "aaDate",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની રકમ" : "Tender App. Amount",
      accessor: "tender_app_amount",
      show: true,
      minWidth: "137px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની તારીખ" : "Tender App. Date",
      accessor: "tender_app_date",
      show: true,
      minWidth: "127px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? " % ઉંચા / નીચા" : "% Above / Below",
      accessor: "above_below",
      show: true,
      minWidth: "107px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div className="celllll" style={{ whiteSpace: "pre-wrap" }}>
          {cell.value}
        </div>
      ),
    },
    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency",
      accessor: "name_of_agency",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "વર્ક ઓર્ડરની તારીખ / કામ શરૂ કર્યા તારીખ & કામ પુર્ણ થવાની તારીખ(વર્ક ઓર્ડર મુજબ)"
        : "Work Order Date & Date of Completion As per W.O.",
      accessor: "workOrderDate",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામ પૂર્ણ થવાની સંભવિત તારીખ"
        : "Likely Date of Completion",
      accessor: "likely_date_of_completion",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "સંભવિત કૂલ પ્રોજેક્ટ રકમ"
        : "Tentative Total Project Cost",
      accessor: "tentativetotal",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `શરૂઆતથી ${TodayDate()} સુધી થયેલ કુલ ખર્ચ`
        : `Total Expen. occu. till ${TodayDate()} on Project`,
      accessor: "totalExpen",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "હાલની સ્પીલ ઓવર" : "Current Spill Over of Project",
      accessor: "currSpillOver",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div
          className={`${cell.value < 0 ? "text-danger" : ""}`}
          style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
        >
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState ? "નાણાંકીય પ્રગતિ %" : "Financial Progress in %",
      accessor: "financialprogress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "ભૌતિક પ્રગતિ %" : "Physical Progress in %",
      accessor: "physicalProgress",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "Status of Work in Detail",
      accessor: "status_of_work_in_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી વિલંબ થવાનું કારણ : વિભાગના લીધે / અન્ય કારણ"
        : "Reason : Time overrun & Due to Department / Other",
      accessor: "reason_due_department",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી વિલંબ થવાનું કારણ : ઇજારદારશ્રીના લીધે"
        : "Reason : Time overrun & Due to Agency",
      accessor: "reason_due_agency",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "વિભાગ ધ્વારા લેવાયેલ પગલાં"
        : "Action Taken by Department",
      accessor: "action_taken_by_department",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};

export function addDuplicateForObjectWithSpecificValue(dataArray) {
  const result = [];
  // Remove duplicate package ids
  const seenPackageIds = {};
  const data = dataArray.filter((item) => {
    if (item.package_id > 0) {
      if (!seenPackageIds[item.package_id]) {
        seenPackageIds[item.package_id] = true;
        return true;
      }
      return false;
    } else {
      return true;
    }
  });

  let sr_no = 1;
  for (const obj of data) {
    if (obj.govtplan_bytender === "govt") {
      result.push({
        ...obj,
        name_of_work_title: "Government Plant",
        dtp_approval_date: obj.govplan_date,
        dtp_amount: obj.govplan_amount,
        tentative_tpc: obj.govt_total_project_cost,
        tender_app_amount:
          obj.govplan_amount === null ? "--" : obj.govplan_amount,
        tender_app_date: obj.govplan_date,
        sr_no: sr_no++,
        above_below: "--",
        agency_gujarati: "સરકારી પ્લાન્ટ",
        agency: "Govt. Plant",
        work_order_date: "--",
        date_of_completion_as_per_wo: "--",
        likely_date_of_completion: "--",
        date_of_completion_as_per_approved_timelimit: "--",
        tender_expen_incured_last_fy_year: obj.govt_expen_incured_last_fy_year,
        tender_spill_over_this_fy_year_n_current_year_approved_work:
          obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
        tender_target_expenditure_of_current_fy_year:
          obj.govt_target_expenditure_of_current_fy_year,
        tender_current_year_upto_today: obj.govt_current_year_upto_today,
        tender_probable_expen_from_today_to_end_of_this_fy_year:
          obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
        tender_total_expenditure_as_on_today_on_this_project:
          obj.govt_total_expenditure_as_on_today_on_this_project,
        workstatus_detail: obj.govt_plan_work_detail,

        tender_current_spillover_of_project:
          obj.govt_current_spillover_of_project,
      });
      result.push({
        ...obj,
        id: "",
        name_of_work_title: "Furniture+Misc.",
        circle_name: "--",
        unique_code: "--",
        division_name: "--",
        subdivision_name: "--",
        budget_heads_name: "--",
        scheme_of_budgets_name: "--",
        chainage_from_to: "--",
        length_in_km: "--",
        proposed_work: "--",
        widening_cw: "--",
        paa_amount: "--",
        paa_date: "--",
        aa_amount: "--",
        aa_date: "--",
        ts_amount: "--",
        ts_date: "--",
        sr_no: "",
        tentative_tpc: obj.tender_total_project_cost,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
    } else if (obj.package_id !== 0 && obj.package_id !== null) {
      result.push({
        ...obj,
        name_of_work_title: "Package",
        sr_no: sr_no++,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
      obj.sub_work &&
        obj.sub_work.map((row) =>
          result.push({
            ...row,
            name_of_work_title: "",
            sr_no: "",
            dtp_amount: "--",
            dtp_approval_date: "--",
            tender_opening_date: "--",
            tender_app_amount: "--",
            tender_app_date: "--",
            agency_gujarati: "--",
            agency: "--",
            work_order_date: "--",
            date_of_completion_as_per_wo: "--",
            likely_date_of_completion: "--",
            date_of_completion_as_per_approved_timelimit: "--",
            tentative_tpc: "--",
            tender_expen_incured_last_fy_year: "--",
            tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
            tender_target_expenditure_of_current_fy_year: "--",
            tender_current_year_upto_today: "--",
            tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
            tender_total_expenditure_as_on_today_on_this_project: "--",
            financial_progress: "--",
            physical_progress: "--",
            workstatus_detail: "--",
            work_status: "--",
            above_below: "--",
            stopped_due_to_department_other: "--",
            stopped_due_to_agency: "--",
            stopped_action_taken_on_agency: "--",
          })
        );
      // }
    } else {
      result.push({
        ...obj,
        name_of_work_title: "",
        sr_no: sr_no++,
        above_below:
          obj.per_above_below === null
            ? ""
            : `${obj.per_above_below}\n${obj.above_below}`,
      });
    }
  }
  return result;
}

export const getFormattedDate = (date) => moment(date).format("DD-MM-YYYY");
