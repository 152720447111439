import moment from "moment";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row, Table } from "reactstrap";
import { getFinancialYearDates } from "../../../../common/utils";

const Expenditure = () => {
  const workData = useSelector((state) => state?.getWork);

  const [currentYear, setCurrentYear] = useState();

  const calculateSum = (value1, value2) => {
    return parseFloat(value1) + parseFloat(value2);
  };

  const TodayDate = moment();
  useEffect(() => {
    if (TodayDate) {
      if (parseInt(moment(TodayDate).format("MM")) < 4) {
        setCurrentYear(
          `${parseInt(moment(TodayDate).format("YYYY") - 1)}-${moment(
            TodayDate
          ).format("YY")}`
        );
      } else {
        setCurrentYear(
          `${moment(TodayDate).format("YYYY")}-${
            parseInt(moment(TodayDate).format("YY")) + 1
          }`
        );
      }
    }
  }, []);

  const getFinancialYearDate = getFinancialYearDates();

  return (
    <>
      <Card className="app-card p-2">
        <Row id="table-striped">
          <Col md={12} className="expenditure-table">
            <Card>
              <div className="table-responsive">
                <Table className="table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        Expen. incurred upto{" "}
                        {moment().month(2).date(31).format("DD-MM-YYYY")} (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data?.govt_expen_incured_last_fy_year,
                          workData?.data?.tender_expen_incured_last_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Spill over as on{" "}
                        {getFinancialYearDate.currentYear.start} &amp; Spill
                        over of Current Year Approved Work (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_spill_over_this_fy_year_n_current_year_approved_work,
                          workData?.data
                            ?.tender_spill_over_this_fy_year_n_current_year_approved_work
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Target Expenditure of Year {currentYear} (Lacs)</td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_target_expenditure_of_current_fy_year,
                          workData?.data
                            ?.tender_target_expenditure_of_current_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Current Year Expenditure From{" "}
                        {getFinancialYearDate.currentYear.start} to till date
                        (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data?.govt_current_year_upto_today,
                          workData?.data?.tender_current_year_upto_today
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Probable Expen. from Today to{" "}
                        {getFinancialYearDate.currentYear.end} for year {""}
                        {currentYear} (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_probable_expen_from_today_to_end_of_this_fy_year,
                          workData?.data
                            ?.tender_probable_expen_from_today_to_end_of_this_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total Expenditure occured till today on Project (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_total_expenditure_as_on_today_on_this_project,
                          workData?.data
                            ?.tender_total_expenditure_as_on_today_on_this_project
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Current Spill over of Project (Lacs)</td>
                      <td>
                        {calculateSum(
                          workData?.data?.govt_current_spillover_of_project,
                          workData?.data?.tender_expen_incured_last_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Final Modified Target Expenditure of Year {currentYear}{" "}
                        (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_final_modified_target_expenditure_of_this_fy_year,
                          workData?.data
                            ?.tender_final_modified_target_expenditure_of_this_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Modified Probable Expen. for year {currentYear} (Lacs)
                      </td>
                      <td>
                        {calculateSum(
                          workData?.data
                            ?.govt_modified_probeble_expen_from_today_to_fy_year,
                          workData?.data
                            ?.tender_modified_probeble_expen_from_today_to_fy_year
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Expenditure;
