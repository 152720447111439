import { getUserData } from "../../../../utility/Utils";
import {
  FYOnlyYears,
  ROLE,
  TodayDate,
  TomorrowDate,
  getFinancialYearDates,
} from "../../../../common/utils";

// const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

export const columnsList = [
  "sr_no",
  "division_name",
  "sub_division_name",
  "budget_head",
  "name_of_work",
  "paa_amount",
  "paa_date",
  "ts_amount",
  "ts_date",
  "dtp_amount",
  "dtp_approval_date",
  "tender_app_amount",
  "tender_app_date",
  "per_above_below",
  "agency",
  "work_order_date",
  "date_of_completion_as_per_wo",
  "likely_date_of_completion",
  "tender_expen_incured_last_fy_year",
  "tender_spill_over_this_fy_year_n_current_year_approved_work",
  "tender_target_expenditure_of_current_fy_year",
  "tender_current_year_upto_today",
  "tender_probable_expen_from_today_to_end_of_this_fy_year",
  "workstatus_detail",
];

/* eslint-disable multiline-ternary */
export const getHeaderColumnsByRole = (engGujState, handleEditStage) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "sr_no",
      show: true,
      minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "division_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "પેટા વિભાગનું નામ" : "Name of SUB Division ",
      accessor: "sub_division_name",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE, ROLE.DEE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામનું નામ" : "Name Of Work",
      accessor: "name_of_work",
      show: true,
      minWidth: "303px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => {
        const title = cell.value.substring(0, cell.value.indexOf("\n"));
        let val = cell.value;
        if (title) {
          val = cell.value.replace(
            title,
            `<span class="work-title">${title}</span>`
          );
        }
        return (
          <div
            className="celllll"
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            onClick={() => handleEditStage(cell.row.original)}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        );
      },
    },
    // * paa
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી રકમ" : "PAA Amount",
      accessor: "paa_amount",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "સૈધ્ધાંતિક મંજુરી તારીખ" : "PAA Date",
      accessor: "paa_date",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    //  * ts
    {
      Header: engGujState ? "તાંત્રીક મંજુરી રકમ" : "TS Amount",
      accessor: "ts_amount",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "તાંત્રીક મંજુરી તારીખ" : "TS Date",
      accessor: "ts_date",
      show: true,
      minWidth: "135px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    // * DTP
    {
      Header: engGujState ? "ડીટીપી મંજુરી રકમ" : "DTP Amount",
      accessor: "dtp_amount",
      show: true,
      minWidth: "137px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "ડીટીપી મંજુરી તારીખ" : "DTP Date",
      accessor: "dtp_approval_date",
      show: true,
      minWidth: "137px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની રકમ" : "Tender App. Amount",
      accessor: "tender_app_amount",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "મંજુર થયેલ ટેન્‍ડરની તારીખ" : "Tender App. Date",
      accessor: "tender_app_date",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "% ઉંચા / નીચા" : "% Above / Below",
      accessor: "per_above_below",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{cell.value}</div>
      ),
    },

    {
      Header: engGujState ? "એજન્‍સીનું નામ" : "Name of Agency",
      accessor: "agency",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "વર્ક ઓર્ડરની તારીખ / કામ શરૂ કર્યા તારીખ"
        : " Work Order Date",
      accessor: "work_order_date",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામ પુર્ણ થવાની તારીખ (વર્ક ઓર્ડર મુજબ)"
        : "Date of Completion As per W.O.",
      accessor: "date_of_completion_as_per_wo",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? "કામ પૂર્ણ થવાની સંભવિત તારીખ"
        : "Likely Date of Completion",
      accessor: "likely_date_of_completion",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    // Need Key
    {
      Header: engGujState
        ? `${getFinancialYearDates()?.lastYear?.end} સુધીનો ખર્ચ`
        : `Expen. incu. upto ${
            getFinancialYearDates()?.lastYear?.end
          } (LAST F.Y.)`,
      accessor: "tender_expen_incured_last_fy_year",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `તા.${
            getFinancialYearDates()?.currentYear?.start
          } ની સ્પીલ ઓવર તથા ચાલુ વર્ષનાં મંજૂર થયેલ કામની સ્પીલઓવર`
        : `SPILL OVER AS ON ${
            getFinancialYearDates()?.currentYear?.start
          }  & OF CURRENT YEAR APP. WORK`,
      accessor: "tender_spill_over_this_fy_year_n_current_year_approved_work",
      show: true,
      minWidth: "121px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `સને ${FYOnlyYears()} નો કુલ સંભવિત ખર્ચ`
        : `Target Expen. of Year ${FYOnlyYears()}`,
      accessor: "tender_target_expenditure_of_current_fy_year",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      class: "cell-hover",
      tab: "7",
      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState
        ? `તા. ${
            getFinancialYearDates()?.currentYear?.start
          } થી ${TodayDate()} સુધીનો ખર્ચ (ફક્ત ચાલુ વર્ષ નો ખર્ચ)`
        : `Current Year Expen. From ${
            getFinancialYearDates()?.currentYear?.start
          } to till ${TodayDate()}`,
      accessor: "tender_current_year_upto_today",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState
        ? `આજ ${TodayDate()} થી તા.${
            getFinancialYearDates()?.currentYear?.end
          } સુધીનો સંભવિત ખર્ચ વર્ષ ${FYOnlyYears()}`
        : `Probable Expen. from ${TomorrowDate()} to ${
            getFinancialYearDates()?.currentYear?.end
          } for year ${FYOnlyYears()}`,
      accessor: "tender_probable_expen_from_today_to_end_of_this_fy_year",
      show: true,
      minWidth: "196px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
      class: "cell-hover",
      tab: "7",
      Cell: (cell) => (
        <div style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}>
          {cell.value}
        </div>
      ),
    },

    {
      Header: engGujState
        ? "કામની વિગતવાર ભૌતિક પરિસ્થિતિ"
        : "Status of Work in Detail",
      accessor: "workstatus_detail",
      show: true,
      minWidth: "387px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  const currentUserRole = getUserData()?.role;

  return columnsArr.filter((row) => {
    if (row.roles.includes(currentUserRole)) return row;
  });
};
