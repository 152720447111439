/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";

import { dispatch } from "../store";
import { getCircleList } from "../../services/adminDashboard";
// import axios from "axios";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    isLoading: false,
    data: [],

    error: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    isFectchSuccess(state) {
      state.isLoading = false;
    },
    // GET Cirlce
    getLocationdata: (state, action) => {
      state.data = action.payload;
      // [...state.data, (state.data.sub_division = action.payload)];
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    UpdateSubDivision: (state, action) => {
      state.data.sub_division = action.payload;
    },
  },
});

export const {
  startLoading,
  getLocationdata,
  isFectchSuccess,
  hasError,
  UpdateSubDivision,
} = locationSlice.actions;

export default locationSlice.reducer;

export const CircleList = (id) => {
  return async () => {
    dispatch(locationSlice.actions.startLoading());

    try {
      const response = await getCircleList(id);
      console.log("response: ", response.data);
      dispatch(locationSlice.actions.getLocationdata(response?.data));
      dispatch(locationSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(locationSlice.actions.hasError(error));
    }
  };
};
