import {
  CreatePackageSvg,
  HomeSvg,
  ReportsSvg,
  // AllProjectSvg,
  // PreexecutionSvg,
  // ExecutionSvg,
  // CompeletedSvg,
  // StoppedSvg,
  // Exegt6Svg,
  // UserGroup,
  // FourCircle,
} from "../../assets/images";
import { ROLE } from "../../common/utils";
export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <HomeSvg size={20} />,
    navLink: "/ee/dashboard",
    roles: [ROLE.EE],
  },

  {
    id: "expenditure_entry",
    title: "Expenditure Entry",
    icon: <ReportsSvg size={20} />,
    children: [
      {
        id: "main_work",
        title: "Main Work",
        icon: <ReportsSvg size={20} />,
        navLink: "/ee/expenditure-entry",
        roles: [ROLE.EE],
      },
      {
        id: "consultancy",
        title: "Consultancy",
        icon: <ReportsSvg size={20} />,
        navLink: "/ee/consultancy-expenditure",
        roles: [ROLE.EE],
      },
    ],
  },
  {
    id: "createpackage",
    title: "Create Package",
    icon: <CreatePackageSvg size={20} />,
    navLink: "/ee/create-package",
    roles: [ROLE.EE],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <ReportsSvg size={20} />,
    navLink: "/reports",
    roles: [ROLE.EE],
  },
  // {
  //   id: "work",
  //   title: "New Work",
  //   icon: <New_Work />,
  //   navLink: "/se/work",
  //   roles: [ROLE.SE],
  // },
  // {
  //   id: "Dropdown",
  //   title: "Dropdown",
  //   icon: <DropdownSvg />,
  //   navLink: "/se/dropdown",
  //   roles: [ROLE.SE],
  // },
];
