/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { ROLE } from "../../../../common/utils";
import {
  getAgencyList,
  getBudgetHeadList,
  getProposedWorkList,
} from "../../../../services/adminDashboard";
import { getSubDivisionByDivision } from "../../../../services/reportsServices";
import { getSchemeOfBudget } from "../../../../services/seServices";

const defaultValues = {
  subDivision: "",
  budgetHead: "",
  schemeOfBudget: "",
  proposedWork: "",
  agency: "",
};

const FilterStoppedP6 = ({
  canvasOpenData,
  setCanvasOpenData,
  getFilterData,
  selectedDivisionIds,
}) => {
  const loginUser = useSelector((state) => state.auth.userData.data);
  const { reset, control, setValue, watch } = useForm({
    defaultValues,
  });

  const [subDivisionOption, setSubDivisionOption] = useState([]);
  const [BudgetHeadOption, setBudgetHeadOption] = useState([]);
  const [schemeOfBudgetOption, setSchemeOfBudgetOption] = useState([]);
  const [proposedWorkOption, SetProposedWorkOption] = useState([]);
  const [agencyOption, SetAgencyOption] = useState([]);

  const allValues = watch();

  useEffect(() => {
    const divisionIdToFetch =
      loginUser?.role === ROLE.EE || loginUser?.role === ROLE.DEE
        ? loginUser?.division_id
        : selectedDivisionIds?.division_id;

    if (divisionIdToFetch) {
      getSubDivisionByDivision(divisionIdToFetch)
        .then((res) => setSubDivisionOption(res?.data))
        .catch((err) => getErrorMsg(err));
    }
    if (selectedDivisionIds === null) {
      setValue("subDivision", "");
      setSubDivisionOption([]);
    }
  }, [loginUser, selectedDivisionIds]);

  // Fetch Scheme of Budget Options
  useEffect(() => {
    const selectedBudgetHeadIds = allValues?.budgetHead;
    if (selectedBudgetHeadIds) {
      getSchemeOfBudget(selectedBudgetHeadIds)
        .then((res) => setSchemeOfBudgetOption(res?.data))
        .catch((err) => getErrorMsg(err));
    }
    if (!allValues?.budgetHead) {
      setValue("budgetHead", "");
      setValue("schemeOfBudget", "");
      setSchemeOfBudgetOption([]);
    }
  }, [allValues.budgetHead]);

  // Fetch Budget Head, Proposed Work, and Agency Options
  useEffect(() => {
    async function fetchData() {
      try {
        const [budgetHeadResponse, proposedWorkResponse, agencyResponse] =
          await Promise.all([
            getBudgetHeadList(),
            getProposedWorkList(),
            getAgencyList(),
          ]);

        setBudgetHeadOption(budgetHeadResponse?.data);
        SetProposedWorkOption(proposedWorkResponse?.data);
        SetAgencyOption(agencyResponse?.data);
      } catch (err) {
        getErrorMsg(err);
      }
    }

    fetchData();
  }, []);

  // Handle Field Value Changes
  const handleFieldHasValue = () => {
    const {
      subDivision,
      budgetHead,
      schemeOfBudget,
      proposedWork,
      agency,
      financialProgress,
    } = allValues;

    const value = {
      divsion_id: selectedDivisionIds?.division_id,
      subdivision_id: subDivision,
      budgethead_id: budgetHead,
      schemeOfBudget_id: schemeOfBudget,
      proposedwork_id: proposedWork,
      agency_ids: agency,
      financial_progress: financialProgress,
    };

    getFilterData(value);
  };

  useEffect(() => {
    handleFieldHasValue();
  }, [allValues]);

  return (
    <>
      <Offcanvas
        direction={"end"}
        isOpen={canvasOpenData?.isOpen}
        toggle={() =>
          setCanvasOpenData({
            isOpen: false,
          })
        }
        className="canvas-main-body"
      >
        <OffcanvasHeader
          toggle={() =>
            setCanvasOpenData({
              isOpen: false,
            })
          }
          className="for-offcanvas"
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">Filters</h5>
            <Button
              color="primary"
              outline
              onClick={() => {
                reset({});
                setCanvasOpenData({
                  isOpen: false,
                });
              }}
            >
              Reset filters
            </Button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div>
            <Form>
              <Row className="gy-1 pt-1">
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="subDivision">Sub Division</Label>
                  <Controller
                    id="subDivision"
                    control={control}
                    name="subDivision"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={subDivisionOption}
                        getOptionValue={(opt) => opt.sub_division_id}
                        getOptionLabel={(opt) => opt.sub_division_name}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={subDivisionOption?.filter((row) => {
                          const subDivisions = watch("subDivision").split(",");
                          return subDivisions.includes(
                            `${row.sub_division_id}`
                          );
                        })}
                        onChange={(e) => {
                          const subDivisionIdArr = e.map(
                            (row) => row.sub_division_id
                          );
                          const subDivisionIds = subDivisionIdArr.join(",");
                          setValue("subDivision", subDivisionIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="budgetHead">Budget Head</Label>
                  <Controller
                    id="budgetHead"
                    control={control}
                    name="budgetHead"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={BudgetHeadOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.budgethead}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={BudgetHeadOption.filter((row) => {
                          const BudgetHeads = watch("budgetHead").split(",");
                          return BudgetHeads.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const BudgetHeadIdArr = e.map((row) => row.id);
                          const BudgetHeadIds = BudgetHeadIdArr.join(",");
                          setValue("budgetHead", BudgetHeadIds);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="schemeOfBudget">Scheme of Budget</Label>
                  <Controller
                    id="schemeOfBudget"
                    control={control}
                    name="schemeOfBudget"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={schemeOfBudgetOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.scheme_gujarati}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={schemeOfBudgetOption.filter((row) => {
                          const schemeOfBudgets =
                            watch("schemeOfBudget").split(",");
                          return schemeOfBudgets.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const schemeOfBudgetIdArr = e.map((row) => row.id);
                          const schemeOfBudgetIds =
                            schemeOfBudgetIdArr.join(",");
                          setValue("schemeOfBudget", schemeOfBudgetIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="proposedWork">Proposed Work</Label>
                  <Controller
                    id="proposedWork"
                    control={control}
                    name="proposedWork"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={proposedWorkOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.proposed_work}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={proposedWorkOption.filter((row) => {
                          const proposedWorks =
                            watch("proposedWork").split(",");
                          return proposedWorks.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const proposedWorkIdArr = e.map((row) => row.id);
                          const proposedWorkIds = proposedWorkIdArr.join(",");
                          setValue("proposedWork", proposedWorkIds);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col xs={12} className="mb-1 mt-0 form-custom-input">
                  <Label for="agency">Agency</Label>
                  <Controller
                    id="agency"
                    control={control}
                    name="agency"
                    render={({ field }) => (
                      <Select
                        {...field}
                        // isClearable
                        options={agencyOption}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.agency}
                        classNamePrefix="select"
                        className="react-select"
                        isMulti
                        value={agencyOption.filter((row) => {
                          const agencys = watch("agency")?.split(",");
                          return agencys?.includes(`${row.id}`);
                        })}
                        onChange={(e) => {
                          const agencyIdArr = e.map((row) => row.id);
                          const agencyIds = agencyIdArr?.join(",");
                          setValue("agency", agencyIds);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100"
                outline
                onClick={() =>
                  setCanvasOpenData({
                    isOpen: false,
                  })
                }
              >
                Cancel
              </Button>
            </Form>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default React.memo(FilterStoppedP6);
