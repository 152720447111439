import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

import {
  getCEDashboardDataList,
  getCEDashboardHandwiseDatalist,
} from "../../services/ceServices";

export const ceDashboardSlice = createSlice({
  name: "cedashboard",
  initialState: {
    isLoading: false,
    data: [],
    handWiseLoading: false,
    expanditureHandwise: [],
    error: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startHandwiseLoading(state) {
      state.handWiseLoading = true;
    },
    isFectchSuccess(state) {
      state.isLoading = false;
    },
    isHandwiseFectchSuccess(state) {
      state.handWiseLoading = false;
    },
    // GET Cirlce
    getDashboardData: (state, action) => {
      state.data = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Expanditure Handwise
    expanditureHandwise: (state, action) => {
      state.expanditureHandwise = action.payload;
    },
  },
});

export const {
  startLoading,
  getDashboardData,
  isFectchSuccess,
  hasError,
  expanditureHandwise,
  startHandwiseLoading,
} = ceDashboardSlice.actions;

export default ceDashboardSlice.reducer;

export const getCEDashboardData = () => {
  return async () => {
    dispatch(ceDashboardSlice.actions.startLoading());
    try {
      const response = await getCEDashboardDataList();
      dispatch(ceDashboardSlice.actions.getDashboardData(response?.data));
      dispatch(ceDashboardSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(ceDashboardSlice.actions.hasError(error));
    }
  };
};

export const getCEDashboardHandwiseData = (data) => {
  return async () => {
    dispatch(ceDashboardSlice.actions.startHandwiseLoading());
    try {
      const response = await getCEDashboardHandwiseDatalist(data);
      dispatch(ceDashboardSlice.actions.expanditureHandwise(response?.data));
      dispatch(ceDashboardSlice.actions.isHandwiseFectchSuccess());
    } catch (error) {
      dispatch(ceDashboardSlice.actions.hasError(error));
    }
  };
};
