/* eslint-disable object-shorthand */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "@styles/react/libs/react-select/_react-select.scss";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { getCategoryOfRoadList } from "../../../../services/adminDashboard";
import {
  getMLAConstituencyDropDown,
  getMLANameDropDown,
  getMpConstituencyDropDown,
  getMpNameDropDown,
  getSubDivisionDropDown,
  getTalukaDropDown,
  updateBasicDetailsWork,
} from "../../../../services/reportsServices";
import RNBLoader from "../../../../@core/components/spinner/RNBLoader";
import UILoader from "@components/ui-loader";
import { ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";

const BasicDetailsSchema = yup.object().shape({
  pragati_gcode: yup
    .string()
    .max(20, "Pragati G Code should not exceed 20 characters")
    .nullable(),
  taluka_id: yup
    .string()
    .transform((value) => {
      return value;
    })
    .required("Select Taluka")
    .nullable(),

  subdivision_id: yup
    .string()
    .transform((value) => {
      return value;
    })
    .required("Select Sub Division")
    .nullable(),
  categoryroad_id: yup
    .string()
    .transform((value) => {
      return value;
    })
    .required("Select Category of Road")
    .nullable(),
  mp_constituency: yup.string().required("Select MP Constituency").nullable(),
  // mp_name: yup.string().required("MP Name (Member of Parliament)").nullable(),
  mla_constituency: yup.string().required("Select MLA Constituency").nullable(),
  // mla_name: yup
  //   .string()
  //   .required("MLA Name (Member of Legislative Assembly)")
  //   .nullable(),
  treatment: yup.string().required("Please enter the details of Proposed Work"),
});

const BasicDetails = () => {
  // ** Get Data From Store
  const workData = useSelector((state) => state?.getWork);
  console.log("workData: basic", workData);
  const currentUserRole = getUserData()?.role;
  // ** Local States
  const [TalukaDropDownOption, setTalukaDropDownOption] = useState();
  const [subDivisionDropDownOption, setSubDivisionDropDownOption] = useState();
  const [categoryofRoadDropDownOption, setCategoryofRoadDropDownOption] =
    useState();
  const [mpConstituencyDropDownOption, setMPconstituencyDropDownOption] =
    useState();
  const [mpNameDropDownOption, setMpNameDropDownOption] = useState();

  const [mlaConstituencyDropDownOption, setMLAconstituencyDropDownOption] =
    useState();
  const [mlaNameDropDownOption, setMLANameDropDownOption] = useState();

  useEffect(() => {
    if (workData?.data?.division_id) {
      //**for taluka DropDown Option
      getTalukaDropDown(workData?.data?.division_id)
        .then((res) => {
          setTalukaDropDownOption(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      // ** for SubDivision DropDown Option
      getSubDivisionDropDown(workData?.data?.division_id)
        .then((res) => {
          setSubDivisionDropDownOption(res?.data?.sub_division);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // ** Get  categoryRoad
  }, [workData]);

  // ** Hooks
  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(BasicDetailsSchema) });

  const handleMPConstituencyChange = (val) => {
    // setValue("mp_constituency", e);
    const MPID = watch("mp_constituency");
    const value = {
      peram1: val,
      mp_id: MPID,
    };
    getMpNameDropDown(value, workData?.data?.id)
      .then((res) => {
        setMpNameDropDownOption(res?.data);

        const findadata = res.data.all.find(
          (row) => row.id === res?.data?.default
        );

        setValue("mp_name", findadata || "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMLAConstituencyChange = (val) => {
    const MLAID = watch("mla_constituency");
    const value = {
      peram1: val,
      mp_id: MLAID,
    };
    getMLANameDropDown(value, workData?.data?.id)
      .then((res) => {
        setMLANameDropDownOption(res?.data);

        const findadata = res.data.all.find(
          (row) => row.id === res?.data?.default
        );

        setValue("mla_name", findadata || "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // ** APi
    // ** for MP Constituency DropDown Option
    const mpId = workData?.data?.mp_id === null ? 0 : workData?.data?.mp_id;
    if (mpId !== undefined) {
      getMpConstituencyDropDown(mpId)
        .then((res) => {
          setMPconstituencyDropDownOption(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // ** Get MP name on Page Load
    if (workData?.data?.id && workData?.data?.mp_id) {
      const value = {
        peram1: 0,
        mp_id: workData?.data?.mp_id === null ? 0 : workData?.data?.mp_id,
      };
      getMpNameDropDown(value, workData?.data?.id)
        .then((res) => {
          setMpNameDropDownOption(res?.data);
          if (res?.data?.default && res.data.all) {
            const findadata = res?.data?.all?.find(
              (row) => row.id === res?.data?.default
            );
            setValue("mp_name", findadata || "");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // ** for MLA DropDowm
    const mlaId = workData?.data?.mla_id === null ? 0 : workData?.data?.mla_id;
    if (mlaId !== undefined) {
      getMLAConstituencyDropDown(mlaId)
        .then((res) => {
          setMLAconstituencyDropDownOption(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // ** Get automated name
    if (workData?.data?.id && workData?.data?.mla_id) {
      const value = {
        peram1: 0,
        mp_id: workData?.data?.mla_id === null ? 0 : workData?.data?.mla_id,
      };
      getMLANameDropDown(value, workData?.data?.id)
        .then((res) => {
          setMLANameDropDownOption(res?.data);
          if (res?.data?.default && res.data.all) {
            const findadata = res?.data?.all?.find(
              (row) => row.id === res?.data?.default
            );
            setValue("mla_name", findadata || "");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // **api end

    //**  Default Set Value
    if (workData?.data) {
      reset({
        circle_name: workData?.data?.circle_name
          ? workData?.data?.circle_name
          : "",
        unique_code: workData?.data?.unique_code
          ? workData?.data?.unique_code
          : "",
        division_name: workData?.data?.division_name
          ? workData?.data?.division_name
          : "",
        budget_head_name: workData?.data?.budget_head_name
          ? workData?.data?.budget_head_name
          : "",
        scheme_of_budget_name: workData?.data?.scheme_of_budget_name
          ? workData?.data?.scheme_of_budget_name
          : "",
        name_of_work_english: workData?.data?.name_of_work_english
          ? workData?.data?.name_of_work_english
          : "",
        name_of_work_gujarati: workData?.data?.name_of_work_gujarati
          ? workData?.data?.name_of_work_gujarati
          : "",

        chainage_from_to: workData?.data?.chainage_from_to
          ? workData?.data?.chainage_from_to
          : "",
        length_in_km: workData?.data?.length_in_km
          ? workData?.data?.length_in_km
          : "",
        proposed_work_name: workData?.data?.proposed_work_name
          ? workData?.data?.proposed_work_name
          : "",
        pragati_gcode: workData?.data?.pragati_gcode
          ? workData?.data?.pragati_gcode
          : "",
        taluka_id: workData?.data?.taluka_id ? workData?.data?.taluka_id : "",
        subdivision_id: workData?.data?.subdivision_id
          ? workData?.data?.subdivision_id
          : "",
        categoryroad_id: workData?.data?.categoryroad_id
          ? workData?.data?.categoryroad_id
          : "",
        treatment: workData?.data?.treatment ? workData?.data?.treatment : "",
      });
    }
  }, [workData?.data]);

  useEffect(() => {
    if (mpConstituencyDropDownOption?.default) {
      setValue("mp_constituency", mpConstituencyDropDownOption?.default);
    }
  }, [mpConstituencyDropDownOption?.default]);

  useEffect(() => {
    if (mlaConstituencyDropDownOption?.default) {
      setValue("mla_constituency", mlaConstituencyDropDownOption?.default);
    }
  }, [mlaConstituencyDropDownOption?.default]);

  useEffect(() => {
    getCategoryOfRoadList()
      .then((res) => {
        setCategoryofRoadDropDownOption(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (data) => {
    console.log("BasicDetails Data->", data);
    const sendAPIData = {
      work_id: workData?.data?.id,
      pragati_gcode: data?.pragati_gcode,
      mp_id: data?.mp_name?.id,
      mla_id: data?.mla_name?.id,
      taluka_id: data?.taluka_id,
      subdivision_id: data?.subdivision_id,
      categoryroad_id: data?.categoryroad_id,
      treatment: data?.treatment,
    };
    // console.log("sendAPI Data->", sendAPIData);
    // BasicDetails API
    updateBasicDetailsWork(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => getErrorMsg(err));
  };

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <>
      <UILoader blocking={workData?.isLoading} loader={<RNBLoader />}>
        <Card>
          <CardBody className="basic_tab">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={isFieldsetDisabled}>
                <Row className="gy-1">
                  {/*  Disabled Field */}
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="circle_name">
                      Circle
                    </Label>
                    <Controller
                      control={control}
                      name="circle_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="circle_name"
                            disabled
                            // value={field.value}
                            invalid={errors.circle_name && true}
                          />
                        );
                      }}
                    />
                    {errors.circle_name && (
                      <FormFeedback>{errors.circle_name.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="unique_code">
                      Unique Code
                    </Label>
                    <Controller
                      control={control}
                      name="unique_code"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="unique_code"
                            disabled
                            invalid={errors.unique_code && true}
                          />
                        );
                      }}
                    />
                    {errors.unique_code && (
                      <FormFeedback>{errors.unique_code.message}</FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="division_name">
                      Division
                    </Label>
                    <Controller
                      control={control}
                      name="division_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="division_name"
                            disabled
                            invalid={errors.division_name && true}
                          />
                        );
                      }}
                    />
                    {errors.division_name && (
                      <FormFeedback>
                        {errors.division_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="budget_head_name">
                      Budget Head
                    </Label>
                    <Controller
                      control={control}
                      name="budget_head_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="budget_head_name"
                            invalid={errors.budget_head_name && true}
                          />
                        );
                      }}
                    />
                    {errors.budget_head_name && (
                      <FormFeedback>
                        {errors.budget_head_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="scheme_of_budget_name">
                      Scheme of Budget
                    </Label>
                    <Controller
                      control={control}
                      name="scheme_of_budget_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="scheme_of_budget_name"
                            invalid={errors.scheme_of_budget_name && true}
                          />
                        );
                      }}
                    />
                    {errors.scheme_of_budget_name && (
                      <FormFeedback>
                        {errors.scheme_of_budget_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="name_of_work_english">
                      Name of Work (English)
                    </Label>
                    <Controller
                      control={control}
                      name="name_of_work_english"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="name_of_work_english"
                            invalid={errors.name_of_work_english && true}
                          />
                        );
                      }}
                    />
                    {errors.name_of_work_english && (
                      <FormFeedback>
                        {errors.name_of_work_english.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="name_of_work_gujarati">
                      Name of Work (Gujarati)
                    </Label>
                    <Controller
                      control={control}
                      name="name_of_work_gujarati"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="name_of_work_gujarati"
                            disabled
                            invalid={errors.name_of_work_gujarati && true}
                          />
                        );
                      }}
                    />
                    {errors.name_of_work_gujarati && (
                      <FormFeedback>
                        {errors.name_of_work_gujarati.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="chainage_from_to">
                      Chainage From - To
                    </Label>
                    <Controller
                      control={control}
                      name="chainage_from_to"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="chainage_from_to"
                            // value={field.value}

                            invalid={errors.chainage_from_to && true}
                          />
                        );
                      }}
                    />
                    {errors.chainage_from_to && (
                      <FormFeedback>
                        {errors.chainage_from_to.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/*  Length In KM */}
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="length_in_km">
                      Length In KM
                    </Label>
                    <Controller
                      control={control}
                      name="length_in_km"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="length_in_km"
                            //   placeholder="Enter your Email id/Username"
                            // value={field.value}
                            invalid={errors.length_in_km && true}
                          />
                        );
                      }}
                    />
                    {errors.length_in_km && (
                      <FormFeedback>{errors.length_in_km.message}</FormFeedback>
                    )}
                  </Col>
                  {/* Proposed Work */}
                  <Col md={4} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="proposed_work_name">
                      Proposed Work
                    </Label>
                    <Controller
                      control={control}
                      name="proposed_work_name"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            disabled
                            id="proposed_work_name"
                            //   placeholder="Enter your Email id/Username"
                            // value={field.value}
                            invalid={errors.proposed_work_name && true}
                          />
                        );
                      }}
                    />
                    {errors.proposed_work_name && (
                      <FormFeedback>
                        {errors.proposed_work_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <hr />
                  {/*  Pragati G Code */}
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="pragati_gcode">
                      Pragati G Code
                    </Label>
                    <Controller
                      control={control}
                      name="pragati_gcode"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            id="pragati_gcode"
                            invalid={errors.pragati_gcode && true}
                          />
                        );
                      }}
                    />
                    {console.log("errors:>>>>>", errors)}
                    {errors.pragati_gcode && (
                      <FormFeedback className="d-block">
                        {errors.pragati_gcode.message}
                      </FormFeedback>
                    )}
                  </Col>

                  {/* Taluka */}
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="taluka_id">
                      Taluka
                    </Label>
                    <Controller
                      id="taluka_id"
                      control={control}
                      name="taluka_id"
                      render={({ field }) => (
                        <Select
                          // defaultMenuIsOpen
                          {...field}
                          options={TalukaDropDownOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                          value={TalukaDropDownOption?.find(
                            (item) => item?.id === watch("taluka_id")
                          )}
                          onChange={(e) => {
                            field.onChange(e.id);
                          }}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                      // onChange={(e) => field.onChange(e)}
                    />
                    {errors?.taluka_id && (
                      <FormFeedback className="d-block">
                        {errors.taluka_id.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="subdivision_id">
                      Sub Division
                    </Label>
                    <Controller
                      id="subdivision_id"
                      control={control}
                      name="subdivision_id"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={subDivisionDropDownOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.division_name}
                          classNamePrefix="select"
                          className="react-select"
                          value={subDivisionDropDownOption?.find(
                            (item) => item?.id === watch("subdivision_id")
                          )}
                          onChange={(e) => field.onChange(e.id)}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                    />

                    {errors.subdivision_id && (
                      <FormFeedback className="d-block">
                        {errors.subdivision_id.message}
                      </FormFeedback>
                    )}
                  </Col>

                  {/* Category of Road */}
                  <Col md={3} xs={12} className="mb-1 mt-0">
                    {/*----------------Pending----------------*/}
                    <Label className="form-label" for="categoryroad_id">
                      Category of Road
                    </Label>
                    <Controller
                      id="categoryroad_id"
                      control={control}
                      name="categoryroad_id"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={categoryofRoadDropDownOption}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                          value={categoryofRoadDropDownOption?.find(
                            (item) => item?.id === watch("categoryroad_id")
                          )}
                          onChange={(e) => {
                            field.onChange(e.id);
                          }}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                    />
                    {errors.categoryroad_id && (
                      <FormFeedback className="d-block">
                        {errors.categoryroad_id.message}
                      </FormFeedback>
                    )}
                  </Col>

                  {/*  MP Constituency */}
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="mp_constituency">
                      MP Constituency
                    </Label>
                    <Controller
                      id="mp_constituency"
                      control={control}
                      name="mp_constituency"
                      render={({ field }) => (
                        <Select
                          {...field}
                          // isClearable
                          options={mpConstituencyDropDownOption?.all_data}
                          getOptionValue={(opt) => opt.consti_no}
                          getOptionLabel={(opt) => opt.constituency_name_eng}
                          classNamePrefix="select"
                          className="react-select"
                          value={mpConstituencyDropDownOption?.all_data?.find(
                            (item) =>
                              item?.consti_no === watch("mp_constituency")
                          )}
                          onChange={(e) => {
                            field.onChange(e?.consti_no);
                            // setValue("mp_constituency", e?.consti_no);
                            handleMPConstituencyChange(1);
                          }}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                    />
                    {errors.mp_constituency && (
                      <FormFeedback className="d-block">
                        {errors.mp_constituency.message}
                      </FormFeedback>
                    )}
                  </Col>

                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="mp_name">
                      MP Name (Member of Parliament)
                    </Label>
                    <Controller
                      id="react-select"
                      control={control}
                      name="mp_name"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled
                          isClearable={false}
                          options={mpNameDropDownOption?.all}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          classNamePrefix="select"
                          className="react-select"
                        />
                      )}
                    />
                    {errors.mp_name && (
                      <FormFeedback className="d-block">
                        {errors.mp_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="mla_constituency">
                      MLA Constituency
                    </Label>
                    <Controller
                      id="mla_constituency"
                      control={control}
                      name="mla_constituency"
                      render={({ field }) => (
                        // ** pending isClearable
                        <Select
                          // isClearable
                          options={mlaConstituencyDropDownOption?.all_data}
                          getOptionValue={(opt) => opt.consti_no}
                          getOptionLabel={(opt) => opt.constituency_name_eng}
                          classNamePrefix="select"
                          className="react-select"
                          value={mlaConstituencyDropDownOption?.all_data?.find(
                            (item) =>
                              item?.consti_no === watch("mla_constituency")
                          )}
                          onChange={(e) => {
                            field.onChange(e.consti_no);
                            handleMLAConstituencyChange(1);
                          }}
                          isDisabled={isFieldsetDisabled}
                        />
                      )}
                    />
                    {errors.mla_constituency && (
                      <FormFeedback className="d-block">
                        {errors.mla_constituency.message}
                      </FormFeedback>
                    )}
                  </Col>
                  <Col md={6} xs={12} className="mb-1 mt-0">
                    {/*----------------Pending----------------*/}
                    <Label className="form-label" for="mla_name">
                      MLA Name (Member of Legislative Assembly)
                    </Label>
                    <Controller
                      id="mla_name"
                      control={control}
                      name="mla_name"
                      render={({ field }) => (
                        <Select
                          isDisabled
                          isClearable
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          options={mlaNameDropDownOption?.all}
                          classNamePrefix="select"
                          className="react-select"
                          {...field}
                          invalid={errors.mla_name && true}
                        />
                      )}
                    />
                    {errors.mla_name && (
                      <FormFeedback className="d-block">
                        {errors.mla_name.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/* not define */}
                  <Col md={12} xs={12} className="mb-1 mt-0">
                    <Label className="form-label" for="treatment">
                      Details of Proposed Work
                    </Label>
                    <Controller
                      control={control}
                      name="treatment"
                      render={({ field }) => {
                        return (
                          <Input
                            type="textarea"
                            rows="3"
                            {...field}
                            id="treatment"
                            className="gujarati-bg"
                            invalid={errors.treatment && true}
                          />
                        );
                      }}
                    />
                    {errors.treatment && (
                      <FormFeedback>{errors.treatment.message}</FormFeedback>
                    )}
                  </Col>
                  <Col xs={12}>
                    <Button
                      className="btn btn-outline-dark waves-effect me-25"
                      type="reset"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </Form>
          </CardBody>
        </Card>
      </UILoader>
    </>
  );
};

export default BasicDetails;
