import { lazy } from "react";
import { ROLE } from "../../common/utils";

const SEDashboard = lazy(() => import("../../views/SERole/SEDashboard"));
const EditPermission = lazy(() => import("../../views/SERole/EditPermission"));
const NewWork = lazy(() => import("../../views/SERole/NewWork/work"));
const DropDown = lazy(() => import("../../views/SERole/Dropdown/DropDown"));
const OldWork = lazy(() => import("../../views/SERole/OldWork/OldWork"));

// const Locations = lazy(() =>
//   import("../../views/superAdmin/Loactions/Locations")
// );

const SERoutes = [
  {
    path: "/se/dashboard",
    element: <SEDashboard />,
    roles: [ROLE.SE],
  },
  {
    path: "/se/edit_permission",
    element: <EditPermission />,
    roles: [ROLE.SE],
  },
  {
    path: "/se/work",
    element: <NewWork />,
    roles: [ROLE.SE],
  },
  {
    path: "/se/dropdown",
    element: <DropDown />,
    roles: [ROLE.SE],
  },
  {
    path: "/se/old_completed_works_se",
    element: <OldWork />,
    roles: [ROLE.SE],
  },
];

export default SERoutes;
