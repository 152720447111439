/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import {
  getDivisionbyCircle,
  getTalukaListData,
} from "../../services/seServices";

export const talukaSlice = createSlice({
  name: "taluka",
  initialState: {
    isLoading: false,
    talukaloading: false,
    division: [],
    taluka: [],
    error: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    isFectchSuccess(state) {
      state.isLoading = false;
    },
    // GET Division
    getdivisiondata: (state, action) => {
      state.division = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    starttalukaLoading(state) {
      state.talukaloading = true;
    },
    gettaluka: (state, action) => {
      state.taluka = action.payload;
    },
    istalukaFectchSuccess(state) {
      state.talukaloading = false;
    },
  },
});

export const {
  startLoading,
  getdivisiondata,
  isFectchSuccess,
  hasError,
  istalukaFectchSuccess,
  gettaluka,
} = talukaSlice.actions;

export default talukaSlice.reducer;

export const divisionList = (id) => {
  return async () => {
    dispatch(talukaSlice.actions.startLoading());

    try {
      const response = await getDivisionbyCircle(id);
      console.log("response: ", response.data);
      dispatch(talukaSlice.actions.getdivisiondata(response?.data));
      dispatch(talukaSlice.actions.isFectchSuccess());
    } catch (error) {
      dispatch(talukaSlice.actions.hasError(error));
    }
  };
};

export const getTalukaList = (id) => {
  console.log("id: ", id);
  return async () => {
    dispatch(talukaSlice.actions.starttalukaLoading());

    try {
      const response = await getTalukaListData(id);
      dispatch(talukaSlice.actions.gettaluka(response?.data));
      dispatch(talukaSlice.actions.istalukaFectchSuccess());
    } catch (error) {
      dispatch(talukaSlice.actions.hasError(error));
    }
  };
};
