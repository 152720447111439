// /api/adashboard

import authHttp from "./authHttp";

// ** Get Super Admin Data
export const getSuperAdminDashboardData = async () => {
  const url = `/api/dashboard`;
  const { data } = await authHttp.get(url);
  return data;
};

// MP
export const getgetMPList = async () => {
  const url = `/api/getMP`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addMpConstituency = async (value) => {
  const url = `/api/mp-consi/store`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getMpConstyEdit = async (id) => {
  const url = `api/mpConstyEdit/${id}/edit`;
  const { data } = await authHttp.get(url);
  return data;
};

export const MpConstyDelete = async (id) => {
  const url = `api/mpDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const UpdateMpConsty = async (value) => {
  const url = `api/mpConsiUpdate/update`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getMpEdit = async (id) => {
  const url = `/api/mpEdit/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const AddMpDetails = async (value) => {
  const url = `api/mpSave`;
  const { data } = await authHttp.post(url, value);
  return data;
};
export const UpdateMpDetails = async (value) => {
  const url = `/api/mpUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// MLA
export const getMLA = async () => {
  const url = `/api/getMLA`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addMlaConstituency = async (value) => {
  const url = `/api/mla-consi/store`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getMlaConstyEdit = async (id) => {
  const url = `api/mlaConstyEdit/${id}/edit`;
  const { data } = await authHttp.get(url);
  return data;
};
export const UpdateMlaConsty = async (value) => {
  const url = `api/mlaConsiUpdate/update`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getMlaEdit = async (id) => {
  const url = `/api/mlaEdit/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};
export const AddMlaDetails = async (value) => {
  const url = `/api/mlaSave`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const UpdateMlaDetails = async (value) => {
  const url = `/api/mlaUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const MlaConstyDelete = async (id) => {
  const url = `api/mlaDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

// ** Monitaring Stage

export const getMonitaringStageList = async () => {
  const url = `/api/monitoringStage`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteMonitaringStageList = async (id) => {
  const url = `/api/monitoringStageDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addMonitaringStage = async (value) => {
  const url = `/api/addMonitoringStage`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateMonitaringStage = async (value) => {
  const url = `/api/monitoringStageUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** Work Status Module

export const getWorkStatusList = async () => {
  const url = `/api/workStatus`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteWorkStatus = async (id) => {
  const url = `/api/workStatusDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addWorkStatus = async (value) => {
  const url = `/api/addWorkStatus`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateWorkStatus = async (value) => {
  const url = `/api/workStatusUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

//Proposed Work API /api/proposedWork
export const getProposedWorkList = async () => {
  const url = `/api/proposedWork`;

  const { data } = await authHttp.get(url);
  return data;
};

// ** Agency

export const getAgencyList = async () => {
  const url = `/api/agency`;

  const { data } = await authHttp.get(url);
  return data;
};

export const deleteProposedWorkList = async (id) => {
  const url = `/api/proposedWorkDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addProposedWork = async (value) => {
  const url = `/api/addProposedWork`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateProposedWork = async (value) => {
  const url = `/api/proposedWorkUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// TenderType  API
export const getTenderTypeList = async () => {
  const url = `/api/tendertype`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteTenderTypeList = async (id) => {
  const url = `/api/tenderTypeDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addTenderType = async (value) => {
  const url = `/api/addTenderType`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateTenderType = async (value) => {
  const url = `/api/tenderTypeUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};
export const deleteAgency = async (id) => {
  const url = `/api/agencyDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addAgency = async (value) => {
  const url = `/api/addAgency`;
  const { data } = await authHttp.post(url, value);
  return data;
};
export const updateAgency = async (value) => {
  const url = `/api/agencyUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

//Category Of Road API
export const getCategoryOfRoadList = async () => {
  const url = `/api/categoryRoad`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteCategoryOfRoadList = async (id) => {
  const url = `/api/categoryRoadDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addCategoryOfRoad = async (value) => {
  const url = `/api/addCategoryRoad`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateCategoryOfRoad = async (value) => {
  const url = `/api/categoryRoadUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

//SCHEME OF BUDGET API
export const getSchemeOfBudgetList = async () => {
  const url = `/api/schemeOfBudget`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteSchemeOfBudgetList = async (id) => {
  const url = `/api/schemeOfBudgetDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addSchemeOfBudget = async (value) => {
  const url = `/api/addSchemeOfBudget`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateSchemeOfBudget = async (value) => {
  const url = `/api/schemeOfBudgetUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

//BUDGET HEAD APIs
export const getBudgetHeadList = async () => {
  const url = `/api/budgetHead`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteBudgetHeadList = async (id) => {
  const url = `/api/budgetHeadDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addBudgetHead = async (value) => {
  const url = `/api/addBudgetHead`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateBudgetHead = async (value) => {
  const url = `/api/budgetHeadUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

//**  Location Api
export const getCircleList = async (id) => {
  const url = `/api/circleCascade/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getSubDivisionList = async (id) => {
  const url = `/api/divisionCascade/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getceRoles = async () => {
  const url = `/api/ceRoles`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addCircle = async (value) => {
  const url = `/api/add-Circle`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateCircleUser = async (value) => {
  const url = `/api/circle/update`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getCircleUser = async (id) => {
  const url = `/api/circle/${id}/edit`;
  const { data } = await authHttp.get(url);
  return data;
};

// /

//SecretaryUsers APIs
export const getSecretaryUserList = async () => {
  const url = `/api/secretoryUser`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteSecretaryUserList = async (id) => {
  const url = `/api/secretoryUserDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addSecretaryUser = async (value) => {
  const url = `/api/addSecretoryUser`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateSecretaryUser = async (value) => {
  const url = `/api/secretoryUserUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const changeSecretaryUserPassword = async (value) => {
  const url = `/api/secretoryUserChangePassword`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// Government User APIs

export const changeGovernmentUserPassword = async (value) => {
  const url = `/api/governmentUserChangePassword`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const addGovernmentUser = async (value) => {
  const url = `/api/addGovernmentUser`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const deleteGovernmentUserList = async (id) => {
  const url = `/api/governmentUserDelete/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getGovernmentUserList = async () => {
  const url = `/api/governmentUser`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateGovernmentUser = async (value) => {
  const url = `/api/governmentUserUpdate`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** add-division API
export const addDivision = async (value) => {
  const url = `/api/add-division`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getDivisionUser = async (id) => {
  const url = `/api/division/${id}/edit`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateDivisionUser = async (value) => {
  const url = `/api/division/update`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// ** add-subdivision API
export const addSubDivision = async (value) => {
  const url = `/api/add-sub-division`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getSubDivisionUser = async (id) => {
  const url = `/api/sub-division/${id}/edit`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateSubDivisionUser = async (value) => {
  const url = `/api/sub-division/update`;
  const { data } = await authHttp.post(url, value);
  return data;
};

// /api/division/55/edit

// EditProfile
export const UpdateProfile = async (value) => {
  const url = `/api/updateadmindetails/${value.id}`;
  const { data } = await authHttp.post(url, value);
  return data;
};
export const getEditUserList = async () => {
  const url = `/api/editProfile`;
  const { data } = await authHttp.get(url);
  return data;
};

export const columnChanges = async (value) => {
  const url = `/api/columnchanges`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getColumnlist = async (value) => {
  const url = `/api/column-list`;
  const { data } = await authHttp.post(url, value);
  return data;
};
