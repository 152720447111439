import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "../../../components/ReactDatePicker/index";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
// import moment from "moment";
import { toast } from "react-toastify";
import { getErrorMsg } from "../../../../common/ErrorMessage";
import { updateWorkRevisedAArequired } from "../../../../services/reportsServices";
import { YMDdate, ROLE } from "../../../../common/utils";
import { getUserData } from "../../../../utility/Utils";
import { getWorkTabsData } from "../../../../redux/WorkRedux/getWorkSlice";

const WorkStatusAARequired = ({ updatedate }) => {
  const workData = useSelector((state) => state?.getWork);
  const currentUserRole = getUserData()?.role;
  const dispatch = useDispatch();
  const WorkStatusData = yup.object().shape({
    date_raa: yup.string().when("allow_target_radio", {
      is: "yes",
      then: yup.string().required("Select Date of RAA"),
    }),
    amt_raa: yup
      .string()
      .when("allow_target_radio", {
        is: "yes",
        then: yup
          .string()
          .matches(
            /^(?!0(\.0{1,2})?$)\d{0,10}(\.\d{0,2})?$/,
            "Amount of RAA should not exceed 10 Digits"
          )
          .required("Enter Amount of RAA")
          .nullable(),
      })
      .nullable(),
  });

  // ** Hooks
  const {
    reset,
    control,
    watch,
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(WorkStatusData),
  });

  const dateraa = watch("date_raa");

  const { ref: allow_target_radio_select, ...allow_target_radioVal } = register(
    "allow_target_radio",
    {
      required: true,
    }
  );
  console.log("error_message__", errors);
  const handleDateChangeDateraa = (date) => {
    // setDateraa(date);
    if (date === null) {
      setValue("date_raa", null);
      setError("date_raa", {
        type: "manual",
        message: "Select Date of RAA",
      });
    } else if (
      moment(workData?.data?.work_order_date).isAfter(date, "YYYY-MM-DD") ===
      true
    ) {
      setValue("date_raa", date);
      setError("date_raa", {
        type: "manual",
        message: "Date of RAA should be after the Work order date.",
      });
    } else {
      setValue("date_raa", date);
      setError("date_raa", "");
    }
  };

  const RevisedAAonSubmit = (data) => {
    console.log("RevisedAAonSubmit: ", data);
    const sendAPIData = {
      work_id: workData?.data?.id,
      revised_aa_required: watch("allow_target_radio"),
      date_raa: data.date_raa ? YMDdate(data.date_raa) : null,
      amt_raa: data.amt_raa,
    };

    updateWorkRevisedAArequired(sendAPIData)
      .then((res) => {
        toast.success(res?.message);
        dispatch(getWorkTabsData(updatedate));
      })
      .catch((err) => getErrorMsg(err));
  };

  useEffect(() => {
    if (workData?.data) {
      reset({
        date_raa: workData?.data?.date_raa ? workData?.data?.date_raa : "",
        amt_raa: workData?.data?.amt_raa ? workData?.data?.amt_raa : "",

        allow_target_radio: workData?.data?.revised_aa_required || "",
      });
    }
  }, [workData?.data]);

  const isFieldsetDisabled = currentUserRole === ROLE.CE;

  return (
    <div>
      <Row>
        <Form>
          <fieldset disabled={isFieldsetDisabled}>
            <Row>
              <Col md={4} xs={12} className="mt-0">
                <Label className="form-label" for="division_name">
                  Revised AA Required for this Project?
                </Label>
                <div className="demo-inline-spacing checkbox">
                  <div className="custom-control custom-radio">
                    <Input
                      innerRef={allow_target_radio_select}
                      {...allow_target_radioVal}
                      type="radio"
                      id="yes"
                      value="yes"
                      name="allow_target_radio"
                      // checked={selectedRevisedAAOption === "yes"}
                      // onChange={(event) =>
                      //   setSelectedRevisedAAOption(event.target.id)
                      // }
                    />
                    <label
                      className="ms-50 custom-control-label line-height-inherit"
                      htmlFor="yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <Input
                      innerRef={allow_target_radio_select}
                      {...allow_target_radioVal}
                      type="radio"
                      id="no"
                      value="no"
                      name="allow_target_radio"
                      // checked={selectedRevisedAAOption === "no"}
                      onChange={
                        () => {
                          setValue("date_raa", "");
                          setValue("amt_raa", "");
                          setValue("allow_target_radio", "no");
                        }

                        // setSelectedRevisedAAOption(event.target.id)
                      }
                    />
                    <label
                      className="ms-50 custom-control-label line-height-inherit"
                      htmlFor="no"
                    >
                      No
                    </label>
                  </div>
                </div>
              </Col>
              {watch("allow_target_radio") === "yes" && (
                <Col md={8} xs={12}>
                  <Row>
                    <Col md={6} xs={12} className="DatePicker-w100">
                      <Label className="form-label" for="date_raa">
                        Date of RAA
                      </Label>
                      <Controller
                        control={control}
                        name="date_raa"
                        render={({ field }) => (
                          <ReactDatePicker
                            {...field}
                            selected={dateraa || ""}
                            placeholder="DD-MM-YYYY"
                            onChange={handleDateChangeDateraa}
                          />
                        )}
                      />
                      {errors.date_raa && (
                        <FormFeedback className="d-block">
                          {errors.date_raa.message}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col md={6} xs={12}>
                      <Label className="form-label" for="amt_raa">
                        Amount of RAA.
                      </Label>
                      <Controller
                        control={control}
                        name="amt_raa"
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              id="amt_raa"
                              //   placeholder="Enter your Email id/Username"
                              // value={field.value}
                              invalid={errors.amt_raa && true}
                              onChange={(e) => {
                                const pattern =
                                  /^(?!0(\.0{1,2})?$)\d{0,25}(\.\d{0,2})?$/;
                                if (pattern.test(e.target.value)) {
                                  field.onChange(e);
                                }
                              }}
                            />
                          );
                        }}
                      />
                      {errors.amt_raa && (
                        <FormFeedback>{errors.amt_raa.message}</FormFeedback>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xs={12} className=" mt-50">
                <Button
                  className="btn btn-outline-dark waves-effect me-25"
                  type="reset"
                  outline
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit(RevisedAAonSubmit)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </Row>
    </div>
  );
};

export default WorkStatusAARequired;
