// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Label,
  Button,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { User, Check, X } from "react-feather";
import { useForm, Controller } from "react-hook-form";

// ** Utils
// import { selectThemeColors } from '@utils'

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { yupResolver } from "@hookform/resolvers/yup";

//Yup validation
const AddNewPlantData = yup.object().shape({
  tender_type: yup.string().required("Enter tender type").typeError(""),
});

const AddNewPlant = () => {
  // ** States
  const [show, setShow] = useState(false);

  // ** Hooks
  const {
    // reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddNewPlantData),
  });
  const onSubmit = (data) => {
    console.log("AddNewPlant>>", data);
  };

  const options_plant = [
    { value: "NH", label: "NH" },
    { value: "STATE", label: "STATE" },
  ];
  return (
    <Fragment>
      <Card>
        <CardBody className="text-center">
          <User className="font-large-2 mb-1" />
          <CardTitle tag="h5">Add New Plant For EE-User</CardTitle>
          <Button color="primary" onClick={() => setShow(true)}>
            Show
          </Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-xl"
      >
        <ModalHeader
          className="bg-transparent custom-modal-yellow p-1"
          toggle={() => setShow(!show)}
        >
          {/* <h4 className="modal-title" id="myModalLabel16">
            Add New Plant
          </h4> */}
          {/* <div className="modal-header modal-popup">
            <h4 className="modal-title" id="myModalLabel16">
              Add New Plant
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div> */}
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="text-center pb-1 custom-modal-yellow">
            <h1 className="mb-1">Add New Plant</h1>
          </div>
          <Form className="px-2 pt-2" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <label className="custom-label-new-plant">New Entry</label>
                <div className="demo-inline-spacing checkbox">
                  <div className="custom-control custom-radio">
                    <Input
                      type="radio"
                      id="New_Entry_1"
                      name="Land_Acquisition_radio"
                      className="custom-control-input"
                    />
                    <Label
                      className="ms-50 form-check-label custom-control-label"
                      for="New_Entry_1"
                    >
                      Main Work
                    </Label>
                  </div>
                  <div className="custom-control custom-radio">
                    <Input
                      type="radio"
                      id="New_Entry_2"
                      name="Land_Acquisition_radio"
                      className="custom-control-input"
                    />
                    <Label
                      className="ms-50 form-check-label custom-control-label"
                      for="New_Entry_2"
                    >
                      Patch work / Paver Patta / FDIR Work
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="gy-1 mt-1 mb-2">
              {/* IF Main Work Selected =========================================== */}
              <Col md={3} xs={12} className="mb-1 mt-0">
                <Label className="form-label" for="work_name">
                  કામનું નામ
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="work_name"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={options_plant}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      // value={field.value}
                      invalid={errors.work_name && true}
                      placeholder="Select Work"
                    />
                  )}
                />
                {errors.work_name && (
                  <FormFeedback>{errors.work_name.message}</FormFeedback>
                )}
              </Col>
              {/* IF Main Work Selected =========================================== */}
              {/* IF Patch work Selected =========================================== */}
              {/*  <Col md={3} xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label custom-label-new-plant"
                  for="work_name_patch"
                >
                  કામનું નામ
                </Label>
                <Controller
                  control={control}
                  name="work_name_patch"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="work_name_patch"
                        // value={field.value}
                        invalid={errors.work_name_patch && true}
                      />
                    );
                  }}
                />
                {errors.work_name_patch && (
                  <FormFeedback>{errors.work_name_patch.message}</FormFeedback>
                )}
              </Col> */}
              {/* IF Patch work Selected =========================================== */}
              {/* IF Main Work Selected field disable =========================================== */}
              <Col md={3} xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label custom-label-new-plant"
                  for="work_length_km"
                >
                  કામગીરીની લંબાઈ - કી.મી.
                </Label>
                <Controller
                  control={control}
                  name="work_length_km"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="work_length_km"
                        // value={field.value}
                        invalid={errors.work_length_km && true}
                      />
                    );
                  }}
                />
                {errors.work_length_km && (
                  <FormFeedback>{errors.work_length_km.message}</FormFeedback>
                )}
              </Col>
              {/* IF Main Work Selected field disable =========================================== */}
              {/* IF Patch work Selected  =========================================== */}
              <Col md={3} xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  ખાનગી / સરકારી પ્લાન્ટ
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="private_govt_patch_plan"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={options_plant}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      // value={field.value}
                      placeholder="Please select"
                      invalid={errors.private_govt_patch_plan && true}
                    />
                  )}
                />
                {errors.private_govt_patch_plan && (
                  <FormFeedback>
                    {errors.private_govt_patch_plan.message}
                  </FormFeedback>
                )}
              </Col>
              {/* IF Path Work Selected =========================================== */}
              {/* IF Main Work Selected field disable=========================================== */}
              {/* <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  ખાનગી / સરકારી પ્લાન્ટ
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col> */}
              {/* IF Patch work Selected =========================================== */}
              <Col md={3} xs={12} className="mb-1 mt-0">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  ઇજારદારનું નામ
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="mla_name"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={options_plant}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      // value={field.value}
                      placeholder="Please select"
                      invalid={errors.mla_name && true}
                    />
                  )}
                />
                {errors.mla_name && (
                  <FormFeedback>{errors.mla_name.message}</FormFeedback>
                )}
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  ઇજારદારનાં સંબંધિત વ્યક્તિનું નામ
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        // placeholder="Enter Tender Type"
                        // value={field.value}
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  ઇજારદારનાં સંબંધિત વ્યક્તિનો મોબાઇલ નંબર
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        // placeholder="Enter Tender Type"
                        // value={field.value}
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  પ્લાન્ટનું લોકેશન
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        // placeholder="Enter Tender Type"
                        // value={field.value}
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  પ્લાન્ટનો SCADA કોડ
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        // placeholder="Enter Tender Type"
                        // value={field.value}
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col>

              <Col md={3} xs={12} className="my-1">
                <label className="custom-label-new-plant">
                  SCADA પર વિગતો આવે છે?
                </label>
                <div className="demo-inline-spacing checkbox">
                  <div className="custom-control custom-radio">
                    <Input
                      type="radio"
                      id="SCADA_1"
                      name="SCADA_radio"
                      className="custom-control-input"
                    />
                    <Label
                      className="ms-50 form-check-label custom-control-label"
                      for="SCADA_1"
                    >
                      હા
                    </Label>
                  </div>
                  <div className="custom-control custom-radio">
                    <Input
                      type="radio"
                      id="SCADA_2"
                      name="SCADA_radio"
                      className="custom-control-input"
                    />
                    <Label
                      className="ms-50 form-check-label custom-control-label"
                      for="SCADA_2"
                    >
                      ના
                    </Label>
                  </div>
                </div>
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label className="form-label" for="tender_type">
                  બેચ મિક્સ પ્લાન્ટ / ડ્રમ મિક્સ પ્લાન્ટ
                </Label>
                <Controller
                  id="react-select"
                  control={control}
                  name="mla_name"
                  render={({ field }) => (
                    <Select
                      isClearable
                      options={options_plant}
                      classNamePrefix="select"
                      className="react-select"
                      {...field}
                      placeholder="Please select"
                      invalid={errors.mla_name && true}
                    />
                  )}
                />
                {errors.mla_name && (
                  <FormFeedback>{errors.mla_name.message}</FormFeedback>
                )}
              </Col>
              <Col md={3} xs={12} className="mb-1 mt-1">
                <Label
                  className="form-label custom-label-new-plant"
                  for="tender_type"
                >
                  પેવરની સંખ્યા
                </Label>
                <Controller
                  control={control}
                  name="tender_type"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="tender_type"
                        // placeholder="Enter Tender Type"
                        // value={field.value}
                        invalid={errors.tender_type && true}
                      />
                    );
                  }}
                />
                {errors.tender_type && (
                  <FormFeedback>{errors.tender_type.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} className="modal-footer add_plant pe-1">
                <Button
                  className="btn btn-outline-dark waves-effect"
                  type="reset"
                  outline
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewPlant;
