/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
// * React Import
import React, { useEffect, useMemo, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// * Redux
import { useSelector } from "react-redux";

// * React Select
import Select from "react-select";

// * React Table
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// * ReactStrap
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";

// * Utils
import {
  DMYFormat,
  ROLE,
  SelectCustomStyles,
  dateFormate,
} from "../../../../common/utils";

// * Images And SVG
import ColumnsVisibility from "../../../../assets/images/ColumnsVisibility.png";
import Excel from "../../../../assets/images/excel.png";
import Print from "../../../../assets/images/print.png";
import {
  FilterSvg,
  SortArrowDown,
  SortArrowtop,
} from "../../../../assets/images";

// * React Paginagte
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import moment from "moment";
import ReactToPrint from "react-to-print";

// * Services - API's
import {
  columnChanges,
  getColumnlist,
} from "../../../../services/adminDashboard";
import { getTimeOverRunP2Report } from "../../../../services/reportsServices";

// * Common Component
import PrintTable from "../../PrintTable";
import { getErrorMsg } from "../../../../common/ErrorMessage";

// * Filter For Report
import FilterInProgressAll from "./FilterTimeOverRunP2";
import { getHeaderColumnsByRole } from "./timeOverRunUtils";
import FilterInputManageRole from "../../common/FilterInputManageRole";
import { Controller, useForm } from "react-hook-form";
import { getDivisionListWOID } from "../../../../services/ceServices";
import { getDivisionbyCircle } from "../../../../services/seServices";

// **  Column Freeze as per Login Role
const columnsFreeze = new Map([
  [ROLE.SECRETORY, 5],
  [ROLE.CE, 5],
  [ROLE.SE, 5],
  [ROLE.EE, 4],
  [ROLE.DEE, 4],
]);

const TimeOverRunP2 = () => {
  const loginUser = useSelector((state) => state.auth.userData.data);

  const navigate = useNavigate();

  // ** Function for change Data Structure and show in table
  function addDuplicateForObjectWithSpecificValue(dataArray) {
    const result = [];

    // * Remove duplicate package ids
    const seenPackageIds = {};
    const data = dataArray.filter((item) => {
      if (item.package_id > 0) {
        if (!seenPackageIds[item.package_id]) {
          seenPackageIds[item.package_id] = true;
          return true;
        }
        return false;
      } else {
        return true;
      }
    });

    let sr_no = 1;
    for (const obj of data) {
      if (obj.govtplan_bytender === "govt") {
        result.push({
          ...obj,
          name_of_work_title: "Government Plant",
          dtp_approval_date: obj.govplan_date,
          dtp_amount: obj.govplan_amount,
          tentative_tpc: obj.govt_total_project_cost,
          tender_app_amount:
            obj.govplan_amount === null ? "--" : obj.govplan_amount,
          tender_app_date: obj.govplan_date,
          sr_no: sr_no++,
          above_below: "--",
          agency_gujarati: "સરકારી પ્લાન્ટ",
          agency: "Govt. Plant",
          work_order_date: "--",
          date_of_completion_as_per_wo: "--",
          likely_date_of_completion: "--",
          date_of_completion_as_per_approved_timelimit: "--",
          tender_expen_incured_last_fy_year:
            obj.govt_expen_incured_last_fy_year,
          tender_spill_over_this_fy_year_n_current_year_approved_work:
            obj.govt_spill_over_this_fy_year_n_current_year_approved_work,
          tender_target_expenditure_of_current_fy_year:
            obj.govt_target_expenditure_of_current_fy_year,
          tender_current_year_upto_today: obj.govt_current_year_upto_today,
          tender_probable_expen_from_today_to_end_of_this_fy_year:
            obj.govt_probable_expen_from_today_to_end_of_this_fy_year,
          tender_total_expenditure_as_on_today_on_this_project:
            obj.govt_total_expenditure_as_on_today_on_this_project,
          workstatus_detail: obj.govt_plan_work_detail,
        });
        result.push({
          ...obj,
          // id: "",
          name_of_work_title: "Furniture+Misc.",
          unique_code: "--",
          division_name: "--",
          subdivision_name: "--",
          budget_heads_name: "--",
          scheme_of_budgets_name: "--",
          chainage_from_to: "--",
          length_in_km: "--",
          proposed_work: "--",
          widening_cw: "--",
          paa_amount: "--",
          paa_date: "--",
          paa_amount: "--",
          paa_aa_date: "--",
          ts_amount: "--",
          ts_date: "--",
          sr_no: "",
          tentative_tpc: obj.tender_total_project_cost,
          per_above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
          time_over_due_to_department_other: "--",
          time_over_due_to_agency: "--",
          time_over_action_taken_on_agency: "--",
        });
      } else if (obj.package_id !== 0 && obj.package_id !== null) {
        result.push({
          ...obj,
          name_of_work_title: "Package",
          sr_no: sr_no++,
          per_above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
        obj.sub_work &&
          obj.sub_work.map((row) =>
            result.push({
              ...row,
              name_of_work_title: "",
              sr_no: "",
              dtp_amount: "--",
              dtp_approval_date: "--",
              tender_app_amount: "--",
              tender_app_date: "--",
              per_above_below: "--",
              agency: "--",
              work_order_date: "--",
              date_of_completion_as_per_wo: "--",
              likely_date_of_completion: "--",
              tender_expen_incured_last_fy_year: "--",
              tender_spill_over_this_fy_year_n_current_year_approved_work: "--",
              tender_target_expenditure_of_current_fy_year: "--",
              tender_current_year_upto_today: "--",
              tender_probable_expen_from_today_to_end_of_this_fy_year: "--",
              workstatus_detail: "--",
              time_over_due_to_department_other: "--",
              time_over_due_to_agency: "--",
              time_over_action_taken_on_agency: "--",
            })
          );
        // }
      } else {
        result.push({
          ...obj,

          name_of_work_title: "",
          sr_no: sr_no++,
          per_above_below:
            obj.per_above_below === null
              ? ""
              : `${obj.per_above_below}\n${obj.above_below}`,
        });
      }
    }
    return result;
  }

  //**  States

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [frozenColumn] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [divisionOption, setDivisionOption] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  // * Pagination
  const [paginationValues, setPaginationValues] = useState({
    currentPage: 1,
    rowPerPage: 50,
    AllPages: 1,
  });

  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: "all", label: "All" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // ** Get List Of Tender Type

  useEffect(() => {
    // handleApi();
    setIsLoading(true);
    const val = {
      page: paginationValues.currentPage,
      per_page: paginationValues.rowPerPage,
    };
    getTimeOverRunP2Report(val)
      .then((res) => {
        setPaginationValues({
          ...paginationValues,
          AllPages: res.data.last_page,
        });

        // setTableData(res?.data?.data);
        const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        getErrorMsg(err);
        setIsLoading(false);
      });
  }, [paginationValues.currentPage, paginationValues.rowPerPage]);

  const getFilterData = React.useCallback(
    (data) => {
      const value = {
        ...data,
        page: paginationValues.currentPage,
        per_page: paginationValues.rowPerPage,
      };

      getTimeOverRunP2Report(value)
        .then((res) => {
          const data = addDuplicateForObjectWithSpecificValue(res?.data?.data);
          setTableData(data);
        })
        .catch((err) => {
          console.log("data>>>>> 1", err);
          getErrorMsg(err);
        });
    },
    [getTimeOverRunP2Report]
  );

  // * For filter DATA ===========
  const [canvasOpenData, setCanvasOpenData] = useState({
    isOpen: false,
  });

  //  * Filter Canvas Open
  const handleFilterOffset = () => {
    setCanvasOpenData({ isOpen: true });
  };

  // * For english/gujrati button
  const [searchParams] = useSearchParams();
  const [engGujState, setEngGujState] = useState(false);

  useEffect(() => {
    const watchRoute = searchParams.get("lang");
    watchRoute === "1" ? setEngGujState(true) : setEngGujState(false);
  }, [searchParams.get("lang")]);

  const handleGujaratiEnglish = () => {
    setEngGujState(!engGujState);
    navigate({
      pathname: "/report_time_over_run_p_2",
      search: createSearchParams({
        lang: engGujState ? "0" : "1",
      }).toString(),
    });
  };

  // ** Edit WORK
  const handleEditStage = (data) => {
    const url = `/update-work/${data?.id}`;
    window.open(url, "_blank");
  };

  // ** Dynamic Get Data
  const data = useMemo(() => {
    return tableData.map((item) => ({
      id: item.id,
      sr_no: item.sr_no,
      division_name: item.division_name,
      sub_division_name: item.sub_division_name,
      budget_head: item.budget_heads_name,
      name_of_work: engGujState
        ? `${item.name_of_work_title}\n${item.name_of_work_gujarati}`
        : `${item.name_of_work_title}\n${item.name_of_work_english}`,
      // * PAA
      paa_amount: item.paa_amount,
      paa_date:
        item.paa_date === null
          ? ""
          : item.paa_date === "--"
          ? item.paa_date
          : DMYFormat(item.paa_date),
      // * TS Date
      ts_amount: item.ts_amount,
      ts_date:
        item.ts_date === null
          ? ""
          : item.ts_date === "--"
          ? item.ts_date
          : DMYFormat(item.ts_date),

      // * DTP
      dtp_amount: item.dtp_amount,
      dtp_approval_date:
        item.dtp_approval_date === null
          ? ""
          : item.dtp_approval_date === "--"
          ? item.dtp_approval_date
          : DMYFormat(item.dtp_approval_date),

      tender_app_amount: item.tender_app_amount,

      tender_app_date:
        item.tender_app_date === null
          ? ""
          : item.tender_app_date === "--"
          ? item.tender_app_date
          : DMYFormat(item.tender_app_date),
      per_above_below: item.per_above_below,
      agency: engGujState ? item.agency_gujarati : item.agency,

      work_order_date:
        item.work_order_date === null
          ? ""
          : item.work_order_date === "--"
          ? item.work_order_date
          : DMYFormat(item.work_order_date),

      date_of_completion_as_per_wo:
        item.date_of_completion_as_per_wo === null
          ? ""
          : item.date_of_completion_as_per_wo === "--"
          ? item.date_of_completion_as_per_wo
          : DMYFormat(item.date_of_completion_as_per_wo),

      likely_date_of_completion:
        item.likely_date_of_completion === null
          ? ""
          : item.likely_date_of_completion === "--"
          ? item.likely_date_of_completion
          : DMYFormat(item.likely_date_of_completion),

      //
      tender_expen_incured_last_fy_year: item.tender_expen_incured_last_fy_year,

      tender_spill_over_this_fy_year_n_current_year_approved_work:
        item.tender_spill_over_this_fy_year_n_current_year_approved_work,

      tender_target_expenditure_of_current_fy_year:
        item.tender_target_expenditure_of_current_fy_year,

      tender_current_year_upto_today: item.tender_current_year_upto_today,
      tender_probable_expen_from_today_to_end_of_this_fy_year:
        item.tender_probable_expen_from_today_to_end_of_this_fy_year,

      workstatus_detail: item.workstatus_detail,

      time_over_due_to_department_other: item.time_over_due_to_department_other,
      time_over_due_to_agency: item.time_over_due_to_agency,
      time_over_action_taken_on_agency: item.time_over_action_taken_on_agency,
    }));
  }, [tableData, engGujState]);

  // ** Columns For Table
  const columns = useMemo(
    () => getHeaderColumnsByRole(engGujState, handleEditStage),
    [engGujState]
  );

  // ** UseTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: useMemo(() => data, [data]),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // **
  const dropdownOptions = columns.map((column) => ({
    value: column.accessor,
    label: column.Header,
  }));

  // ** Frozen Column

  function getFrozenColumnLeft(columnIndex) {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (!hiddenColumns.includes(column.accessor)) {
        left += column.minWidth ? parseInt(column.minWidth, 10) : 120;
      }
    }
    return left;
  }

  const handleColumnsApi = (array) => {
    const commaSeparatedString = array.length > 0 ? array.join(",") : "";
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "timeOverRunP2",
      columns: commaSeparatedString,
    };
    columnChanges(updateData).catch((err) => {
      getErrorMsg(err);
    });
  };

  const getHiddenColumnsIndex = (columnsArr) => {
    const data = [];
    for (const row of columnsArr) {
      columns.forEach((item, index) => {
        if (row === item.accessor) {
          data.push(index);
        }
      });
    }
    return data;
  };

  const handleToggleHiddenColumns = (index, value) => {
    if (hiddenColumns.includes(value)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== value));
      const hiddenColIndex = getHiddenColumnsIndex(
        hiddenColumns.filter((column) => column !== value)
      );
      const array = [...hiddenColIndex];
      handleColumnsApi(array);
    } else {
      setHiddenColumns([...hiddenColumns, value]);
      const hiddenColIndex = getHiddenColumnsIndex(hiddenColumns);
      const array = [...hiddenColIndex, index];
      handleColumnsApi(array);
    }
  };

  const getHiddenColumnsFilter = (columnsArr) => {
    const data = [];
    const hiddenCol = columnsArr.split(",");
    const noEmptyValue = hiddenCol.filter((item) => item !== "");
    if (noEmptyValue.length > 0) {
      for (const row of noEmptyValue) {
        const item = columns[row];
        data.push(item.accessor);
      }
    }
    return data;
  };

  useEffect(() => {
    const updateData = {
      user_id: loginUser.user_id,
      module_name: "timeOverRunP2",
    };
    getColumnlist(updateData)
      .then((res) => {
        const hiddenColumns = getHiddenColumnsFilter(res.data.columns);
        setHiddenColumns(hiddenColumns || []);
      })
      .catch((err) => {
        getErrorMsg(err);
      });

    if (loginUser) {
      if (loginUser?.role === ROLE.CE || loginUser?.role === ROLE.SECRETORY) {
        getDivisionListWOID()
          .then((res) => setDivisionOption(res?.data))
          .catch((err) => getErrorMsg(err));
      } else if (loginUser.role === ROLE.SE) {
        getDivisionbyCircle(loginUser.circle_id)
          .then((res) => setDivisionOption(res?.data))
          .catch((err) => getErrorMsg(err));
      }
    }
  }, []);

  const { control, watch } = useForm({});

  return (
    <>
      <div className="table-title  ee_new_work_title">
        <h2 className="text-black">
          {`EXPENDITURE REVIEW : TIME OVER RUN WORKS (P-2)`}
        </h2>

        {dateFormate()}
      </div>
      <Card>
        <CardBody className="basic_tab p-0">
          <Row className="gy-1 px-2 py-50">
            <FilterInputManageRole
              currentRole={loginUser?.role}
              accessibleRoles={[ROLE.CE, ROLE.SE]}
            >
              <Col xs={4} className="mb-1 mt-0 expselet  pt-2">
                <Label
                  for="division"
                  className="form-label filter_label"
                  style={{ fontSize: "17px" }}
                >
                  Select Division
                </Label>
                <Controller
                  id="division"
                  control={control}
                  name="division"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="All"
                      isClearable
                      options={divisionOption}
                      getOptionValue={(opt) => opt.division_id}
                      getOptionLabel={(opt) => opt.division_name}
                      classNamePrefix="select"
                      className="react-select"
                      value={watch("division")}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </Col>
            </FilterInputManageRole>
          </Row>

          <div className="dt-action-button btn_search_box d-flex">
            <div>
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">Excel</span>
              </Button.Ripple>
              <ReactToPrint
                trigger={() => (
                  <Button.Ripple
                    color="primary"
                    className="projects_btn me-25"
                    outline
                  >
                    <img src={Print} alt="icon" className="me-1" />
                    <span className="align-middle">Print</span>
                  </Button.Ripple>
                )}
                content={() => {
                  const props = {
                    title: `EXPENDITURE REVIEW : TIME OVER RUN WORKS (P-2) Dt.${moment().format(
                      "DD-MM-YYYY"
                    )}(Rs in Lacs)`,
                    getTableProps,
                    headerGroups,
                    hiddenColumns,
                    frozenColumn,
                    getFrozenColumnLeft,
                    getTableBodyProps,
                    rows,
                    prepareRow,
                  };
                  PrintTable(props);
                }}
              />
              <Button.Ripple
                color="primary"
                className="projects_btn me-25"
                outline
              >
                <img src={Excel} alt="icon" className="me-1" />
                <span className="align-middle">PDF</span>
              </Button.Ripple>
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="projects_btn me-25"
              >
                <DropdownToggle color="primary" outline caret>
                  <img src={ColumnsVisibility} alt="icon" className="me-1" />
                  <span className="align-middle">Columns Visibility</span>
                </DropdownToggle>
                <DropdownMenu className="p-40 vb-drop column_visibility">
                  {dropdownOptions.map((option, index) => (
                    <Button.Ripple
                      key={option.value}
                      color="primary"
                      onClick={() =>
                        handleToggleHiddenColumns(index, option.value)
                      }
                      className={
                        !hiddenColumns.includes(option.value)
                          ? "active w-100 visibility-Option"
                          : "w-100 visibility-Option bg-white"
                      }
                    >
                      {option.label}
                    </Button.Ripple>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Button.Ripple
                className="me-25"
                onClick={() => handleGujaratiEnglish()}
              >
                <span className="align-middle ms-25">Guj. / Eng.</span>
              </Button.Ripple>
            </div>
            <div className="search_Box d-flex">
              <input
                type="text"
                placeholder="Search"
                className="form-control search_bar"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <Button
                color="primary"
                className="filter-btn-width"
                onClick={() => handleFilterOffset()}
              >
                <FilterSvg />
                <span className="ps-25">Filters</span>
              </Button>
            </div>
          </div>
          <div style={{ overflowX: "auto", maxHeight: "65vh" }}>
            <table
              {...getTableProps()}
              style={{ minWidth: "100%" }}
              className="dataTable table-bordered sticky-header"
            >
              <colgroup>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, columnIndex) => {
                    const isVisible = !hiddenColumns.includes(column.id);
                    return (
                      <col
                        key={columnIndex}
                        style={{
                          visibility: isVisible ? "visible" : "collapse",
                          minWidth: column.minWidth ? column.minWidth : "120px",
                        }}
                      />
                    );
                  })
                )}
              </colgroup>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, columnIndex) => {
                      const isVisible = !hiddenColumns.includes(column.id);
                      const isFrozen =
                        frozenColumn === column.id ||
                        columnIndex < columnsFreeze.get(loginUser?.role);
                      const headerCellClass = classNames({
                        "dtfc-fixed-left": isFrozen,
                        "dtfc-cell": !isFrozen,
                        desc: column.isSorted && column.isSortedDesc,
                        asc: column.isSorted && !column.isSortedDesc,
                      });
                      return (
                        <th
                          {...column.getHeaderProps()}
                          className={headerCellClass}
                          style={{
                            visibility: isVisible ? "visible" : "collapse",
                            position: isFrozen ? "sticky" : "static",
                            left: isFrozen
                              ? `${getFrozenColumnLeft(columnIndex)}px`
                              : "auto",
                            zIndex: 1,
                          }}
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="columnSort"
                          >
                            {isVisible && column.render("Header")}
                            <span className="dataSort">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortArrowDown />
                                ) : (
                                  <SortArrowtop />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        const isVisible = !hiddenColumns.includes(
                          cell.column.id
                        );
                        const isFrozen =
                          frozenColumn === cell.column.id ||
                          cellIndex < columnsFreeze.get(loginUser?.role);
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={
                              isFrozen ? "dtfc-fixed-left" : "dtfc-cell"
                            }
                            style={{
                              visibility: isVisible ? "visible" : "collapse",
                              position: isFrozen ? "sticky" : "static",
                              left: isFrozen
                                ? `${getFrozenColumnLeft(cellIndex)}px`
                                : "auto",
                              zIndex: 0,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && !tableData?.length ? (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : (
              ""
            )}
            {(isLoading === false && tableData?.length === 0) ||
            (isLoading === false && rows.length === 0) ? (
              <div
                style={{
                  border: "1px solid #ebe9f1",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "10px",
                    height: "auto",
                    fontSize: "14px",
                  }}
                >
                  No data available.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="dt_footer">
            <div className="dt_pg_info">
              <span>Show</span>
              <Select
                value={{
                  value: paginationValues.rowPerPage,
                  label:
                    paginationValues.rowPerPage === "all"
                      ? "All"
                      : paginationValues.rowPerPage,
                }}
                styles={SelectCustomStyles}
                options={options}
                onChange={(selectedOption) => {
                  setPaginationValues({
                    ...paginationValues,
                    currentPage: 1,
                    rowPerPage: selectedOption.value,
                  });
                }}
                className="dt_pg_select"
                menuPortalTarget={document.body}
              />

              <span>entries</span>
            </div>

            {/* Paginate*/}
            <ReactPaginate
              nextLabel=""
              pageCount={paginationValues.AllPages}
              previousLabel=""
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={(selected) => {
                setPaginationValues({
                  ...paginationValues,
                  currentPage: selected.selected + 1,
                });
              }}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              containerClassName="pagination react-paginate"
            />
          </div>
        </CardBody>
      </Card>
      {/* Abstract Modal  */}
      {/* {abstractModal?.isOpen && (
        <InProgressTLFiftyPhyTwentyFiveAbstractModal
          abstractModal={abstractModal}
          loginUser={loginUser}
          setAbstractModal={setAbstractModal}
        />
      )} */}
      {/* Filter Canvas Open  */}
      {/* {canvasOpenData?.isOpen && ( */}
      <FilterInProgressAll
        canvasOpenData={canvasOpenData}
        setCanvasOpenData={setCanvasOpenData}
        getFilterData={getFilterData}
        selectedDivisionIds={watch("division")}
      />
      {/* // )} */}
    </>
  );
};

export default TimeOverRunP2;
