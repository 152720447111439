// ** React Imports
import { Outlet } from "react-router-dom";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import navigation from "@src/navigation/vertical";

import LogoMain from "@src/assets/images/logo.png";
import NavbarUser from "../@core/layouts/components/navbar/NavbarUser";
import { useState } from "react";

const VerticalLayout = (props) => {
  const [menuData, setMenuData] = useState("1");
  console.log("menuData: ", menuData);

  const { skin, setSkin } = props;
  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menuData={navigation} {...props}>
      <div className="header-top">
        <div className="row align-items-center h-100 ">
          <div className="col-lg-4 col-md-4 col-xl-3 text-lg-left">
            <img src={LogoMain} className="img-fluid main-logo" alt="logo" />
          </div>
          <div className="col-lg-6 col-md-6 col-xl-6">
            <h2 className="main-title">
              State R & B Database Management System
            </h2>
          </div>
          <div className="col-lg-2 col-md-2 col-xl-3 col-2 top-user-info">
            <ul className="flex-row nav navbar-nav">
              <li className={`nav-item ${menuData === "1" && "active"}`}>
                <button
                  className={`nav-link ${
                    menuData === "2" && "opacity-50"
                  }  btn `}
                  onClick={() => setMenuData("1")}
                  // href="{{ route('roads.dashboard') }}"
                >
                  ROADS
                </button>
              </li>
              <li className={`nav-item ${menuData === "2" && "active"}`}>
                <button
                  className={`nav-link ${
                    menuData === "1" && "opacity-50"
                  }  btn `}
                  onClick={() => setMenuData("2")}
                  // href="{{ route('buildings.dashboard') }}"
                >
                  BUILDINGS
                </button>
              </li>
            </ul>

            <NavbarUser skin={skin} setSkin={setSkin} />
            <div className="dropdown">
              <a
                className="nav-link dropdown-user-link dropdown-toggle p-0"
                id="dropdown-user"
                href="javascript:void(0);"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar bg-light-secondary avatar-lg">
                  {/* <span
                                className="avatar-content">{{ Session::get('user')->role == '1' ? 'SA' : config('constant.role_name.' . Session::get('user')->role) }}</span> */}
                </div>
              </a>

              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown-user"
              >
                <ul className="flex-row nav navbar-nav">
                  <li className="nav-item active">
                    <a className="nav-link" href="javascript:void(0);">
                      ROADS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="javascript:void(0);"
                      style={{ opacity: "0.5" }}
                    >
                      BUILDINGS
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-shadow d-xl-none">
            <ul className="nav navbar-nav d-xl-none">
              <li className="nav-item">
                <a
                  className="nav-link menu-toggle menu-icon"
                  href="javascript:void(0);"
                >
                  <i className="ficon i-menu" data-feather="menu"></i>
                  {/* {{-- show only menu is open --}} */}
                  <i className="ficon i-open" data-feather="x"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
