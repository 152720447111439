import React from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import MainTitle from "../components/MainTitle/MainTitle";
import WorkDetailsTabs from "../Reports/WorkDetails/WorkDetailsTabs";
import { useSelector } from "react-redux";

// { editWork, setEditWork }
const EditWorkModal = ({ editWork, setEditWork }) => {
  const workData = useSelector((state) => state?.getWork);

  return (
    <>
      <Modal
        isOpen={editWork?.isOpen}
        toggle={() => setEditWork({ isOpen: false, data: "" })}
        className="modal-dialog-centered modal-xl edit-work-modal"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setEditWork({ isOpen: false, data: "" })}
        ></ModalHeader>
        <ModalBody className="px-sm-2 mx-50 pb-2">
          <>
            <h1 className="text-center mb-1">Edit Work</h1>
            <MainTitle
              title={workData?.data?.name_of_work_english}
              tpc={workData?.data?.tentative_tpc}
            />
          </>

          <WorkDetailsTabs modal editWork={editWork} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditWorkModal;
