/* eslint-disable object-shorthand */
import { ROLE } from "../../../../../../common/utils";

const currentUserRole = JSON.parse(localStorage.getItem("userData"))?.role;

/* eslint-disable multiline-ternary */
export const getAbstractHeaderColumnsByRole = (engGujState) => {
  const columnsArr = [
    {
      Header: engGujState ? "ક્રમ" : "Sr No",
      accessor: "sr_no",
      show: true,
      // minWidth: "60px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "વર્તુળનું નામ" : "Name of Circle ",
      accessor: "name_Of_circle",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY],
    },
    {
      Header: engGujState ? "વિભાગનું નામ" : "Name of Division ",
      accessor: "name_Of_Division",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE],
    },
    {
      Header: engGujState ? "બજેટ સદર" : "Budget Head",
      accessor: "budget_head",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામની યોજના / બજેટ આઈટમનું નામ"
        : "Total No of Work In Time Over Run > 12 Months",
      accessor: "total_works",
      show: true,
      minWidth: "145px",
      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },

    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Length in KM",
      accessor: "length_in_km",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState ? "કામગીરી ની લંબાઈ (કિ.મી.)" : "Total Project Cost",
      accessor: "totalProjectCost",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
    {
      Header: engGujState
        ? "કામગીરી ની લંબાઈ (કિ.મી.)"
        : "Total current Spill Over",
      accessor: "totalCurrSpillOver",
      show: true,
      minWidth: "98px",

      roles: [ROLE.CE, ROLE.SECRETORY, ROLE.SE, ROLE.EE],
    },
  ];

  return columnsArr.filter((row) => {
    if (row?.roles?.includes(currentUserRole)) return row;
  });
};

export const getAbstractData = (data) => {
  const output = [];
  let last_sum_total_works = 0;
  let last_sum_length_in_km = 0;
  let last_sum_tentative_tpc = 0;
  let last_sum_total_curr_spill_over = 0;

  let sr_no = 1;

  if (currentUserRole === ROLE.CE) {
    for (const circle in data) {
      let circle_name;
      let c_sum_total_works = 0;
      let c_sum_length_in_km = 0;
      let c_sum_tentative_tpc = 0;
      let c_sum_total_curr_spill_over = 0;

      for (const division in data[circle]) {
        let d_sum_total_works = 0;
        let d_sum_length_in_km = 0;
        let d_sum_tentative_tpc = 0;
        let d_sum_total_curr_spill_over = 0;

        for (const schemeOfBudget in data[circle][division]) {
          const budgetHeadsData = data[circle][division][schemeOfBudget][0];

          output.push({
            name_of_circle: circle,
            name_of_division: division,
            scheme_of_budget: schemeOfBudget,
            sr_no: sr_no++,
            ...budgetHeadsData,
          });

          d_sum_total_works += parseFloat(budgetHeadsData.total_works);
          d_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);
          d_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);
          circle_name = budgetHeadsData.circle_name;
          d_sum_total_curr_spill_over += parseFloat(
            budgetHeadsData.total_current_spillover
          );
        }

        output.push({
          bg_color: "#ffc0cb",
          total_works: parseFloat(d_sum_total_works.toFixed(2)),
          length_in_km: parseFloat(d_sum_length_in_km.toFixed(2)),
          tentative_tpc: parseFloat(d_sum_tentative_tpc.toFixed(2)),
          sr_no: "Total",
          total_current_spillover: parseFloat(
            d_sum_total_curr_spill_over.toFixed(2)
          ),
        });

        c_sum_total_works += d_sum_total_works;
        c_sum_length_in_km += d_sum_length_in_km;
        c_sum_tentative_tpc += d_sum_tentative_tpc;
        c_sum_total_curr_spill_over += d_sum_total_curr_spill_over;
      }

      output.push({
        bg_color: "#98fb98",
        total_works: parseFloat(c_sum_total_works.toFixed(2)),
        length_in_km: parseFloat(c_sum_length_in_km.toFixed(2)),
        tentative_tpc: parseFloat(c_sum_tentative_tpc.toFixed(2)),
        sr_no: circle_name,
        total_current_spillover: parseFloat(
          c_sum_total_curr_spill_over.toFixed(2)
        ),
      });

      last_sum_total_works += c_sum_total_works;
      last_sum_length_in_km += c_sum_length_in_km;
      last_sum_tentative_tpc += c_sum_tentative_tpc;
      last_sum_total_curr_spill_over += c_sum_total_curr_spill_over;
    }

    output.push({
      bg_color: "#FFFF00",
      total_works: last_sum_total_works,
      length_in_km: parseFloat(last_sum_length_in_km.toFixed(2)),
      tentative_tpc: parseFloat(last_sum_tentative_tpc.toFixed(2)),

      sr_no: "All Circle Total",
      total_current_spillover: parseFloat(
        last_sum_total_curr_spill_over.toFixed(2)
      ),
    });

    return output;
  } else if (currentUserRole === ROLE.SE) {
    let last_sum_total_works = 0;
    let last_sum_length_in_km = 0;
    let last_sum_tentative_tpc = 0;
    let circle_name;

    for (const division in data) {
      let d_sum_total_works = 0;
      let d_sum_length_in_km = 0;
      let d_sum_tentative_tpc = 0;

      for (const schemeOfBudget in data[division]) {
        const budgetHeadsData = data[division][schemeOfBudget][0];
        output.push({
          name_of_division: division,
          scheme_of_budget: schemeOfBudget,
          sr_no: sr_no++,
          ...budgetHeadsData,
        });

        d_sum_total_works += parseFloat(budgetHeadsData.total_works);

        d_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);

        d_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);
        circle_name = budgetHeadsData.circle_name;
      }

      output.push({
        bg_color: "#ffc0cb",
        total_works: d_sum_total_works,
        length_in_km: d_sum_length_in_km,
        tentative_tpc: d_sum_tentative_tpc,
        sr_no: "Total",
      });

      last_sum_total_works += parseFloat(d_sum_total_works);
      last_sum_length_in_km += parseFloat(d_sum_length_in_km);
      last_sum_tentative_tpc += parseFloat(d_sum_tentative_tpc);
    }
    output.push({
      bg_color: "#98fb98",
      total_works: last_sum_total_works,
      length_in_km: last_sum_length_in_km,
      tentative_tpc: last_sum_tentative_tpc,
      sr_no: circle_name,
    });

    return output;
  } else if (currentUserRole === ROLE.EE) {
    let last_sum_total_works = 0;
    let last_sum_length_in_km = 0;
    let last_sum_tentative_tpc = 0;

    let last_sum_paa_amount_total = 0;
    let last_sum_paa_block_estimates_remaining_to_submit = 0;
    let last_sum_paa_block_estimates_submitted_to_govt_yes = 0;

    // for (const budgetHead in data) {
    for (const schemeOfBudget in data) {
      const budgetHeadsData = data[schemeOfBudget][0];

      output.push({
        // budget_heads_name: budgetHead,
        scheme_of_budget: schemeOfBudget,
        ...budgetHeadsData,
      });

      // last_sum_total_works += budgetHeadsData.total_works;

      last_sum_total_works += parseFloat(budgetHeadsData.total_works);
      last_sum_length_in_km += parseFloat(budgetHeadsData.length_in_km);
      last_sum_tentative_tpc += parseFloat(budgetHeadsData.tentative_tpc);

      last_sum_paa_amount_total += parseInt(budgetHeadsData.paa_amount_total);

      last_sum_paa_block_estimates_remaining_to_submit += parseInt(
        budgetHeadsData.paa_block_estimates_remaining_to_submit
      );
      last_sum_paa_block_estimates_submitted_to_govt_yes += parseInt(
        budgetHeadsData.paa_block_estimates_submitted_to_govt_yes
      );
    }
    // }
    // if (!budgetHeadsData) {
    output.push({
      bg_color: "#ffc0cb",
      // budget_heads_name: "All Heads",
      sr_no: "Total",
      total_works: last_sum_total_works,

      length_in_km: last_sum_length_in_km,
      tentative_tpc: last_sum_tentative_tpc,

      paa_amount_total: last_sum_paa_amount_total,
      paa_block_estimates_remaining_to_submit:
        last_sum_paa_block_estimates_remaining_to_submit,
      paa_block_estimates_submitted_to_govt_yes:
        last_sum_paa_block_estimates_submitted_to_govt_yes,
    });
    // }

    return output;
  }
};
